/* eslint-disable import/no-import-module-exports */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import storeSaga from 'services/store/saga';
import userSaga from 'services/user/saga';
import authSaga from 'services/auth/saga';
import itemSaga from 'services/item/saga';
import themeSaga from 'services/theme/saga';
import templateSaga from 'services/template/saga';
import worldManagementSaga from 'services/world-management/saga';
import channelSaga from 'services/channel/saga';
import { createBrowserHistory } from 'history';
import PublicStoreTemplate from 'routes/public-store/PublicStoreTemplate';
import PublicStore from 'routes/public-store/PublicStore';
import PublicStoreWithEdit from 'routes/public-store/PublicStoreWithEdit';
// import PublicStoreMultiplayer from 'routes/public-store/PublicStoreMultiplayer.doblyio';
import PublicStoreMultiplayer from 'routes/public-store/PublicStoreMultiplayer';
import Home from 'routes/home/Home';
import Onboarding from 'routes/onboarding/onboarding';
import MindAR from 'routes/mind-ar/MindAR';
import MindARFace from 'routes/mind-ar/MindARFace';
import Login from 'routes/login/login';
import Website from 'routes/website/index';
import Theme from './theme';
import store from './store';
// import Keymaker from './routes/keymaker/keymaker';
import InternalTest from './InternalTest';

const LazyDemo = lazy(() => import('./routes/demo/index'));
const LazyWebsite = lazy(() => import('./routes/website/index'));

// import reportWebVitals from './reportWebVitals';
const browserHistory = createBrowserHistory({ window });
const isWebxr = process.env.NODE_ENV !== 'production';

// add service saga to sagaMiddleware
[
  authSaga,
  userSaga,
  storeSaga,
  itemSaga,
  themeSaga,
  templateSaga,
  worldManagementSaga,
  channelSaga,
].map(store.runSaga);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes history={browserHistory}>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/theme" element={<Theme />} />
          <Route path="/website/*" element={<LazyWebsite />} />
          <Route path="/demo/*" element={<LazyDemo />} />
          {/* <Route path="/shopify" element={<ShopifyPage />} />
          <Route path="/shopifyAdmin" element={<ShopifyAdminPage />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/test" element={<InternalTest />} /> */}
          <Route path="/room/*" element={<PublicStoreMultiplayer />} />
          {isWebxr && <Route path="/s-edit/*" element={<PublicStoreWithEdit />} />}
          <Route path="/s/*" element={<PublicStore />} />
          <Route path="/template/*" element={<PublicStoreTemplate />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/mind-ar" element={<MindAR />} />
          <Route path="/mind-ar-face" element={<MindARFace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
  // module.hot.accept();
}
