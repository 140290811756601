/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
require('aframe');
require('aframe-extras');

import React, { useEffect, useState, Suspense } from 'react';
import { compose, bindActionCreators } from 'redux';
import { Entity, Scene } from 'aframe-react';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getStoreByName } from 'services/store/actions';
import { makeSelectStoreCurrent, makeSelectStoreError, makeSelectStoreLoading } from 'services/store/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Alert from '@mui/material/Alert';

import TWEEN from '@tweenjs/tween.js';

import infoPanel from './assets/component/3b4174ec8224442b47b3418553b75556.jpeg';

import Robot from './assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from './assets/bitcoin_icon.png';
import ethIcon from './assets/eth_icon.png';

import './App.css';

import './components/map';
import './components/info-panel';

import './components/chatbot/tour-guide.js';
import './components/chatbot/chatbox-info-panel.js';
import './components/chatbot/chatbox-look-at.js';
import './components/chatbot/chatbox.js';
import './components/popup-panel.js';
import 'components/rounded';

import { makeSelectStoreMap } from './services/store/selectors';


const CustomList = (props) => {
  const { title, description, position, asset, priceList } = props;
  const btc = priceList[0].amount;
  const eth = priceList[1].amount;

  let isModel = asset[0].type !== 'image';
  const ratio = asset[0].ratio;
  const url = asset[0].url;

  return (
    isModel ? 
    <Entity 
      id={title}
      position={position}
      gltf-model={url} 
      scale={`${0.2 * ratio} ${0.2 * ratio} ${0.2 * ratio}`} 
      rotation="0 0 0"
      info-panel={`title:${title};content:${description}; adjustX: 0.5; adjustY: 0.5; btc: ${btc}; eth: ${eth}`}
      animation="property: rotation; from: 0 0 0; to: 0 360 0; loop: true; dur: 5000; easing: linear; dir: normal;" 
    ></Entity> 
    :
    <Entity 
      id={title}
      position={position}
      geometry="primitive: plane; height: 1.25; width: 1.25;"
      material={`src:url(${url}); side: double`}
      rotation="0 0 0"
      info-panel={`title:${title};content:${description}; adjustX: 0.5; adjustY: 0.5; btc: ${btc}; eth: ${eth}`}
      animation="property: rotation; from: 0 0 0; to: 0 360 0; loop: true; dur: 5000; easing: linear; dir: normal;" 
    ></Entity> 
  );
};

CustomList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  // model: PropTypes.string,
  asset: PropTypes.any,
  image: PropTypes.string,
  priceList: PropTypes.any,
};

function InternalTest(props) {
  const { current, error, loading } = props;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const data = urlParams.get('data');

  window.TWEEN = TWEEN;
  const mapHeight = 19;
  const mapWidth = 19;
  const WALL_SIZE = 1.5;
  
  const itemList = JSON.parse(localStorage.getItem('itemList'));
  
  const generateCustomItemList = () => {
    const filteredList = _.filter(itemList, (o) => !_.isEmpty(o.location) && !_.isEmpty(o.color));
    filteredList.forEach((item, index) => {
      const y = Math.floor(item.location / mapWidth);
      const x = item.location - Math.floor(item.location / mapWidth) * mapWidth;
      const position = `${((x - (mapWidth / 2)) * WALL_SIZE)} 1.5 ${(y - (mapHeight / 2)) * WALL_SIZE}`;
      filteredList[index].position = position;
    })
    return filteredList;
  };

  useEffect(() => {
    try {
      props.getStoreByName(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (!data || error) {
    return (<Alert severity="error"> World name error </Alert>)
  }
  return (
    loading ? <SimpleBackdrop open /> :
    <div className="App">
      <header className="App-header">
      <button className="button" onClick={
            function() {
              document.querySelector('.a-enter-vr-button').click()
            }
          }>VR
        </button>
        <Scene
          loading-screen="dotsColor: blue; backgroundColor: black"
          environment
          cursor="rayOrigin: mouse"
        >
          <a-assets>
            <img id="pexels_anni" src={current.floorTexture} />
            <img id="background-360-sky" src={current.skyTexture} />
            <img id="info_panel_1" src={infoPanel} />
            <img id="ethIcon" src={ethIcon} />
            <img id="bitcoinIcon" src={bitcoinIcon} />
            {current.wallTexture && <img id="wallTexture" src={current.wallTexture} crossOrigin="anonymous" />}
            
          </a-assets>
          <a-grid id="ground" src="#pexels_anni" material="repeat: 2 2" rotation="-90 0 0" shadow="receive: true;" />
          <a-sky src="#background-360-sky" material={`${current.skyTexture ? "" : "color: #c18fff"}`}></a-sky>
          <Entity id="walls" init-walls={`${current.map ? `map:${current.map[0].data}` : ''}; wallTexture: ${current.wallTexture ? '#wallTexture' : '#wall-texture'}; wallColor: ${current.wallTexture ? '#fff' : '#aa63ff'}`}></Entity>

          {generateCustomItemList().map(item => <CustomList key={item._id || item.id} title={item.title} description={item.description} asset={item.asset} ratio={item.ratio} position={item.position} priceList={item.priceList} />)}

          <Entity
            id="shop_logo"
            position="6 0.1 10.5"
            geometry="primitive: plane; height: 1; width: 1.5;"
            material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
            rotation="-90 0 90"
          ></Entity>
          
          <Entity
            id="shop_logo"
            position="-0.5 2.5 -4.25"
            geometry="primitive: plane; height: 2; width: 3;"
            material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
            rotation="0 0 0"
          ></Entity>
          
          {/* Player */}
          <Entity
            id="rig"
            movement-controls="constrainToNavMesh: true; speed: 0.3; fly: false"
            position="0 0 0"
            rotation="0 90 0"
          >
            {/* position="8 0 11" */}
            <Entity
              id="player"
              camera="userHeight: 1.6"
              look-controls="pointerLockEnabled: true"
              position="0 1.6 0"
            >
              {/* <a-entity raycaster="showLine: true; far: 100; lineColor: red; lineOpacity: 0.5"></a-entity> */}
            </Entity>
            <Entity laser-controls="hand: left"></Entity>
            <Entity laser-controls="hand: right"></Entity>
          </Entity>

          {/* Light */}
          {/* <Entity light="type: ambient; intensity: 1.2"></Entity> */}

          <Entity
            id="tour-guide-bot"
            position="-1.5 0.1 9"        
            gltf-model={`${current.assistant ? current.assistant[0].url : "#tour-guide-bot-glb"}`} scale={`${current.assistant ? `${0.2 * current.assistant[0].ratio} ${0.2 * current.assistant[0].ratio} ${0.2 * current.assistant[0].ratio}` : "0.2 0.2 0.2"}`}
            animation-mixer="clip:Wave"
            chatbox=""
            chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot.  \n\nLet’s go!; scale: ${current.assistant ? current.assistant[0].ratio : 1}`}
            tour-guide="#rig"
          ></Entity>

          {/* <a-link 
            position="4 1.2 -3" rotation="0 -20 0" 
            title="Teleporter"
            href="#"
            image="https://thumbs.dreamstime.com/b/vr-image-futuristic-sci-fi-city-modern-fiction-skyscrapers-buildings-galaxy-discover-spacescape-119014727.jpg"
            popup-panel=""
          ></a-link> */}
        </Scene>
      </header>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  map: makeSelectStoreMap(),
  current: makeSelectStoreCurrent(),
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreByName,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

InternalTest.propTypes = {
  map: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  current: PropTypes.shape({}),
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  getStoreByName: PropTypes.func,
};

export default compose(
  withConnect,
)(InternalTest);
