/* eslint-disable camelcase */
import {
  takeLatest, call, put, all, select, take, putResolve,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';

import { makeSelectUserId } from 'services/user/selectors';
import { makeSelectStoreId, makeSelectStoreCurrentTheme } from 'services/store/selectors';
import { getStoreDetail, updateStoreCurrentTheme } from 'services/store/actions';
import * as routeActions from 'services/route/actions';
import {
  createThemeSuccess,
  createThemeFail,
  editThemeSuccess,
  editThemeFail,
  getThemeListByUserIdSuccess,
  getThemeListByUserIdFail,
  deleteThemeSuccess,
  deleteThemeFail,
} from './actions';
import * as themeActions from './actions';

import {
  CREATE_THEME,
  EDIT_THEME,
  DELETE_THEME,
  GET_THEME_LIST_BY_USERID,
} from './constants';

const api = API.create();

export function* createTheme(data) {
  const result = yield call(api.storeManagament.create, data.data);
  if (result.ok) {
    const { isUseTheme } = data.data;
    if (isUseTheme) {
      yield put(updateStoreCurrentTheme(result.data.data.items[0]));
    }
    const userId = yield select(makeSelectUserId());
    yield put(themeActions.getThemeListByUserId(userId));
    yield put(createThemeSuccess({ isUseTheme, ...result.data }));
    yield put(routeActions.navigateRouteTo(-1));
  } else {
    yield put(createThemeFail(result.data.error));
  }
}

export function* editTheme(data) {
  const result = yield call(api.storeManagament.edit, data.data);
  if (result.ok) {
    const { isUseTheme, id } = data.data;
    const currentTheme = yield select(makeSelectStoreCurrentTheme());
    if (isUseTheme || currentTheme._id === id) {
      yield put(updateStoreCurrentTheme(result.data.data.items[0]));
    }
    yield put(editThemeSuccess({ isUseTheme, ...result.data }));
    yield put(routeActions.navigateRouteTo(-1));
  } else {
    yield put(editThemeFail(result.data.error));
  }
}

export function* getThemeListByUserId(data) {
  const { id } = data;
  const result = yield call(api.theme.getByUserId, id);
  if (result.ok) {
    yield put(getThemeListByUserIdSuccess(result.data));
  } else {
    yield put(getThemeListByUserIdFail(result.data.error.message));
  }
}

export function* deleteTheme(data) {
  const { id } = data;
  const result = yield call(api.theme.delete, id);
  if (result.ok) {
    yield put(deleteThemeSuccess(result.data));
    yield put(routeActions.navigateRouteTo(-1));
  } else {
    yield put(deleteThemeFail(result.data.error.message));
  }
}

export default function* themeSaga() {
  yield all([
    takeLatest(CREATE_THEME, createTheme),
    takeLatest(EDIT_THEME, editTheme),
    takeLatest(DELETE_THEME, deleteTheme),
    takeLatest(GET_THEME_LIST_BY_USERID, getThemeListByUserId),
  ]);
}
