import _ from 'lodash';

export const generateCustomItemList = (current, WALL_SIZE = 1.5) => {
  const filteredList = _.cloneDeep(_.filter(current.items, (o) => !o.hidden));
  const mapHeight = current.map[0].height;
  const mapWidth = current.map[0].width;
  filteredList.forEach((item, index) => {
    const y = Math.floor(item.location / mapWidth);
    const x = item.location - Math.floor(item.location / mapWidth) * mapWidth;
    const position = `${((x - (mapWidth / 2)) * WALL_SIZE)} 1.5 ${(y - (mapHeight / 2)) * WALL_SIZE}`;
    filteredList[index].position = position;
  });
  return filteredList;
};
