export const GET_THEME_LIST_BY_USERID = 'GET_THEME_LIST_BY_USERID';
export const GET_THEME_LIST_BY_USERID_SUCCESS = 'GET_THEME_LIST_BY_USERID_SUCCESS';
export const GET_THEME_LIST_BY_USERID_FAIL = 'GET_THEME_LIST_BY_USERID_FAIL';
export const DELETE_THEME = 'DELETE_THEME';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_FAIL = 'DELETE_THEME_FAIL';
export const EDIT_THEME = 'EDIT_THEME';
export const EDIT_THEME_SUCCESS = 'EDIT_THEME_SUCCESS';
export const EDIT_THEME_FAIL = 'EDIT_THEME_FAIL';
export const CREATE_THEME = 'CREATE_THEME';
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_FAIL = 'CREATE_THEME_FAIL';
export const SET_THEME_SHOW_SNACKBAR_BAR = 'SET_THEME_SHOW_SNACKBAR_BAR';
export const SET_THEME_SHOW_MODAL = 'SET_THEME_SHOW_MODAL';
export const USE_THEME_SUCCESS = 'USE_THEME_SUCCESS';
export const USE_THEME_FAIL = 'USE_THEME_FAIL';
export const REMOVE_THEME_ERROR = 'REMOVE_THEME_ERROR';
export const MODAL_TYPE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  USE_THEME: 'USE_THEME',
};
