export const conversionEventId = {
  signUp: 'AW-10975360890/eJUeCLjn24cYEPruuvEo',
  step1: 'AW-10975360890/oc2wCLLn24cYEPruuvEo',
  step2: 'AW-10975360890/9FCFCLXn24cYEPruuvEo',
};

export const conversionAccountId = 'AW-10975360890';

export const googleConversion = (id) => {
  if (window.gtag && id !== undefined && process.env.NODE_ENV === 'production') {
    window.gtag('event', 'conversion', { send_to: id });
  }
};
