/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import { DEAUTHENTICATE } from 'services/auth/constants';
import _ from 'lodash';
import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  SET_USER_SHOW_SNACKBAR_BAR,
} from './constants';

export const initialState = {
  _id: '',
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  name: '',
  role: '',
  error: null,
  loading: false,
  snackbar: {
    title: 'Successfully updated',
    isShow: false,
    type: null,
  },
};

const userReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CREATE_USER:
      draft.loading = true;
      break;
    case CREATE_USER_SUCCESS: {
      draft.loading = false;
      updater(action.data.data, draft);
      draft.error = null;
      break;
    }
    case CREATE_USER_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case LOGIN:
      draft.loading = true;
      break;
    case LOGIN_SUCCESS: {
      draft.loading = false;
      draft.error = null;
      break;
    }
    case LOGIN_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_PROFILE_SUCCESS:
      draft.loading = false;
      updater(action.data, draft);
      break;
    case GET_PROFILE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case UPDATE_USER:
      draft.loading = true;
      draft.error = null;
      break;
    case UPDATE_USER_SUCCESS: {
      draft.loading = false;
      draft.error = null;
      updater(action.data.data, draft);
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = true;
      draft.snackbar = temp;
      break;
    }
    case UPDATE_USER_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case DEAUTHENTICATE:
      draft._id = '';
      break;
    case SET_USER_SHOW_SNACKBAR_BAR: {
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = false;
      draft.snackbar = temp;
      break;
    }
    default:
  }
});

export default userReducer;
