import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectItemDomain = (state) => state.item || initialState;

export const makeSelectItemList = () => createSelector(selectItemDomain, _.property('list'));
export const makeSelectItemError = () => createSelector(selectItemDomain, _.property('error'));
export const makeSelectItemLoading = () => createSelector(selectItemDomain, _.property('loading'));
export const makeSelectItemIsShowSnackbar = () => createSelector(selectItemDomain, _.property('isShowSnackbar'));
export const makeSelectItemSnackbar = () => createSelector(selectItemDomain, _.property('snackbar'));
export const makeSelectItemOutletLoading = () => createSelector(selectItemDomain, _.property('outletLoading'));
