import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import _ from 'lodash';
import ImageGrid from '../ImageGrid';
import styles from '../../Templates.module.scss';

export default function TemplateBody(props) {
  const {
    search, templateList, goToEditTemplate, handleOpen, isCurrentTemplate,
  } = props;

  const filteredTemplateList = (keyword) => {
    if (_.isEmpty(keyword)) return templateList;
    return templateList.filter((template) => _.includes(_.lowerCase(template.name), _.lowerCase(keyword)));
  };

  return (
    <Box component="div" sx={{ flexGrow: 1, background: '#EAEAEA' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={3}
        columns={16}
        sx={{
          width: '100%',
          margin: '0',
          padding: '12px 22px',
        }}
      >
        {templateList && filteredTemplateList(search).map((template, index) => (
          <Grid
            xs={4}
            md={4}
            key={template._id}
          >
            <div className={styles.gridItemImageWrapper}>
              <ImageGrid
                imgSrc={template.thumbnail || template.logo}
                imgStyle={styles.gridItemImage}
                callback={() => handleOpen(index)}
              />
            </div>
            <Typography className={styles.templateTitle} variant="subtitle1" component="h6">
              {template.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TemplateBody.propTypes = {
  currentTemplate: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
  }),
  current: PropTypes.shape({
    logo: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  templateList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  search: PropTypes.string,
  goToEditTemplate: PropTypes.func,
  handleOpen: PropTypes.func,
  isCurrentTemplate: PropTypes.func,
};
