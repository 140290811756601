/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import AgoraRTC from 'agora-rtc-sdk-ng';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import _ from 'lodash';
import { PublicStoreButton } from './PublicStoreButton';

const client = AgoraRTC.createClient({
  mode: 'rtc',
  codec: 'vp9',
});

export function AgoraVoiceChat(props) {
  const { channelInfo } = props;
  const [remoteUsers, setRemoteUsers] = useState({});
  const [joined, setJoined] = useState(false);
  const [audioTrack, setAudioTrack] = useState(null);
  const [options, setOptions] = useState(null);

  const createMicrophoneAudioTrack = async (options = {
    encoderConfig: 'music_standard',
  // eslint-disable-next-line arrow-body-style
  }) => {
    return AgoraRTC.createMicrophoneAudioTrack(options);
  };

  const initTracks = async () => {
    const track = await createMicrophoneAudioTrack();
    setAudioTrack(track);
    return [track];
  };

  const subscribe = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    setRemoteUsers((pre) => ({
      ...pre,
      [id]: user,
    }));
    subscribe(user, mediaType);
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === 'audio') {
      const id = user.uid;
      setRemoteUsers((pre) => {
        delete pre[id];
        return {
          ...pre,
        };
      });
    }
  };

  const join = async () => {
    const newOptions = _.clone(channelInfo);
    try {
      if (!options) {
        setOptions(channelInfo);
      }

      // Join a channel
      newOptions.uid = await client.join(newOptions.appId, newOptions.name, newOptions.token || null, newOptions.uid || null);
      const tracks = await initTracks();
      await client.publish(tracks);
      setJoined(true);
    } catch (error) {
      console.error(error);
    }
  };
  const leave = async () => {
    audioTrack?.close();
    setAudioTrack(null);
    setRemoteUsers({});
    await client?.leave();
    setJoined(false);
    const msg = 'client leaves channel success!';
    console.log(msg);
  };

  useEffect(() => {
    console.log(channelInfo);
    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    return () => leave();
  }, []);

  return (
    joined ? <PublicStoreButton id="leave" startIcon={<VoiceOverOffIcon />} onClick={async () => { leave(); }}>Leave Chat</PublicStoreButton>
      : (
        <PublicStoreButton
          id="join"
          startIcon={<RecordVoiceOverIcon />}
          onClick={async () => {
            join(channelInfo);
          }}
        >
          Join Chat
        </PublicStoreButton>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectStoreError(),
  // loading: makeSelectStoreLoading(),
  // channelInfo: makeSelectChannelInfo(),
  // channelError: makeSelectChannelError(),
  // channelLoading: makeSelectChannelLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

AgoraVoiceChat.propTypes = {
  // loading: PropTypes.bool,
  channelInfo: PropTypes.shape({
    _id: PropTypes.string,
  }),
  // error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export default compose(
  withConnect,
)(AgoraVoiceChat);
