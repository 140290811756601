/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getStoreByPublicIdWithItems } from 'services/store/actions';
import { makeSelectStorePublicStoreDetail, makeSelectStoreError, makeSelectStoreLoading } from 'services/store/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Alert from '@mui/material/Alert';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';

import TWEEN from '@tweenjs/tween.js';

import InstructionLegend from 'webComponents/InstructionLegend/InstructionLegend';
import CustomEntity from 'webComponents/CustomEntity/CustomEntity';
import infoPanel from 'assets/component/3b4174ec8224442b47b3418553b75556.jpeg';
import KeymakerChatBox from 'webComponents/keymaker/KeymakerChatBox';
// import Robot from './assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from 'assets/bitcoin_icon.png';
import ethIcon from 'assets/eth_icon.png';
import { isIgnoreAsset, isIgnoreBackgroundMusic } from 'utils/asset.utils';
import { generateCustomItemList } from 'utils/item.utils';
import { VRButton, EnvironmentComponent, FloorComponent, WallComponent } from './components/PublisStoreComponents';
import { setBackgroundMusic } from 'utils/public-store.utils';
// import 'networked-aframe';

// import './App.css';

import 'components/map';
import 'components/rounded';
import 'components/info-panel';
import 'components/keymaker-panel';

import 'components/chatbot/tour-guide';
import 'components/chatbot/chatbox-info-panel';
import 'components/chatbot/chatbox-look-at';
import 'components/chatbot/chatbox';
import 'components/popup-panel';
import 'components/gltf-model-loader'
import 'components/joystick.js';

require('aframe');
require('aframe-extras');

const isWebxr = process.env.NODE_ENV !== 'production';

function PublicStore(props) {
  const { current, error, loading } = props;
  const [isShowKeymakerChatBox, setIsShowKeymakerChatBox] = useState(false);
  const storePublicId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  window.TWEEN = TWEEN;

  useEffect(() => {
    try {
      props.getStoreByPublicIdWithItems(storePublicId);
    } catch (error) {
      console.error(error);
    };
  }, []);

  if (error) {
    return (<Alert severity="error"> {error} </Alert>);
  }
  if (!storePublicId) {
    return (<Alert severity="error"> World Public Id error </Alert>);
  }

  return (
    (loading || _.isEmpty(current)) ? <SimpleBackdrop open={loading} />
      : (
        <div className="App">
          <header className="App-header">
            <VRButton />
            <InstructionLegend name={current.name} isShowKeymakerChatBox={isShowKeymakerChatBox} setIsShowKeymakerChatBox={setIsShowKeymakerChatBox} />
            {isWebxr && <KeymakerChatBox name={current.name} />}
            <a-scene
              loading-screen="dotsColor: blue; backgroundColor: black"
              environment="preset: studio"
              cursor="rayOrigin: mouse"
              // joystick
              sound={setBackgroundMusic(current)}
            >
              <a-assets>
                <img id="pexels_anni" src={current.theme[0].floor} crossOrigin="anonymous" />
                {/* <img id="background_360_sky" src={current.theme[0].environment} crossOrigin="anonymous" /> */}
                <img id="info_panel_1" src={infoPanel} crossOrigin="anonymous" />
                <img id="ethIcon" src={ethIcon} crossOrigin="anonymous" />
                <img id="bitcoinIcon" src={bitcoinIcon} crossOrigin="anonymous" />
                {current.theme[0].wall && <img id="wall" src={current.theme[0].wall} crossOrigin="anonymous" />}
                <a-asset-item id="tree" src="https://double-storage.s3.ap-southeast-1.amazonaws.com/model/asset/6eeeec2e-f8f1-4e75-8a2d-96e7b83737f0-1691645423621.glb"></a-asset-item>
                {!isIgnoreBackgroundMusic(current.backgroundMusic) && 
                  <audio id="backgroundMusicAudio" src={current.backgroundMusic[0].url} preload="auto" crossOrigin="anonymous"></audio>
                }
              </a-assets>
              {EnvironmentComponent(current.theme[0].environment)}
              {FloorComponent(current.theme[0].floor)}
              {WallComponent(current.theme[0].wall, current.map)}

              {generateCustomItemList(current).map((item) => (
                <CustomEntity
                  key={item._id || item.id}
                  item={item}
                />
              ))}

              <a-entity
                id="shop_logo"
                position="-0.5 2.5 -4.25"
                geometry="primitive: plane; height: 2; width: 3;"
                material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
                rotation="0 0 0"
              />

              {/* Player */}
              <a-entity
                id="rig"
                movement-controls="controls: nipple, keyboard;constrainToNavMesh: true; speed: 0.3; fly: false"
                nipple-controls="mode: static; lookJoystickEnabled: false; moveJoystickPosition: right"
                position="0 0 0"
                rotation="0 0 0"
              >
                <a-entity
                  id="player"
                  camera="userHeight: 1.6"
                  look-controls="touchEnabled: true"
                  position="0 1.6 0"
                >
                  {/* <a-entity raycaster="showLine: true; far: 100; lineColor: red; lineOpacity: 0.5"></a-entity> */}
                </a-entity>
                <a-entity laser-controls="hand: left" />
                <a-entity laser-controls="hand: right" />
                {/* <a-entity tracked-controls="controller: 0; idPrefix: OpenVR"></a-entity> */}

              </a-entity>
              {/* <a-entity src="#tree" scale="10 10 10" gltf-model-loader={`src:#tree`}></a-entity> */}
            {!isIgnoreAsset(current.theme[0].assistant)
              && (
              <a-entity
                id="tour-guide-bot"
                position="-1.5 0.1 9"
                gltf-model={`${current.theme[0].assistant ? current.theme[0].assistant[0].url : '#tour-guide-bot-glb'}`}
                scale={`${current.theme[0].assistant ? `${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio}` : '1 1 1'}`}
                animation-mixer="clip:Wave"
                chatbox=""
                chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot. \n\nLet’s go!; scale: ${current.theme[0].assistant ? current.theme[0].assistant[0].ratio : 1}`}
                tour-guide="#rig"
              />
            )}
            </a-scene>
          </header>
        </div>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectStorePublicStoreDetail(),
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreByPublicIdWithItems,
    setIsShowKeymakerChat,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

PublicStore.propTypes = {
  map: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
    }), PropTypes.number]),
  ),
  loading: PropTypes.bool,
  current: PropTypes.shape({
    name: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.number),
    })),
    theme: PropTypes.arrayOf(PropTypes.shape({
      assistant: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        ratio: PropTypes.number,
      })),
      environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
      floor: PropTypes.string,
      wall: PropTypes.string,
    })),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  setIsShowKeymakerChat: PropTypes.func,
  getStoreByPublicIdWithItems: PropTypes.func,
};

export default compose(
  withConnect,
)(PublicStore);
