import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  REGET_HOME_DATA,
  REGET_HOME_DATA_SUCCESS,
  GET_DATA_FROM_SCRATCH,
  SET_USER_SHOW_SNACKBAR_BAR,
} from './constants';

export const createUser = (data, callback) => ({
  type: CREATE_USER,
  data,
  callback,
});

export const createUserSuccess = (data) => ({
  type: CREATE_USER_SUCCESS,
  data,
});

export const createUserFail = (error) => ({
  type: CREATE_USER_FAIL,
  error,
});

export const login = (data, callback) => ({
  type: LOGIN,
  data,
  callback,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

export const loginFail = (error) => ({
  type: LOGIN_FAIL,
  error,
});

export const getProfile = (callback, storeId = null) => ({
  type: GET_PROFILE,
  storeId,
  callback,
});

export const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  data,
});

export const getProfileFail = (error) => ({
  type: GET_PROFILE_FAIL,
  error,
});

export const getDataFromScratch = (callback) => ({
  type: REGET_HOME_DATA,
  callback,
});

export const getDataFromScratchSuccess = (data) => ({
  type: REGET_HOME_DATA_SUCCESS,
  data,
});

export const getDataFromScratchFail = (error) => ({
  type: GET_DATA_FROM_SCRATCH,
  error,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  data,
});

export const updateUserSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  data,
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  error,
});

export const setShowSnackbar = (bool) => ({
  type: SET_USER_SHOW_SNACKBAR_BAR,
  bool,
});
