/* eslint-disable global-require */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import apisauce from 'apisauce';
import _ from 'lodash';
import { makeSelectIsAuthenticated } from 'services/auth/selectors';
import { deauthenticate } from 'services/auth/actions';
import { setShowSnackbar } from 'services/route/actions';
import { jsonToFormData } from 'utils/utils';
// import * as reduxStore from './store';

const HTTP_SERVER_ROOT = process.env.REACT_APP_API_URL;

const create = () => {
  const api = apisauce.create({
    // baseURL: `${HTTP_SERVER_ROOT}/webhook/${ADAPTER}/`,
    baseURL: `${HTTP_SERVER_ROOT}/`,
    // headers: {},
    withCredentials: true,
    timeout: 6 * 10 * 1000, // extend timeout for uploading assets
  });

  api.addRequestTransform((request) => {
    const reduxStore = require('./store');
    const key = _.get(reduxStore.default.getState(), ['auth', 'access_token']);
    if (key) {
      request.headers.Authorization = `Bearer ${key}`;
    }
  });

  const selectIsAuthenticated = makeSelectIsAuthenticated();
  api.addMonitor((response) => {
    try {
      const reduxStore = require('./store');
      if (response.status === 401) {
        // de-auth
        const ignoreList = ['/login', '/onboarding'];
        const { pathname } = window.location;
        const isAuthenticated = selectIsAuthenticated(reduxStore.default.getState());
        if (isAuthenticated) {
          // frontend thought itself was authenticated, but back-end said no
          // simply put, frontend got a wrong token, maybe expired token?
          // we need to notify all that frontend was in fact not authenticated
          reduxStore.default.dispatch(deauthenticate({ isVoluntary: false }));
        }
        // TODO: should put this check to somewhere else, not here
        // if (!ignoreList.includes(pathname)) window.location.replace('/login');
      } else if (response.problem === 'TIMEOUT_ERROR') {
        reduxStore.default.dispatch(setShowSnackbar(true));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const auth = (function authAPI() {
    const url = 'auth/';

    return {
      login: ({ email, password }) => api.post(`${url}login`, { email, password }),
      logout: ({ id }) => api.post(`${url}logout`, { id }),
      register: ({
        email, password, store, themeId,
      }) => api.put(`${url}register`, {
        email, password, store, themeId,
      }),
      registerWithTemplate: (data) => api.put(`${url}register/template`, data),
      refresh: ({ id }) => api.post(`${url}refresh`, { id }),
    };
  }());

  const user = (function userAPI() {
    // const url = (id) => `user/`;
    const url = 'user/';
    return {
      register: ({
        name, email, password, theme, store,
      }) => api.put(url, {
        name, email, password, theme, store,
      }),
      profile: () => api.get(`${url}profile`),
      update: ({ userId, data }) => api.post(`${url}${userId}`, data),
    };
  }());

  const store = (function storeAPI() {
    const url = 'store/';

    return {
      create: (data) => {
        // eslint-disable-next-line no-param-reassign
        delete data._id;
        return api.put(`${url}`, data);
      },
      createByTemplate: (data) => api.put(`${url}template`, data),
      list: (userId) => api.get(`${url}user/${userId}`),
      detail: (storeId) => api.get(`${url}${storeId}`),
      validateStoreName: (name) => api.get(`${url}name/${name}`),
      getStoreTheme: () => api.get('theme/', {}),
      name: (name) => api.get(`${url}name/${name}`),
      publicStore: (name) => api.get(`${url}double/name/${name}`),
      publicStoreByPublicId: (id) => api.get(`${url}double/publicid/${id}`),
      publicStoreByPublicIdInEditMode: (id) => api.get(`${url}double/publicid/edit/${id}`),
      update: ({ storeId, data }) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const {
          mapId, map, name, description, logoFile,
        } = data;
        if (mapId && map) {
          body.append('mapId', mapId);
          body.append('map', JSON.stringify(map[0]));
        }
        if (name) body.append('name', name);
        if (description) body.append('description', description);
        if (logoFile) body.append('logoFile', logoFile);
        return api.post(`${url}${storeId}`, body, config);
      },
      updateTheme: ({ storeId, themeId }) => api.post(`${url}theme/${storeId}`, { themeId }),
    };
  }());

  const theme = (function themeAPI() {
    const url = 'theme/';

    return {
      getByUserId: (id) => api.get(`${url}user/${id}`),
      create: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('name', data.name);
        body.append('description', data.description);
        body.append('userId', data.userId);
        body.append('environment', data.environment.file);
        const dataEnv = _.cloneDeep(data.environment);
        delete dataEnv.file;
        body.append('environment', JSON.stringify(dataEnv));

        if (data.wall) body.append('wall', data.wall);
        if (data.floor) body.append('floor', data.floor);
        if (data.thumbnail) body.append('thumbnail', data.thumbnail);

        body.append('assistant', data.assistant.file);
        body.append('assistantRatio', data.assistant.ratio);
        body.append('isUseTheme', data.isUseTheme);
        body.append('storeId', data.storeId);
        return api.put(`${url}`, body, config);
      },
      edit: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('name', data.name);
        body.append('description', data.description);
        body.append('id', data.id);
        body.append('isUseTheme', data.isUseTheme);
        body.append('storeId', data.storeId);
        if (_.has(data, 'thumbnail')) {
          body.append('thumbnail', data.thumbnail);
        }
        if (_.has(data, 'environment')) {
          body.append('environment', data.environment);
        }
        if (_.has(data, 'wall')) {
          body.append('wall', data.wall);
        }
        if (_.has(data, 'floor')) {
          body.append('floor', data.floor);
        }
        if (_.has(data, 'assistant')) {
          body.append('assistant', data.assistant.file);
        }
        if (data.assistantId) {
          body.append('assistantId', data.assistantId);
        }
        if (data.assistantRatio) {
          body.append('assistantRatio', data.assistantRatio);
        }
        return api.post(`${url}${data.id}`, body, config);
      },
      delete: (id) => api.delete(`${url}${id}`),
    };
  }());

  const storeManagament = (function storeManagamentAPI() {
    const url = 'store-management/';

    return {
      getByUserId: (id) => api.get(`${url}user/${id}`),
      create: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('name', data.name);
        body.append('description', data.description);
        body.append('userId', data.userId);
        body.append('environment', data.environment.file); // env file
        const dataEnv = _.cloneDeep(data.environment);
        delete dataEnv.file;
        body.append('environment', JSON.stringify(dataEnv));

        if (data.wall) body.append('wall', data.wall);
        if (data.floor) body.append('floor', data.floor);
        if (data.thumbnail) body.append('thumbnail', data.thumbnail);

        body.append('assistant', data.assistant.file);
        body.append('assistantRatio', data.assistant.ratio);
        body.append('isUseTheme', data.isUseTheme);
        body.append('storeId', data.storeId);
        return api.put(`${url}`, body, config);
      },
      edit: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('name', data.name);
        body.append('description', data.description);
        body.append('id', data.id);
        body.append('isUseTheme', data.isUseTheme);
        body.append('storeId', data.storeId);
        if (_.has(data, 'thumbnail')) {
          body.append('thumbnail', data.thumbnail);
        }
        if (_.has(data, 'environment')) {
          if (_.has(data.environment, 'file')) {
            body.append('environment', data.environment.file);
          }
          const dataEnv = _.cloneDeep(data.environment);
          if (_.has(data.environment, '_id')) {
            dataEnv.id = dataEnv._id;
            delete dataEnv._id;
          }
          delete dataEnv.file;
          body.append('environment', JSON.stringify(dataEnv));
        }
        if (_.has(data, 'wall')) {
          body.append('wall', data.wall);
        }
        if (_.has(data, 'floor')) {
          body.append('floor', data.floor);
        }
        if (_.has(data, 'assistant')) {
          body.append('assistant', data.assistant.file);
        }
        if (data.assistantId) {
          body.append('assistantId', data.assistantId);
        }
        if (data.assistantRatio) {
          body.append('assistantRatio', data.assistantRatio);
        }
        return api.post(`${url}${data.id}`, body, config);
      },
      delete: (id) => api.delete(`${url}${id}`),
      updateWorldAdmin: (data) => {
        const {
          // eslint-disable-next-line no-shadow
          name, description, logoFile, url, storeId, templateId, isTemplate,
        } = data;
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        if (name) body.append('name', name);
        if (description) body.append('description', description);
        if (logoFile) body.append('logoFile', logoFile);
        if (url) body.append('url', url);
        if (storeId) body.append('storeId', storeId);
        if (_.has(data, 'templateId')) body.append('templateId', templateId);
        if (_.has(data, 'isTemplate')) body.append('isTemplate', isTemplate);
        return api.post('store-management/admin/world/update', body, config);
      },
    };
  }());

  const template = (function templateAPI() {
    const url = 'template/';

    return {
      getAll: () => api.get(`${url}default`, {}),
      getOne: ({ id }) => api.get(`${url}${id}`, {}),
    };
  }());

  const item = (function itemAPI() {
    const url = 'item/';

    return {
      create: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        if (data.asset) {
          if (data.asset[0].type === 'image') {
            body.append('file', data.imageFile);
          } else {
            body.append('file', data.modelFile);
          }
          body.append('asset', JSON.stringify(data.asset[0]));
        }
        body.append('storeId', data.storeId);
        body.append('title', data.title);
        body.append('description', data.description);
        body.append('priceList', JSON.stringify(data.priceList));
        if (data.advanceSetting) {
          body.append('advanceSetting', JSON.stringify(data.advanceSetting));
          if (data.advanceSetting.hasPrice) {
            body.append('priceList', JSON.stringify(data.priceList));
          }
        }
        if (data.link) {
          body.append('link', JSON.stringify(data.link));
        }
        if (data.actionList) {
          body.append('actionList', JSON.stringify(data.actionList));
        }
        return api.post(`${url}`, body, config);
      },
      update: (data) => {
        const { _id } = data;
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        if (data.asset) {
          if (data.asset[0].type === 'image') {
            body.append('file', data.imageFile);
          } else {
            body.append('file', data.modelFile);
          }
          body.append('asset', JSON.stringify(data.asset[0]));
        }
        body.append('title', data.title);
        body.append('storeId', data.storeId);
        body.append('description', data.description);
        body.append('priceList', JSON.stringify(data.priceList));
        if (data.actionList) {
          body.append('actionList', JSON.stringify(data.actionList));
        }
        // if (data.advanceSetting) {
        //   body.append('advanceSetting', JSON.stringify(data.advanceSetting));
        //   if (data.advanceSetting.hasPrice) {
        //     body.append('priceList', JSON.stringify(data.priceList));
        //   }
        // }
        if (data.link) {
          body.append('link', JSON.stringify(data.link));
        }
        return api.post(`${url}${_id}`, body, config);
      },
      getAll: (storeId) => api.get(`${url}store/${storeId}`),
      deactivate: (itemId) => api.get(`${url}deactivate/${itemId}`),
      location: ({ storeId, items }) => api.post(`${url}location/${storeId}`, { items }),
      updateFromWorld: (data) => {
        const { items } = data;
        console.info(items);
        // console.error(data.items);
        return api.post(`${url}s-edit/`, { items });
      },
    };
  }());

  const assistant = (function assistantAPI() {
    const url = 'assistant/';
    return {
      retrieve: (id) => api.get(`${url}${id}`),
      update: ({ assistantId, data }) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        if (_.has(data, 'file')) {
          body.append('file', data.file);
        }
        body.append('ratio', parseFloat(data.ratio));
        body.append('type', data.type);
        return api.post(`${url}${assistantId}`, body, config);
      },
    };
  }());

  const map = (function mapAPI() {
    const url = 'map/';
    return {
      update: ({ id, data }) => api.post(`${url}${id}`, data),
    };
  }());

  const message = (function messageAPI() {
    const newApi = apisauce.create({
      // baseURL: `${HTTP_SERVER_ROOT}/webhook/${ADAPTER}/`,
      baseURL: 'https://qa.delight.global/webhook/webwidget/',
      // headers: {},
      withCredentials: true,
      timeout: 6 * 10 * 1000, // extend timeout for uploading assets
    });

    return {
      send: ({ webhook, data }) => newApi.post(`${webhook}/`, data),
    };
  }());

  const shopify = (function shopifyAPI() {
    const sub = 'shopify/';
    return {
      products: (() => api.get(`${sub}products`)),
    };
  }());

  const crypto = (function cryptoAPI() {
    function smartAdAPI() {
      const url = 'https://dkeczjtk8h.execute-api.ap-southeast-1.amazonaws.com/';
      const tempAPI = apisauce.create({
        baseURL: url,
        timeout: 10000,
      });
      return {
        teleport: (data) => tempAPI.post('teleport', data),
      };
    }
    return {
      smartAd: smartAdAPI(),
    };
  }());

  const multimediaAsset = (function multimediaAssetAPI() {
    const url = 'multimedia-asset/';
    return {
      addBackgroundMusic: (data) => {
        const body = jsonToFormData(data);
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        // body.append('file', data.file);
        body.delete('_id');
        console.log(data);
        console.log(body);
        // Display the key/value pairs
        // eslint-disable-next-line no-restricted-syntax
        for (const pair of body.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`);
        }
        return api.post(`${url}add-background-music-to-world`, body, config);
      },
      deleteBackgroundMusic: (data) => {
        const { id, worldId } = data;
        return api.delete(`${url}${id}`, { worldId });
      },
      updateBackgroundMusic: (data) => {
        const { _id } = data;
        console.log(data);
        if (_.has(data, 'file')) {
          const config = {};
          config.headers = { 'Content-Type': 'multipart/form-data' };
          const body = jsonToFormData(data);
          body.delete('_id');
          return api.post(`${url}${_id}`, body, config);
        }
        // console.log(body);
        return api.post(`${url}${_id}`, data);
      },
    };
  }());

  const channel = (function channelAPI() {
    const url = 'channel/';
    return {
      get: (data) => api.post(`${url}info`, data),
      getAll: () => api.get(`${url}all`),
      create: (data) => api.post(`${url}`, data),
      update: (data) => api.put(`${url}`, data),
      delete: (id) => api.delete(`${url}${id}`),
    };
  }());

  return {
    auth,
    user,
    store,
    theme,
    storeManagament,
    template,
    item,
    assistant,
    map,
    message,
    shopify,
    crypto,
    channel,
    multimediaAsset,
  };
};

export default {
  create,
};
