/* eslint-disable camelcase */
import { constant } from 'lodash';
import {
  GET_PERSISTED_TOKEN_SUCCESS,
  CREATE_AUTH_TOKEN_SUCCESS,
  DELETE_AUTH_TOKEN,
  DELETE_AUTH_TOKEN_SUCCESS,
  DELETE_AUTH_TOKEN_FAIL,
  DEAUTHENTICATE,
  REGISTER_WITH_TEMPLATE,
  REGISTER_WITH_TEMPLATE_SUCCESS,
  REGISTER_WITH_TEMPLATE_FAIL,
  SET_HOME_SHOW_SNACKBAR,
} from './constants';

export function getPersistedTokenSuccess(access_token) {
  return { type: GET_PERSISTED_TOKEN_SUCCESS, access_token };
}
export function createAuthTokenSuccess(access_token, refresh_token) {
  return { type: CREATE_AUTH_TOKEN_SUCCESS, access_token, refresh_token };
}

export function logout(id, callback) {
  return { type: DELETE_AUTH_TOKEN, id, callback };
}
export function deleteAuthTokenSuccess() {
  return { type: DELETE_AUTH_TOKEN_SUCCESS };
}
export function deleteAuthTokenFail() {
  return { type: DELETE_AUTH_TOKEN_FAIL };
}

export function deauthenticate({ isVoluntary }) {
  return { type: DEAUTHENTICATE, isVoluntary };
}

export const registerWithTemplate = (data, callback) => ({
  type: REGISTER_WITH_TEMPLATE,
  data,
  callback,
});

export const registerWithTemplateSuccess = (data) => ({
  type: REGISTER_WITH_TEMPLATE_SUCCESS,
  data,
});

export const registerWithTemplateFail = (error) => ({
  type: REGISTER_WITH_TEMPLATE_FAIL,
  error,
});

export const setShowSnackbar = (bool) => ({
  type: SET_HOME_SHOW_SNACKBAR,
  bool,
});
