/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';
import _ from 'lodash';
import { makeSelectTemplateOutletLoading } from 'services/template/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import {
  getTemplateList,
} from 'services/template/actions';

function TemplatesOutlet(props) {
  const { loading } = props;
  useEffect(() => {
    props.getTemplateList();
  }, []);

  return (
    (loading) ? <SimpleBackdrop open={loading} /> : <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectTemplateOutletLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getTemplateList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TemplatesOutlet.propTypes = {
  loading: PropTypes.bool.isRequired,
  getTemplateList: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(TemplatesOutlet);
