import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import WhiteNormalButton from 'webComponents/NormalButton/WhiteNormalButton';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { makeSelectUserId } from 'services/user/selectors';
import {
  makeSelectThemeList,
  makeSelectThemeError,
  makeSelectThemeLoading,
  makeSelectThemeIsShowSnackbar,
  makeSelectThemeSnackbar,
} from 'services/theme/selectors';
import {
  makeSelectStoreCurrentTheme,
} from 'services/store/selectors';
import {
  createTheme, editTheme, deleteTheme, setShowSnackbar, removeThemeError,
} from 'services/theme/actions';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import CustomTabs from 'webComponents/Tabs/CustomTabs';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import HomeTabPanel from './components/HomeTabPanel';
import TextureTabPanel from './components/TextureTabPanel';
import TextureTabPanelOnlyImage from './components/TextureTabPanel_onlyImage';
import AssistantTabPanel from './components/AssistantTabPanel';

import styles from './EditThemes.module.scss';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EditThemes(props) {
  const {
    loading, error, themeList, storeCurrentTheme, snackbar,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateTheme, setIsCreateItem] = useState(searchParams.get('new') || false);
  const [isDeleteTheme, setIsDeleteTheme] = useState(false);
  const [info, setInfo] = useState({
    title: '',
    description: '',
  });
  const [environment, setEnvironment] = useState({
    id: '',
    type: 'image',
    file: null,
    metadata: {
      ratio: 1,
    },
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [wall, setWall] = useState(null);
  const [floor, setFloor] = useState(null);
  const [assistant, setAssistant] = useState({
    id: '',
    file: null,
    ratio: 1,
  });
  const [isDefaultTheme, setIsDefaultTheme] = useState(false);
  const [themeError, setThemeError] = useState({
    home: false,
    thumbnail: false,
    environment: false,
    wall: false,
    floor: false,
    assistant: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const themeId = searchParams.get('tid') || '';
  const storeId = searchParams.get('sid');

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToThemePage = () => {
    props.removeThemeError();
    navigate(-1);
  };

  const isCurrentTheme = () => themeId === storeCurrentTheme._id;

  const validateTheme = () => {
    const errorDetail = {
      home: _.isEmpty(info.title),
      environment: _.isNil(environment),
      // assistant: _.isNil(assistant.file),
      thumbnail: false,
    };
    setThemeError(errorDetail);
    return !(errorDetail.home || errorDetail.environment); // || errorDetail.assistant
  };

  const handleSaveButton = (isUseTheme) => {
    const isValid = validateTheme();
    if (!isValid) return;
    if (isCreateTheme) {
      props.createTheme({
        userId: props.userId,
        name: info.title,
        description: info.description,
        environment,
        thumbnail,
        wall,
        floor,
        assistant,
        storeId,
        isUseTheme,
      });
    } else {
      const editData = {
        id: themeId,
        name: info.title,
        description: info.description,
        assistantRatio: assistant.ratio,
        assistantId: assistant.id,
        storeId,
        environment,
        isUseTheme,
      };

      if (!_.isString(thumbnail)) editData.thumbnail = thumbnail;
      // if (!_.isString(environment.file)) editData.environment = environment;
      if (!_.isString(wall)) editData.wall = wall;
      if (!_.isString(floor)) editData.floor = floor;
      if (!_.isString(assistant.file)) editData.assistant = assistant;
      props.editTheme(editData);
    }
  };

  const setThemeSnackbar = (bool) => {
    props.setShowSnackbar(bool);
    if (isCreateTheme || isDeleteTheme) {
      goToThemePage();
    }
  };

  const getCustomThemeType = () => {
    if (isDefaultTheme) return 'View';
    if (isCreateTheme) return 'Create';
    return 'Edit';
  };

  const isAssistantTooBig = () => {
    if (error) return error.code === 413;
    return false;
  };

  useEffect(() => {
    if (!isCreateTheme && !_.isEmpty(themeList)) {
      const currentTheme = _.find(themeList, { _id: themeId });
      if (!currentTheme) goToThemePage();
      setIsDefaultTheme(currentTheme.isDefault);
      setEnvironment(_.isString(currentTheme.environment) ? currentTheme.environment : currentTheme.environment[0]);
      setThumbnail(currentTheme.thumbnail || null);
      setWall(currentTheme.wall || null);
      setFloor(currentTheme.floor || null);
      setInfo({
        title: currentTheme.name,
        description: currentTheme.description,
      });

      if (!_.isEmpty(currentTheme.assistant)) {
        setAssistant({
          id: currentTheme.assistant[0]._id,
          file: currentTheme.assistant[0].url,
          ratio: currentTheme.assistant[0].ratio,
        });
      }
    }
  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle={`${getCustomThemeType()} Custom Theme`} isNeedBackButton />
      <GeneralContent
        backgroundColor="#E9E9E9"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div className={styles.topRow}>
          <Typography variant="menuAndInput" component="p">
            Create your own custom theme. You can either save it or save and apply your theme.
          </Typography>
          <IconButton
            aria-label="close"
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => goToThemePage()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* -------------- Tab ------------- */}
        <Paper elevation={1} className={`tabsWrapper ${styles.tabsWrapper}`}>
          <div className={`tabsWrapperInner ${styles.tabsWrapperInner}`}>
            <CustomTabs
              className={`customTabs ${styles.customTabs}`}
              value={value}
              onChange={handleChange}
            >
              <Tab sx={themeError.home ? { color: 'red !important' } : { }} label={`Title${themeError.home ? '*' : ''}`} {...a11yProps(0)} />
              <Tab label="Thumbnail (Optional)" {...a11yProps(1)} />
              <Tab sx={themeError.environment ? { color: 'red !important' } : {}} label={`Environment${themeError.environment ? '*' : ''}`} {...a11yProps(2)} />
              <Tab label="Wall (Optional)" {...a11yProps(3)} />
              <Tab label="Floor (Optional)" {...a11yProps(4)} />
              <Tab sx={themeError.assistant || isAssistantTooBig() ? { color: 'red !important' } : {}} label={`Store assistant (Optional)${themeError.assistant || isAssistantTooBig() ? '*' : ''}`} {...a11yProps(5)} />
            </CustomTabs>
            <div className={`tabPanelContainer ${styles.tabPanelContainer}`}>
              <HomeTabPanel value={value} index={0} info={info} setInfo={setInfo} isShowError={themeError.home} />
              <TextureTabPanelOnlyImage key="thumbnail" value={value} index={1} type="thumbnail" asset={thumbnail} setFile={setThumbnail} isShowError={themeError.thumbnail} isOptional />
              <TextureTabPanel key="environment" value={value} index={2} type="environment" asset={environment} setFile={setEnvironment} isShowError={themeError.environment} allowModal />
              <TextureTabPanelOnlyImage
                key="wall"
                value={value}
                index={3}
                type="wall"
                asset={wall}
                setFile={setWall}
                isShowError={themeError.wall}
                isOptional
                hint="* Wall will be disappeared If empty"
              />
              <TextureTabPanelOnlyImage
                key="floor"
                value={value}
                index={4}
                type="floor"
                asset={floor}
                setFile={setFloor}
                isShowError={themeError.floor}
                isOptional
                hint="* floor will be disappeared If empty"
              />
              <AssistantTabPanel value={value} index={5} assistant={assistant} setFile={setAssistant} isShowError={themeError.assistant || isAssistantTooBig()} errorMessage={isAssistantTooBig() ? '*File size is too large, should <= 30 mb' : '* Missing File'} />
            </div>
          </div>
        </Paper>
        {/* -------- bottom button group ---------- */}
        <Box
          component="div"
          className={`buttonGroups ${styles.buttonGroups}`}
          sx={{ display: isDefaultTheme && 'none !important' }}
        >
          <ErrorComponet error={error} objectKey="" />
          {(!isCreateTheme && !isCurrentTheme()) && (
            <LoadingButton
              loading={loading}
              variant="outlined"
              color="error"
              onClick={() => {
                setIsDeleteTheme(true);
                props.deleteTheme(themeId);
              }}
            >
              delete
            </LoadingButton>
          )}
          <WhiteNormalButton
            text="save theme"
            loading={loading}
            callback={() => handleSaveButton(false)}
          />
          <NormalButton
            text="save & use theme"
            loading={loading}
            callback={() => handleSaveButton(true)}
          />
        </Box>
        {/* </Grid> */}
      </GeneralContent>
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => setThemeSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectThemeLoading(),
  error: makeSelectThemeError(),
  userId: makeSelectUserId(),
  themeList: makeSelectThemeList(),
  storeCurrentTheme: makeSelectStoreCurrentTheme(),
  snackbar: makeSelectThemeSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createTheme,
    editTheme,
    deleteTheme,
    setShowSnackbar,
    removeThemeError,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

EditThemes.propTypes = {
  createTheme: PropTypes.func,
  editTheme: PropTypes.func,
  deleteTheme: PropTypes.func,
  setShowSnackbar: PropTypes.func,
  removeThemeError: PropTypes.func,
  userId: PropTypes.string,
  themeList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  storeCurrentTheme: PropTypes.shape({
    _id: PropTypes.string,
  }),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
};

export default compose(
  withConnect,
)(EditThemes);
