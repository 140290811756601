import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { makeSelectUser } from 'services/user/selectors';
import { createStore } from 'services/store/actions';
import { makeSelectOnboardingStoreName, makeSelectSelectedStoreTheme } from 'services/store/selectors';
import { makeSelectIsAuthenticated } from 'services/auth/selectors';
import { isLogined } from 'utils/user.utils';
import StoreName from './Step/StoreName/StoreName';
import StoreSelection from './Step/StoreSelection/StoreSelection';
import Register from './Step/Register/Register';

import { customTheme } from '../../muiUtils/customTheme/index';

const theme = createTheme(customTheme);

function OnboardingContent(props) {
  const {
    user, name, selectedStoreTheme, isAuth,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const navigateToPreviewPage = () => {
    navigate(-1);
  };

  const redirectToDashboard = (storeId = null) => {
    if (storeId) {
      navigate(`/home?sid=${storeId}`, { replace: true, isLogin: true });
    } else {
      navigate('/home', { isLogin: true });
    }
  };

  const createNewStore = () => {
    const clonedSelectedTheme = _.cloneDeep(selectedStoreTheme);
    clonedSelectedTheme.name = name;
    clonedSelectedTheme.userIdList = [user._id];
    clonedSelectedTheme.themeId = selectedStoreTheme._id;
    props.createStore(clonedSelectedTheme, redirectToDashboard);
  };

  // eslint-disable-next-line consistent-return
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StoreName handleNext={handleNext} isLogined={isLogined(user)} handlePrevious={navigateToPreviewPage} />;
      case 1:
        return <StoreSelection handleNext={handleNext} handlePrevious={handlePrevious} isLogined={isLogined(user)} createNewStore={createNewStore} name={name} />;
      case 2:
        return <Register handleNext={redirectToDashboard} handlePrevious={handlePrevious} />;
      default:
        throw new Error('Unknown step');
    }
  }

  useEffect(() => {
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {getStepContent(activeStep)}
    </ThemeProvider>
  );
}

OnboardingContent.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string,
  }),
  isAuth: PropTypes.bool,
  name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  createStore: PropTypes.func.isRequired,
  selectedStoreTheme: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

function Onboarding(props) {
  const {
    user, name, selectedStoreTheme, isAuth,
  } = props;
  useEffect(() => {
  }, []);
  return (
    <OnboardingContent
      user={user}
      createStore={props.createStore}
      selectedStoreTheme={selectedStoreTheme}
      name={name}
      isAuth={isAuth}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectOnboardingStoreName(),
  isAuth: makeSelectIsAuthenticated(),
  user: makeSelectUser(),
  selectedStoreTheme: makeSelectSelectedStoreTheme(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createStore,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Onboarding.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string,
  }),
  isAuth: PropTypes.bool,
  name: PropTypes.string,
  createStore: PropTypes.func.isRequired,
  selectedStoreTheme: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

export default compose(
  withConnect,
)(Onboarding);
