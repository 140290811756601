/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileUploadInput from 'webComponents/FileUploadInput/FileUploadInput';
import _ from 'lodash';
import { isImage, isSupported3DModal } from 'utils/asset.utils';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import NumericFormatRatio from 'webComponents/NumericFormatRatio/NumericFormatRatio';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import { Asset } from 'type/asset.type';
import styles from './TextureTabPanel.module.scss';

function TextureTabPanel_onlyImage(props) {
  const {
    children, value, index, setFile, asset, type, isOptional, hint, isShowError, allowModal, ...other
  } = props;
  const [isUnknownFile, setIsUnknownFile] = useState(false);
  const [filename, setFilename] = useState('');
  const [fileObjectUrl, setFileObjectUrl] = useState(null);

  const handleFileChange = (event, isReplace = false) => {
    const [file] = event.target.files;
    setFilename(_.isUndefined(file.name) ? '' : file.name);
    if (!file || (!allowModal && !isImage(file.type)) || (allowModal && !isSupported3DModal(file.name) && !isImage(file.type))) {
      setIsUnknownFile(true);
      if (isReplace) setFileObjectUrl(null);
      return;
    }

    setFileObjectUrl(URL.createObjectURL(file));
    setFile(file);
  };

  const handleRatioChange = (event) => {
    const ratio = event.target.value;
    if (ratio >= 0) {
      setFile((prevState) => ({
        ...prevState,
        ratio,
      }));
    }
  };

  const removeFile = () => {
    setFileObjectUrl(null);
    setFilename('');
    setFile(null);
    setIsUnknownFile(false);
  };

  const getAssetComponent = () => {
    if (allowModal && isSupported3DModal(filename)) {
      return (
        <>
          <div className={styles.modalWrapper}>
            <ErrorBoundary>
              <CanvasModel model={fileObjectUrl || asset} ratio={props.ratio || 1} />
            </ErrorBoundary>
          </div>
          <NumericFormatRatio
            sx={{ mt: 2, width: '221px' }}
            name="ratio"
            value={props.ratio || 1}
            label="Ratio"
            variant="outlined"
            min="0"
            onChange={handleRatioChange}
            placeholder="ratio"
          />
        </>
      );
    }
    return (
      <div className={styles.imgWrapper}>
        <Box
          component="img"
          src={fileObjectUrl || asset}
          crossOrigin="anonymous"
          alt={`${type} file`}
        />
      </div>
    );
  };

  return (
    <div
      className={`textureTabPanel ${styles.textureTabPanel}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isShowError && <ErrorComponet objectKey="" error="* Missing File" />}
      { (fileObjectUrl || asset)
        ? (
          <div className={styles.fileUploaded}>
            <div className={styles.titleGroup}>
              <Typography variant="menuAndInput" component="h6">{`Upload ${type} file`}</Typography>
              <span style={{ color: '#5219F6', textDecoration: 'underline' }}>
                <label htmlFor={`file_uploads_${type}`} style={{ cursor: 'pointer' }}>Replace file</label>
                <input
                  type="file"
                  name={`file_uploads_${type}`}
                  id={`file_uploads_${type}`}
                  required="required"
                  hidden
                  onChange={(event) => handleFileChange(event, true)}
                />
              </span>
            </div>
            {getAssetComponent()}
            {isOptional
              && (
              <Button
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeFile}
              >
                Remove file
              </Button>
              )}
          </div>
        ) : (
          <>
            <FileUploadInput
              pageTitle={`Upload ${type} file`}
              pageFileFormatContent="Support png, jpeg and jpg"
              callback={handleFileChange}
              isValid={!isUnknownFile}
              filename={filename}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {hint}
            </Typography>
          </>
        )}
    </div>
  );
}

TextureTabPanel_onlyImage.defaultProps = {
  isShowError: false,
  isOptional: false,
  hint: '',
  allowModal: false,
  ratio: 1,
  // isDefaultTheme: false,
};

TextureTabPanel_onlyImage.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  setFile: PropTypes.func.isRequired,
  asset: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File), PropTypes.instanceOf(Asset)]),
  isOptional: PropTypes.bool,
  isShowError: PropTypes.bool,
  hint: PropTypes.string,
  allowModal: PropTypes.bool,
  ratio: PropTypes.number,
  // isDefaultTheme: PropTypes.bool,
};

export default TextureTabPanel_onlyImage;
