import _ from 'lodash';

const ROLES_ADMIN = ['Admin', 'SuperAdmin'];

export const isLogined = (user) => {
  if (_.has(user, '_id')) {
    return !_.isEmpty(user._id);
  }
  return false;
};

export const isLoginedByUserId = (userId) => !(_.isNull(userId) || _.isEmpty(userId));

export const isAdmin = (role) => ROLES_ADMIN.map((r) => r.toLocaleLowerCase()).includes(role.toLocaleLowerCase());
