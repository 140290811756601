/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';
import _ from 'lodash';
import { getAllItems } from 'services/item/actions';
import { makeSelectStoreId } from 'services/store/selectors';
import { makeSelectItemOutletLoading } from 'services/item/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';

function LayoutsOutlet(props) {
  const outletLoading = false;
  useEffect(() => {
  }, []);

  return (
    (outletLoading) ? <SimpleBackdrop open={outletLoading} /> : <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
  storeId: makeSelectStoreId(),
  outletLoading: makeSelectItemOutletLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllItems,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

LayoutsOutlet.propTypes = {
};

export default compose(
  withConnect,
)(LayoutsOutlet);
