/* eslint-disable */

// TODO: title & content limit
// TODO: Button clickable

class ShoppingButtonAttribute {
  constructor(isBuyButton, containerColor, insideContainerColor, textColor) {
    this.isBuyButton = isBuyButton;
    this.containerColor = containerColor;
    this.insideContainerColor = insideContainerColor;
    this.textColor = textColor;
  }
}

class SizeElmenent {
  constructor(width, height, radius) {
    this.width = width;
    this.height = height;
    this.radius = radius;
  }
}

const TITLE = "title";
const CONTENT = "content";
const boldFontPath = "../assets/font/Rubik-Bold.fnt";

AFRAME.registerComponent('info-panel', {
  schema: {
    title: {default: ''},
    content: {default: ''},
    width: {default: 2.1},
    ratio: {default: 1.5},
    radius: {default: 0.1},
    padding: {default: 0.02},
    distanceFar: {default: 4.5},
    distanceClose: {default: 3.5},
    adjustX: {default: 0},
    adjustY: {default: 1},
    adjustZ: {default: 0},
    eth: {default: 0.01},
    btc: {default: 0.01},
    hasPrice: {default: false},
    isAddToCartButton: {default: false},
    isBuyButton: {default: false},
    isEnableStripeTest: {default: true},
    isLink: {default: false},
    linkTitle: {default: ''},
    linkUrl: {default: 'https://double.store'},
    actionList: {default: '[]'}
  },

  init: function() {
    this.cam = document.getElementById('rig');
    let el = this.el;
    let data = this.data;
    this.height = data.width / data.ratio;
    this.isShowed = false;
    let parent_position = el.getAttribute('position');
    this.outsideRoundedContainer = this.createRoundedContainer({
      x: parent_position.x + data.adjustX, 
      y: parent_position.y + data.adjustY,
      z: parent_position.z + data.adjustZ}, 
      new SizeElmenent(data.width, this.height, data.radius), 
      "#79b6e4"
    );

    this.insideRoundedContainer = this.createRoundedContainer({x: 0.01, y: 0.01, z: 0.001}, new SizeElmenent(data.width - data.padding, this.height - data.padding, data.radius), "#3f3e73");
    this.outsideRoundedContainer.appendChild(this.insideRoundedContainer);
    this.titleEl = this.createContent(data.title, 3.5, {x: 0.08, y: this.height - 0.25, z: 0.001}, TITLE) // this.data.title
    this.outsideRoundedContainer.appendChild(this.titleEl);

    this.contentEl = this.createContent(data.content, 
      1.9, 
      {x: 0.08, y: this.height - 0.65, z: 0.001},
      CONTENT
    );

    this.outsideRoundedContainer.appendChild(this.contentEl);

    // if (data.isLink) {
    //   this.linkButton = this.createButton(data.linkTitle, {x: 1.2, y: 0.43, z: 0.002}, new SizeElmenent(0.75, 0.1, 0.03), new ShoppingButtonAttribute(true, "#36356d", "white", "#36356d"));
    //   this.linkButton.addEventListener('click', async function() {
    //     window.open(data.linkUrl);
    //   });
    // }

    if (data.hasPrice) {
      this.btcEl = this.createBTCPriceComponent();
      this.ethEl = this.createEthPriceComponent();
  
      this.outsideRoundedContainer.appendChild(this.btcEl);
      this.outsideRoundedContainer.appendChild(this.ethEl);
    }
    // const test = true;
    // if (test || data.isBuyButton) {
    //   this.buyButton = this.createButton("BUY NOW", {x: 1.5, y: 0.25, z: 0.002}, new SizeElmenent(0.45, 0.15, 0.03), new ShoppingButtonAttribute(true, "#36356d", "white", "#36356d"));
    //   this.buyButton.addEventListener('click', async function() {
    //     window.open("https://api.double.store/checkout.html")
    //     // const response = await fetch('https://api.double.store/checkout.html', {
    //     //   method: 'POST'
    //     // });
    //     // if (response.ok) {
    //     //   window.open(response.url)
    //     // }
    //   });
    //   this.outsideRoundedContainer.appendChild(this.buyButton);
    // }
    if (data.actionList) {
      JSON.parse(data.actionList).map((action, index) => {
        switch (index) {
          case 0:
            const temp = this.createButton(action.title, {x: 1.5, y: 0.25, z: 0.002}, new SizeElmenent(0.45, 0.125, 0.03), new ShoppingButtonAttribute(true, "#36356d", "white", "#36356d"));
            temp.addEventListener('click', async function() {
              window.open(action.url);
            });
            this.outsideRoundedContainer.appendChild(temp);
            break;
          case 1: {
            const temp = this.createButton(action.title, {x: 1.5, y: 0.1, z: 0.002}, new SizeElmenent(0.45, 0.125, 0.03), new ShoppingButtonAttribute(false, "#7eb3e2", "#7eb3e2" ,"white"));
            temp.addEventListener('click', async function() {
              window.open(action.url);
            });
            this.outsideRoundedContainer.appendChild(temp);
            break;
          }
          default:
        }
      });
    }

    let sceneEl = document.querySelector('a-scene');
    sceneEl.insertBefore(this.outsideRoundedContainer, sceneEl.firstChild);
  },
  update: function() {
    // entityEl.parentNode.removeChild(entityEl);
  },
  remove: function() {
    this.outsideRoundedContainer.parentNode.removeChild(this.outsideRoundedContainer);
  },
  tick: function() {
    let camPos = this.cam.object3D.position;
    let spherePos = this.el.object3D;
    let panelPos = this.outsideRoundedContainer.object3D.position;
    let deepCloneCamPos = JSON.parse(JSON.stringify(spherePos.position));
    deepCloneCamPos.y = camPos.y;
    let distance = camPos.distanceTo(deepCloneCamPos);
    let isShowComponent = distance > 1 && distance <= this.data.distanceFar;
    let isShowDetailComponent = distance <= this.data.distanceClose;
    let isShowingDetail = this.isShowingDetail();
    let parent_position = this.el.getAttribute('position');
    let out = this.outsideRoundedContainer.getDOMAttribute('position');
    this.outsideRoundedContainer.setAttribute("position", {x: parent_position.x + this.data.adjustX, 
      y: parent_position.y + this.data.adjustY,
      z: parent_position.z + this.data.adjustZ});
    if (isShowComponent) {
      this.outsideRoundedContainer.setAttribute('visible', true);  
      this.outsideRoundedContainer.setAttribute("position", {x: parent_position.x + this.data.adjustX, 
        y: parent_position.y + this.data.adjustY,
        z: parent_position.z + this.data.adjustZ});
      if (isShowDetailComponent) {
        if (!isShowingDetail) {
          this.showDetail();
        }
      }
    } else {
      this.outsideRoundedContainer.object3D.lookAt(camPos.x, panelPos.y, camPos.z);
      this.hideDetail();
      this.outsideRoundedContainer.setAttribute('visible', false);
    }
  },
  /**
   * 
   * @param {*} position x,y,z
   * @param {*} size width, height, radius
   * @param {*} color 
   */
  createRoundedContainer: function(position, size, color) {
    let roundedContainer = document.createElement('a-rounded');
    roundedContainer.setAttribute("position", {x: position.x, y: position.y, z: position.z});
    roundedContainer.setAttribute("width", size.width);
    roundedContainer.setAttribute("height", size.height);
    roundedContainer.setAttribute("radius", size.radius);
    roundedContainer.setAttribute("color", color);
    return roundedContainer;
  },
  createContent: function(text, textWidth, position, type="content") {
    let containerEl = document.createElement('a-entity');
    containerEl.setAttribute("geometry", {primitive: "plane", height: "auto", width: "auto"});
    containerEl.setAttribute("material", {side: "double"});
    containerEl.setAttribute("position", position);
    // containerEl.setAttribute("text", { value: text });
    let contentEl = document.createElement('a-text');
    contentEl.setAttribute("width", textWidth);    
    
    // contentEl.setAttribute("height", textWidth / 2);
    let textLength = text.length;
    switch(type.toLowerCase()) {
      case "title":
        contentEl.setAttribute("font", boldFontPath);
        const nextLineLimit = 30;
        if (textLength >= nextLineLimit) {
          contentEl.setAttribute("width", textWidth - 1);
          const lastSpace = text.lastIndexOf(' ');
          const newStr = text.substring(0, lastSpace) + '\n' + text.substring(lastSpace + 1);
          contentEl.setAttribute("value", newStr);
        } else {
          contentEl.setAttribute("value", text);
        }
        break;
      case "content":
        contentEl.setAttribute("line-height", 55);
        contentEl.setAttribute("value", text);
        break;
      default:
    }

    containerEl.appendChild(contentEl);

    return containerEl;
  },
  createEthPriceComponent: function() {
    let containerEl = document.createElement('a-entity');
    containerEl.setAttribute("position", "0.90 0.3 0.01");

    let img = document.createElement('a-image');
    img.setAttribute("src", "#ethIcon");
    img.setAttribute("scale", "0.225 0.3");

    let priceEl = document.createElement('a-text');
    priceEl.setAttribute("value", this.data.eth);
    priceEl.setAttribute("position", "0.125 0 0");
    if (`${this.data.eth}`.length >= 4) {
      priceEl.setAttribute("width", 2);
    } else {
      priceEl.setAttribute("width", 3);
    }
    priceEl.setAttribute("font", boldFontPath);

    containerEl.appendChild(img);
    containerEl.appendChild(priceEl);

    return containerEl;
  },
  createBTCPriceComponent: function() {
    let containerEl = document.createElement('a-entity');
    containerEl.setAttribute("position", "0.2 0.3 0.01");

    let img = document.createElement('a-image');
    img.setAttribute("src", "#bitcoinIcon");
    img.setAttribute("scale", "0.3 0.3");

    let priceEl = document.createElement('a-text');
    priceEl.setAttribute("value", this.data.btc);
    priceEl.setAttribute("position", "0.15 0 0");
    if (`${this.data.btc}`.length >= 4) {
      priceEl.setAttribute("width", 2);
    } else {
      priceEl.setAttribute("width", 3);
    }
    priceEl.setAttribute("font", boldFontPath);

    containerEl.appendChild(img);
    containerEl.appendChild(priceEl);

    return containerEl;
  },
  createButton: function(content, position, size, shoppingButtonAttribute) {
    let outsideContainerEl = this.createRoundedContainer(position, size, shoppingButtonAttribute.insideContainerColor);
    let textEl = document.createElement('a-text');
    
    let height = outsideContainerEl.getAttribute('height');
    let width = outsideContainerEl.getAttribute('width');
    let widthRatio = 0.05;
    if (shoppingButtonAttribute.isBuyButton) {
      textEl.setAttribute("width", "2.25");
    } else {
      textEl.setAttribute("width", "1.55");
    }
    textEl.setAttribute("position", `${width * widthRatio} ${height/2 * 1.1} 0.001`);
    textEl.setAttribute("color", shoppingButtonAttribute.textColor);
    textEl.setAttribute("value", content);
    textEl.setAttribute("font", boldFontPath);

    outsideContainerEl.appendChild(textEl);

    return outsideContainerEl;
  },
  showDetail: function() {
    this.contentEl.setAttribute('visible', true);
    this.outsideRoundedContainer.setAttribute("height", this.height);
    let out = this.outsideRoundedContainer.getDOMAttribute('position');
    out.y += 0.25;
    // this.outsideRoundedContainer.setAttribute("position", out); // y + 0.25 - 0.25    
    this.insideRoundedContainer.setAttribute("height", this.height - this.data.padding);
    this.titleEl.setAttribute("position", `${0.08} ${this.height - 0.25} ${0.001}`);
  },
  hideDetail: function() {
    this.contentEl.setAttribute('visible', false);
    this.outsideRoundedContainer.setAttribute("height", 1);
    let out = this.outsideRoundedContainer.getDOMAttribute('position');
    out.y -= 0.25;
    // this.outsideRoundedContainer.setAttribute("position", out);
    this.insideRoundedContainer.setAttribute("height", 1 - this.data.padding);
    this.titleEl.setAttribute("position", `${0.08} ${this.height - 0.7} ${0.001}`);
  },
  isShowingDetail: function() {
    return this.contentEl.getAttribute('visible');
  }
})