import { combineReducers } from 'redux';
import messageReducer from 'services/message/reducer';
import storeReducer from 'services/store/reducer';
import itemReducer from 'services/item/reducer';
import userReducer from 'services/user/reducer';
import authReducer from 'services/auth/reducer';
import themeReducer from 'services/theme/reducer';
import routeReducer from 'services/route/reducer';
import templateReducer from 'services/template/reducer';
import keymakerReducer from 'services/keymaker/reducer';
import channelReducer from 'services/channel/reducer';

export default combineReducers({
  message: messageReducer,
  store: storeReducer,
  item: itemReducer,
  user: userReducer,
  auth: authReducer,
  theme: themeReducer,
  template: templateReducer,
  route: routeReducer,
  keymaker: keymakerReducer,
  channel: channelReducer,
});
