import React from 'react';
import { LoadingButton } from '@mui/lab';
import { PropTypes } from 'prop-types';

function NormalButton({
  children, loading, text, sx = {}, callback = () => {}, ...props
}) {
  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      sx={sx}
      onClick={() => callback()}
      {...props}
    >
      {text}
      {children}
    </LoadingButton>
  );
}

NormalButton.defaultProps = {
  loading: false,
  sx: { fontWeight: 700 },
};

NormalButton.propTypes = {
  text: PropTypes.string,
  sx: PropTypes.shape(),
  callback: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default NormalButton;
