/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import 'aframe';
import 'mind-ar/dist/mindar-face-aframe.prod';
import MindARFaceViewer from './mindar-face-viewer';

function MindAR(props) {
  const [started, setStarted] = useState(null);

  return (
    <div className="App" style={{textAlign: 'center'}}>

      <div className="control-buttons" style={{ position: 'absolute' }}>
        {started === null && <button style={{
        fontSize: 'larger', zIndex: 1000,
      }} onClick={() => {setStarted('aframe')}}>Start Face tracking</button>}
        {started !== null && <button style={{
        fontSize: 'larger', zIndex: 1000,
      }} onClick={() => {setStarted(null)}}>Stop</button>}
      </div>

      {started === 'aframe' && (
        <div className='container' style={{ margin: '20px auto', position: 'relative', height: '300px', width: '600px' }}>
          <MindARFaceViewer />
          <video></video>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

MindAR.propTypes = {
};

export default compose(
  withConnect,
)(MindAR);
