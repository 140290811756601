import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

export default function ImageGrid(props) {
  const {
    imgSrc, imgStyle, isCurrentTheme, callback,
  } = props;
  return (
    <div
      role="button"
      onKeyPress={() => {}}
      tabIndex="0"
      onClick={() => callback()}
    >
      <img
        className={imgStyle}
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
        src={imgSrc}
        crossOrigin="anonymous"
        alt="theme"
        loading="lazy"
      />
    </div>
  );
}

ImageGrid.defaultProps = {
  isCurrentTheme: false,
  callback: () => {},
};

ImageGrid.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgStyle: PropTypes.string.isRequired,
  isCurrentTheme: PropTypes.bool,
  callback: PropTypes.func,
};
