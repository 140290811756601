import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import BootstrapInput from 'webComponents/Input/BootstrapInput';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Grid from '@mui/material/Unstable_Grid2';
import NumericFormatRatio from 'webComponents/NumericFormatRatio/NumericFormatRatio';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'lodash';
import Switch from '@mui/material/Switch';
import { addBackgroundMusic, updateBackgroundMusic, deleteBackgroundMusic } from 'services/store/actions';
import { makeSelectStoreLoading } from 'services/store/selectors';
import MusicPlayerSlider from './MusicPlayerSlider';
import VolumeControl from './VolumeControl';

function MusicContainer(props) {
  const { data, loading, worldId } = props;
  const [backgroundMusic, setBackgroundMusic] = useState(_.cloneDeep(data));
  const [audioFile, setAudioFile] = useState(null);
  const [tempAudioObjectUrl, setTempAudioObjectUrl] = useState(null);
  const supportedformatList = ['audio/mpeg', 'audio/wav'];
  const [error, setError] = useState('');
  const fileSizeLimit = 20;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updateFields = (prevData) => ({
      ...prevData,
      [name]: value,
    });
    if (_.startsWith(name, 'metadata')) {
      const metadata = _.cloneDeep(backgroundMusic.metadata);
      const field = name.split('.')[1];
      metadata[field] = value;
      updateFields = (prevData) => ({
        ...prevData,
        metadata,
      });
    }
    setBackgroundMusic(updateFields);
  };

  const handleAudioChange = (event) => {
    const [file] = event.target.files;
    const { type, size, name } = file;
    const isValidFileType = supportedformatList.includes(type);
    if (!isValidFileType) {
      setError('Unsupported file format');
      return;
    }
    if (size / 1024 / 1024 > fileSizeLimit) {
      setError(`File Size larger than ${fileSizeLimit} MB`);
      return;
    }
    setAudioFile(file);
    setTempAudioObjectUrl(URL.createObjectURL(file));
    setError('');
    handleInputChange({
      target: {
        name: 'title',
        value: name,
      },
    });
    handleInputChange({
      target: {
        name: 'metadata.format',
        value: name.split('.')[name.split('.').length - 1],
      },
    });
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    const e = {
      target: {
        name,
        value: checked,
      },
    };
    handleInputChange(e);
  };

  const hasMusic = () => backgroundMusic._id !== '' || audioFile || tempAudioObjectUrl;
  const hasError = () => !_.isEmpty(error);
  const hasNewMusic = () => !_.isNull(audioFile);
  const isExistMusic = () => backgroundMusic._id !== '';
  const save = () => {
    const temp = _.cloneDeep(backgroundMusic);
    if (audioFile) {
      temp.file = audioFile;
    }
    if (isExistMusic()) {
      props.updateBackgroundMusic(temp);
    } else {
      props.addBackgroundMusic(temp);
    }
  };

  const audioElement = () => document.getElementById('audio-background-music');

  const setAudioVolume = (event) => {
    const { value } = event.target;
    handleInputChange(event);
    audioElement().volume = value / 100;
  };

  useEffect(() => {
  }, []);

  return (
    <Box component="div" sx={{ maxWidth: '450px' }}>
      {/* <MusicPlayerSlider audio={tempAudioObjectUrl} /> */}
      <Stack spacing={5}>
        <Box component="div">
          <Typography id="input-slider" sx={{ marginBottom: 1 }}>
            Background Music
          </Typography>
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            * File size is limited to a max of 30 MB
          </Typography>

          {hasError()
            && (
            <Typography variant="subtitle2" sx={{ marginBottom: 1, color: 'red' }}>
              {error}
            </Typography>
            )}

          {hasMusic() && <audio id="audio-background-music" controls src={tempAudioObjectUrl || backgroundMusic.url} style={{ marginTop: '8px', marginBottom: '8px' }}><track kind="captions" /></audio>}
          {hasMusic() && <div><b>{hasNewMusic(audioFile) ? audioFile.name : backgroundMusic.title}</b></div>}
          <Box component="div" sx={{ maringTop: 1 }}>
            <span style={{ color: '#5219F6', textDecoration: 'underline' }}>
              <label htmlFor="audio_uploads" style={{ cursor: 'pointer' }}>{hasMusic() ? 'Replace audio' : 'Upload Audio' }</label>
              <input
                type="file"
                name="audio_uploads"
                id="audio_uploads"
                required="required"
                hidden
                onChange={(event) => handleAudioChange(event)}
              />
            </span>
          </Box>
        </Box>
        {hasMusic() && <VolumeControl volume={backgroundMusic.metadata.volume} setAudioVolume={setAudioVolume} />}
        {hasMusic()
        && (
        <Box component="div">
          <Typography id="input-slider" sx={{ marginBottom: 1 }}>
            Loop
            <Checkbox
              name="metadata.isLoop"
              checked={backgroundMusic.metadata.isLoop}
              onChange={handleCheckBoxChange}
            />
          </Typography>
          <FormHelperText>Whether to loop the sound once the sound finishes playing.</FormHelperText>
        </Box>
        )}
        {hasMusic()
        && (
        <Box component="div">
          <Typography id="input-slider" sx={{ marginBottom: 1 }}>
            Apply to the world
            <Switch
              name="isActive"
              checked={backgroundMusic.isActive}
              onChange={handleCheckBoxChange}
            />
          </Typography>
          <FormHelperText>Immediately apply to the world.</FormHelperText>
        </Box>
        )}
        {hasMusic()
        && (
        <Grid container spacing={2}>
          {
            isExistMusic() && (
            <Grid xs={3}>
              <LoadingButton
                loading={loading}
                variant="outlined"
                color="error"
                onClick={() => {
                  // setIsDeleteTheme(true);
                  // props.deleteTheme(themeId);
                  props.deleteBackgroundMusic(backgroundMusic._id, worldId);
                }}
              >
                delete
              </LoadingButton>
            </Grid>
            )
          }
          <Grid xs={3}><LoadingButton loading={loading} variant="contained" size="large" onClick={() => save()}>Save</LoadingButton></Grid>
        </Grid>
        )}
      </Stack>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addBackgroundMusic,
    deleteBackgroundMusic,
    updateBackgroundMusic,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

MusicContainer.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
  }),
  addBackgroundMusic: PropTypes.func,
  deleteBackgroundMusic: PropTypes.func,
  updateBackgroundMusic: PropTypes.func,
  loading: PropTypes.bool,
  worldId: PropTypes.string,

};

export default compose(
  withConnect,
)(MusicContainer);
