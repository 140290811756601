export const GET_PERSISTED_TOKEN_SUCCESS = 'GET_PERSISTED_TOKEN_SUCCESS';

export const CREATE_AUTH_TOKEN_SUCCESS = 'CREATE_AUTH_TOKEN_SUCCESS';

export const DELETE_AUTH_TOKEN = 'DELETE_AUTH_TOKEN';
export const DELETE_AUTH_TOKEN_SUCCESS = 'DELETE_AUTH_TOKEN_SUCCESS';
export const DELETE_AUTH_TOKEN_FAIL = 'DELETE_AUTH_TOKEN_FAIL';

export const DEAUTHENTICATE = 'DEAUTHENTICATE';
export const LOGOUT = 'LOGOUT';

export const REGISTER_WITH_TEMPLATE = 'REGISTER_WITH_TEMPLATE';
export const REGISTER_WITH_TEMPLATE_SUCCESS = 'REGISTER_WITH_TEMPLATE_SUCCESS';
export const REGISTER_WITH_TEMPLATE_FAIL = 'REGISTER_WITH_TEMPLATE_FAIL';

export const SET_HOME_SHOW_SNACKBAR = 'SET_HOME_SHOW_SNACKBAR';
