import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import SvgIcon from '@mui/material/SvgIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';
import { makeSelectKeymakerIsShow } from 'services/keymaker/selectors';
import ChatIcon from '@mui/icons-material/Chat';
// import KeyboardIcon from '../../assets/Onboarding/icons/noun-keyboard.png';
// import SwipeIcon from '../../assets/Onboarding/icons/noun-swipe.png';
// import EscIcon from '../../assets/Onboarding/icons/esc.png';

function LeftBottomIconList(props) {
  const {
    name, isShowInstruction, setIsShowInstruction, isShowChat, setIsShowChat,
  } = props;

  return (
    <div style={{
      zIndex: 1000,
      left: 50,
      bottom: 25,
      position: 'absolute',
      color: 'white',
    }}
    >
      <Stack direction="row" spacing={3}>
        <IconButton
          aria-label="close"
          sx={{
            backgroundColor: 'rgba(237, 228, 255, 0.55)',
            color: 'white',
          }}
          onClick={() => { setIsShowInstruction(!isShowInstruction); }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          sx={{
            backgroundColor: 'rgba(237, 228, 255, 0.55)',
            color: 'white',
          }}
          onClick={() => { setIsShowChat(!isShowChat); }}
        >
          <ChatIcon />
        </IconButton>
        {/* <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Exit</Typography>
        <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Move</Typography>
        <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Pan</Typography> */}
      </Stack>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

LeftBottomIconList.propTypes = {
  name: PropTypes.string,
  setIsShowInstruction: PropTypes.func,
  setIsShowChat: PropTypes.func,
  isShowChat: PropTypes.bool,
  isShowInstruction: PropTypes.bool,
  // isShowKeymakerChat: PropTypes.bool,
};

export default compose(
  withConnect,
)(LeftBottomIconList);
