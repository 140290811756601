/* eslint-disable react/no-unused-prop-types */

import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { makeSelectStoreCurrentMap } from 'services/store/selectors';

const Styles = {
  // canvas: {
  //   border: '1px solid #E5E5E5',
  // },
  block: {
    wall: {
      color: 'black',
    },
  },
};

function Map(props) {
  const {
    map, pinItemOnMap, items, selectedIndex,
  } = props;
  const clonedMap = _.cloneDeep(map);
  const [mapWidth, setMapWidth] = useState(clonedMap.width);
  const [mapHeight, setMapHeight] = useState(clonedMap.height);
  const size = 27;

  const tempMap = _.cloneDeep(clonedMap.data);
  const getItemByMapIndex = (index) => _.find(items, { location: _.toString(index) });

  function drapMap() {
    const buffer = document.createElement('canvas').getContext('2d');
    const ctx = document.getElementById('mapCanvas');
    const context = ctx.getContext('2d');

    buffer.canvas.width = mapWidth * size;
    buffer.canvas.height = mapHeight * size;

    for (let index = 0; index < tempMap.length; index += 1) {
      buffer.fillStyle = (tempMap[index] === 2) ? '#000000' : '#ffffff';

      const item = getItemByMapIndex(index);
      if (item) {
        buffer.fillStyle = item.hidden ? buffer.fillStyle : item.color;
      }

      buffer.fillRect((index % mapWidth) * size, Math.floor(index / mapWidth) * size, size, size);
    }

    context.canvas.width = mapWidth * size;
    context.canvas.height = mapHeight * size;
    context.drawImage(buffer.canvas, 0, 0, buffer.canvas.width, buffer.canvas.height, 0, 0, context.canvas.width, context.canvas.height);
  }

  function getMapIndex(event) {
    const canvas = document.getElementById('mapCanvas');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const index = Math.floor(y / size) * mapWidth + Math.floor(x / size);
    return index;
  }

  function allowToPinOnMap(index) {
    return tempMap[index] !== 2;
  }

  function onCanvasMouseDown(event) {
    event.stopPropagation();
    event.preventDefault();
    const index = getMapIndex(event);
    if (!allowToPinOnMap(index)) {
      return;
    }
    pinItemOnMap(index);
    drapMap();
  }

  function onCanvasMouseMove(event) {
  }

  function onMouseUp() {
  }

  useEffect(() => {
    drapMap();
    const canvas = document.getElementById('mapCanvas');
    canvas.addEventListener('mousedown', onCanvasMouseDown);
    canvas.addEventListener('mousemove', onCanvasMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      canvas.removeEventListener('mousedown', onCanvasMouseDown);
      canvas.removeEventListener('mousemove', onCanvasMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [selectedIndex, items]);

  return <canvas style={Styles.canvas} id="mapCanvas"></canvas>;
}

const mapStateToProps = createStructuredSelector({
  map: makeSelectStoreCurrentMap(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Map.propTypes = {
  pinItemOnMap: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  map: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
    location: PropTypes.string,
  })),
};

export default compose(
  withConnect,
)(Map);
