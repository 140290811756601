/* eslint-disable camelcase */
import _ from 'lodash';

export function isValidStoreUrl(location) {
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const has_sid = searchParams.has('sid');
  if (!has_sid) return false;
  return !_.isEmpty(searchParams.get('sid'));
}

export function getStoreId(search) {
  const searchParams = new URLSearchParams(search);
  return searchParams.get('sid');
}
