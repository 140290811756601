import {
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_FAIL,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATE_DETAIL_FAIL,
} from './constants';

export const getTemplateList = () => ({
  type: GET_TEMPLATE_LIST,
});

export const getTemplateListSuccess = (data) => ({
  type: GET_TEMPLATE_LIST_SUCCESS,
  data,
});

export const getTemplateListFail = (error) => ({
  type: GET_TEMPLATE_LIST_FAIL,
  error,
});

export const getTemplateDetail = (id) => ({
  id,
  type: GET_TEMPLATE_DETAIL,
});

export const getTemplateDetailSuccess = (data) => ({
  type: GET_TEMPLATE_DETAIL_SUCCESS,
  data,
});

export const getTemplateDetailFail = (error) => ({
  type: GET_TEMPLATE_DETAIL_FAIL,
  error,
});
