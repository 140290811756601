const { Abc } = require('@mui/icons-material');

/* eslint-disable */
AFRAME.registerComponent('keymaker-panel', {
  schema: {
    distanceFar: {default: 4.5},
    distanceClose: {default: 3.5},
    isShow: {default: false},
    // setIsShow: {default: () => {}},
  },

  init: function() {
    this.cam = document.getElementById('rig');
    var el = this.el;
    var data = this.data;
  },
  update: function() {
  },
  remove: function() {
  },
  tick: function() {
    let camPos = this.cam.object3D.position;
    let spherePos = this.el.object3D;
    let deepCloneCamPos = JSON.parse(JSON.stringify(spherePos.position));
    deepCloneCamPos.y = camPos.y;
    let distance = camPos.distanceTo(deepCloneCamPos);
    let isShowComponent = distance > 1 && distance <= this.data.distanceFar;
    // console.log(this.data);
    if (!this.data.isShow) {
      // Function(this.data.setIsShow(isShowComponent));
      // abc();
      // this.data.setIsShow(isShowComponent).parseFunction();
    }
  }
})