import {
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  GET_STORE_DETAIL,
  GET_STORE_DETAIL_SUCCESS,
  GET_STORE_DETAIL_FAIL,
  GET_STORE_LIST,
  GET_STORE_LIST_SUCCESS,
  GET_STORE_LIST_FAIL,
  GET_STORE_BY_NAME,
  GET_STORE_BY_NAME_SUCCESS,
  GET_STORE_BY_NAME_FAIL,
  GET_STORE_BY_NAME_WITH_ITEMS,
  GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS,
  GET_STORE_BY_NAME_WITH_ITEMS_FAIL,
  UPDATE_STORE_NAME,
  UPDATE_STORE_LOGO,
  UPDATE_STORE_MAP,
  UPDATE_STORE_MAP_SUCCESS,
  UPDATE_STORE_MAP_FAIL,
  UPDATE_STORE_MAP_BASE64_IMG,
  UPDATE_STORE_ASSISTANT,
  UPDATE_STORE_ASSISTANT_SUCCESS,
  UPDATE_STORE_ASSISTANT_FAIL,
  VALIDATE_STORE_NAME,
  VALIDATE_STORE_NAME_SUCCESS,
  VALIDATE_STORE_NAME_FAIL,
  GET_STORE_THEME,
  GET_STORE_THEME_SUCCESS,
  GET_STORE_THEME_FAIL,
  SET_STORE_THEME,
  UPDATE_STORE_DETAIL,
  UPDATE_STORE_DETAIL_SUCCESS,
  UPDATE_STORE_DETAIL_FAIL,
  UPDATE_ONBOARDING_STORE_NAME,
  GET_STORE_BY_PUBLIC_ID_WITH_ITEMS,
  STORE_SHOW_SNACKBAR_BAR,
  UPDATE_STORE_THEME,
  UPDATE_STORE_THEME_SUCCESS,
  UPDATE_STORE_THEME_FAIL,
  UPDATE_STORE_CURRENT_THEME,
  SET_LAYOUT_SHOW_SNACKBAR_BAR,
  CREATE_STORE_BY_TEMPLATE,
  CREATE_STORE_BY_TEMPLATE_SUCCESS,
  CREATE_STORE_BY_TEMPLATE_FAIL,
  UPDATE_STORE_DETAIL_FROM_ADMIN,
  ADD_BACKGROUND_MUSIC,
  ADD_BACKGROUND_MUSIC_SUCCESS,
  ADD_BACKGROUND_MUSIC_FAIL,
  UPDATE_BACKGROUND_MUSIC,
  UPDATE_BACKGROUND_MUSIC_SUCCESS,
  UPDATE_BACKGROUND_MUSIC_FAIL,
  DELETE_BACKGROUND_MUSIC,
  DELETE_BACKGROUND_MUSIC_SUCCESS,
  DELETE_BACKGROUND_MUSIC_FAIL,
  BACKGROUND_MUSIC_SNACKBAR,
} from './constants';

export const createStore = (data, callback) => ({
  type: CREATE_STORE,
  data,
  callback,
});

export const createStoreSuccess = (data) => ({
  type: CREATE_STORE_SUCCESS,
  data,
});

export const createStoreFail = (error) => ({
  type: CREATE_STORE_FAIL,
  error,
});
export const getStoreList = (userId, storeId = null, callback = () => {}) => ({
  type: GET_STORE_LIST,
  userId,
  callback,
  storeId,
});

export const getStoreListSuccess = (data) => ({
  type: GET_STORE_LIST_SUCCESS,
  data,
});

export const getStoreListFail = (error) => ({
  type: GET_STORE_LIST_FAIL,
  error,
});

export const getStoreDetail = (storeId) => ({
  type: GET_STORE_DETAIL,
  storeId,
});

export const getStoreDetailSuccess = (data) => ({
  type: GET_STORE_DETAIL_SUCCESS,
  data,
});

export const getStoreDetailFail = (error) => ({
  type: GET_STORE_DETAIL_FAIL,
  error,
});

export const updateStoreName = (data) => ({
  type: UPDATE_STORE_NAME,
  data,
});

export const updateStoreLogo = (data) => ({
  type: UPDATE_STORE_LOGO,
  data,
});

export const updateStoreMap = ({ id, data }) => ({
  type: UPDATE_STORE_MAP,
  id,
  data,
});

export const updateStoreMapSuccess = (data) => ({
  type: UPDATE_STORE_MAP_SUCCESS,
  data,
});

export const updateStoreMapFail = (error) => ({
  type: UPDATE_STORE_MAP_FAIL,
  error,
});

export const updateStoreMapBase64Image = (data) => ({
  type: UPDATE_STORE_MAP_BASE64_IMG,
  data,
});

export const updateStoreAssistant = ({ assistantId, data }) => ({
  type: UPDATE_STORE_ASSISTANT,
  assistantId,
  data,
});

export const updateStoreAssistantSuccess = (data) => ({
  type: UPDATE_STORE_ASSISTANT_SUCCESS,
  data,
});

export const updateStoreAssistantFail = (error) => ({
  type: UPDATE_STORE_ASSISTANT_FAIL,
  error,
});

export const validateStoreName = (data, callback) => ({
  type: VALIDATE_STORE_NAME,
  data,
  callback,
});

export const validateStoreNameSuccess = () => ({
  type: VALIDATE_STORE_NAME_SUCCESS,
});

export const validateStoreNameFail = (error) => ({
  type: VALIDATE_STORE_NAME_FAIL,
  error,
});

export const getStoreTheme = () => ({
  type: GET_STORE_THEME,
});

export const getStoreThemeSuccess = (data) => ({
  type: GET_STORE_THEME_SUCCESS,
  data,
});

export const getStoreThemeFail = (error) => ({
  type: GET_STORE_THEME_FAIL,
  error,
});

export const setStoreTheme = (data) => ({
  type: SET_STORE_THEME,
  data,
});

export const updateStoreDetail = ({ storeId, data }) => ({
  type: UPDATE_STORE_DETAIL,
  storeId,
  data,
});

export const updateStoreDetailFromAdmin = () => ({
  type: UPDATE_STORE_DETAIL_FROM_ADMIN,
});

export const updateStoreDetailSuccess = (data) => ({
  type: UPDATE_STORE_DETAIL_SUCCESS,
  data,
});

export const updateStoreDetailFail = (error) => ({
  type: UPDATE_STORE_DETAIL_FAIL,
  error,
});

export const updateOnboardingStoreName = (data) => ({
  type: UPDATE_ONBOARDING_STORE_NAME,
  data,
});

export const getStoreByName = (name) => ({
  type: GET_STORE_BY_NAME,
  name,
});

export const getStoreByNameSuccess = (data) => ({
  type: GET_STORE_BY_NAME_SUCCESS,
  data,
});

export const getStoreByNameFail = (error) => ({
  type: GET_STORE_BY_NAME_FAIL,
  error,
});

export const getStoreByNameWithItems = (name) => ({
  type: GET_STORE_BY_NAME_WITH_ITEMS,
  name,
});

export const getStoreByNameWithItemsSuccess = (data) => ({
  type: GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS,
  data,
});

export const getStoreByNameWithItemsFail = (error) => ({
  type: GET_STORE_BY_NAME_WITH_ITEMS_FAIL,
  error,
});

export const getStoreByPublicIdWithItems = (id, isEdit = false) => ({
  type: GET_STORE_BY_PUBLIC_ID_WITH_ITEMS,
  id,
  isEdit,
});

export const setShowSnackbar = (bool) => ({
  type: STORE_SHOW_SNACKBAR_BAR,
  bool,
});

export const updateStoreTheme = (data) => ({
  type: UPDATE_STORE_THEME,
  data,
});

export const updateStoreThemeSuccess = (data) => ({
  type: UPDATE_STORE_THEME_SUCCESS,
  data,
});

export const updateStoreThemeFail = (error) => ({
  type: UPDATE_STORE_THEME_FAIL,
  error,
});

export const updateStoreCurrentTheme = (theme) => ({
  type: UPDATE_STORE_CURRENT_THEME,
  theme,
});

export const setShowLayoutSnackbar = (bool) => ({
  type: SET_LAYOUT_SHOW_SNACKBAR_BAR,
  bool,
});

export const createStoreByTemplate = (data) => ({
  type: CREATE_STORE_BY_TEMPLATE,
  data,
});

export const createStoreByTemplateSuccess = (data) => ({
  type: CREATE_STORE_BY_TEMPLATE_SUCCESS,
  data,
});

export const createStoreByTemplateFail = (error) => ({
  type: CREATE_STORE_BY_TEMPLATE_FAIL,
  error,
});

export const addBackgroundMusic = (data) => ({
  type: ADD_BACKGROUND_MUSIC,
  data,
});

export const addBackgroundMusicSuccess = (data) => ({
  type: ADD_BACKGROUND_MUSIC_SUCCESS,
  data,
});

export const addBackgroundMusicFail = (error) => ({
  type: ADD_BACKGROUND_MUSIC_FAIL,
  error,
});

export const updateBackgroundMusic = (data) => ({
  type: UPDATE_BACKGROUND_MUSIC,
  data,
});

export const updateBackgroundMusicSuccess = (data) => ({
  type: UPDATE_BACKGROUND_MUSIC_SUCCESS,
  data,
});

export const updateBackgroundMusicFail = (error) => ({
  type: UPDATE_BACKGROUND_MUSIC_FAIL,
  error,
});

export const deleteBackgroundMusic = (id, worldId) => ({
  type: DELETE_BACKGROUND_MUSIC,
  id,
  worldId,
});

export const deleteBackgroundMusicSuccess = (data) => ({
  type: DELETE_BACKGROUND_MUSIC_SUCCESS,
  data,
});

export const deleteBackgroundMusicFail = (error) => ({
  type: DELETE_BACKGROUND_MUSIC_FAIL,
  error,
});

export const setShowBackgroundMusicSnackbar = (bool) => ({
  type: BACKGROUND_MUSIC_SNACKBAR,
  bool,
});
