import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Backbutton(props) {
  return (
    <IconButton {...props}>
      <ArrowBackIcon />
    </IconButton>
  );
}

export default Backbutton;
