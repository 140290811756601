// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ep0wJyVORvJTtnI6QoPd{background-color:#d9d9d9;width:calc(100% - 40px);max-width:386px;display:flex;justify-content:center;align-items:center}.QAZwJk9Dqr3zVhuu8w3Q{position:relative;width:fit-content}.QAZwJk9Dqr3zVhuu8w3Q .VYHgGvEPWClJLxdrns0s{position:absolute;top:36px;right:0}`, "",{"version":3,"sources":["webpack://./src/routes/store/store.module.scss"],"names":[],"mappings":"AAEA,sBACE,wBAAA,CACA,uBAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,QAAA,CACA,OAAA","sourcesContent":["@use '../../scss//base/typography';\n\n.imgWrapper {\n  background-color: #D9D9D9;\n  width: calc(100% - 40px);\n  max-width: 386px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.customDomainWrapper {\n  position: relative;\n  width: fit-content;\n\n  .copyButtonWrapper {\n    position: absolute;\n    top: 36px;\n    right: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrapper": `Ep0wJyVORvJTtnI6QoPd`,
	"customDomainWrapper": `QAZwJk9Dqr3zVhuu8w3Q`,
	"copyButtonWrapper": `VYHgGvEPWClJLxdrns0s`
};
export default ___CSS_LOADER_EXPORT___;
