import React from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

function GeneralTitle({
  pageTitle, pageSubtitle, isNeedBackButton, children,
}) {
  const navigate = useNavigate();
  return (
    <Box
      className="generalTitle"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      }}
    >
      <div>
        { isNeedBackButton
          ? (
            <Button
              startIcon={<ArrowBackIosNewIcon />}
              sx={{
                color: 'black',
                fontSize: 24,
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'inherit',
                },
              }}
              onClick={() => { navigate(-1); }}
            >
              {pageTitle}
            </Button>
          )
          : (
            <span>
              <Typography variant="h5" component="h1" sx={{ fontWeight: 600 }}>
                {pageTitle}
              </Typography>
            </span>
          )}
        {pageSubtitle
          && <Typography variant="body1" component="p" sx={{ marginTop: '6px' }}>{pageSubtitle}</Typography>}
      </div>
      {children}
    </Box>
  );
}

GeneralTitle.defaultProps = {
  isNeedBackButton: false,
};

GeneralTitle.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  pageSubtitle: PropTypes.string,
  isNeedBackButton: PropTypes.bool,

};

export default GeneralTitle;
