/* eslint-disable */
AFRAME.registerComponent('chatbox-info-panel', {
  schema: {
    content: {default: ''},
    side: {default: 'left'}, // right
    scale: {default: 1},
  },

  init: function() {
    var el = this.el;
    this.parent_position = el.getAttribute('position');
    this.panel = document.createElement('a-rounded');
    this.panel.setAttribute("radius", 0.5);
    
    var panel = this.panel;
    panel.setAttribute("geometry", {primitive: "plane", height: "auto", width: "auto"});
    // panel.setAttribute("position", {x: this.parent_position.x + 0.5, y: this.parent_position.y + 8, z: this.parent_position.z - 9});
    // panel.setAttribute("material", {src: "#scroll", opacity: 0.5, side: "double", color: "grey"});
    panel.setAttribute("material", {color: "black", opacity: 0.5, side: "double", color: "grey"});
    // panel.setAttribute("position", {x: this.parent_position.x + 1.5, y: this.parent_position.y + 8 / this.data.scale , z: this.parent_position.z - 9});
    
    panel.setAttribute("rotation", {x: 0, y: 0, z: 0});
    panel.setAttribute("scale", {x: 1 / this.data.scale, y: 1 / this.data.scale, z: 1 / this.data.scale})
    this.contentEl = document.createElement('a-entity');
    var content = this.contentEl;
    content.setAttribute('text', {
      value: this.data.content,
      color: "#ffffff",
      width: 0.25,
      side: "double",
      anchor: 'center',
      align: 'left',
    });

    var scale = 8;
    content.setAttribute('scale', {x: scale, y: scale, z: scale});
    var tour_guide_bot = document.getElementById("tour-guide-bot");
    panel.appendChild(content);
    tour_guide_bot.insertBefore(this.panel, tour_guide_bot.firstChild);

    // var totalWidth = data.value.length * (data.width / data.wrapCount)
    // el.setAttribute("chatbox-info-panel", "content: Teleporting to R2D2 ...");
    this.el.addEventListener('model-loaded', this.adjustModelHeight.bind(this));

    // let sceneEl = document.querySelector('a-scene');
    // sceneEl.insertBefore(this.panel, sceneEl.firstChild);
  },
  update: function() {
    this.contentEl.setAttribute('text', {
      value: this.data.content,
    });
    var data = this.contentEl.getDOMAttribute('text');
    var wrapCount = 50;
    var contentSplit = this.data.content.split(/\n|\\n/).map(x => Math.ceil(x.length/wrapCount) === 0 ? 1 : Math.ceil(x.length/wrapCount));
    var contentSplitSum = contentSplit.reduce((a, b) => a + b, 0)
    var panel = this.panel;
    var adjustedHeight = contentSplitSum / 8;
    panel.setAttribute("geometry", {
      width: 2.5,
      height: adjustedHeight < 1.5 ? 1.5 : adjustedHeight
    });
  },
  adjustModelHeight () {
    const mesh = this.el.getObject3D('mesh');
    const size = new THREE.Box3().setFromObject(mesh)
    const limit = 1;
    const modelHeight = ( size.max.y - size.min.y ) / 10;
    const isNeedAdjustHeight = modelHeight > limit;
    console.log(modelHeight, limit)
    console.log(this.parent_position)
    console.log({x: this.parent_position.x + 1.5, y: this.parent_position.y + (isNeedAdjustHeight ? modelHeight : 0 ), z: this.parent_position.z - 9})
    this.panel.setAttribute("position", {x: this.parent_position.x + 1.5, y: this.parent_position.y + 10, z: this.parent_position.z - 9});
  },
  remove: function() {
    // entityEl.parentNode.removeChild(entityEl);
  }
})