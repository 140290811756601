export const button = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        '&.MuiButton-text': {
          textTransform: 'capitalize',
        },
        '&.MuiButton-root': {
          textTransform: 'capitalize',
        },
      },
    },
  },
  // MuiButton: {
  //   variants: [
  //     {
  //       props: { isround: true },
  //       style: {
  //         borderRadius: '100px',
  //       },
  //     },
  //   ],
  // },
};
