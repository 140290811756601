import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import './FileUploadInput.scss';

function FileUploadInput({
  pageTitle,
  pageFileFormatContent,
  isValid, filename,
  callback = () => {},
}) {
  const getFileDummyClass = () => {
    if (filename !== '') {
      if (isValid) return 'file-dummy-success';
      return 'file-dummy-error';
    }
    return '';
  };

  return (
    <div className="form-group file-area">
      <label htmlFor="images">
        <Typography component="span" variant="menuAndInput">
          {pageTitle}
        </Typography>
      </label>
      <input
        type="file"
        name="images"
        id="images"
        required="required"
        onChange={(event) => callback(event)}
      />
      <div className={`file-dummy ${getFileDummyClass()}`}>
        <div className="success">
          <b>{filename}</b>
          {' '}
          selected.
        </div>
        <div className="error">Invalid File, please upload again.</div>
        <div className="default">Choose file</div>
        <div className="formatLabel">{pageFileFormatContent}</div>
      </div>
    </div>
  );
}

FileUploadInput.defaultProps = {
  pageTitle: 'Upload item file',
  pageFileFormatContent: 'Support png, jpeg, jpg for 2d images, glb, gltf for 3D model & File size should be <= 30MB',
  isValid: true,
  filename: '',
};

FileUploadInput.propTypes = {
  callback: PropTypes.func,
  pageTitle: PropTypes.string,
  pageFileFormatContent: PropTypes.string,
  isValid: PropTypes.bool,
  filename: PropTypes.string,
};

export default FileUploadInput;
