import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import { PropTypes } from 'prop-types';

function GeneralContent({
  maxWidth = false,
  disablePadding = false,
  backgroundColor = '#F8F8F8',
  display = 'block',
  style,
  children,
  elevation = 1,
}) {
  const padding = disablePadding ? '0px' : '30px';

  return (
    <Paper
      className="generalContent"
      elevation={elevation}
      sx={{
        backgroundColor,
        flexGrow: '1',
        marginBottom: '50px',
        display,
        padding: { padding },
        ...style,
      }}
    >
      {children}
    </Paper>
  );
}

GeneralContent.propTypes = {
  backgroundColor: PropTypes.string,
  disablePadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  display: PropTypes.string,
  style: PropTypes.shape({}),
  elevation: PropTypes.number,
};

export default GeneralContent;
