/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
// import 'aframe';
// import 'aframe-extras';
// import 'aframe-rounded';
require('aframe');
require('aframe-extras');

import React, { useEffect, useState, Suspense } from 'react';
import { compose, bindActionCreators } from 'redux';
import { Entity, Scene } from 'aframe-react';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import TWEEN from '@tweenjs/tween.js';
import infoPanel from './assets/component/3b4174ec8224442b47b3418553b75556.jpeg';
import goku from './assets/goku_6.jpg';
import gokuInFrame from './assets/goku_in_frame.png';
import R2D2Obj from './assets/Low_Poly_R2D2/Low_Poly_R2D2.obj';
import R2D2mtl from './assets/Low_Poly_R2D2/Low_Poly_R2D2.mtl';
import SwordMinecraftMtl from './assets/DiamondSword/SwordMinecraft.mtl';
import SwordMinecraftObj from './assets/DiamondSword/SwordMinecraft.obj';
import StoneGiantMtl from './assets/StoneGiant/Stone.mtl';
import StoneGiantObj from './assets/StoneGiant/Stone.obj';
import Robot from './assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from './assets/bitcoin_icon.png';
import ethIcon from './assets/eth_icon.png';
import InstructionLegend from 'webComponents/InstructionLegend/InstructionLegend';
import { isIgnoreAsset } from 'utils/asset.utils';

import './components/map';
import './components/info-panel';

import './components/chatbot/tour-guide.js';
import './components/chatbot/chatbox-info-panel.js';
import './components/chatbot/chatbox-look-at.js';
import './components/chatbot/chatbox.js';
import './components/popup-panel.js';
import 'components/rounded';

function Theme(props) {
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const defaultLogoUrl = 'https://double-storage.s3.ap-southeast-1.amazonaws.com/theme/default/gamers_paradise_logo_web.jpeg';
  const name = urlParams.get('name') || '';
  window.TWEEN = TWEEN;

  useEffect(() => {
    try {
      const themeDetail = urlParams.get('data');
      // themeDetail = themeDetail.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
      const data = JSON.parse(themeDetail);
      setCurrent(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return ( 
    loading ? <SimpleBackdrop open /> :
    <div className="App">
      <header className="App-header">
        <button className="button" onClick={
          function() {
            document.querySelector('.a-enter-vr-button').click()
          }
        }>
          VR
        </button>
        <InstructionLegend name={name} />
        <Scene
          id="aframe-scene"
          // loading-screen="dotsColor: blue; backgroundColor: black"
          // environment
          // cursor="rayOrigin: mouse"
        >
          <a-assets>
            <img id="floor-texture" src={current.floor} crossOrigin="anonymous" />
            <img id="sky-texture" src={current.environment} crossOrigin="anonymous"  />
            <img id="info_panel_1" src={infoPanel} crossOrigin="anonymous"  />
            <img id="ethIcon" src={ethIcon} crossOrigin="anonymous"  />
            <img id="bitcoinIcon" src={bitcoinIcon} crossOrigin="anonymous"  />
            {current.wall && <img id="wall" src={current.wall} crossOrigin="anonymous"  />}
            
            <img id="goku_nft" src={goku} />
            <img id="gokuInFrame" src={gokuInFrame} />
            <img id="ethIcon" src={ethIcon} />
            <img id="bitcoinIcon" src={bitcoinIcon} />
            
            <a-asset-item id="tour-guide-bot-glb" src={Robot}></a-asset-item>
            <a-asset-item id="Low_Poly_R2D2-obj" src={R2D2Obj}></a-asset-item>
            <a-asset-item id="Low_Poly_R2D2-mtl" src={R2D2mtl}></a-asset-item>
            <a-asset-item id="SwordMinecraft-obj" src={SwordMinecraftObj}></a-asset-item>
            <a-asset-item id="SwordMinecraft-mtl" src={SwordMinecraftMtl}></a-asset-item>
            <a-asset-item id="StoneGiant-obj" src={StoneGiantObj}></a-asset-item>
            <a-asset-item id="StoneGiant-mtl" src={StoneGiantMtl}></a-asset-item>
            
          </a-assets>
          {!isIgnoreAsset(current.floor) &&
            <a-grid id="ground" src="#floor-texture" material="repeat: 2 2" rotation="-90 0 0" shadow="receive: true;" />
          }
          {!isIgnoreAsset(current.wall) &&
            <Entity id="walls" init-walls={`
              ${current.map ? `map:${current.map[0].data}` : ''};
              wall: ${current.wall ? '#wall' : '#wall-texture'};
              wallColor: ${current.wall ? '#fff' : '#aa63ff'};
              height:${current.map[0].height};
              width:${current.map[0].width};
              `} 
            />
          }
          <a-sky src="#sky-texture" material={`${current.environment ? "" : "color: #c18fff"}`}></a-sky>

          <Entity
            id="shop_logo"
            position="6 0.1 10.5"
            geometry="primitive: plane; height: 1; width: 1.5;"
            material={`shader: flat; src: ${`url(${current.logo || defaultLogoUrl})`}; side: double`}
            rotation="-90 0 90"
          />
          
          <Entity
            id="shop_logo"
            position="-0.5 2.5 -4.25"
            geometry="primitive: plane; height: 2; width: 3;"
            material={`shader: flat; src: ${`url(${current.logo || defaultLogoUrl})`}; side: double`}
            rotation="0 0 0"
          />
          
          {/* Player */}
          <Entity
            id="rig"
            movement-controls="constrainToNavMesh: true; speed: 0.3; fly: false"
            position="0 0 0"
            rotation="0 90 0"
          >
            {/* position="8 0 11" */}
            <Entity
              id="player"
              camera="userHeight: 1.6"
              look-controls="pointerLockEnabled: true"
              position="0 1.6 0"
            >
              {/* <a-entity raycaster="showLine: true; far: 100; lineColor: red; lineOpacity: 0.5"></a-entity> */}
            </Entity>
            <Entity laser-controls="hand: left"></Entity>
            <Entity laser-controls="hand: right"></Entity>
          </Entity>

          {/* Light */}
          {/* <Entity light="type: ambient; intensity: 1.2"></Entity> */}

          {!isIgnoreAsset(current.assistant)
          && (
            <Entity
              id="tour-guide-bot"
              position="-1.5 0.1 9"        
              gltf-model={`${current.assistant ? current.assistant[0].url : "#tour-guide-bot-glb"}`} scale={`${current.assistant ? `${current.assistant[0].ratio} ${current.assistant[0].ratio} ${current.assistant[0].ratio}` : "1 1 1"}`}
              animation-mixer="clip:Wave"
              chatbox=""
              // chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot.  \n\nLet’s go!; scale: ${current.assistant ? current.assistant[0].ratio : 1}`}
              tour-guide="#rig"
            />
          )}
          <Entity 
            id="R2D2"
            position="-5 0.1 -3"
            scale="0.2 0.2 0.2" 
            obj-model="obj: #Low_Poly_R2D2-obj; mtl: #Low_Poly_R2D2-mtl"
            rotation="0 90 0"
            info-panel="
            title: R2-D2;
            adjustX: -0.75; adjustY: 1.65; adjustZ: 1;
            content: Are you looking for a smart, loyal and courageous robot companion? R2-D2 is the droid that does it all. He's saved the galaxy more than once and now he’s ready to serve you well!"
            spot-light="color:#003D50;"
          />

          <Entity
            id="goku"
            position="-7.49 1.75 1"
            geometry="primitive: plane; height: 1.75; width: 1.75;"
            material={`src:#gokuInFrame; side: double`}
            rotation="0 90 0"
            info-panel="
            title: Goku Mosaic NFT;
            adjustX: 0.5; adjustY: 0.5;
            content: Score a unique, digital collectible of this beloved warrior, defender and martial arts master. The pixelated mosaic design is truly one-of-a-kind and is the perfect homage to a multifaceted, multi-talented hero."
          />

          <Entity 
            id="Minecraft"
            position="-5 1.2 4" 
            scale="0.04 0.04 0.04" 
            obj-model="obj: #SwordMinecraft-obj; mtl: #SwordMinecraft-mtl"
            animation="property: rotation; from: -90 -180 0; to: -90 360 180; loop: true; dur: 3000; easing: linear; dir: normal;" 
            rotation="-90 0 0"
            info-panel="
            title: Minecraft Diamond Sword;
            adjustY: 0.75;
            content: As one of the rarest ores, diamonds make some of the strongest equipment that will give you a huge advantage against other players. Enchant your sword to inflict maximum damage!"
          />

          <Entity
            id="stone-giant"
            position="4 0.8 2.5"  scale="0.3 0.3 0.3" 
            animation="property: rotation; from: 0 0 0; to: 0 360 0; loop: true; dur: 5000; easing: linear; dir: normal;" 
            shadow = "cast: true;"
            obj-model="obj: #StoneGiant-obj; mtl: #StoneGiant-mtl" 
            info-panel="
            title: Stone giant;
            adjustX: -1.5; adjustY: 1;
            content: Reclusive, quiet, and peaceful, but only if they are left alone. Awaken the Stone Giant he is a force to be reckoned with. Get too close and he might send you flying through the air!;"
            spot-light="color: #660062; intensity: 30;"
          />

          {/* <a-link 
            position="4 1.2 -3" rotation="0 -20 0" 
            title="Teleporter"
            href="#"
            image="https://thumbs.dreamstime.com/b/vr-image-futuristic-sci-fi-city-modern-fiction-skyscrapers-buildings-galaxy-discover-spacescape-119014727.jpg"
            popup-panel=""
          ></a-link> */}
        </Scene>
      </header>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Theme.propTypes = {
};

export default compose(
  withConnect,
)(Theme);
