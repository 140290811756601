import React from 'react';
import { LoadingButton } from '@mui/lab';
import { PropTypes } from 'prop-types';

function WhiteNormalButton({
  loading = false, text, sx = {}, callback = () => {}, ...props
}) {
  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      sx={{
        fontWeight: 700,
        backgroundColor: 'white',
        color: 'primary.light',
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
      onClick={() => callback()}
      {...props}
    >
      {text}
    </LoadingButton>
  );
}

WhiteNormalButton.propTypes = {
  text: PropTypes.string,
  sx: PropTypes.shape(),
  callback: PropTypes.func,
  loading: PropTypes.bool,
};

export default WhiteNormalButton;
