import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ImageGrid from '../ImageGrid';
import styles from '../../Themes.module.scss';

export default function ThemeBody(props) {
  const {
    search, themeList, currentTheme, goToEditTheme, handleOpen, isCurrentTheme,
  } = props;

  const filteredThemeList = (keyword) => {
    if (_.isEmpty(keyword)) return themeList;
    return themeList.filter((theme) => _.includes(_.lowerCase(theme.name), _.lowerCase(keyword)));
  };

  return (
    <Box component="div" sx={{ flexGrow: 1, background: '#EAEAEA' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={3}
        columns={16}
        sx={{
          width: '100%',
          margin: '0',
          padding: '12px 22px',
        }}
      >
        <Grid
          xs={4}
          md={4}
          key="white_add"
        >
          <div className={styles.gridItemImageWrapper}>
            <Paper
              className={styles.gridItemImage}
              elevation={0}
              sx={{
                borderRadius: '10px',
                border: '3px #E1E1E1 dashed',
                backgroundColor: '#C4C4C4',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'grid',
                cursor: 'pointer',
              }}
              onClick={() => {
                goToEditTheme({ isEdit: false });
              }}
            >
              <div className={styles.crossHair}>
                <div className={styles.verticalLine}></div>
                <div className={styles.horizontalLine}></div>
              </div>
            </Paper>
          </div>
          <Typography sx={{ marginTop: '12px' }} variant="subtitle1" component="h6">
            Create custom theme
          </Typography>
        </Grid>
        <Grid
          xs={4}
          md={4}
          key="current_theme"
        >
          <div className={styles.gridItemImageWrapper}>
            <ImageGrid
              imgSrc={currentTheme.thumbnail || currentTheme.environment}
              imgStyle={styles.gridItemImage}
              isCurrentTheme
              callback={() => {
                const index = _.findIndex(themeList, { _id: currentTheme._id });
                handleOpen(index);
              }}
            />
          </div>
          <Typography className={styles.themeTitle} variant="subtitle1" component="h6">
            {`Current theme (${currentTheme.name})`}
          </Typography>
        </Grid>
        <LazyLoadComponent>
          {themeList && filteredThemeList(search).map((theme, index) => (
            <Grid
              xs={4}
              md={4}
              key={theme._id}
              sx={{
                display: isCurrentTheme(theme) ? 'none' : 'display',
              }}
            >
              <div className={styles.gridItemImageWrapper}>
                <ImageGrid
                  imgSrc={theme.thumbnail || theme.environment}
                  imgStyle={styles.gridItemImage}
                  callback={() => handleOpen(index)}
                />
              </div>
              <Typography className={styles.themeTitle} variant="subtitle1" component="h6">
                {theme.name}
              </Typography>
            </Grid>
          ))}
        </LazyLoadComponent>
      </Grid>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ThemeBody.propTypes = {
  currentTheme: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
  }),
  current: PropTypes.shape({
    logo: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  search: PropTypes.string,
  goToEditTheme: PropTypes.func,
  handleOpen: PropTypes.func,
  isCurrentTheme: PropTypes.func,
};
