import {
  ADD_ITEM,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAIL,
  EDIT_ITEM,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_FAIL,
  MASS_EDIT_ITEM,
  GET_ALL_ITEMS,
  GET_ALL_ITEMS_SUCCESS,
  GET_ALL_ITEMS_FAIL,
  DEACTIVTATE_ITEM,
  DEACTIVTATE_ITEM_SUCCESS,
  DEACTIVTATE_ITEM_FAIL,
  UPDATE_ITEMS_LOCATION,
  UPDATE_ITEMS_LOCATION_SUCCESS,
  UPDATE_ITEMS_LOCATION_FAIL,
  EDIT_ITEM_FROM_WORLD,
  EDIT_ITEM_FROM_WORLD_SUCCESS,
  EDIT_ITEM_FROM_WORLD_FAIL,
  SET_ITEM_SHOW_SNACKBAR,
  SET_ITEM_SHOW_MODAL,
} from './constants';

export const addItem = (data, callback) => ({
  type: ADD_ITEM,
  data,
  callback,
});

export const addItemSuccess = (data) => ({
  type: ADD_ITEM_SUCCESS,
  data,
});

export const addItemFail = (error) => ({
  type: ADD_ITEM_FAIL,
  error,
});

export const editItem = (index, data, callback = () => {}) => ({
  type: EDIT_ITEM,
  index,
  data,
  callback,
});

export const editItemSuccess = (index, data) => ({
  type: EDIT_ITEM_SUCCESS,
  index,
  data,
});

export const editItemFail = (error) => ({
  type: EDIT_ITEM_FAIL,
  error,
});

export const massEditItem = (data) => ({
  type: MASS_EDIT_ITEM,
  data,
});

export const getAllItems = ({ storeId }) => ({
  type: GET_ALL_ITEMS,
  storeId,
});

export const getAllItemsSuccess = (data) => ({
  type: GET_ALL_ITEMS_SUCCESS,
  data,
});

export const getAllItemsFail = (error) => ({
  type: GET_ALL_ITEMS_FAIL,
  error,
});

export const deactivateItem = (itemId, callback = () => {}) => ({
  type: DEACTIVTATE_ITEM,
  itemId,
  callback,
});

export const deactivateItemSuccess = (data) => ({
  type: DEACTIVTATE_ITEM_SUCCESS,
  data,
});

export const deactivateItemFail = (error) => ({
  type: DEACTIVTATE_ITEM_FAIL,
  error,
});

export const updateItemsLocation = ({ storeId, items }) => ({
  type: UPDATE_ITEMS_LOCATION,
  storeId,
  items,
});

export const updateItemsLocationSuccess = (data) => ({
  type: UPDATE_ITEMS_LOCATION_SUCCESS,
  data,
});

export const updateItemsLocationFail = ({ error }) => ({
  type: UPDATE_ITEMS_LOCATION_FAIL,
  error,
});

export const setShowSnackbar = (bool) => ({
  type: SET_ITEM_SHOW_SNACKBAR,
  bool,
});

export const setShowModal = (bool) => ({
  type: SET_ITEM_SHOW_MODAL,
  bool,
});

export const editItemFromWorld = (data) => ({
  type: EDIT_ITEM_FROM_WORLD,
  data,
});

export const editItemFromWorldSuccess = (data) => ({
  type: EDIT_ITEM_FROM_WORLD_SUCCESS,
  data,
});

export const editItemFromWorldFail = (error) => ({
  type: EDIT_ITEM_FROM_WORLD_FAIL,
  error,
});
