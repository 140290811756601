export const palette = {
  primary: {
    main: '#5219F6',
  },
  text: {
    primary: '#404040',
    secondary: '#404040',
  },
  grey: {
    D091: '#E9E9E9',
    D082: '#D0D0D0',
    D077: '#C4C4C4',
    D058: '#939393',
    D055: '#8C8C8C',
    D040: '#666666',
    D025: '#404040',
    D015: '#272626',
  },
  // secondary: {
  //   light: '#F5D658',
  //   main: '#FFA75C',
  //   dark: '#F58A58',
  // },
};
