/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import _ from 'lodash';
import {
  ROUTE_ININTIAL,
  ROUTE_NAVIGATE,
  SET_ROUTE_SHOW_SNACKBAR_BAR,
} from './constants';

export const initialState = {
  navigate: null,
  snackbar: {
    title: 'Timeout, Please try it later',
    isShow: false,
  },
};

const routeReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ROUTE_ININTIAL:
      draft.navigate = action.navigate;
      break;
    case ROUTE_NAVIGATE: {
      const { path } = action;
      if (draft.navigate) {
        draft.navigate(path);
      }
      break;
    }
    case SET_ROUTE_SHOW_SNACKBAR_BAR: {
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = action.bool;
      draft.snackbar = temp;
      break;
    }
    default:
  }
});

export default routeReducer;
