/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FileUploadInput from 'webComponents/FileUploadInput/FileUploadInput';
import _ from 'lodash';
import { isImage, isSupported3DModal } from 'utils/asset.utils';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import NumericFormatRatio from 'webComponents/NumericFormatRatio/NumericFormatRatio';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import { Asset } from 'type/asset.type';
import styles from './TextureTabPanel.module.scss';

function TextureTabPanel(props) {
  const {
    children, value, index, setFile, asset, type, isOptional, hint, isShowError, allowModal, ...other
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const isCreateTheme = searchParams.get('new');
  const [isUnknownFile, setIsUnknownFile] = useState(false);
  const [filename, setFilename] = useState('');
  const [fileObjectUrl, setFileObjectUrl] = useState(null);

  const handleFileChange = (event, isReplace = false) => {
    const [file] = event.target.files;
    setFilename(_.isUndefined(file.name) ? '' : file.name);
    if (!file || (!allowModal && !isImage(file.type)) || (allowModal && !isSupported3DModal(file.name) && !isImage(file.type))) {
      setIsUnknownFile(true);
      if (isReplace) setFileObjectUrl(null);
      return;
    }
    setFileObjectUrl(URL.createObjectURL(file));

    if (isCreateTheme) {
      if (isSupported3DModal(file.name)) {
        setFile({
          file,
          type: 'model',
          metadata: {
            ratio: 1,
            model: 'glb',
          },
        });
      } else {
        setFile({
          type: 'image',
          file,
        });
      }
    } else if (isSupported3DModal(file.name)) {
      let temp = _.cloneDeep(asset);
      if (_.isString(asset)) {
        temp = {};
      }
      // eslint-disable-next-line dot-notation
      delete temp['url'];
      temp.file = file;
      temp.type = 'model';
      temp.metadata = {
        ratio: 1,
        model: 'glb',
      };
      setFile(temp);
    } else { // image
      let temp = _.cloneDeep(asset);
      if (typeof temp === 'string') {
        temp = {};
      }
      // eslint-disable-next-line dot-notation
      delete temp['metadata'];
      // eslint-disable-next-line dot-notation
      delete temp['url'];
      temp.file = file;
      temp.type = 'image';
      setFile(temp);
    }
  };

  const handleRatioChange = (event) => {
    const ratio = event.target.value;
    if (ratio >= 0) {
      setFile((prevState) => ({
        ...prevState,
        metadata: {
          ratio: _.toNumber(ratio),
          model: 'glb',
        },
      }));
    }
  };

  const removeFile = () => {
    setFileObjectUrl(null);
    setFilename('');
    setFile({
      file: null,
      type: 'image',
    });
    setIsUnknownFile(false);
  };

  const getAssetComponent = () => {
    if (allowModal) {
      if ((isCreateTheme && isSupported3DModal(filename))
      || (!isCreateTheme && asset.type === 'model')) {
        return (
          <>
            <div className={styles.modalWrapper}>
              <ErrorBoundary>
                <CanvasModel model={fileObjectUrl || asset.url} ratio={props.asset.metadata.ratio} />
              </ErrorBoundary>
            </div>
            <NumericFormatRatio
              sx={{ mt: 2, width: '221px' }}
              name="ratio"
              value={props.asset.metadata.ratio}
              label="Ratio"
              variant="outlined"
              min="0"
              onChange={handleRatioChange}
              placeholder="ratio"
            />
          </>
        );
      }
    }
    return (
      <div className={styles.imgWrapper}>
        <Box
          component="img"
          src={fileObjectUrl || (_.isString(asset) ? asset : asset.url)}
          crossOrigin="anonymous"
          alt={`${type} file`}
        />
      </div>
    );
  };

  const isEmptyAsset = () => {
    if (_.isObject(asset)) {
      return (fileObjectUrl || asset.file || asset.url);
    }
    return (fileObjectUrl || asset);
  };

  useEffect(() => {
  }, []);

  return (
    <div
      className={`textureTabPanel ${styles.textureTabPanel}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isShowError && <ErrorComponet objectKey="" error="* Missing File" />}
      { isEmptyAsset()
        ? (
          <div className={styles.fileUploaded}>
            <div className={styles.titleGroup}>
              <Typography variant="menuAndInput" component="h6">{`Upload ${type} file`}</Typography>
              <span style={{ color: '#5219F6', textDecoration: 'underline' }}>
                <label htmlFor={`file_uploads_${type}`} style={{ cursor: 'pointer' }}>Replace file</label>
                <input
                  type="file"
                  name={`file_uploads_${type}`}
                  id={`file_uploads_${type}`}
                  required="required"
                  hidden
                  onChange={(event) => handleFileChange(event, true)}
                />
              </span>
            </div>
            {getAssetComponent()}
            {isOptional
              && (
              <Button
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeFile}
              >
                Remove file
              </Button>
              )}
          </div>
        ) : (
          <>
            <FileUploadInput
              pageTitle={`Upload ${type} file`}
              pageFileFormatContent="Support png, jpeg, jpg for 2d images, glb, gltf for 3D model"
              callback={handleFileChange}
              isValid={!isUnknownFile}
              filename={filename}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {hint}
            </Typography>
          </>
        )}
    </div>
  );
}

TextureTabPanel.defaultProps = {
  isShowError: false,
  isOptional: false,
  hint: '',
  allowModal: false,
  // isDefaultTheme: false,
};

TextureTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  setFile: PropTypes.func.isRequired,
  asset: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File), PropTypes.shape({
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    type: PropTypes.string,
    metadata: PropTypes.shape({
      ratio: PropTypes.number,
      model: PropTypes.string,
    }),
  })]),
  isOptional: PropTypes.bool,
  isShowError: PropTypes.bool,
  hint: PropTypes.string,
  allowModal: PropTypes.bool,
  // isDefaultTheme: PropTypes.bool,
};

export default TextureTabPanel;
