import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import _ from 'lodash';

export default function ImageGrid(props) {
  const {
    imgSrc, imgStyle, isCurrentTheme, callback,
  } = props;

  const isModel = () => {
    if (!_.isArray(imgSrc)) return false;
    if (imgSrc[0].type === 'image') return false;
    return true;
  };

  return (
    <div
      role="button"
      onKeyPress={() => {}}
      tabIndex="0"
      onClick={() => callback()}
    >
      {
        isModel()
          ? (
            <div
              className={imgStyle}
              style={{
                border: `${isCurrentTheme ? '3px solid #58A1F9' : 'none'} `,
                width: '100%',
                height: '100%',
                backgroundColor: '#fde2e4',
              }}
            >
              <ErrorBoundary>
                <CanvasModel model={imgSrc[0].url} ratio={imgSrc[0].metadata.ratio} />
              </ErrorBoundary>
            </div>
          ) : (
            <img
              className={imgStyle}
              style={{
                border: `${isCurrentTheme ? '3px solid #58A1F9' : 'none'} `,
                width: '100%',
                height: '100%',
              }}
              src={_.isString(imgSrc) ? imgSrc : imgSrc[0].url}
              crossOrigin="anonymous"
              alt={isCurrentTheme ? 'current' : 'theme'}
              loading="lazy"
            />
          )
      }
    </div>
  );
}

ImageGrid.defaultProps = {
  isCurrentTheme: false,
  callback: () => {},
};

ImageGrid.propTypes = {
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
  imgStyle: PropTypes.string.isRequired,
  isCurrentTheme: PropTypes.bool,
  callback: PropTypes.func,
};
