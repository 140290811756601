import React from 'react';
import { Typography, Link } from '@mui/material';
import nounIdeaImg from '../../../assets/icon/noun-idea.png';
import peopleFilledImg from '../../../assets/icon/people-filled.png';
import phoneImg from '../../../assets/icon/phone-icon.png';
import removeRedEyeFilledImg from '../../../assets/icon/remove-red-eye-filled.png';

import styles from './SupportGuide.module.scss';

export default function SupportGuide() {
  return (
    <div className={styles.supportGuide}>
      <div className={styles.item}>
        <div className={styles.imgWrapper}>
          <img className={styles.ideaImg} src={nounIdeaImg} alt="" />
        </div>
        <div>
          <Typography component="h2" variant="body1">Learn</Typography>
          <Typography component="p" variant="body1" sx={{ display: 'inline' }}>
            <Link href="https://double.store/" target="_blank" rel="noreferrer" sx={{ display: 'inline' }}>
              Watch video tutorials
            </Link>
            {' '}
            to learn how to set up your Metaverse Store
          </Typography>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.imgWrapper}>
          <img className={styles.phoneImg} src={phoneImg} alt="" />
        </div>
        <div>
          <Typography component="h2" variant="body1">Contact Us</Typography>
          <Typography component="p" variant="body1" sx={{ display: 'inline' }}>
            <Link href="https://double.store/" target="_blank" rel="noreferrer" sx={{ display: 'inline' }}>
              Ask questions
            </Link>
            {' '}
            and access our dedicated support team
          </Typography>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.imgWrapper}>
          <img className={styles.eyeImg} src={removeRedEyeFilledImg} alt="" />
        </div>
        <div>
          <Typography component="h2" variant="body1">
            Get Featured
          </Typography>
          <Typography component="p" variant="body1" sx={{ display: 'inline' }}>
            <Link href="https://double.store/" target="_blank" rel="noreferrer" sx={{ display: 'inline' }}>
              Share your work
            </Link>
            {' '}
            and become a featured community member
          </Typography>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.imgWrapper}>
          <img className={styles.peopleImg} src={peopleFilledImg} alt="" />
        </div>
        <div>
          <Typography component="h2" variant="body1">
            Community
          </Typography>
          <Typography component="p" variant="body1" sx={{ display: 'inline' }}>
            <Link href="https://double.store/" target="_blank" rel="noreferrer" sx={{ display: 'inline' }}>
              Meet and connect
            </Link>
            {' '}
            with business owners and innovators using Double
          </Typography>
        </div>
      </div>
    </div>
  );
}
