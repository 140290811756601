/* eslint-disable default-param-last */
import { produce } from 'immer';
import _ from 'lodash';
import {
  GET_PERSISTED_TOKEN_SUCCESS,
  CREATE_AUTH_TOKEN_SUCCESS,
  DELETE_AUTH_TOKEN,
  DELETE_AUTH_TOKEN_SUCCESS,
  DELETE_AUTH_TOKEN_FAIL,
  DEAUTHENTICATE,
  REGISTER_WITH_TEMPLATE,
  REGISTER_WITH_TEMPLATE_SUCCESS,
  REGISTER_WITH_TEMPLATE_FAIL,
  SET_HOME_SHOW_SNACKBAR,
} from './constants';

export const initialState = {
  access_token: null,
  refresh_token: null,
  deauthenticateReason: null,
  snackbar: {
    title: '',
    isShow: false,
    type: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action) => produce(
  state,
  (draft) => {
    // !!!! !!!! !!!! !!!! !!!! !!!! !!!!
    // when changing the token, don't forget to also add handlers in ./saga
    // !!!! !!!! !!!! !!!! !!!! !!!! !!!!
    switch (action.type) {
      case GET_PERSISTED_TOKEN_SUCCESS: {
        draft.access_token = action.access_token;
        draft.deauthenticateReason = null;
        break;
      }
      case CREATE_AUTH_TOKEN_SUCCESS: {
        draft.access_token = action.access_token;
        draft.refresh_token = action.refresh_token;
        draft.deauthenticateReason = null;
        break;
      }
      case DELETE_AUTH_TOKEN: {
        break;
      }
      case DELETE_AUTH_TOKEN_SUCCESS: {
        draft.access_token = null;
        break;
      }
      case DELETE_AUTH_TOKEN_FAIL: {
        break;
      }
      case DEAUTHENTICATE: {
        draft.access_token = null;
        draft.deauthenticateReason = (
          action.isVoluntary ? 'logout' : 'unauthorized'
        );
        break;
      }
      case SET_HOME_SHOW_SNACKBAR: {
        const temp = _.cloneDeep(draft.snackbar);
        temp.isShow = action.bool;
        draft.snackbar = temp;
        break;
      }
    }
  },
);

export default authReducer;
