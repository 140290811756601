import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import {
  updateStoreTheme,
} from 'services/store/actions';
import {
  makeSelectUserId,
} from 'services/user/selectors';
import {
  makeSelectStoreCurrentTheme,
} from 'services/store/selectors';
import {
  makeSelectTemplateList,
  makeSelectTemplateError,
  makeSelectTemplateLoading,
  // makeSelectThemeSnackbar,
} from 'services/template/selectors';
import {
  setShowSnackbar,
} from 'services/theme/actions';
import { isLoginedByUserId } from 'utils/user.utils';
import TemplateDetailModal from './components/Modal/TemplateDetailModal';
import TemplateCreateModal from './components/Modal/TemplateCreateModal';
import TemplateHeader from './components/TemplateHeader/TemplateHeader';
import TemplateBody from './components/TemplateBody/TemplateBody';
import styles from './Templates.module.scss';

function Templates(props) {
  const {
    templateList, currentTheme, snackbar, userId,
  } = props;
  const [activeTemplateId, setActiveThemeId] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const handleOpen = (themeIndex) => { setActiveThemeId(themeIndex); setOpenDetailModal(true); };
  const handleClose = () => setOpenDetailModal(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get('sid');

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const goToEditTheme = ({ themeId, isEdit = true }) => {
    navigate(`edit${location.search}${isEdit ? `&tid=${themeId}` : '&new=true'}`);
  };

  const [openCreateModel, setOpenDetailModalCreateModel] = useState(false);

  const handleOpenCreateModel = () => {
    setOpenDetailModalCreateModel(true);
  };

  const handleCloseCreateModel = () => {
    setOpenDetailModalCreateModel(false);
  };

  useEffect(() => {
  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Templates" pageSubtitle="A template is a ready made world that you can customize using our library of themes and objects" />
      <GeneralContent disablePadding display="flex" elevation={0}>
        <div className={`generalContentContainer ${styles.generalContentContainer}`}>
          <TemplateHeader
            search={search}
            handleSearchChange={handleSearchChange}
          />
          <TemplateBody
            search={search}
            templateList={templateList}
            currentTheme={currentTheme}
            goToEditTheme={goToEditTheme}
            handleOpen={handleOpen}
          />
        </div>
      </GeneralContent>
      { openDetailModal && (
        <TemplateDetailModal
          open={openDetailModal}
          handleClose={handleClose}
          // isCurrentTheme={isCurrentTheme}
          goToEditTheme={goToEditTheme}
          storeId={storeId}
          templateList={templateList}
          activeTemplateId={activeTemplateId}
          handleOpenCreateModel={handleOpenCreateModel}
        />
      )}
      {/* <ThemeSuccessModal /> */}
      { openCreateModel
        && (
        <TemplateCreateModal
          userId={userId}
          templateId={templateList[activeTemplateId]._id}
          handleClose={handleCloseCreateModel}
          open={openCreateModel}
          isLogined={isLoginedByUserId(userId)}
        />
        )}
      {/* <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowSnackbar(false)}
      /> */}
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectTemplateError(),
  loading: makeSelectTemplateLoading(),
  templateList: makeSelectTemplateList(),
  currentTheme: makeSelectStoreCurrentTheme(),
  userId: makeSelectUserId(),
  // snackbar: makeSelectThemeSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreTheme,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Templates.propTypes = {
  userId: PropTypes.string,
  currentTheme: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
  }),
  current: PropTypes.shape({
    logo: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  templateList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  // updateStoreTheme: PropTypes.func,
  // setShowSnackbar: PropTypes.func,
};

export default compose(
  withConnect,
)(Templates);
