export const DEFAULT_CREATE_BACKGROUND_MUSIC_DATA = {
  worldId: '',
  _id: '',
  url: '',
  title: '',
  type: 'audio',
  isActive: true,
  metadata: {
    format: 'mp3',
    volume: 100,
    isLoop: true,
  },
};
