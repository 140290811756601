import { color } from '../variables';
import { palette } from './palette';
import { components } from './components/components';
import { typography } from './typography';

export const customTheme = {
  palette,
  typography,
  components,
};
