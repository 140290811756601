import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectUserDomain = (state) => state.user || initialState;
export const makeSelectUser = () => createSelector(selectUserDomain, (s) => { const a = s; return a; });
export const makeSelectUserId = () => createSelector(selectUserDomain, _.property('_id'));
export const makeSelectUserError = () => createSelector(selectUserDomain, _.property('error'));
export const makeSelectUserLoading = () => createSelector(selectUserDomain, _.property('loading'));
export const makeSelectUserSnackbar = () => createSelector(selectUserDomain, _.property('snackbar'));
export const makeSelectUserRole = () => createSelector(selectUserDomain, _.property('role[0].name'));
