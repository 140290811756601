/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */

import React, { useEffect, useState, Suspense } from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';

function CustomEntity(props) {
  const {
    _id, title, description, position, asset, priceList, advanceSetting, actionList, link, pictureFrameType, rotation
  } = props.item;
  console.log(props.item);
  const isKeyExist = (key) => {
    if (advanceSetting) {
      return _.has(advanceSetting, key);
    }
    return false;
  };

  const generateNewPosition = () => {
    const positionList = position.split(' ');
    let x = parseFloat(positionList[0]);
    let y = parseFloat(positionList[1]);
    let z = parseFloat(positionList[2]);
    if (isKeyExist('position')) {
      x = parseFloat(advanceSetting.position.x);
      y = parseFloat(advanceSetting.position.y);
      z = parseFloat(advanceSetting.position.z);
    }
    return `${x} ${y} ${z}`;
  };

  const generateNewRotation = () => {
    let temp = rotation;
    if (isKeyExist('rotation')) {
      temp = `0 ${advanceSetting.rotation.y} 0`;
    }
    return temp;
  }

  const isModel = asset[0].type !== 'image';
  const { metadata } = asset[0];
  const { url } = asset[0];

  let infoPanel = `
    title:${title};
    content:${description || ''};
    ${isKeyExist('hasPrice') ? `hasPrice: ${advanceSetting.hasPrice};` : ';'}
    `;
  if (isKeyExist('hasPrice')) {
    if (advanceSetting.hasPrice) {
      infoPanel += `${isKeyExist('isAddToCartButton') ? `isAddToCartButton: ${advanceSetting.isAddToCartButton};` : ';'}
      ${isKeyExist('isBuyButton') ? `isBuyButton: ${advanceSetting.isBuyButton};` : ';'}`;
    }
  }
  infoPanel += `
    ${isKeyExist('isLink') ? `isLink: ${advanceSetting.isLink}; linkTitle: ${link.title}; linkUrl: ${link.url};` : ';'}
    ${advanceSetting ? (advanceSetting.hasPrice ? `btc: ${priceList[0].amount}; eth: ${priceList[1].amount};` : '') : ';'}
  `;

  if (actionList) {
    infoPanel += `actionList: ${JSON.stringify(actionList)};`;
  }

  infoPanel += `
    hasPrice: true; btc: ${priceList[1].amount}; eth: ${priceList[2].amount};
  `;

  useEffect(() => {
  }, []);

  return (
    isModel
      ? (
        <a-entity
          id={title}
          position={generateNewPosition()}
          gltf-model={url}
          scale={`${metadata.ratio} ${metadata.ratio} ${metadata.ratio}`}
          rotation={generateNewRotation()}
          info-panel={infoPanel}
          class="cube"
          mixin="cube"
          dragdropposition={`id: ${_id}`}
          animation={`${isKeyExist('isRotate') ? `enabled: ${advanceSetting.isRotate}` : 'enabled: true'};property: rotation; from: 0 0 0; to: 0 360 0; loop: true; dur: 5000; easing: linear; dir: normal;`}
        >
          <a-light type="ambient" intensity="0.7"></a-light>
          <a-light type="point" intensity="0.4" position="0 5 0"></a-light>
          <a-light type="point" intensity="0.4" position="0 -5 0"></a-light>
        </a-entity>
      )
      : (
        <a-entity
          id={title}
          position={generateNewPosition()}
          geometry="primitive: plane; height: 1.25; width: 1.25;"
          material={`src:url(${url}); side: double`}
          rotation={generateNewRotation()}
          info-panel={infoPanel}
          animation={`${isKeyExist('isRotate') ? `enabled: ${advanceSetting.isRotate}` : 'enabled: true'};property: rotation; from: 0 0 0; to: 0 360 0; loop: true; dur: 5000; easing: linear; dir: normal;`}
          // picture-frame={`type: ${pictureFrameType}`}
          class="cube"
          mixin="cube"
          dragdropposition={`id: ${_id}`}
        />
      )
  );
}

CustomEntity.defaultProps = {
  pictureFrameType: '0',
  rotation: "0 180 0"
}

CustomEntity.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  rotation: PropTypes.string,
  // model: PropTypes.string,
  asset: PropTypes.any,
  image: PropTypes.string,
  priceList: PropTypes.any,
  advanceSetting: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    z: PropTypes.string,
    hasPrice: PropTypes.bool,
  }),
  link: PropTypes.shape({}),
  actionList: PropTypes.arrayOf(PropTypes.shape({})),
  pictureFrameType: PropTypes.string,
};

export default CustomEntity;