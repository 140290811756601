import React, { useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import TabPanel from 'webComponents/Tabs/TabPanel';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  makeSelectStorePublicId,
} from 'services/store/selectors';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import islandImg from '../../../../assets/Items/art/artwork_portal.png';
import emailImg from '../../../../assets/icon/email.png';
import facebookImg from '../../../../assets/icon/facebook_logo_circle.png';
import linkedinImg from '../../../../assets/icon/linkedin.png';
import twitterImg from '../../../../assets/icon/twitter_logo.png';
import whatsappImg from '../../../../assets/icon/whatsapp_logo.png';
import style from './panel.modules.scss';

function ShareTabPanel(props) {
  const { publicId } = props;
  const sharableStoreUrl = `${window.location.origin}/s/${publicId}`;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TabPanel {...props}>
      <div className={style.container}>
        <div className={style.text}>
          <Typography variant="h1" sx={{ marginBottom: '40px' }}>Share your world</Typography>
          <div className={style.buttonRow}>
            <EmailShareButton
              className={style.shareButton}
              // url={sharableStoreUrl}
              subject="Join me in my Metaverse"
              body={`Check out this Metaverse space I created using Double. ${sharableStoreUrl}\n\nIt took just a few minutes and no coding at all. Let me know what you think of it!`}
              separator="\n"
            >
              <img className={style.emailIcon} src={emailImg} alt="" />
              <Typography className={style.shareButtonLabel} variant="subtitle2">Email</Typography>
            </EmailShareButton>
            <FacebookShareButton
              className={style.shareButton}
              url={sharableStoreUrl}
              quote="Check out this Metaverse space I created using Double - it took just a few minutes and no coding at all!"
              hashtag="#Double #metaverse"
            >
              <img className={style.facebookIcon} src={facebookImg} alt="" />
              <Typography className={style.shareButtonLabel} variant="subtitle2">Facebook</Typography>
            </FacebookShareButton>
            <LinkedinShareButton
              className={style.shareButton}
              url={sharableStoreUrl}
              title="Invitation to join my Metaverse"
              summary={`This is a Metaverse space I created using Double ${sharableStoreUrl}, take a look and build your own here`}
            >
              <img className={style.linkedinIcon} src={linkedinImg} alt="" />
              <Typography className={style.shareButtonLabel} variant="subtitle2">Linkedin</Typography>
            </LinkedinShareButton>
            <TwitterShareButton
              className={style.shareButton}
              url={sharableStoreUrl}
              title="Ready to try the Metaverse?"
              via={'Here\'s the space I created using Double'}
              hashtag="#Double #metaverse"
              related={[] /* FIXME: add related twitter handle to follow */}
            >
              <img className={style.twitterIcon} src={twitterImg} alt="" />
              <Typography className={style.shareButtonLabel} variant="subtitle2">Twitter</Typography>
            </TwitterShareButton>
            <WhatsappShareButton
              className={style.shareButton}
              url={sharableStoreUrl}
              title={'Ready to try the Metaverse? Here\'s the space I created using Double'}
              separator="\n"
            >
              <img className={style.whatsappIcon} src={whatsappImg} alt="" />
              <Typography className={style.shareButtonLabel} variant="subtitle2">Whatsapp</Typography>
            </WhatsappShareButton>
          </div>
          <Box className={style.linkBox}>
            {sharableStoreUrl}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  title="Copied"
                  placement="top"
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <Button
                    className={style.linkButton}
                    variant="contained"
                    size="large"
                    onClick={() => {
                      handleTooltipOpen();
                      navigator.clipboard.writeText(sharableStoreUrl);
                    }}
                  >
                    Copy
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Box>
        </div>
        <img src={islandImg} alt="" />
      </div>
    </TabPanel>
  );
}

const mapStateToProps = createStructuredSelector({
  publicId: makeSelectStorePublicId(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ShareTabPanel.propTypes = {
  publicId: PropTypes.string,
};

export default compose(
  withConnect,
)(ShareTabPanel);
