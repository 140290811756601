/* eslint-disable no-bitwise */
/* eslint-disable no-undef */
// probably only need the KTX2Loader since aframe gives access to
// the GLTFLoader and DRACOLoader.
// import { GLTFLoader } from './path_to_three/examples/jsm/loaders/GLTFLoader.js';
// eslint-disable-next-line import/extensions
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';

// import { GLTFLoader } from '../three/addons/loaders/GLTFLoader.js';
// this is a 'minimal' version of the gltf-component,
// a more faithful one is linked later on
const THREE_PATH = 'three';
AFRAME.registerComponent('gltf-model-loader', {
  schema: { type: 'model' },
  init() {
    const DRACO_LOADER = new DRACOLoader().setDecoderPath(`${THREE_PATH}/examples/jsm/libs/draco/gltf/`);
    const KTX2_LOADER = new KTX2Loader().setTranscoderPath(`${THREE_PATH}/examples/jsm/libs/basis/`);
    const loader = new GLTFLoader()
      .setCrossOrigin('anonymous')
      .setDRACOLoader(DRACO_LOADER)
      .setKTX2Loader(KTX2_LOADER)
      .setMeshoptDecoder(MeshoptDecoder);

    const scene = document.querySelector('a-scene');
    // load the model:
    const that = this;
    loader.load(
      this.data,
      (gltf) => {
        const model = gltf.scene || gltf.scenes[0];
        console.log(gltf);
        console.log(model.children[0]);
        // assuming el is an entity;
        // scene.add(model.children[0]);
        console.log(that.el.sceneEl);
        const t = new THREE.Object3D();
        t.add(model);
        that.el.setObject3D('mesh', t);
        const sceneEl = document.querySelector('a-scene');
        that.el.sceneEl.insertBefore(that.el, that.el.sceneEl.firstChild);
      },
      undefined /* in progress */,
      (err) => {
        console.log('error:', err);
      },
    );
  },
});
