import * as React from 'react';
import TextField from '@mui/material/TextField';

function NumericFormatRatio(props) {
  const { ...other } = props;

  return (
    <TextField
      type="number"
      {...other}
    />
  );
}

export default NumericFormatRatio;
