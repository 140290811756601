// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EZYjhUtaUwgEVusABhsA{display:flex;align-items:center;width:100%;padding:20px 26px;background:#f8f8f8;box-shadow:1px 1px 2px 1px rgba(0,0,0,.08);border-radius:4px 0px 0px 0px;margin-bottom:2px}.P_93veDj0H9bv2HtY6cu{display:flex;align-items:center;width:33px;margin-right:30px}.L7bufTAljmiuAxFqH_3A{width:32px;height:32px}.aE8jkQOG96CaxDktjFaI{width:33px;height:33px}.UUcIw5fkgUPnGhuAcniN{width:29px;height:29px}.NIEJ8eAR3yVGgYWe86R2{width:32px;height:31px}`, "",{"version":3,"sources":["webpack://./src/routes/home/components/SupportGuide.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,0CAAA,CACA,6BAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CAEF,sBACE,UAAA,CACA,WAAA,CAEF,sBACE,UAAA,CACA,WAAA,CAEF,sBACE,UAAA,CACA,WAAA","sourcesContent":[".supportGuide {\n  // background-color: black;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 20px 26px;\n  background: #F8F8F8;\n  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);\n  border-radius: 4px 0px 0px 0px;\n  margin-bottom: 2px;\n}\n\n.imgWrapper {\n  display: flex;\n  align-items: center;\n  width: 33px;\n  margin-right: 30px;\n}\n\n.peopleImg {\n  width: 32px;\n  height: 32px;\n}\n.ideaImg {\n  width: 33px;\n  height: 33px;\n}\n.phoneImg {\n  width: 29px;\n  height: 29px;\n}\n.eyeImg {\n  width: 32px;\n  height: 31px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `EZYjhUtaUwgEVusABhsA`,
	"imgWrapper": `P_93veDj0H9bv2HtY6cu`,
	"peopleImg": `L7bufTAljmiuAxFqH_3A`,
	"ideaImg": `aE8jkQOG96CaxDktjFaI`,
	"phoneImg": `UUcIw5fkgUPnGhuAcniN`,
	"eyeImg": `NIEJ8eAR3yVGgYWe86R2`
};
export default ___CSS_LOADER_EXPORT___;
