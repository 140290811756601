/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import _ from 'lodash';
import {
  GET_CHANNEL_INFO,
  GET_CHANNEL_INFO_SUCCESS,
  GET_CHANNEL_INFO_FAIL,
} from './constants';

export const initialState = {
  info: {
    appId: '',
    token: '',
    name: '',
  },
  getChannelInfoSucces: false,
  loading: false,
  error: null,
};

const channelReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case GET_CHANNEL_INFO:
      draft.loading = true;
      draft.error = null;
      break;
    case GET_CHANNEL_INFO_SUCCESS:
      draft.info = action.data;
      draft.loading = false;
      break;
    case GET_CHANNEL_INFO_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      break;
  }
});
export default channelReducer;
