import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import TabPanel from 'webComponents/Tabs/TabPanel';
import style from './panel.modules.scss';
import islandImg from '../../../../assets/Items/art/artwork_floating.png';

export default function StoreNamePanel(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const editName = () => navigate(`/store${location.search}`);
  return (
    <TabPanel {...props}>
      <div className={style.container}>
        <div className={style.text}>
          <Typography variant="h1" sx={{ marginBottom: '24px' }}>Change world name</Typography>
          <Typography variant="h3" sx={{ marginBottom: '42px' }}>You’ve already added a name for your world but you can update it at any time.</Typography>
          <Button
            size="large"
            variant="contained"
            sx={{ fontWeight: '700' }}
            onClick={editName}
          >
            Edit Name
          </Button>
        </div>
        <img src={islandImg} alt="" />
      </div>
    </TabPanel>
  );
}
