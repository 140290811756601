import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import Backbutton from 'webComponents/BackButton/Backbutton';
import MenuAppBar from 'webComponents/MenuAppBar/MenuAppBar';
import OutlinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import LoadingButton from '@mui/lab/LoadingButton';
import Stepper from 'webComponents/Stepper/Stepper';
import './StoreName.scss';
import _ from 'lodash';
import { updateOnboardingStoreName } from 'services/store/actions';
import { makeSelectStoreError, makeSelectStoreLoading } from 'services/store/selectors';
import { conversionAccountId, conversionEventId, googleConversion } from 'utils/google.utils';

import doubleLogoImg from '../../../../assets/Onboarding/logo/double_logo.png';

function StoreName(props) {
  const {
    handleNext, loading, error, handlePrevious, isLogined,
  } = props;
  const [storeName, setStoreName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleStoreNameChange = (event) => {
    setStoreName(event.target.value);
  };
  useEffect(() => {
    // google tage is installed in index.html, otherwise, we need another code
    window.gtag('config', conversionAccountId);
  }, []);

  const checkStoreName = () => {
    googleConversion(conversionEventId.step1);
    if (_.isEmpty(storeName)) {
      setErrorMessage('Missing World Name');
    } else {
      props.updateOnboardingStoreName(storeName);
      handleNext();
      // setErrorMessage('');
      // props.validateStoreName(storeName, handleNext);
    }
  };

  return (
    <div className="page-storeName">
      {/* "page-storeName__left" is image */}
      <div className="page-storeName__container">
        <div className="page-storeName__container__left"></div>
        <div className="page-storeName__container__right">
          <div className="page-storeName__container__right__wrapper">
            {/* <Backbutton
              className="page-storeName__container__right__backButton"
              onClick={() => handlePrevious()}
            /> */}
            <img className="page-storeName__container__right__wrapper__logo logo" src={doubleLogoImg} alt="" />
            <Typography className="page-storeName__container__right__wrapper__header header" variant="h5">Choose a name for your world</Typography>
            <OutlinedTextInput
              error={!_.isEmpty(errorMessage) || !_.isEmpty(error)}
              className="page-storeName__container__right__wrapper__input input"
              placeholder="Enter world name"
              fullWidth
              variant="contained"
              autoFocus
              onChange={handleStoreNameChange}
            />
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              color="error"
            >
              {errorMessage || error}
            </Typography>
            <LoadingButton
              variant="contained"
              onClick={checkStoreName}
              size="large"
              fullWidth
              loading={loading}
              sx={{
                marginTop: '26px',
                height: '56px',
                marginBottom: '26px',
                fontSize: '18px',
              }}
            >
              Next
            </LoadingButton>
            <Stepper className="page-storeName__container__right__stepper" activeStep={0} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateOnboardingStoreName,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

StoreName.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  isLogined: PropTypes.bool,
  error: PropTypes.string,
  updateOnboardingStoreName: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(StoreName);
