/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';
import _ from 'lodash';
import {
  makeSelectUserId,
} from 'services/user/selectors';
import { makeSelectThemeOutletLoading } from 'services/theme/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import {
  getThemeListByUserId,
} from 'services/theme/actions';

function ThemesOutlet(props) {
  const { loading, userId } = props;
  useEffect(() => {
    props.getThemeListByUserId(userId);
  }, []);

  return (
    (loading) ? <SimpleBackdrop open={loading} /> : <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectThemeOutletLoading(),
  userId: makeSelectUserId(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getThemeListByUserId,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ThemesOutlet.propTypes = {
  userId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  getThemeListByUserId: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(ThemesOutlet);
