/* eslint-disable no-undef */
AFRAME.registerComponent('cursor-listener', {
  schema: {
    index: { default: 0 },
  },
  init() {
    let lastIndex = -1;
    const COLORS = ['red', 'green', 'blue'];
    const that = this;
    const { el, data } = this;
    const channel = new BroadcastChannel('cursor-listener');
    try {
      const worldId = window.location.pathname.split('/').reverse()[0];
      el.addEventListener('click', (evt) => {
        lastIndex = (lastIndex + 1) % COLORS.length;
        el.setAttribute('material', 'color', COLORS[lastIndex]);
        channel.postMessage({ type: 'cursor-listener', data: { ...evt.detail.intersection.point, index: data.index, worldId } });
      });
    } catch (e) {
      console.warn('Error on get world Id');
    }
  },
});
