// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yf7ndYOEl1kDwN1M9Ws0{background-color:#f8f8f8;border-left:1px solid #dedede;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/webComponents/Tabs/TabPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,6BAAA,CACA,WAAA","sourcesContent":[".tabPanel {\n  background-color: #F8F8F8;\n  border-left: 1px solid #DEDEDE;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabPanel": `yf7ndYOEl1kDwN1M9Ws0`
};
export default ___CSS_LOADER_EXPORT___;
