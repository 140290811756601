/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import './AgoraMessageChat.scss';
import AgoraRTM from 'agora-rtm-sdk';
import useMediaQuery from '@mui/material/useMediaQuery';

export function AgoraMessageChat(props) {
  const matches = useMediaQuery('(min-width:600px)');
  const { channelInfo, setIsShowChat, isShowChat } = props;
  const [showMessageChat, setshowMessageChat] = useState(true);
  const [signalingEngine, setSignalingEngine] = useState(null);
  const [userMessage, setUserMessage] = useState('');
  const user = sessionStorage.getItem('user');
  const [messages, setMessages] = useState([{
    user: '',
    data: 'Hello, Welcome!',
  }]);

  // let signalingEngine = null;

  const sendMessageViaAgora = async (message) => {
    // Send channel message
    try {
      console.log('Channel:', channelInfo.name);
      await signalingEngine.publish(channelInfo.name, message);
    } catch (err) {
      console.log({ err }, 'error occurs at publish message');
    }
  };

  const scrollChatToBottom = () => {
    const messageStackComponent = document.getElementById('messagesStack');
    setTimeout(() => {
      messageStackComponent.scrollTo(0, messageStackComponent.scrollHeight);
    }, 50);
  };

  const addMessage = (userId, message) => {
    setMessages((prevState) => [...prevState, { user: userId, data: message }]);
    scrollChatToBottom();
  };

  const addMessageService = async (message) => {
    if (message === '') return;
    addMessage('You', message);
    await sendMessageViaAgora(message);
    setUserMessage('');
  };

  useEffect(() => {
    // Set up the signaling engine with the provided App ID, UID, and configuration
    async function setupSignalingEngine() {
      try {
        const temp = await new AgoraRTM.RTM(channelInfo.appId, JSON.parse(user).id, { token: channelInfo.messageToken });

        temp.addEventListener('message', (eventArgs) => {
          console.log('message', eventArgs);
          console.log(
            `Received message from ${
              eventArgs.publisher
            }: ${
              eventArgs.message}`,
          );
          addMessage(eventArgs.publisher, eventArgs.message);
        });

        temp.addEventListener('status', (eventArgs) => {
          console.log('status', eventArgs);
          console.log(
            `Connection state changed to: ${
              eventArgs.state
            }, Reason: ${
              eventArgs.reason}`,
          );
        });
        // Presence event handler
        temp.addEventListener('presence', (eventArgs) => {
          console.log('presence', eventArgs);
          if (eventArgs.eventType === 'SNAPSHOT') {
            console.log(
              `User ${eventArgs.snapshot[0].userId} joined channel ${eventArgs.channelName}`,
            );
          } else {
            console.log(
              `Presence event: ${
                eventArgs.eventType
              }, User: ${
                eventArgs.publisher}`,
            );
            addMessage('', `${eventArgs.publisher} joined channel !`);
          }
        });
        // Storage event handler
        temp.addEventListener('storage', (eventArgs) => {
          console.log('storage', eventArgs);
        });
        // Topic event handler
        temp.addEventListener('topic', (eventArgs) => {
          console.log('topic', eventArgs);
        });
        // Lock event handler
        temp.addEventListener('lock', (eventArgs) => {
          console.log('lock', eventArgs);
        });
        // TokenPrivilegeWillExpire event handler
        temp.addEventListener('TokenPrivilegeWillExpire', (eventArgs) => {
          console.log('TokenPrivilegeWillExpire ', eventArgs);
        });

        await temp.login();

        const subscribeOptions = {
          withMessage: true,
          withPresence: true,
          withMetadata: true,
          withLock: true,
        };
        await temp.subscribe(channelInfo.name, subscribeOptions);

        setSignalingEngine(temp);
      } catch (error) {
        console.log('Error:', error);
        // return null;
      }
    }
    async function loginAgoraRTM() {
      // Login
      try {
        await setupSignalingEngine();
      } catch (err) {
        console.log({ err }, 'error occurs at login.');
      }
    }

    async function unsubscribe() {
      try {
        if (signalingEngine) {
          await signalingEngine.unsubscribe(channelInfo.name);
        }
        console.log(`You have successfully left channel ${channelInfo.name}`);
      } catch (error) {
        console.log(error);
      }
    }

    // Logout from the signaling engine
    async function logout() {
      if (signalingEngine) await signalingEngine.logout();
    }

    loginAgoraRTM();
    // unscriber and logout
  }, []);

  return (
    isShowChat ? (
      <Box style={matches ? { width: '400px' } : { width: '100%', bottom: '0%', left: '0%' }} className="message-chat">
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Stack id="messagesStack" sx={{ height: '150px', overflowY: 'scroll' }}>
              {messages.map((message, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${index}-${message.user}`}>
                  {message.user !== '' && (
                  <>
                    <b>{message.user}</b>
                    :
                  </>
                  )}
                  {`${message.data}`}
                </div>
              ))}
            </Stack>
          </Grid>
          <Grid container xs={12} sx={{ height: '50px' }}>
            <Grid xs={9.5}>
              <TextField
                InputLabelProps={{ shrink: true }}
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                fullWidth
                inputProps={{ style: { padding: '2px' } }}
                  // label="Outlined"
                variant="outlined"
                sx={{ height: '10px' }}
              />
            </Grid>
            <Grid xs={2.5}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  // fontWeight: 700,
                  height: '27.5px',
                  backgroundColor: 'white',
                  color: '#4E2FEA',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={(e) => {
                  addMessageService(userMessage);
                  // setShowInstruction(false);
                }}
              >
                Enter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

    ) : (<div />)
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

AgoraMessageChat.propTypes = {
  channelInfo: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    worldId: PropTypes.string,
    appId: PropTypes.string,
    messageToken: PropTypes.string,
  }),
  setIsShowChat: PropTypes.func,
  isShowChat: PropTypes.bool,
};

export default compose(
  withConnect,
)(AgoraMessageChat);
