/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { editItemFromWorld } from 'services/item/actions';
import _ from 'lodash';
import GUI from 'lil-gui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function EditItemLegendWithGUI(props) {
  const { current, worldPublicId } = props;
  const [open, setOpen] = React.useState(false);
  const [saveItems, setSaveItems] = useState([]);
  const newItems = [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const channel = new BroadcastChannel(`item_adjuster_notification-${worldPublicId}`);
  const postMessage = (data) => {
    channel.postMessage(data);
    // saveEditingItems(data.data);
  };

  const onReceiveItemMessage = (event) => {
    const { data } = event;
    switch (data.type) {
      case 'dragstart': {
        const { id, position, ratio } = data.data;
        const itemIndex = _.findIndex(newItems, (o) => o.id === id);
        if (itemIndex !== -1) {
          newItems[itemIndex].position.x = position.x;
          newItems[itemIndex].position.y = position.y;
          newItems[itemIndex].position.z = position.z;
        }
        break;
      }
      case 'dragend': {
        const { id, position } = data.data;
        const itemIndex = _.findIndex(newItems, (o) => o.id === id);
        if (itemIndex !== -1) {
          newItems[itemIndex].position.x = position.x;
          newItems[itemIndex].position.y = position.y;
          newItems[itemIndex].position.z = position.z;
        }
        break;
      }
      default:
      //
    }
  };

  const addGUI = () => {
    const gui = new GUI();
    const guiProperties = {
      name: current.name,
      save: () => {
        const saveNewItems = [];
        newItems.forEach((item) => {
          const saveItem = {};
          saveItem.id = item.id;
          saveItem.position = item.position;
          saveItem.rotation = { y: item.rotation };
          saveItem.isRotate = item.isRotate;
          saveItem.asset = {
            id: item.assetId,
            ratio: item.ratio,
          };
          saveNewItems.push(saveItem);
        });
        setSaveItems(saveNewItems);
        handleClickOpen();
      },
    };
    gui.add(guiProperties, 'name');
    current.items.forEach((item, index) => {
      const folder = gui.addFolder(item.title);
      const properties = [
        { key: 'description', type: 'string', onchange: () => {} },
        {
          key: 'isRotate',
          type: 'boolean',
          onchange: (v) => {
            postMessage({
              type: 'isRotate',
              data: {
                id: item._id,
                isRotate: v,
              },
            });
            // rotation controller
            folder.controllers[2].disable(v);
          },
        },
        {
          key: 'rotation',
          type: 'number',
          onchange: (v) => {
            postMessage({
              type: 'rotation',
              data: {
                id: item._id,
                rotation: {
                  x: 0,
                  y: v,
                  z: 0,
                },
              },
            });
          },
        },
        {
          key: 'position',
          type: 'object',
          onchange: (v) => {
          },
        },
        // { key: 'location', type: 'string', onchange: () => {} },
      ];
      const newItem = {};
      newItem.id = item._id;
      newItem.description = item.description;
      newItem.assetId = item.asset[0]._id;
      newItem.isRotate = _.has(item, 'advanceSetting.isRotate') ? item.advanceSetting.isRotate : true;
      newItem.rotation = _.has(item, 'advanceSetting.rotation') ? item.advanceSetting.rotation.y : 0;
      // newItem.location = item.location;
      const { type } = item.asset[0];
      if (type === 'model') {
        newItem.ratio = item.asset[0].metadata.ratio;
        properties.push({
          key: 'ratio',
          type: 'number',
          onchange: (v) => {
            postMessage({
              type: 'ratio',
              data: {
                id: item._id,
                ratio: v,
              },
            });
          },
        });
      }
      newItem.position = _.has(item, 'advanceSetting.position') ? _.cloneDeep(item.advanceSetting.position) : { x: 0, y: 0, z: 0 };
      // eslint-disable-next-line array-callback-return
      properties.map((property) => {
        // eslint-disable-next-line no-shadow
        const { key, type } = property;
        if (key === 'rotation') {
          folder.add(newItem, key).min(0).max(360).onChange(property.onchange)
            .listen()
            .disable(newItem.isRotate);
        } else if (type === 'number') {
          folder.add(newItem, key).min(0).onChange(property.onchange)
            .listen();
        } else if (type === 'string') {
          folder.add(newItem, key, '').onChange(property.onchange).listen().disable();
        } else if (type === 'boolean') {
          folder.add(newItem, key).onChange(property.onchange).listen();
        }
      });
      const positionFolder = folder.addFolder('Position');
      positionFolder.add(newItem.position, 'x').decimals(6).onChange((v) => {
        postMessage({
          type: 'position',
          data: {
            id: item._id,
            x: v,
          },
        });
      }).listen();
      positionFolder.add(newItem.position, 'y').decimals(6).onChange((v) => {
        postMessage({
          type: 'position',
          data: {
            id: item._id,
            y: v,
          },
        });
      }).listen();
      positionFolder.add(newItem.position, 'z').decimals(6).onChange((v) => {
        postMessage({
          type: 'position',
          data: {
            id: item._id,
            z: v,
          },
        });
      }).listen();

      newItems.push(newItem);
    });

    gui.add(guiProperties, 'save');
    // gui.close();
  };

  const saveNewItems = () => {
    props.editItemFromWorld({ items: saveItems });
    handleClose();
  };

  useEffect(() => {
    addGUI();
    channel.addEventListener('message', onReceiveItemMessage);
    return () => {
      window.removeEventListener('message', onReceiveItemMessage);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Save
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Confirm to save all changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            fontWeight: 700,
            marginTop: '8px',
            backgroundColor: 'white',
            color: '#4E2FEA',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          onClick={saveNewItems}
        >
          Save
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditItemLegendWithGUI.propTypes = {
  worldPublicId: PropTypes.string,
  current: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      advanceSetting: PropTypes.shape({}),
    })),
  }),
  editingItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    rotation: PropTypes.number,
    ratio: PropTypes.number,
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }),
  })),
  setEditingItems: PropTypes.func,
  editItemFromWorld: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    editItemFromWorld,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(EditItemLegendWithGUI);
