/* eslint-disable no-bitwise */
/* eslint-disable no-undef */
AFRAME.registerComponent('dragdropposition', {
  schema: {
    id: { default: '', type: 'string' },
  },
  play() {
    const { el, data } = this;
    const that = this;
    let tempRotation = {};

    try {
      const worldPublicId = window.location.pathname.split('/').reverse()[0];
      const channel = new BroadcastChannel(`item_adjuster_notification-${worldPublicId}`);
      channel.addEventListener('message', (event) => {
        // console.log(event);
        if (event.data.data.id === data.id) {
          switch (event.data.type) {
            case 'rotation': {
              tempRotation = event.data.data.rotation;
              that.el.setAttribute('rotation', tempRotation);
              break;
            }
            case 'ratio': {
              const { ratio } = event.data.data;
              that.el.object3D.scale.set(ratio, ratio, ratio);
              break;
            }
            case 'position': {
              const { x, y, z } = event.data.data;
              if (x) {
                that.el.object3D.position.x = x;
              }
              if (y) {
                that.el.object3D.position.y = y;
              }
              if (z) {
                that.el.object3D.position.z = z;
              }
              break;
            }
            case 'isRotate': {
              const { isRotate } = event.data.data;
              that.el.setAttribute('animation', { enabled: isRotate });
              that.el.setAttribute('rotation', tempRotation);
              break;
            }
            default:
          }
        }
      });
      channel.postMessage({
        type: 'dragstart',
        data: {
          id: data.id,
          position: that.el.object3D.position,
          ratio: that.el.object3D.scale.x,
        },
      });
      this.el.addEventListener('dragstart', (evt) => {
        console.log(that.el.object3D);
        channel.postMessage({
          type: 'dragstart',
          data: {
            id: data.id,
            position: that.el.object3D.position,
            ratio: that.el.object3D.scale.x,
          },
        });
        evt.preventDefault();
      });
      this.el.addEventListener('dragend', (evt) => {
        channel.postMessage({
          type: 'dragend',
          data: {
            position: that.el.object3D.position,
            ratio: that.el.object3D.scale.x,
            id: data.id,
          },
        });
        evt.preventDefault();
      });
    } catch (e) {
      console.warn('Error on get world Id');
    }
  },
});
