import * as React from 'react';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    // height: '56px',
    padding: '16px 12px',
    ...theme.typography.inputText,
    // boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grey.D077}`,
    borderRadius: '2px',

    '&.MuiInputBase-inputSizeSmall': {
      height: '33px',
      boxSizing: 'border-box',
      ...theme.typography.menuAndInput,
    },
  },
}));

export default function CustomOutlinedInput(props) {
  return (
    <StyledOutlinedInput
      {...props}
      inputProps={{
        sx: {
          '&::placeholder': {
            color: 'black', // D040: '#666666' (cannot style through sytled component, so styled here),
          },
        },
      }}
    />
  );
}
