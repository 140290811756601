/* eslint-disable import/extensions */
import React, { useEffect, useRef } from 'react';
// import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';

// eslint-disable-next-line func-names
export default function () {
  const sceneRef = useRef(null);
  const demo = {
    target: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/logo1.mind',
    image: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/logo1.png',
    model: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/model/assistant/default/RobotExpressive.glb',
  };

  // eslint-disable-next-line camelcase
  const demo_shin_chan = {
    target: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/Shin-chan.mind',
    image: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/Shin-chan.jpg',
    model: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/model/assistant/default/RobotExpressive.glb',
  };

  const demo1 = {
    target: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.0/examples/image-tracking/assets/card-example/card.mind',
    image: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/card.png',
    model: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/softmind/scene.gltf',
  };

  useEffect(() => {
    const sceneEl = sceneRef.current;
    console.log(sceneEl.systems);
    console.log(sceneEl.systems['mindar-image-system']);
    const arSystem = sceneEl.systems['mindar-image-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });
    return () => {
      arSystem.stop();
    };
  }, []);

  return (
    <a-scene ref={sceneRef} mindar-image={`imageTargetSrc: ${demo.target}; autoStart: false; uiLoading: no; uiError: no; uiScanning: no; filterBeta: 100;`} color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
      <a-assets>
        <img id="card" src={demo.image} crossOrigin="anonymous" />
        <a-asset-item id="avatarModel" key="avatarModel" src={demo.model}></a-asset-item>
      </a-assets>

      <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

      <a-entity mindar-image-target="targetIndex: 0">
        <a-plane src="#card" position="0 0 0" height="0.552" width="1" rotation="0 0 0"></a-plane>
        <a-gltf-model rotation="0 0 0" position="0 0 0.1" scale="0.075 0.075 0.075" src="#avatarModel" animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"></a-gltf-model>
      </a-entity>
    </a-scene>
  );
}
