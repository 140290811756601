import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from 'webComponents/InputLabel/InputLabel';
import OutLinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import {
  makeSelectStoreName,
  makeSelectStorePublicId,
  makeSelectStoreUrl,
  makeSelectStoreId,
  makeSelectStoreError,
  makeSelectStoreLoading,
  makeSelectStoreIsShowSnackbar,
  makeSelectStoreCurrent,
  makeSelectStoreDescription,
} from 'services/store/selectors';
import {
  makeSelectUserRole,
} from 'services/user/selectors';
import { updateStoreDetail, setShowSnackbar } from 'services/store/actions';
import { updateWorldAdmin } from 'services/world-management/actions';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { isAdmin } from 'utils/user.utils';
import _ from 'lodash';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import styles from './store.module.scss';

const textfieldWidth = '425px';

const isWebxr = process.env.NODE_ENV !== 'production';

function Store(props) {
  const {
    id, publicId, error, loading, isShowSnackbar, current, role,
  } = props;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [url, setUrl] = useState(props.url);
  const [logoFileUrl, setLogoFileUrl] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isTemplate, setIsTemplate] = useState(props.current.isTemplate);
  const navigate = useNavigate();
  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleFileChange = (event) => {
    const [file] = event.target.files;
    setLogoFileUrl(URL.createObjectURL(file));
    setLogoFile(file);
  };

  const handleTemplateBoxChange = (event) => {
    const { checked } = event.target;
    setIsTemplate(checked);
  };

  const save = () => {
    const data = {
      name,
      description,
    };
    if (logoFile) data.logoFile = logoFile;

    if (isAdmin(role)) {
      data.storeId = id;
      if (_.has(props.current, 'templateId')) {
        data.templateId = props.current.templateId;
      }
      data.isTemplate = isTemplate;
      props.updateWorldAdmin(data);
    } else {
      props.updateStoreDetail({ storeId: id, data });
    }
  };

  const generateSharableStoreUrl = () => `${window.location.origin}/s/${publicId}`;

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="World"></GeneralTitle>
      <GeneralContent>
        <Stack spacing={5}>
          <div>
            <InputLabel htmlFor="storeName">
              Name
            </InputLabel>
            <OutLinedTextInput
              id="storeName"
              value={name}
              onChange={handleNameChange}
              sx={{
                width: textfieldWidth,
                maxWidth: '100%',
              }}
            />
            {/* <FormHelperText>Not allow / & ? $ *</FormHelperText> */}
          </div>
          <ErrorComponet error={error} objectKey="name" />
          <div>
            <InputLabel htmlFor="storeDescription">
              Description
            </InputLabel>
            <TextField
              id="storeDescription"
              value={description}
              onChange={handleDescriptionChange}
              sx={{
                width: textfieldWidth,
                maxWidth: '100%',
              }}
              multiline
              rows={3}
            />
          </div>
          <ErrorComponet error={error} objectKey="description" />
          <div className={styles.customDomainWrapper}>
            <InputLabel htmlFor="customDomain">
              Custom Domain
            </InputLabel>
            <OutlinedInput
              disabled
              fullWidth
              placeholder={generateSharableStoreUrl()}
              value={generateSharableStoreUrl()}
              sx={{
                width: textfieldWidth,
                maxWidth: '100%',
                paddingRight: '50px',
              }}
            />
            <span className={styles.copyButtonWrapper}>
              <Button
                variant="text"
                disableRipple
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  background: 'transparent',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                onClick={() => {
                  const storeUrl = generateSharableStoreUrl();
                  navigator.clipboard.writeText(storeUrl);
                  setOpen(true);
                }}
              >
                Copy
              </Button>
            </span>
            <ErrorComponet error={error} objectKey="url" />
          </div>
          <div>
            <Typography
              variant="h6"
              component="h6"
              sx={{ marginBottom: '8px' }}
            >
              Logo
            </Typography>
            <div className={styles.imgWrapper}>
              <Box
                component="img"
                src={logoFileUrl || current.logo}
                sx={{
                  height: '293px',
                  objectFit: 'contain',
                  width: '100%',
                }}
                crossOrigin="anonymous"
                alt="Store logo"
              />
            </div>
          </div>
          <span style={{ color: '#5219F6', textDecoration: 'underline', marginTop: '5px' }}>
            <label htmlFor="file_uploads_logo" style={{ cursor: 'pointer' }}>Change Logo</label>
            <input
              type="file"
              name="file_uploads_logo"
              id="file_uploads_logo"
              required="required"
              hidden
              onChange={(event) => handleFileChange(event, true)}
            />
          </span>
          <Box component="span">
            <Button
              onClick={() => {
                navigate(`/world-music?sid=${id}`);
              }}
              startIcon={<MusicNoteIcon />}
              variant="outlined"
            >
              Music
            </Button>
          </Box>
          {isWebxr
          && (
            <Box component="span">
              <Button
                onClick={() => {
                  navigate(`/layouts?sid=${id}`);
                }}
                variant="outlined"
                startIcon={<Grid4x4Icon />}
              >
                Store Layout
              </Button>
            </Box>
          )}
          {isAdmin(role)
            && (
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isTemplate} onChange={handleTemplateBoxChange} />} label="set as template" />
            </FormGroup>
            )}
          <ErrorComponet error={error} objectKey="" />
          <div>
            <LoadingButton loading={loading} variant="contained" size="large" onClick={() => save()}>Save</LoadingButton>
          </div>
        </Stack>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={() => { setOpen(false); }}
          message="copied to clipboard"
        />
        <SimpleSnackbar
          severity="success"
          open={isShowSnackbar}
          title="Successfully updated"
          handleClose={() => props.setShowSnackbar(false)}
        />
      </GeneralContent>
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectStoreName(),
  description: makeSelectStoreDescription(),
  role: makeSelectUserRole(),
  url: makeSelectStoreUrl(),
  id: makeSelectStoreId(),
  publicId: makeSelectStorePublicId(),
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
  isShowSnackbar: makeSelectStoreIsShowSnackbar(),
  current: makeSelectStoreCurrent(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreDetail,
    setShowSnackbar,
    updateWorldAdmin,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Store.propTypes = {
  id: PropTypes.string.isRequired,
  publicId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  isShowSnackbar: PropTypes.bool,
  updateStoreDetail: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updateWorldAdmin: PropTypes.func.isRequired,
  setShowSnackbar: PropTypes.func.isRequired,
  error: PropTypes.shape({}) || PropTypes.string,
  current: PropTypes.shape({
    logo: PropTypes.string,
    isTemplate: PropTypes.bool,
    templateId: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(Store);
