import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'itemPath' && prop !== 'isActivePath',
})(({ theme, isActivePath }) => ({
  ...(isActivePath && {
    backgroundColor: theme.palette.grey.D091,
  }),
  paddingRight: '24px',
  paddingLeft: '24px',
  paddingTop: '9px',
  paddingBottom: '9px',
  borderBottom: `1px solid ${theme.palette.grey.D091}`,
}));

export default function CustomListItemButton(props) {
  const location = useLocation();
  // const isActivePath = props.itemPath.startsWith(location.pathname);
  const isActivePath = location.pathname.startsWith(props.itemPath);
  return (
    <StyledListItemButton {...props} isActivePath={isActivePath} />
  );
}

CustomListItemButton.propTypes = {
  itemPath: PropTypes.string,
};
