import {
  takeLatest, call, put, all, select,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import { updateStoreDetailSuccess, updateStoreDetailFail, updateStoreDetailFromAdmin } from 'services/store/actions';
import {
  UPDATE_WORLD_ADMIN,
} from './constants';

import {
  updateWorldAdminSuccess,
  updateWorldAdminFail,
} from './actions';

const api = API.create();

export function* updateWorldAdmin(data) {
  const result = yield call(api.storeManagament.updateWorldAdmin, data.data);
  yield put(updateStoreDetailFromAdmin());
  if (result.ok) {
    yield put(updateWorldAdminSuccess(result.data));
    yield put(updateStoreDetailSuccess(result.data));
  } else {
    yield put(updateWorldAdminFail(result.data.error.message));
    yield put(updateStoreDetailFail(result.data.error.message));
  }
}

export default function* worldManagementSaga() {
  yield all([
    takeLatest(UPDATE_WORLD_ADMIN, updateWorldAdmin),
  ]);
}
