import {
  takeLatest, call, put, all, select,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import { makeSelectStoreId } from 'services/store/selectors';
import {
  useThemeSuccess,
} from 'services/theme/actions';
import * as auth from 'services/auth/actions';
import {
  navigateRouteTo,
} from 'services/route/actions';
import { saveData } from 'utils/sessionStoarge.utils';
import {
  createStoreSuccess,
  createStoreFail,
  getStoreListSuccess,
  getStoreListFail,
  validateStoreNameSuccess,
  validateStoreNameFail,
  updateOnboardingStoreName,
  getStoreThemeSuccess,
  getStoreThemeFail,
  getStoreDetailSuccess,
  getStoreDetailFail,
  updateStoreAssistantSuccess,
  updateStoreAssistantFail,
  updateStoreDetailSuccess,
  updateStoreDetailFail,
  updateStoreMapSuccess,
  updateStoreMapFail,
  getStoreByNameSuccess,
  getStoreByNameFail,
  getStoreByNameWithItemsSuccess,
  getStoreByNameWithItemsFail,
  updateStoreThemeSuccess,
  updateStoreThemeFail,
  createStoreByTemplateSuccess,
  createStoreByTemplateFail,
  addBackgroundMusicSuccess,
  addBackgroundMusicFail,
  deleteBackgroundMusicSuccess,
  deleteBackgroundMusicFail,
  updateBackgroundMusicSuccess,
  updateBackgroundMusicFail,
} from './actions';
import {
  CREATE_STORE,
  GET_STORE_DETAIL,
  VALIDATE_STORE_NAME,
  GET_STORE_THEME,
  GET_STORE_LIST,
  UPDATE_STORE_ASSISTANT,
  UPDATE_STORE_DETAIL,
  UPDATE_STORE_MAP,
  GET_STORE_BY_NAME,
  GET_STORE_BY_NAME_WITH_ITEMS,
  GET_STORE_BY_PUBLIC_ID_WITH_ITEMS,
  UPDATE_STORE_THEME,
  CREATE_STORE_BY_TEMPLATE,
  ADD_BACKGROUND_MUSIC,
  UPDATE_BACKGROUND_MUSIC,
  DELETE_BACKGROUND_MUSIC,
} from './constants';

const api = API.create();

export function* createStore(data) {
  const result = yield call(api.store.create, data.data);
  if (result.ok) {
    yield put(createStoreSuccess(result.data));
    window.location.replace(`${window.location.origin}/home?sid=${result.data.data._id}`);
  } else {
    yield put(createStoreFail(result.data.error.message));
  }
}

export function* validateStoreName(data) {
  const { callback } = data;
  const result = yield call(api.store.validateStoreName, data.data);
  if (result.ok) {
    if (_.isEmpty(result.data.data.items)) {
      yield put(validateStoreNameSuccess(result.data));
      yield put(updateOnboardingStoreName(data.data));
      callback();
    } else {
      yield put(validateStoreNameFail('World Name Duplicated'));
    }
  } else {
    yield put(validateStoreNameFail(result.data.error.message));
  }
}

export function* getStoreDetail(data) {
  const { storeId } = data;
  const result = yield call(api.store.detail, storeId);
  if (result.ok) {
    yield put(getStoreDetailSuccess(result.data.data.items[0]));
  } else {
    yield put(getStoreDetailFail(result.data.error.message));
  }
}

export function* getStoreByName(data) {
  const { name } = data;
  const result = yield call(api.store.name, name);
  if (result.ok) {
    yield put(getStoreByNameSuccess(result.data.data.items[0]));
  } else {
    yield put(getStoreByNameFail(result.data.error.message));
  }
}

export function* getStoreByNameWithItems(data) {
  const { name } = data;
  const result = yield call(api.store.publicStore, name);
  if (result.ok) {
    yield put(getStoreByNameWithItemsSuccess(result.data.data.items[0]));
  } else {
    yield put(getStoreByNameWithItemsFail(result.data.error.message));
  }
}

export function* getStoreByPublicIdWithItems(data) {
  const { id, isEdit } = data;
  let result = yield call(api.store.publicStoreByPublicId, id);
  if (isEdit) {
    result = yield call(api.store.publicStoreByPublicIdInEditMode, id);
  }
  if (result.ok) {
    yield put(getStoreByNameWithItemsSuccess(result.data.data.items[0]));
  } else {
    yield put(getStoreByNameWithItemsFail(result.data.error.message));
  }
}

export function* getStoreList(data) {
  const { userId, callback, storeId } = data;
  const result = yield call(api.store.list, userId);
  if (result.ok) {
    const storeList = result.data.data.items;
    yield put(getStoreListSuccess(storeList));
    // add an activeStoreId
    let id = storeId;
    const isValidStoreId = _.findIndex(storeList, ['_id', storeId]) >= 0;
    if (storeId && isValidStoreId) {
      yield call(getStoreDetail, { storeId });
    } else {
      const firstStore = result.data.data.items[0];
      id = firstStore._id;
      window.location.replace(`${window.location.origin}/home?sid=${id}`);
      // eslint-disable-next-line no-underscore-dangle
      // yield call(getStoreDetail, { storeId: firstStore._id });
    }
    callback(id);
  } else {
    yield put(getStoreListFail(result.data.error.message));
  }
}

export function* getStoreTheme() {
  const result = yield call(api.store.getStoreTheme);
  if (result.ok) {
    yield put(getStoreThemeSuccess(result.data.data.items));
  } else {
    yield put(getStoreThemeFail(result.data.error.message));
  }
}

export function* updateStoreAssistant(data) {
  const { assistantId } = data;
  const result = yield call(api.assistant.update, { assistantId, data: data.data });
  if (result.ok) {
    yield put(updateStoreAssistantSuccess(result.data));
  } else {
    yield put(updateStoreAssistantFail(result.data.error.message));
  }
}

export function* updateStoreDetail(data) {
  const { storeId } = data;
  const result = yield call(api.store.update, { storeId, data: data.data });
  if (result.ok) {
    yield put(updateStoreDetailSuccess(result.data));
  } else {
    yield put(updateStoreDetailFail(result.data.error.message));
  }
}

export function* updateStoreMap(data) {
  const { id } = data;
  const result = yield call(api.map.update, { id, data: data.data });
  if (result.ok) {
    yield put(updateStoreMapSuccess(result.data));
  } else {
    yield put(updateStoreMapFail(result.data.error.message));
  }
}

export function* updateStoreTheme(data) {
  const result = yield call(api.store.updateTheme, data.data);
  if (result.ok) {
    const storeId = yield select(makeSelectStoreId());
    yield call(getStoreDetail, { storeId });
    yield put(updateStoreThemeSuccess(result.data));
    yield put(useThemeSuccess());
  } else {
    yield put(updateStoreThemeFail(result.data.error.message));
  }
}

export function* createStoreByTemplate(data) {
  const result = yield call(api.store.createByTemplate, data.data);
  if (result.ok) {
    // switch to new store;
    const storeId = result.data.data._id;
    yield put(createStoreByTemplateSuccess(result.data));
    saveData('showHomeSnackbar', true);
    window.location.replace(`${window.location.origin}/home?sid=${storeId}`);
  } else {
    yield put(createStoreByTemplateFail(result.data.error.message));
  }
}

export function* addBackgroundMusic(data) {
  const result = yield call(api.multimediaAsset.addBackgroundMusic, data.data);
  if (result.ok) {
    yield put(addBackgroundMusicSuccess(result.data));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } else {
    yield put(addBackgroundMusicFail(result.data.error.message));
  }
}

export function* updateBackgroundMusic(data) {
  const result = yield call(api.multimediaAsset.updateBackgroundMusic, data.data);
  if (result.ok) {
    yield put(updateBackgroundMusicSuccess(result.data));
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  } else {
    yield put(updateBackgroundMusicFail(result.data.error.message));
  }
}

export function* deleteBackgroundMusic(data) {
  const result = yield call(api.multimediaAsset.deleteBackgroundMusic, { id: data.id, worldId: data.worldId });
  if (result.ok) {
    yield put(deleteBackgroundMusicSuccess(result.data));
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } else {
    yield put(deleteBackgroundMusicFail(result.data.error.message));
  }
}

export default function* storeSaga() {
  yield all([
    takeLatest(VALIDATE_STORE_NAME, validateStoreName),
    takeLatest(GET_STORE_BY_NAME, getStoreByName),
    takeLatest(GET_STORE_THEME, getStoreTheme),
    takeLatest(GET_STORE_LIST, getStoreList),
    takeLatest(UPDATE_STORE_ASSISTANT, updateStoreAssistant),
    takeLatest(UPDATE_STORE_DETAIL, updateStoreDetail),
    takeLatest(UPDATE_STORE_MAP, updateStoreMap),
    takeLatest(CREATE_STORE, createStore),
    takeLatest(GET_STORE_DETAIL, getStoreDetail),
    takeLatest(GET_STORE_BY_NAME_WITH_ITEMS, getStoreByNameWithItems),
    takeLatest(GET_STORE_BY_PUBLIC_ID_WITH_ITEMS, getStoreByPublicIdWithItems),
    takeLatest(UPDATE_STORE_THEME, updateStoreTheme),
    takeLatest(CREATE_STORE_BY_TEMPLATE, createStoreByTemplate),
    takeLatest(ADD_BACKGROUND_MUSIC, addBackgroundMusic),
    takeLatest(DELETE_BACKGROUND_MUSIC, deleteBackgroundMusic),
    takeLatest(UPDATE_BACKGROUND_MUSIC, updateBackgroundMusic),
  ]);
}
