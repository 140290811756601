import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectStoreDomain = (state) => state.store || initialState;

export const makeSelectStore = () => createSelector(selectStoreDomain, (s) => { const a = s; return a; });
export const makeSelectOnboardingStoreName = () => createSelector(selectStoreDomain, _.property('name'));
export const makeSelectStoreName = () => createSelector(selectStoreDomain, _.property('current.name'));
export const makeSelectStoreDescription = () => createSelector(selectStoreDomain, _.property('current.description'));
export const makeSelectStoreUrl = () => createSelector(selectStoreDomain, _.property('current.url'));
export const makeSelectStoreId = () => createSelector(selectStoreDomain, _.property('current._id'));
export const makeSelectStorePublicId = () => createSelector(selectStoreDomain, _.property('current.publicId'));
export const makeSelectStoreCurrentTheme = () => createSelector(selectStoreDomain, _.property('current.theme[0]'));
export const makeSelectStoreCurrent = () => createSelector(selectStoreDomain, _.property('current'));
export const makeSelectStoreCurrentMap = () => createSelector(selectStoreDomain, _.property('current.map[0]'));
export const makeSelectStoreLogo = () => createSelector(selectStoreDomain, _.property('logo'));
export const makeSelectStoreWallTexture = () => createSelector(selectStoreDomain, _.property('wallTexture'));
export const makeSelectStoreSkyTexture = () => createSelector(selectStoreDomain, _.property('skyTexture'));
export const makeSelectStoreGroundTexture = () => createSelector(selectStoreDomain, _.property('floorTexture'));
export const makeSelectStoreMap = () => createSelector(selectStoreDomain, _.property('map[0]'));
export const makeSelectStoreMapBase64Image = () => createSelector(selectStoreDomain, _.property('mapBase64Image'));
export const makeSelectStoreAssistant = () => createSelector(selectStoreDomain, _.property('current.assistant[0]'));
export const makeSelectStoreError = () => createSelector(selectStoreDomain, _.property('error'));
export const makeSelectStoreTheme = () => createSelector(selectStoreDomain, _.property('theme'));
export const makeSelectStoreLoading = () => createSelector(selectStoreDomain, _.property('loading'));
export const makeSelectSelectedStoreTheme = () => createSelector(selectStoreDomain, _.property('selectedTheme'));
export const makeSelectStoreList = () => createSelector(selectStoreDomain, _.property('list'));
export const makeSelectStoreIsReady = () => createSelector(selectStoreDomain, _.property('isReady'));
export const makeSelectStorePublicStoreDetail = () => createSelector(selectStoreDomain, _.property('publicStoreDetail'));
export const makeSelectStoreIsShowSnackbar = () => createSelector(selectStoreDomain, _.property('isShowSnackbar'));
export const makeSelectStoreLayoutSnackbar = () => createSelector(selectStoreDomain, _.property('layoutSnackbar'));
export const makeSelectStoreBackgroundMusicSnackbar = () => createSelector(selectStoreDomain, _.property('backgroundMusicSnackbar'));
export const makeSelectStoreBackgroundMusic = () => createSelector(selectStoreDomain, _.property('current.backgroundMusic'));
