export const listItemText = {
  MuiListItemText: {
    styleOverrides: {
      root: {
        '.MuiTypography-root': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  },
};
