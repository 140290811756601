import * as React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import AbcIcon from '@mui/icons-material/Abc';

export default function CustomStepper({
  className,
  width,
  activeStep,
}) {
  const steps = ['select name', 'select theme', 'create account'];
  return (
    <Box className={className} sx={{ width }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

CustomStepper.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  activeStep: PropTypes.number,
  // steps: PropTypes.arrayOf(PropTypes.string),
};
