import React, { useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  makeSelectStorePublicId,
} from 'services/store/selectors';

import doubleLogo from '../../assets/double_logo.png';
import ShopIcon from '../../assets/icon/noun-shop.svg';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: '65px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 33px',
  zIndex: `${theme.zIndex.drawer + 1}`,
}));

function MenuAppBar(props) {
  const {
    publicId, className, auth, userId, logout, name,
  } = props;
  // const [auth, setAuth] = useState(true);
  // use auth to control the showing of the account button
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get('sid');

  const navigate = useNavigate();

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate(`/user/profile?sid=${storeId}`);
    handleClose();
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout(userId, navigateToLogin);
  };

  return (
    <StyledAppBar className={className} position="fixed">
      <Box
        component="img"
        alt="Double's logo"
        src={doubleLogo}
        sx={{ width: '174px' }}
      />
      {auth && (
        <div>
          <Button
            variant="contained"
            size="large"
            color="success"
            onClick={() => {
              window.open(`/s/${publicId}`, '_blank');
            }}
            startIcon={<ShopIcon />}
            sx={{
              backgroundColor: '#4CAF50',
              paddingLeft: '30px',
              paddingRight: '30px',
              '&:hover': {
                backgroundColor: '#4CAF50',
              },
              borderRadius: '100px',
            }}
          >
            Enter Store
          </Button>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              variant="circular"
              size="40px"
              sx={{
                bgcolor: '#6142FB',
                '.MuiSvgIcon-root': {
                  width: '1em',
                  height: '1em',
                },
              }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout} sx={{ textTransform: 'initial' }}>Log out</MenuItem>
          </Menu>
        </div>
      )}
      {/* </Toolbar> */}
    </StyledAppBar>
  );
}

const mapStateToProps = createStructuredSelector({
  publicId: makeSelectStorePublicId(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

MenuAppBar.defaultProps = {
  publicId: '',
};

MenuAppBar.propTypes = {
  className: PropTypes.string,
  publicId: PropTypes.string,
  auth: PropTypes.bool,
  userId: PropTypes.string,
  logout: PropTypes.func,
  name: PropTypes.string,
};

export default compose(
  withConnect,
)(MenuAppBar);
