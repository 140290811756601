import {
  ROUTE_ININTIAL,
  ROUTE_NAVIGATE,
  SET_ROUTE_SHOW_SNACKBAR_BAR,
} from './constants';

export const initialRoute = (navigate) => ({
  type: ROUTE_ININTIAL,
  navigate,
});

export const navigateRouteTo = (path) => ({
  type: ROUTE_NAVIGATE,
  path,
});

export const setShowSnackbar = (bool) => ({
  type: SET_ROUTE_SHOW_SNACKBAR_BAR,
  bool,
});
