import _ from 'lodash';

export const isImage = (type) => {
  if (
    type === 'image/png'
    || type === 'image/jpg'
    || type === 'image/jpeg'
    || type === 'image/svg+xml'
  ) return true;
  return false;
};

export const isSupported3DModal = (filename) => filename.endsWith('.glb') || filename.endsWith('.gltf');

export const isIgnoreAsset = (asset) => typeof asset === 'undefined' || _.isEmpty(asset);

export const isIgnoreBackgroundMusic = (backgroundMusic) => backgroundMusic.length === 0;
