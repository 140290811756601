import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

const selectAuth = (state) => state.auth || initialState;

export const selectAuthAccessToken = () => createSelector(selectAuth, _.property('access_token'));
export const makeSelectHomeSnackbar = () => createSelector(selectAuth, _.property('snackbar'));

const makeSelectIsAuthenticated = () => createSelector(
  selectAuthAccessToken,
  (token) => !!(token && token.access_token),
);

const selectIsAuthenticated = makeSelectIsAuthenticated();
export { selectIsAuthenticated, makeSelectIsAuthenticated };
