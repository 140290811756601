import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeSelectKeymakerIsShow } from 'services/keymaker/selectors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './InstructionLegend.scss';

const isWebxr = process.env.NODE_ENV !== 'production';

function EditInstructionLegend(props) {
  const {
    name, isShowKeymakerChat, isFly, setIsFly, editingItems,
  } = props;
  const [showInstruction, setShowInstruction] = useState(true);

  return (
    showInstruction ? (
      <div
        className="instructionLegend"
        style={{
          zIndex: 1000,
          left: '56px',
          bottom: '58px',
          position: 'absolute',
          borderRadius: '4px',
          border: '1px solid rgba(255, 255, 255, 0.85)',
          background: 'rgba(0, 0, 0, 0.45)',
          padding: '36px 32px 37px 32px',
          color: 'white',
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            color: 'white',
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => { setShowInstruction(false); }}
        >
          <CancelIcon />
        </IconButton>
        {/* </div> */}
        <Typography variant="h6" component="h5" sx={{ marginBottom: '18px' }}>
          {`Welcome to ${name}`}
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body1"> Drag and drop: move objects.</Typography>
          <FormControlLabel labelPlacement="start" control={<Checkbox value={isFly} />} label="Fly" onChange={(e) => { setIsFly(e.target.checked); }} />
        </Stack>
      </div>
    ) : (
      <div style={{
        zIndex: 1000,
        left: 50,
        bottom: 25,
        position: 'absolute',
        color: 'white',
      }}
      >
        <Stack direction="row" spacing={3}>
          <IconButton
            aria-label="close"
            sx={{
              backgroundColor: 'rgba(237, 228, 255, 0.55)',
              color: 'white',
            }}
            onClick={() => { setShowInstruction(true); }}
          >
            <MoreHorizIcon />
          </IconButton>
          {/* <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Exit</Typography>
          <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Move</Typography>
          <Typography variant="subtitle1" component="span" style={{ textDecoration: 'underline' }}>Pan</Typography> */}
        </Stack>
      </div>
    )
  );
}

const mapStateToProps = createStructuredSelector({
  isShowKeymakerChat: makeSelectKeymakerIsShow(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

EditInstructionLegend.propTypes = {
  name: PropTypes.string,
  isShowKeymakerChat: PropTypes.bool,
  setIsFly: PropTypes.func,
  isFly: PropTypes.bool,
  editingItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default compose(
  withConnect,
)(EditInstructionLegend);
