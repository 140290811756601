import React, { useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { makeSelectKeymakerIsShow } from 'services/keymaker/selectors';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';
import LeftArrowSvg from './assets/left-arrow.svg';
import RightArrowSvg from './assets/right-arrow.svg';
import SearchSvg from './assets/search.svg';
import MicSvg from './assets/mic.svg';
import CloseSvg from './assets/close.svg';
import PortalPatternSvg from './assets/portal-pattern.svg';
import styles from './KeymakerChatBox.module.scss';

function SliderBox() {
  return (
    <div className={styles.sliderBox}>
      <div className={styles.circle}>
        <PortalPatternSvg />
      </div>
      <div className={styles.label}>Portal name</div>
    </div>
  );
}

function KeymakerChatBox(props) {
  const { name, isShow } = props;

  useEffect(() => {

  }, []);

  return (
    isShow ? (
      <div className={styles.chatBox}>
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => props.setIsShowKeymakerChat(false)}
          aria-label="close"
        >
          <CloseSvg />
        </button>
        <p className={styles.headline}>
          {`Hi, Welcome to ${name} !`}
          <br />
          I’m (name) your Double assistant.
        </p>
        <p className={styles.description}>Description goes here. Description goes here. Description goes here. </p>
        <div className={styles.inputContainer}>
          <button className={styles.searchButton} type="button" aria-label="search">
            <SearchSvg />
          </button>
          <input type="text" placeholder="Search to travel" />
          <button className={styles.micButton} type="button" aria-label="mic">
            <MicSvg />
          </button>
        </div>
        <p className={styles.sliderHeader}>Total results (3)</p>
        <div className={styles.slider}>
          <button className={styles.sliderButton} type="button" aria-label="left-arrow">
            <LeftArrowSvg />
          </button>
          <div className={styles.sliderBoxContainer}>
            <SliderBox />
            <SliderBox />
            <SliderBox />
          </div>
          <button className={styles.sliderButton} type="button" aria-label="right-arrow">
            <RightArrowSvg />
          </button>
        </div>
      </div>
    )
      : (<div />)
  );
}

const mapStateToProps = createStructuredSelector({
  isShow: makeSelectKeymakerIsShow(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIsShowKeymakerChat,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

KeymakerChatBox.propTypes = {
  name: PropTypes.string,
  isShow: PropTypes.bool,
  setIsShowKeymakerChat: PropTypes.func,
};

export default compose(
  withConnect,
)(KeymakerChatBox);
