/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { SET_IS_SHOW_KEYMAKER_CHAT } from './constants';

export const initialState = {
  isShow: false,
};

const keymakerReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SET_IS_SHOW_KEYMAKER_CHAT:
      draft.isShow = action.bool;
      break;
    default:
  }
});

export default keymakerReducer;
