export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';
export const GET_ALL_ITEMS_SUCCESS = 'GET_ALL_ITEMS_SUCCESS';
export const GET_ALL_ITEMS_FAIL = 'GET_ALL_ITEMS_FAIL';
export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAIL = 'ADD_ITEM_FAIL';
export const EDIT_ITEM = 'EDIT_ITEM';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAIL = 'EDIT_ITEM_FAIL';
export const DEACTIVTATE_ITEM = 'DEACTIVTATE_ITEM';
export const DEACTIVTATE_ITEM_SUCCESS = 'DEACTIVTATE_ITEM_SUCCESS';
export const DEACTIVTATE_ITEM_FAIL = 'DEACTIVTATE_ITEM_FAIL';
export const MASS_EDIT_ITEM = 'MASS_EDIT_ITEM';
export const UPDATE_ITEMS_LOCATION = 'UPDATE_ITEMS_LOCATION';
export const UPDATE_ITEMS_LOCATION_SUCCESS = 'UPDATE_ITEMS_LOCATION_SUCCESS';
export const UPDATE_ITEMS_LOCATION_FAIL = 'UPDATE_ITEMS_LOCATION_FAIL';
export const SET_ITEM_SHOW_SNACKBAR = 'SET_ITEM_SHOW_SNACKBAR';
export const SET_ITEM_SHOW_MODAL = 'SET_ITEM_SHOW_MODAL';
export const EDIT_ITEM_FROM_WORLD = 'EDIT_ITEM_FROM_WORLD';
export const EDIT_ITEM_FROM_WORLD_SUCCESS = 'EDIT_ITEM_FROM_WORLD_SUCCESS';
export const EDIT_ITEM_FROM_WORLD_FAIL = 'EDIT_ITEM_FROM_WORLD_FAIL';
export const SNACKBAR_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  PLACE: 'PLACE',
  DELETE: 'DELETE',
};
