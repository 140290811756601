import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MenuAppBar from 'webComponents/MenuAppBar/MenuAppBar';
import _ from 'lodash';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { getStoreList, getStoreDetail } from 'services/store/actions';
import { getProfile, getDataFromScratch } from 'services/user/actions';
import {
  initialRoute,
  setShowSnackbar,
} from 'services/route/actions';
import { makeSelectStoreIsReady, makeSelectStoreName, makeSelectStoreError } from 'services/store/selectors';
import { makeSelectUserId } from 'services/user/selectors';
import { logout } from 'services/auth/actions';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Toolbar from '@mui/material/Toolbar';
import { isValidStoreUrl, getStoreId } from 'utils/path.utils';
import TemplatesOutlet from 'routes/templates/TemplatesOutlet';
import Templates from 'routes/templates/Templates';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import {
  makeSelectRouteSnackbar,
} from 'services/route/selectors';
import { getAllItems } from 'services/item/actions';
import { customTheme } from '../../muiUtils/customTheme/index';

import './index.scss';

const mdTheme = createTheme(customTheme);

function WebsiteContent(props) {
  const location = useLocation();
  React.useEffect(() => {
    initialRoute();
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MenuAppBar className="dashboard" auth={false} />
        <Box className="mainContainer" component="main" sx={{ flexGrow: 1, backgroundColor: '#F1F1F1' }}>
          <Toolbar />
          <Routes>
            <Route path="/templates" element={<TemplatesOutlet />}>
              <Route index element={<Templates />} />
            </Route>
            <Route path="*" element={<TemplatesOutlet />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

WebsiteContent.propTypes = {
};

function Website(props) {
  const { isReady, snackbar } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <WebsiteContent userId={props.userId} logout={props.logout} name={props.name} getStoreDetail={props.getStoreDetail} />
      <SimpleSnackbar
        severity="warning"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowSnackbar(false)}
      />
    </StyledEngineProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  userId: makeSelectUserId(),
  name: makeSelectStoreName(),
  error: makeSelectStoreError(),
  snackbar: makeSelectRouteSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreList,
    getProfile,
    logout,
    getStoreDetail,
    getDataFromScratch,
    initialRoute,
    setShowSnackbar,
    getAllItems,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Website.propTypes = {

  logout: PropTypes.func.isRequired,
  getStoreDetail: PropTypes.func.isRequired,
  userId: PropTypes.string,
  name: PropTypes.string,
  isReady: PropTypes.bool,
  setShowSnackbar: PropTypes.func,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
  // error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export default compose(
  withConnect,
)(Website);
