import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Typography, Box, Button } from '@mui/material';
import TabPanel from 'webComponents/Tabs/TabPanel';
import style from './panel.modules.scss';
import islandImg from '../../../../assets/Items/art/artwork_island.png';

function ItemTabPanel(props) {
  const { isNoItemList } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const addItem = () => navigate(`/items/new${location.search}&new=true`);
  const goToItem = () => navigate(`/items${location.search}`);

  return (
    <TabPanel {...props}>
      <div className={style.container}>
        <div className={style.text}>
          <Typography variant="h1" sx={{ marginBottom: '24px' }}>
            { isNoItemList ? 'Add items to your world' : 'Customize your world'}
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: '42px' }}>
            { isNoItemList ? 'Get your world Metaverse-ready by adding products or content.' : 'Get your world Metaverse-ready by editing the layout, items, and content'}
          </Typography>
          <Button
            size="large"
            variant="contained"
            sx={{ fontWeight: '700' }}
            onClick={isNoItemList ? addItem : goToItem}
          >
            { isNoItemList ? 'Add Item' : 'Edit My World'}
          </Button>
        </div>
        <img src={islandImg} alt="" />
      </div>
    </TabPanel>
  );
}

ItemTabPanel.propTypes = {
  isNoItemList: PropTypes.bool,
};

export default ItemTabPanel;
