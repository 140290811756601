import {
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAIL,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAIL,
  EDIT_THEME,
  EDIT_THEME_SUCCESS,
  EDIT_THEME_FAIL,
  GET_THEME_LIST_BY_USERID,
  GET_THEME_LIST_BY_USERID_SUCCESS,
  GET_THEME_LIST_BY_USERID_FAIL,
  SET_THEME_SHOW_SNACKBAR_BAR,
  USE_THEME_SUCCESS,
  SET_THEME_SHOW_MODAL,
  REMOVE_THEME_ERROR,
} from './constants';

export const createTheme = (data) => ({
  type: CREATE_THEME,
  data,
});

export const createThemeSuccess = (data) => ({
  type: CREATE_THEME_SUCCESS,
  data,
});

export const createThemeFail = (error) => ({
  type: CREATE_THEME_FAIL,
  error,
});

export const getThemeListByUserId = (id) => ({
  type: GET_THEME_LIST_BY_USERID,
  id,
});

export const getThemeListByUserIdSuccess = (data) => ({
  type: GET_THEME_LIST_BY_USERID_SUCCESS,
  data,
});

export const getThemeListByUserIdFail = (error) => ({
  type: GET_THEME_LIST_BY_USERID_FAIL,
  error,
});

export const editTheme = (data) => ({
  type: EDIT_THEME,
  data,
});

export const editThemeSuccess = (data) => ({
  type: EDIT_THEME_SUCCESS,
  data,
});

export const editThemeFail = (error) => ({
  type: EDIT_THEME_FAIL,
  error,
});

export const setShowSnackbar = (bool) => ({
  type: SET_THEME_SHOW_SNACKBAR_BAR,
  bool,
});

export const setShowModal = (bool) => ({
  type: SET_THEME_SHOW_MODAL,
  bool,
});

export const deleteTheme = (id) => ({
  type: DELETE_THEME,
  id,
});

export const deleteThemeSuccess = (data) => ({
  type: DELETE_THEME_SUCCESS,
  data,
});

export const deleteThemeFail = (error) => ({
  type: DELETE_THEME_FAIL,
  error,
});

export const useThemeSuccess = () => ({
  type: USE_THEME_SUCCESS,
});

export const removeThemeError = () => ({
  type: REMOVE_THEME_ERROR,
});
