import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FileUploadInput from 'webComponents/FileUploadInput/FileUploadInput';
import { isSupported3DModal } from 'utils/asset.utils';
import { Typography } from '@mui/material';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import NumericFormatRatio from 'webComponents/NumericFormatRatio/NumericFormatRatio';
import styles from './AssistantTabPanel.module.scss';

function AssistantTabPanel(props) {
  const {
    children, value, index, assistant, setFile, isShowError, errorMessage, ...other
  } = props;
  const [isUnknownFile, setIsUnknownFile] = useState(false);
  const [filename, setFilename] = useState('');
  const [fileObjectUrl, setFileObjectUrl] = useState(null);

  const handleFileChange = (event, isReplace = false) => {
    const [file] = event.target.files;
    setFilename(_.isUndefined(file.name) ? '' : file.name);
    if (!file || !isSupported3DModal(file.name)) {
      setIsUnknownFile(true);
      if (isReplace) setFileObjectUrl(null);
      return;
    }
    setFileObjectUrl(URL.createObjectURL(file));
    setFile((prevState) => ({
      ...prevState,
      file,
    }));
  };

  const handleRatioChange = (event) => {
    const ratio = event.target.value;
    if (ratio >= 0) {
      setFile((prevState) => ({
        ...prevState,
        ratio,
      }));
    }
  };

  const removeFile = () => {
    setFileObjectUrl(null);
    setFilename('');
    setFile({
      id: '',
      file: null,
      ratio: 1,
    });
    setIsUnknownFile(false);
  };

  const generateModalComponent = useMemo(() => (
    <NumericFormatRatio
      sx={{ mt: 2, width: '221px' }}
      name="ratio"
      value={assistant.ratio}
      label="Ratio"
      variant="outlined"
      type="number"
      min="0"
      onChange={handleRatioChange}
      placeholder="ratio"
    />
  ), [assistant]);

  return (
    <div
      className={styles.assistantTabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isShowError && <ErrorComponet objectKey="" error={errorMessage} />}
      {(fileObjectUrl || assistant.file)
        ? (
          <div className={styles.fileUploaded}>
            <div className={styles.titleGroup}>
              <Typography variant="menuAndInput" component="h6">Upload Assistant file</Typography>
              <span style={{ color: '#5219F6', textDecoration: 'underline' }}>
                <label htmlFor="file_uploads" style={{ cursor: 'pointer' }}>Replace file</label>
                <input
                  type="file"
                  name="file_uploads"
                  id="file_uploads"
                  required="required"
                  hidden
                  onChange={(event) => handleFileChange(event, true)}
                />
              </span>
            </div>
            <div className={styles.imgWrapper}>
              <ErrorBoundary>
                <CanvasModel model={fileObjectUrl || assistant.file} ratio={assistant.ratio} />
              </ErrorBoundary>
            </div>
            {generateModalComponent}
            <div>
              <Button
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeFile}
              >
                Remove file
              </Button>
            </div>
          </div>
        ) : (
          <FileUploadInput
            pageTitle="Upload assistant file"
            pageFileFormatContent="Support glb, gltf & file size < 30 MB"
            callback={handleFileChange}
            isValid={!isUnknownFile}
            filename={filename}
          />
        )}
    </div>
  );
}

AssistantTabPanel.defaultProps = {
  errorMessage: '* Missing File',
};
AssistantTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  setFile: PropTypes.func.isRequired,
  assistant: PropTypes.shape({
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    ratio: PropTypes.number.isRequired,
  }),
  isShowError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default AssistantTabPanel;
