export const ITEM_INDEX = 'iindex';

export const DEFAULT_EDIT_JSON = {
  id: '',
  storeId: '',
  title: '',
  description: '',
  image: null,
  oldImage: null,
  priceList: [
    { currency: 'USDT', amount: 0 },
    { currency: 'BTC', amount: 0 },
    { currency: 'ETH', amount: 0 },
  ],
  asset: [
    {
      type: 'image',
      url: '',
      metadata: {
        ratio: 1,
        model: '',
      },
    },
  ],
  imageFile: null,
  modelFile: null,
};
