import * as React from 'react';
import { PropTypes } from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function SimpleBackdrop(props) {
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        sx={{
          color: '#9B51E0',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: 'rgba(179, 179, 179, 0)',
          '.MuiCircularProgress-root': {
            width: '100px!important',
            height: '100px!important',
          },
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

SimpleBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default SimpleBackdrop;
