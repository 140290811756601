import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  createTheme, ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MenuAppBar from 'webComponents/MenuAppBar/MenuAppBar';
import _ from 'lodash';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { getStoreList, getStoreDetail } from 'services/store/actions';
import { getProfile, getDataFromScratch } from 'services/user/actions';
import {
  initialRoute,
} from 'services/route/actions';
import { makeSelectStoreName } from 'services/store/selectors';
import { logout, setShowSnackbar } from 'services/auth/actions';
import { makeSelectHomeSnackbar } from 'services/auth/selectors';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { isValidStoreUrl, getStoreId } from 'utils/path.utils';
import ItemsOutlet from 'routes/items/ItemsOutlet';
import ThemesOutlet from 'routes/themes/ThemesOutlet';
import Themes from 'routes/themes/Themes';
import TemplatesOutlet from 'routes/templates/TemplatesOutlet';
import Templates from 'routes/templates/Templates';
import Layouts from 'routes/layouts/Layouts';
import LayoutsOutlet from 'routes/layouts/LayoutsOutlet';
import CustomLayoutContainer from 'routes/layouts/CustomLayout/CustomLayoutContainer';
import EditThemes from 'routes/themes/EditThemes/EditThemes';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import { getData, removeData } from 'utils/sessionStoarge.utils';
import WorldMusic from 'routes/world-music/world-music';
import MainListItems from '../../MainListItems';
import Store from '../../store/store';
import SetupGuide from '../components/SetupGuide';
import Items from '../../items/Items';
import EditItem from '../../items/ItemList/EditItem/EditItem';
import PlaceOnMapItem from '../../items/ItemList/PlaceOnMapItem/PlaceOnMapItem';
import Profile from '../../profile/profile';
import { customTheme } from '../../../muiUtils/customTheme/index';

import '../Home.scss';

const mdTheme = createTheme(customTheme);
const drawerWidth = 240;

function HomeContent(props) {
  const { snackbar, name } = props;
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    initialRoute();
    const showHomeSnackbar = getData('showHomeSnackbar');
    if (showHomeSnackbar) {
      removeData('showHomeSnackbar');
      props.setShowSnackbar(true);
    }
  }, []);

  const goToItemsPage = () => {
    props.setShowSnackbar(false);
    navigate(`/items${location.search}`);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MenuAppBar className="dashboard" auth userId={props.userId} logout={props.logout} name={props.name} />
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: drawerWidth, bgcolor: '#F1F1F1', boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto', height: 'inherit' }}>
            <List sx={{
              paddingTop: '0',
              height: 'inherit',
              '& .MuiListItemButton-root': {
                '& .MuiListItemIcon-root': {
                  minWidth: 36,
                  '& .MuiSvgIcon-root': {
                    marginRight: 0,
                  },
                },
                '& .MuiSvgIcon-root': {
                  marginRight: 3,
                },
              },
              '& .MuiListItemText-root': {
                color: 'black',
                '& .MuiTypography-root': {
                  fontSize: 18,
                },
              },
            }}
            >
              <MainListItems />
            </List>
          </Box>
        </Drawer>
        <Box className="mainContainer" component="main" sx={{ flexGrow: 1, backgroundColor: '#F1F1F1' }}>
          <Toolbar />
          <Routes>
            <Route index path="/home" element={<SetupGuide />} />
            <Route path="/store" element={<Store />} />
            {/* <Route path="/items/ecommerce" element={<ECommerce />} /> */}
            <Route path="/items" element={<ItemsOutlet />}>
              <Route index element={<Items />} />
              <Route path="edit" element={<EditItem />} />
              <Route path="new" element={<EditItem />} />
              <Route path="place" element={<PlaceOnMapItem />} />
            </Route>
            <Route path="/themes" element={<ThemesOutlet />}>
              <Route index element={<Themes />} />
              <Route path="/themes/edit" element={<EditThemes />} />
            </Route>
            <Route path="/templates" element={<TemplatesOutlet />}>
              <Route index element={<Templates />} />
              {/* <Route path="/themes/edit" element={<EditThemes />} /> */}
            </Route>
            <Route path="/layouts" element={<LayoutsOutlet />}>
              <Route index element={<Layouts />} />
              <Route path="/layouts/custom" element={<CustomLayoutContainer />} />
            </Route>
            <Route path="/world-music" element={<WorldMusic />} />
            <Route path="/user/profile" element={<Profile />} />
            <Route path="*" element={<SetupGuide />} />
          </Routes>
        </Box>
      </Box>
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        handleClose={() => props.setShowSnackbar(false)}
      >
        <div>
          {`Successfully created ${name}. `}
          <span
            onClick={() => goToItemsPage()}
            onKeyPress={() => {
              goToItemsPage();
            }}
            tabIndex={0}
            role="button"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Customize your items.
          </span>
        </div>
      </SimpleSnackbar>
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  snackbar: makeSelectHomeSnackbar(),
  name: makeSelectStoreName(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreList,
    getProfile,
    logout,
    getStoreDetail,
    getDataFromScratch,
    initialRoute,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

HomeContent.propTypes = {
  logout: PropTypes.func,
  userId: PropTypes.string,
  name: PropTypes.string,
  setShowSnackbar: PropTypes.func,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
};

export default compose(
  withConnect,
)(HomeContent);
