/* eslint-disable react/no-danger */
import React from 'react';
import { PropTypes } from 'prop-types';

function Template({ children, ...attrs }) {
  return (
    <template {...attrs} dangerouslySetInnerHTML={{ __html: children }} />
  );
}

Template.propTypes = {
  children: PropTypes.node,
};

export default Template;
