import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import { PropTypes } from 'prop-types';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  ...theme,
  minHeight: '470px',
  '& .MuiTabs-indicator': {
    left: '0px',
    background: '#D9D9D9',
    width: '4px',
  },
  // '&. MuiTab-scroller': {
  //   overflow: 'visible',
  // },
  '& .MuiTab-root': {
    ...theme.typography.body1,
    justifyContent: 'flex-start',
    minHeight: '0',
    height: '64px',
    textTransform: 'capitalize',
    padding: '9px 28px',
    flexDirection: 'row',
    textAlign: 'left',
    // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
    // overflow: 'visible',
    ':hover': {
      background: '#F8F8F8',
    },
    // ':not(:last-child)': {
    //   borderBottom: '1px solid #DEDEDE',
    // },
    borderBottom: '1px solid #DEDEDE',
  },
  '& .Mui-selected': {
    background: '#F8F8F8',
    borderRight: 'transparent',
    outline: '2px',
    color: theme.palette.primary.main,
    // overflow: 'visible',
    // '&:after': {
    //   content: '" "',
    //   paddingRight: '1px',
    //   right: '-1px',
    //   background: '#F8F8F8',
    //   top: 0,
    //   bottom: 0,
    // },
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  background: '#F1F1F1',
  // border: '1px solid #ECECEC',
  // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',

}));

export default function CustomTabs({
  className,
  value,
  onChange,
  children,
}) {
  return (
    <StyledTabs
      className={className}
      value={value}
      onChange={onChange}
      aria-label="theme tabs"
      orientation="vertical"
    >
      {children.map((c) => c)}
    </StyledTabs>
  );
}

CustomTabs.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};
