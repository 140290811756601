/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  UPDATE_DEFAULT_MESSAGE_REQUEST,
  UPDATE_WEBHOOK,
  INCREASE_REQUEST_NUMBER,
  DECREASE_REQUEST_NUMBER,
  UPDATE_TEST_MODE,
} from './constants';

export const initialState = {
  webhook: '',
  message: {},
  loading: false,
  requestNumber: 0,
  isTestMode: false,
  defaultMessageRequest: {
    message: {
      message_id: '',
      from: {
        id: '',
        username: '',
        language_code: 'en',
      },
      date: new Date().getTime(),
    },
  },
};

const updater = (update, data) => {
  const updaterKeys = Object.keys(update.message);
  updaterKeys.forEach((x) => {
    data.message[x] = update.message[x];
  });
};

const messageReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SEND_MESSAGE:
      draft.loading = true;
      draft.requestNumber += 1;
      break;
    case SEND_MESSAGE_SUCCESS:
      draft.loading = false;
      draft.message = action.data;
      break;
    case SEND_MESSAGE_FAIL:
      draft.loading = false;
      break;
    case UPDATE_DEFAULT_MESSAGE_REQUEST:
      updater(action.data, draft.defaultMessageRequest);
      break;
    case UPDATE_WEBHOOK:
      draft.webhook = action.data;
      break;
    case INCREASE_REQUEST_NUMBER:
      draft.requestNumber += 1;
      break;
    case DECREASE_REQUEST_NUMBER:
      draft.requestNumber -= 1;
      break;
    case UPDATE_TEST_MODE:
      draft.isTestMode = action.data;
      break;
    default:
  }
});

export default messageReducer;
