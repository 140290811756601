import React, {
  useState,
} from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import floating from 'assets/Items/art/floating.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { SNACKBAR_TYPE } from 'services/item/constants';

import _ from 'lodash';
import { makeSelectItemList, makeSelectItemSnackbar } from 'services/item/selectors';
import { makeSelectStoreId, makeSelectStoreName } from 'services/store/selectors';
import { getAllItems, setShowSnackbar } from 'services/item/actions';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import WhiteNormalButton from 'webComponents/NormalButton/WhiteNormalButton';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';

import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Detail from './ItemList/Detail/Detail';
import { ITEM_INDEX } from './items.constants';

function Items(props) {
  const { itemList, snackbar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const handleOpen = (index) => {
    setCurrentItemIndex(index);
    navigate(`edit${location.search}&${ITEM_INDEX}=${index}`);
  };

  const addItem = () => navigate(`new${location.search}&new=true`);
  const placeItem = () => {
    props.setShowSnackbar(false);
    navigate(`place${location.search}`);
  };

  const isNoItemList = () => _.isEmpty(props.itemList);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Items">
        {!isNoItemList()
          && (
            <Grid container columnSpacing={1} sx={{ order: { xs: 1, sm: 2 } }}>
              <Grid>
                <WhiteNormalButton
                  text="Place Item"
                  callback={() => placeItem()}
                  size="large"
                />
              </Grid>
              <Grid sx={{ marginLeft: '10px' }}>
                <NormalButton size="large" text="Add Item" callback={() => addItem()} />
              </Grid>
            </Grid>
          )}
      </GeneralTitle>
      {isNoItemList()
        ? (
          <GeneralContent display="flex">
            <Box
              className="isNoItemList"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '1054px',
                width: 'calc(100% - 80px)',
                margin: '0 auto',
              }}
            >
              <Box
                className="leftContent"
                sx={{
                  maxWidth: '392px',
                  width: '37%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Typography sx={{ fontWeight: 700, fontSize: '34px', marginBottom: '20px' }}>
                    Add items to your world
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: '20px', marginBottom: '50px' }}>
                    Get closer to a full Metaverse world by adding products or content.
                  </Typography>
                  <NormalButton
                    text="Add Item"
                    size="large"
                    sx={{
                      fontWeight: 700,
                      marginTop: 2,
                      height: '56px',
                    }}
                    callback={() => addItem()}
                  />
                </div>
              </Box>
              <Box
                className="img right"
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '564px',
                  width: 'calc(63% - 40px)',
                }}
              >
                <Box
                  component="img"
                  src={floating}
                  sx={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </Box>
            </Box>
          </GeneralContent>
        )
        : (
          <GeneralContent>
            <Grid container spacing={3}>
              <LazyLoadComponent>
                {itemList && _.filter(itemList, 'isActive').map((item, index) => (
                  <Grid sm={12} md={6} lg={4} key={item._id}>
                    <Detail
                      handleOpen={handleOpen}
                      title={item.title}
                      description={item.description}
                      priceList={item.priceList}
                      asset={item.asset}
                      index={index}
                    />
                  </Grid>
                ))}
              </LazyLoadComponent>
            </Grid>
          </GeneralContent>
        )}
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        anchorOrigin={snackbar.type === SNACKBAR_TYPE.PLACE ? {
          vertical: 'top',
          horizontal: 'center',
        } : {
          vertical: 'bottom',
          horizontal: 'left',
        }}
        handleClose={() => props.setShowSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  storeId: makeSelectStoreId(),
  name: makeSelectStoreName(),
  itemList: makeSelectItemList(),
  snackbar: makeSelectItemSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllItems,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Items.propTypes = {
  setShowSnackbar: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  itemList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    priceList: PropTypes.arrayOf(PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    })),
    isActive: PropTypes.bool,
  })),
};

export default compose(
  withConnect,
)(Items);
