import React from 'react';
import Button from '@mui/material/Button';
import { PropTypes } from 'prop-types';

export function PublicStoreButton(props) {
  const { onClick, children, ...other } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      type="button"
      onClick={onClick}
      sx={{
        borderRadius: '15px',
        width: '75px',
        padding: '10px 10px 10px 10px',
        color: 'black',
        backgroundColor: 'white',
        opacity: '0.6',
        border: '2px solid #e7e7e7',
        '&:hover': {
          opacity: '0.7',
          backgroundColor: 'white',
        },
      }}
      {...other}
    >
      {children}
    </Button>
  );
}

PublicStoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
