import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  createTheme, ThemeProvider, StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MenuAppBar from 'webComponents/MenuAppBar/MenuAppBar';
import _ from 'lodash';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { getStoreList, getStoreDetail } from 'services/store/actions';
import { getProfile, getDataFromScratch } from 'services/user/actions';
import {
  initialRoute,
  setShowSnackbar,
} from 'services/route/actions';
import { makeSelectStoreIsReady, makeSelectStoreName, makeSelectStoreError } from 'services/store/selectors';
import { makeSelectUserId } from 'services/user/selectors';
import { logout } from 'services/auth/actions';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import { isValidStoreUrl, getStoreId } from 'utils/path.utils';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import {
  makeSelectRouteSnackbar,
} from 'services/route/selectors';
import { getAllItems } from 'services/item/actions';
import MainListItems from '../MainListItems';
import Store from '../store/store';
import SetupGuide from './components/SetupGuide';
import Items from '../items/Items';
import EditItem from '../items/ItemList/EditItem/EditItem';
import PlaceOnMapItem from '../items/ItemList/PlaceOnMapItem/PlaceOnMapItem';
import Profile from '../profile/profile';
import { customTheme } from '../../muiUtils/customTheme/index';
import HomeContent from './HomeContent/HomeContent';
import './Home.scss';

const mdTheme = createTheme(customTheme);
const drawerWidth = 240;

function Home(props) {
  const { isReady, snackbar } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuItemClick = (storeId) => {
    navigate(`/home?sid=${storeId}`);
  };

  useEffect(() => {
    const { state } = location;
    // if (_.has(state, 'isLogin') && state.isLogin) {
    //   props.getStoreList(props.userId, null, onMenuItemClick); // navigate to sid={storeId}
    // } else
    if (isValidStoreUrl(location)) {
      const storeId = getStoreId(location.search);
      props.getProfile(() => {}, storeId);
      props.getAllItems({ storeId });
    } else { // / or /home
      props.getDataFromScratch(onMenuItemClick);
    }
    props.initialRoute(navigate);
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      {
        isReady
          ? <HomeContent userId={props.userId} logout={props.logout} name={props.name} getStoreDetail={props.getStoreDetail} />
          : <SimpleBackdrop open />
      }
      <SimpleSnackbar
        severity="warning"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowSnackbar(false)}
      />
    </StyledEngineProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  isReady: makeSelectStoreIsReady(),
  userId: makeSelectUserId(),
  name: makeSelectStoreName(),
  error: makeSelectStoreError(),
  snackbar: makeSelectRouteSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreList,
    getProfile,
    logout,
    getStoreDetail,
    getDataFromScratch,
    initialRoute,
    setShowSnackbar,
    getAllItems,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Home.propTypes = {
  getProfile: PropTypes.func.isRequired,
  getDataFromScratch: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  getStoreDetail: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  getStoreList: PropTypes.func.isRequired,
  getAllItems: PropTypes.func.isRequired,
  userId: PropTypes.string,
  name: PropTypes.string,
  isReady: PropTypes.bool,
  initialRoute: PropTypes.func,
  setShowSnackbar: PropTypes.func,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
  // error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export default compose(
  withConnect,
)(Home);
