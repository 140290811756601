import {
  UPDATE_WORLD_ADMIN,
  UPDATE_WORLD_ADMIN_SUCCESS,
  UPDATE_WORLD_ADMIN_FAIL,
} from './constants';

export const updateWorldAdmin = (data) => ({
  type: UPDATE_WORLD_ADMIN,
  data,
});

export const updateWorldAdminFail = (error) => ({
  type: UPDATE_WORLD_ADMIN_FAIL,
  error,
});

export const updateWorldAdminSuccess = (data) => ({
  type: UPDATE_WORLD_ADMIN_SUCCESS,
  data,
});
