import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import OutlinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import MenuAppBarpBar from 'webComponents/MenuAppBar/MenuAppBar';
import { login, getProfile } from 'services/user/actions';
import { makeSelectUserError } from 'services/user/selectors';
import { makeSelectStoreName, makeSelectSelectedStoreTheme, makeSelectStoreLoading } from 'services/store/selectors';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import { isErrorOccurredIn } from 'utils/error';
import { useNavigate } from 'react-router-dom';
import { customTheme } from '../../muiUtils/customTheme/index';

import doubleLogoImg from '../../assets/Onboarding/logo/double_logo.png';

import './login.scss';

const theme = createTheme(customTheme);

function Login(props) {
  const {
    name, error, loading,
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const navigateToOnboarding = () => {
    navigate('/onboarding');
  };

  const navigateToDashboard = (userId) => {
    navigate(`/home?sid=${userId}`, {
      state: { isLogin: true },
    });
  };

  const onClickSumbit = () => {
    props.login({
      email,
      password,
    }, navigateToDashboard);
  };

  useEffect(() => {
    // add
    const token = localStorage.getItem('double-dashboard/auth');
    if (token) {
      try {
        const t = JSON.parse(token);
        if (t.access_token) props.getProfile(navigateToDashboard);
      } catch (err) {
        //
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="page-onboarding-login">
        <MenuAppBarpBar className="page-storeName__appBar" auth={false} />
        <div className="page-onboarding-login__container">
          <div className="page-onboarding-login__container__left"></div>
          <div className="page-onboarding-login__container__right">

            <div className="page-onboarding-login__container__right__wrapper">
              <img className="page-onboarding-login__container__right__wrapper__logo logo" src={doubleLogoImg} alt="" />
              <Typography
                className="page-onboarding-login__container__right__wrapper__header header"
                variant="h5"
              >
                Login
              </Typography>
              <OutlinedTextInput
                className="page-onboarding-login__container__right__wrapper__email input"
                error={isErrorOccurredIn('email')}
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter email"
                fullWidth
                name="email"
                onChange={handleEmailChange}
              />
              <Typography
                className="page-onboarding-login__container__right__wrapper__email error"
                variant="caption"
                display="block"
                gutterBottom
              >
                <ErrorComponet error={error} objectKey="email" />
              </Typography>
              <OutlinedTextInput
                className="page-onboarding-login__container__right__wrapper__password input"
                error={isErrorOccurredIn('password')}
                variant="outlined"
                type="password"
                name="password"
                placeholder="Enter password"
                fullWidth
                onChange={handlePasswordChange}
              />
              <Typography
                className="page-onboarding-login__container__right__wrapper__email error"
                variant="caption"
                display="block"
                gutterBottom
              >
                <ErrorComponet error={error} objectKey="password" />
                <ErrorComponet error={error} objectKey="" />
              </Typography>
              <LoadingButton
                className="page-onboarding-login__container__right__wrapper__button"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => onClickSumbit()}
                loading={loading}
                sx={{
                  height: '56px',
                }}
              >
                Login
              </LoadingButton>
              <LoadingButton
                className="page-onboarding-login__container__right__wrapper__button"
                variant="outlined"
                fullWidth
                onClick={navigateToOnboarding}
                loading={loading}
                sx={{
                  height: '56px',
                }}
                size="large"
              >
                Try it for free
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectStoreName(),
  error: makeSelectUserError(),
  loading: makeSelectStoreLoading(),
  theme: makeSelectSelectedStoreTheme(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login,
    getProfile,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Login.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  login: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(Login);
