export const typography = {
  // Heading
  h1: {
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '40px',
    color: '#404040',
  },
  // Sub Heading
  h2: {
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '35px',
    color: '#404040',
  },

  h3: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '160%', /* or 32px */
    letterSpacing: '0.15px',
    color: '#272626',
  },

  h5: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '133.4%',
    /* identical to box height, or 32px */
    color: '#272626',
  },

  // // Body Copy
  bodyCopy1: {
    fontWeight: '400',
    fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
    fontSize: '26px',
    lineHeight: '30px',
    color: '#404040',
  },
  // Button
  button: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '26px',
  },
  // Input Label
  menuAndInput: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#272626',
  },
  inputText: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  clickable: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
  },
  // created by jeffrey, not in figma
  caption1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  toolTip: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
  },
};
