import React from 'react';
import Template from './Template';

// Camera Rig / Player
export function MultiplayerRigTemplate() {
  return <Template id="rig-template">{'<a-entity></a-entity>'}</Template>;
}

// a few spheres make a head + eyes + pupils
export function MultiplayerAvatarTemplate() {
  const head = (
    <Template id="avatar-template" key="avatar-template">
      {`<a-entity id="head" class="avatar">
          <a-gltf-model class="head" rotation="0 -180 0" position="0 -1 0" gltf-model="#defaultAvatarHead"></a-gltf-model>
        </a-entity>`}
    </Template>
  );

  const body = (
    <Template id="body-template" key="body-template">
      {`<a-entity class="body">
      <a-gltf-model position="0 -1 0" gltf-model="#defaultAvatarBody" animation-mixer="clip: *"></a-gltf-model>
    </a-entity>`}
    </Template>
  );

  const leftHand = (
    <Template id="left-hand-template" key="left-hand-template">
      {`<a-entity class="left-controller">
      <a-gltf-model position="0 -1 0" gltf-model="#defaultAvatarLeftHand" animation-mixer="clip: *"></a-gltf-model>
      </a-entity>`}
    </Template>
  );

  const rightHand = (
    <Template id="right-hand-template" key="right-hand-template">
      {`<a-entity class="right-controller">
      <a-gltf-model position="0 -1 0" gltf-model="#defaultAvatarRightHand" animation-mixer="clip: *"></a-gltf-model>
      </a-entity>`}
    </Template>
  );

  return [head, body, leftHand, rightHand];
}

// a few spheres make a head + eyes + pupils
export function MultiplayerAvatarTemplateOrigin() {
  return (
    <Template id="avatar-template">
      {`<a-entity class="avatar">
      {/* <!-- notice this child sphere, with class .head, has the random-color component; this modifies the material component's color property --> */}
      <a-sphere
        class="head"
        scale="0.2 0.22 0.2"
        color="red"
      ></a-sphere>
      <a-entity class="face" position="0 0.05 0">
        <a-sphere
          class="eye"
          color="white"
          position="0.06 0.05 -0.16"
          scale="0.04 0.04 0.04"
        >
          <a-sphere
            class="pupil"
            color="black"
            position="0 0 -1"
            scale="0.2 0.2 0.2"
          ></a-sphere>
        </a-sphere>
        <a-sphere
          class="eye"
          color="white"
          position="-0.06 0.05 -0.16"
          scale="0.04 0.04 0.04"
        >
          <a-sphere
            class="pupil"
            color="black"
            position="0 0 -1"
            scale="0.2 0.2 0.2"
          ></a-sphere>
        </a-sphere>
      </a-entity>
    </a-entity>
    `}
    </Template>
  );
}
