import React, { useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import WhiteNormalButton from 'webComponents/NormalButton/WhiteNormalButton';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import {
  updateStoreTheme,
} from 'services/store/actions';
import _ from 'lodash';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import styles from '../../Themes.module.scss';

function ThemeDetailModal(props) {
  const {
    open, handleClose, themeList, activeThemeId, isCurrentTheme, storeId, goToEditTheme,
  } = props;

  const { environment } = themeList[activeThemeId];

  const isModel = () => {
    if (!_.isArray(environment)) return false;
    if (environment[0].type === 'image') return false;
    return true;
  };

  return (
    <Modal
      className="ThemeModal"
      open={open}
      onClose={handleClose}
      aria-labelledby="theme-detail-modal-title"
      aria-describedby="theme-detail-modal-description"
    >
      <Box className={styles.modalBody}>
        <div className={styles.modalBoxWrapper}>
          <IconButton
            className={styles.modalCloseButton}
            aria-label="close"
            sx={{ alignSelf: 'flex-end' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.modalContentWrapper}>
            {
              (isModel() && themeList[activeThemeId].thumbnail === null)
                ? (
                  <div
                    className={styles.modalImage}
                    style={{
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: '10px',
                      width: 203,
                      height: 204,
                      backgroundColor: '#fde2e4',
                    }}
                  >
                    <ErrorBoundary>
                      <CanvasModel model={`${environment[0].url}?q=1`} ratio={environment[0].metadata.ratio} />
                    </ErrorBoundary>
                  </div>
                )
                : (
                  <img
                    className={styles.modalImage}
                    style={{
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      borderRadius: '10px',
                      width: 203,
                      height: 204,
                      backgroundColor: 'white',
                    }}
                    src={themeList[activeThemeId].thumbnail || (_.isString(environment) ? environment : environment[0].url)}
                    crossOrigin="anonymous"
                  />
                )
            }
            <div className={styles.modalText}>
              <Typography variant="h6" component="h6">
                {themeList[activeThemeId].name}
              </Typography>
              <Typography
                id="theme-modal-title"
                variant="body1"
                component="p"
                sx={{
                  marginTop: 1,
                  overflow: 'scroll',
                  display: '-webkit-box',
                  height: '150px',
                  // WebkitLineClamp: '5',
                }}
              >
                {themeList[activeThemeId].description}
              </Typography>
            </div>
          </div>
          <Grid container>
            <Grid xs={6}>
              <Typography variant="h6" component="h6" />
            </Grid>
            { isCurrentTheme(themeList[activeThemeId]) && <Grid xs={3} sx={{ pr: 1 }} />}
            <Grid xs={3} sx={isCurrentTheme(themeList[activeThemeId]) ? { pl: 1 } : { pr: 1 }}>
              <WhiteNormalButton
                fullWidth
                text={themeList[activeThemeId].isDefault ? 'view theme' : 'edit theme'}
                callback={() => {
                  goToEditTheme({ isEdit: true, themeId: themeList[activeThemeId]._id });
                }}
              />
            </Grid>
            { !isCurrentTheme(themeList[activeThemeId])
              && (
              <Grid xs={3} sx={{ pl: 1 }}>
                <NormalButton
                  fullWidth
                  text="use theme"
                  callback={() => {
                    props.updateStoreTheme({
                      storeId,
                      themeId: themeList[activeThemeId]._id,
                    });
                    handleClose();
                  }}
                />
              </Grid>
              )}
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreTheme,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ThemeDetailModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isCurrentTheme: PropTypes.func,
  goToEditTheme: PropTypes.func,
  activeThemeId: PropTypes.number,
  storeId: PropTypes.string,
  themeList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  updateStoreTheme: PropTypes.func,
};

export default compose(
  withConnect,
)(ThemeDetailModal);
