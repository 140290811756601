import { inputLabel } from './inputLabel';
import { outlinedInput } from './outLinedInput';
import { button } from './button';
import { listItem } from './listItem';
import { listItemText } from './listItemText';

export const components = {
  // ...inputLabel,
  // ...outlinedInput,
  ...button,
  ...listItemText,
  ...listItem,
};
