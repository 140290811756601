/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import React, { useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getTemplateDetail } from 'services/template/actions';
import { makeSelectTemplateCurrent, makeSelectTemplateError, makeSelectTemplateLoading } from 'services/template/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Alert from '@mui/material/Alert';

import TWEEN from '@tweenjs/tween.js';

import InstructionLegend from 'webComponents/InstructionLegend/InstructionLegend';
import CustomEntity from 'webComponents/CustomEntity/CustomEntity';
import infoPanel from 'assets/component/3b4174ec8224442b47b3418553b75556.jpeg';

import Robot from 'assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from 'assets/bitcoin_icon.png';
import ethIcon from 'assets/eth_icon.png';
import KeymakerChatBox from 'webComponents/keymaker/KeymakerChatBox';
import { generateCustomItemList } from 'utils/item.utils';
// import './App.css';
import { VRButton, EnvironmentComponent, FloorComponent, WallComponent } from './components/PublisStoreComponents';

import 'components/map';
import 'components/rounded';
import 'components/info-panel';

import 'components/chatbot/tour-guide';
import 'components/chatbot/chatbox-info-panel';
import 'components/chatbot/chatbox-look-at';
import 'components/chatbot/chatbox';
import 'components/popup-panel';

require('aframe');
require('aframe-extras');

const isWebxr = process.env.NODE_ENV !== 'production';

function PublicStore(props) {
  const { current, error, loading } = props;
  const templateId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  window.TWEEN = TWEEN;

  const isIgnoreAsset = (asset) => typeof asset === 'undefined' || _.isEmpty(asset) || _.isNull(asset);

  useEffect(() => {
    try {
      props.getTemplateDetail(templateId);
    } catch (err) {
      console.error(err);
    }
  }, []);

  if (!templateId || error) {
    return (<Alert severity="error"> Template Id error </Alert>);
  }

  return (
    (loading || _.isEmpty(current)) ? <SimpleBackdrop open={loading} />
      : (
        <div className="App">
          <header className="App-header">
            <VRButton />
            <InstructionLegend name={current.name} />
            {isWebxr && <KeymakerChatBox name={current.name} />}
            <a-scene
              light="defaultLightsEnabled: false"
              loading-screen="dotsColor: blue; backgroundColor: black"
              environment="preset: studio"
              cursor="rayOrigin: mouse"
            >
              <a-assets>
                <img id="pexels_anni" src={current.theme[0].floor} crossOrigin="anonymous" />
                <img id="background_360_sky" src={current.theme[0].environment} crossOrigin="anonymous" />
                <img id="info_panel_1" src={infoPanel} crossOrigin="anonymous" />
                <img id="ethIcon" src={ethIcon} crossOrigin="anonymous" />
                <img id="bitcoinIcon" src={bitcoinIcon} crossOrigin="anonymous" />
                {current.theme[0].wall && <img id="wall" src={current.theme[0].wall} crossOrigin="anonymous" />}

              </a-assets>
              {EnvironmentComponent(current.theme[0].environment)}
              {FloorComponent(current.theme[0].floor)}
              {WallComponent(current.theme[0].wall, current.map)}

              {generateCustomItemList(current).map((item) => (
                <CustomEntity
                  key={item._id || item.id}
                  item={item}
                />
              ))}

              {current.logo && 
                <a-entity
                  id="shop_logo"
                  position="-0.5 2.5 -4.25"
                  geometry="primitive: plane; height: 2; width: 3;"
                  material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
                  rotation="0 0 0"
                />
              }

              {/* Player */}
              <a-entity
                id="rig"
                movement-controls="constrainToNavMesh: true; speed: 0.3; fly: false"
                position="0 0 0"
                rotation="0 0 0"
              >
                {/* position="8 0 11" */}
                <a-entity
                  id="player"
                  camera="userHeight: 1.6"
                  look-controls="touchEnabled: true"
                  position="0 1.6 0"
                >
                  {/* <a-entity raycaster="showLine: true; far: 100; lineColor: red; lineOpacity: 0.5"></a-entity> */}
                </a-entity>
                <a-entity laser-controls="hand: left" />
                <a-entity laser-controls="hand: right" />
              </a-entity>

              {!isIgnoreAsset(current.theme[0].assistant)
            && (
            <a-entity
              id="tour-guide-bot"
              position="-1.5 0.1 9"
              gltf-model={`${current.theme[0].assistant ? current.theme[0].assistant[0].url : '#tour-guide-bot-glb'}`}
              scale={`${current.theme[0].assistant ? `${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio}` : '1 1 1'}`}
              animation-mixer="clip:Wave"
              chatbox=""
              // chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot.  \n\nLet’s go!; scale: ${current.theme[0].assistant ? current.theme[0].assistant[0].ratio : 1}`}
              tour-guide="#rig"
            />
            )}
            </a-scene>
          </header>
        </div>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectTemplateCurrent(),
  error: makeSelectTemplateError(),
  loading: makeSelectTemplateLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getTemplateDetail,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

PublicStore.propTypes = {
  map: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
    }), PropTypes.number]),
  ),
  loading: PropTypes.bool,
  current: PropTypes.shape({
    name: PropTypes.string,
    theme: PropTypes.arrayOf(PropTypes.shape({
      assistant: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        ratio: PropTypes.number,
      })),
      map: PropTypes.arrayOf(PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        data: PropTypes.arrayOf(PropTypes.number),
      })),
      environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
      floor: PropTypes.string,
      wall: PropTypes.string,
    })),
    items: PropTypes.arrayOf(PropTypes.shape({
    })),
    logo: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  getStoreByPublicIdWithItems: PropTypes.func,
};

export default compose(
  withConnect,
)(PublicStore);
