/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */

require('aframe');
require('aframe-extras');
require('super-hands');

import React, { useEffect, useState, useCallback } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getStoreByPublicIdWithItems } from 'services/store/actions';
import { makeSelectStorePublicStoreDetail, makeSelectStoreError, makeSelectStoreLoading } from 'services/store/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Alert from '@mui/material/Alert';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';

import TWEEN from '@tweenjs/tween.js';

import EditInstructionLegend from 'webComponents/InstructionLegend/EditInstructionLegend';
import CustomEntity from 'webComponents/CustomEntity/CustomEntity-test';
import infoPanel from 'assets/component/3b4174ec8224442b47b3418553b75556.jpeg';
import KeymakerChatBox from 'webComponents/keymaker/KeymakerChatBox';
// import Robot from './assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from 'assets/bitcoin_icon.png';
import ethIcon from 'assets/eth_icon.png';
import { isIgnoreAsset, isIgnoreBackgroundMusic } from 'utils/asset.utils';
import MapPositionLegend from 'webComponents/MapPositionLegend/MapPositionLegend';
import EditItemLegendWithGUI from 'webComponents/MapPositionLegend/EditItemLegendWithGUI';
import { generateCustomItemList } from 'utils/item.utils';
import { VRButton, EnvironmentComponent, FloorComponent, WallComponent } from './components/PublisStoreComponents';
import { setBackgroundMusic } from 'utils/public-store.utils';

// import './App.css';

import 'components/info-panel';
import 'components/rounded';
import 'components/keymaker-panel';

import 'components/chatbot/tour-guide';
import 'components/chatbot/chatbox-info-panel';
import 'components/chatbot/chatbox-look-at';
import 'components/chatbot/chatbox';
import 'components/popup-panel';
import 'components/map-test';
import 'components/cursor-listener.js';
import 'components/bundle.js';
import 'components/dragdropposition';

const isWebxr = process.env.NODE_ENV !== 'production';

function PublicStoreWithEdit(props) {
  const { current, error, loading } = props;
  const [isShowKeymakerChatBox, setIsShowKeymakerChatBox] = useState(false);
  const [editingItems, setEditingItems] = useState([]);
  const worldPublicId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  window.TWEEN = TWEEN;
  const [isFly, setIsFly] = useState(false);

  useEffect(() => {
    try {
      props.getStoreByPublicIdWithItems(worldPublicId, true);
    } catch (err) {
      console.error(err);
    }
  }, []);

  if (error) {
    return (<Alert severity="error"> {error} </Alert>);
  }
  if (!worldPublicId) {
    return (<Alert severity="error"> World Public Id error </Alert>);
  }
  
  return (
    (loading || _.isEmpty(current)) ? <SimpleBackdrop open={loading} />
      : (
        <div className="App">
            <VRButton />
            {/* <MapPositionLegend map={current.map[0]} isFly={isFly} setIsFly={setIsFly} /> */}
            <EditInstructionLegend name={`${current.name} (Edit Mode)`} isShowKeymakerChatBox={!isShowKeymakerChatBox} setIsShowKeymakerChatBox={setIsShowKeymakerChatBox} isFly={isFly} setIsFly={setIsFly} editingItems={editingItems} setEditingItems={setEditingItems} current={current} />
            {current && <EditItemLegendWithGUI map={current.map[0]} worldPublicId={worldPublicId} current={current} editingItems={editingItems} setEditingItems={setEditingItems} />}
            {/* <EditItemLegend map={current.map[0]} worldPublicId={worldPublicId} current={current} editingItems={editingItems} setEditingItems={setEditingItems} /> */}
            {isWebxr && <KeymakerChatBox name={current.name} />}
            <a-scene
              light="defaultLightsEnabled: true"
              loading-screen="dotsColor: blue; backgroundColor: black"
              physics="debug: true;"
              environment="preset: studio"
              cursor="rayOrigin: mouse"
              touch-to-click-converter
              stats
              sound={setBackgroundMusic(current)}
              // style={{display: 'none'}}
            >
              <a-assets>
                <img id="pexels_anni" src={current.theme[0].floor} crossOrigin="anonymous" />
                <img id="background_360_sky" src={current.theme[0].environment} crossOrigin="anonymous" />
                <img id="info_panel_1" src={infoPanel} crossOrigin="anonymous" />
                <img id="ethIcon" src={ethIcon} crossOrigin="anonymous" />
                <img id="bitcoinIcon" src={bitcoinIcon} crossOrigin="anonymous" />
                {current.theme[0].wall && <img id="wall" src={current.theme[0].wall} crossOrigin="anonymous" />}
                {!isIgnoreBackgroundMusic(current.backgroundMusic) && 
                  <audio id="backgroundMusicAudio" src={current.backgroundMusic[0].url} preload="auto" crossOrigin="anonymous"></audio>
                }
              </a-assets>
              <a-mixin id="cube" geometry="primitive: box; width: 0.5; height: 0.5; depth: 0.5"
                 hoverable="" grabbable="" stretchable="" draggable="" droppable=""
                 shadow
                 event-set__dragdrop="_event: drag-drop; geometry.primitive: sphere; geometry.radius: 0.25"
                 event-set__hoveron="_event: hover-start; material.opacity: 0.7; transparent: true"
                 event-set__hoveroff="_event: hover-end; material.opacity: 1; transparent: false"
                 event-set__dragon="_event: dragover-start; material.wireframe: true"
                 event-set__dragoff="_event: dragover-end; material.wireframe: false"
                 >
              </a-mixin>
              {EnvironmentComponent(current.theme[0].environment)}
              {FloorComponent(current.theme[0].floor)}
              {WallComponent(current.theme[0].wall, current.map)}


              {generateCustomItemList(current).map((item) => (
                <CustomEntity
                  key={item._id || item.id}
                  item={item}
                />
              ))}

              <a-entity
                id="shop_logo"
                position="-0.5 2.5 -4.25"
                geometry="primitive: plane; height: 2; width: 3;"
                material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
                rotation="0 0 0"
              />

              {/* Player */}
              <a-entity
                id="rig"
                movement-controls={`constrainToNavMesh: true; speed: 0.3; fly: ${isFly}`}
                position="0 0 0"
                rotation="0 0 0"
                look-controls
                wasd-controls
              >
                  <a-entity
                    id="player"
                    camera="userHeight: 1.6"
                    look-controls="pointerLockEnabled: false"
                    position="0 1.6 0"
                    capture-mouse=""
                    cursor-listener
                    raycaster="objects: .cube" cursor="rayOrigin:mouse"
                    static-body="shape: sphere; sphereRadius: 0.001"
                    super-hands="colliderEvent: raycaster-intersection;
                                  colliderEventProperty: els;
                                  colliderEndEvent:raycaster-intersection-cleared;
                                  colliderEndEventProperty: clearedEls;"
                  ></a-entity>
                <a-entity laser-controls="hand: left" />
                <a-entity laser-controls="hand: right" />
              </a-entity>
              <a-entity static-body="shape: sphere; sphereRadius: 0.02;" hand-controls="hand: left" sphere-collider="objects: [dynamic-body];" grab></a-entity>
              <a-entity static-body="shape: sphere; sphereRadius: 0.02;" hand-controls="hand: right" sphere-collider="objects: [dynamic-body];" grab></a-entity>

            {!isIgnoreAsset(current.theme[0].assistant)
            && (
            <a-entity
              id="tour-guide-bot"
              position="-1.5 0.1 9"
              gltf-model={`${current.theme[0].assistant ? current.theme[0].assistant[0].url : '#tour-guide-bot-glb'}`}
              scale={`${current.theme[0].assistant ? `${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio}` : '1 1 1'}`}
              animation-mixer="clip:Wave"
              chatbox=""
              // chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot.  \n\nLet’s go!; scale: ${current.theme[0].assistant ? current.theme[0].assistant[0].ratio : 1}`}
              tour-guide="#rig"
            />
            )}
            </a-scene>
        </div>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectStorePublicStoreDetail(),
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreByPublicIdWithItems,
    setIsShowKeymakerChat,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

PublicStoreWithEdit.propTypes = {
  map: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
    }), PropTypes.number]),
  ),
  loading: PropTypes.bool,
  current: PropTypes.shape({
    name: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.number),
    })),
    theme: PropTypes.arrayOf(PropTypes.shape({
      assistant: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        ratio: PropTypes.number,
      })),
      environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
      floor: PropTypes.string,
      wall: PropTypes.string,
    })),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  setIsShowKeymakerChat: PropTypes.func,
  getStoreByPublicIdWithItems: PropTypes.func,
};

export default compose(
  withConnect,
)(PublicStoreWithEdit);
