/* eslint-disable camelcase */
import {
  takeLatest, call, put, all, select, take, putResolve,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getTemplateListSuccess,
  getTemplateListFail,
  getTemplateDetailSuccess,
  getTemplateDetailFail,
} from './actions';
import { GET_TEMPLATE_LIST, GET_TEMPLATE_DETAIL } from './constants';

const api = API.create();

export function* getTemplateList() {
  const result = yield call(api.template.getAll);
  if (result.ok) {
    yield put(getTemplateListSuccess(result.data.data.items));
  } else {
    yield put(getTemplateListFail(result.data.error));
  }
}

export function* getTemplateDetail(data) {
  const { id } = data;
  const result = yield call(api.template.getOne, { id });
  if (result.ok) {
    yield put(getTemplateDetailSuccess(result.data.data.items[0]));
  } else {
    yield put(getTemplateDetailFail(result.data.error));
  }
}

export default function* templateSaga() {
  yield all([
    takeLatest(GET_TEMPLATE_LIST, getTemplateList),
    takeLatest(GET_TEMPLATE_DETAIL, getTemplateDetail),
  ]);
}
