import {
  GET_CHANNEL_INFO,
  GET_CHANNEL_INFO_SUCCESS,
  GET_CHANNEL_INFO_FAIL,
} from './constants';

export const getChannelInfo = (data, callback) => ({
  type: GET_CHANNEL_INFO,
  data,
  callback,
});

export const getChannelInfoSuccess = (data) => ({
  type: GET_CHANNEL_INFO_SUCCESS,
  data,
});

export const getChannelInfoFail = (error) => ({
  type: GET_CHANNEL_INFO_FAIL,
  error,
});
