/* eslint-disable default-param-last */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  GET_STORE_BY_NAME,
  GET_STORE_BY_NAME_SUCCESS,
  GET_STORE_BY_NAME_FAIL,
  GET_STORE_BY_NAME_WITH_ITEMS,
  GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS,
  GET_STORE_BY_NAME_WITH_ITEMS_FAIL,
  GET_STORE_DETAIL,
  GET_STORE_DETAIL_SUCCESS,
  GET_STORE_DETAIL_FAIL,
  GET_STORE_LIST,
  GET_STORE_LIST_SUCCESS,
  GET_STORE_LIST_FAIL,
  UPDATE_STORE_NAME,
  UPDATE_STORE_LOGO,
  UPDATE_STORE_MAP_BASE64_IMG,
  UPDATE_STORE_ASSISTANT,
  UPDATE_STORE_ASSISTANT_SUCCESS,
  UPDATE_STORE_ASSISTANT_FAIL,
  VALIDATE_STORE_NAME_SUCCESS,
  VALIDATE_STORE_NAME_FAIL,
  VALIDATE_STORE_NAME,
  GET_STORE_THEME_SUCCESS,
  GET_STORE_THEME_FAIL,
  GET_STORE_THEME,
  SET_STORE_THEME,
  UPDATE_STORE_DETAIL,
  UPDATE_STORE_DETAIL_SUCCESS,
  UPDATE_STORE_DETAIL_FAIL,
  UPDATE_ONBOARDING_STORE_NAME,
  UPDATE_STORE_MAP,
  UPDATE_STORE_MAP_SUCCESS,
  UPDATE_STORE_MAP_FAIL,
  GET_STORE_BY_PUBLIC_ID_WITH_ITEMS,
  STORE_SHOW_SNACKBAR_BAR,
  UPDATE_STORE_THEME,
  UPDATE_STORE_THEME_SUCCESS,
  UPDATE_STORE_THEME_FAIL,
  UPDATE_STORE_CURRENT_THEME,
  SET_LAYOUT_SHOW_SNACKBAR_BAR,
  CREATE_STORE_BY_TEMPLATE,
  CREATE_STORE_BY_TEMPLATE_SUCCESS,
  CREATE_STORE_BY_TEMPLATE_FAIL,
  UPDATE_STORE_DETAIL_FROM_ADMIN,
  ADD_BACKGROUND_MUSIC,
  ADD_BACKGROUND_MUSIC_SUCCESS,
  ADD_BACKGROUND_MUSIC_FAIL,
  DELETE_BACKGROUND_MUSIC,
  DELETE_BACKGROUND_MUSIC_SUCCESS,
  DELETE_BACKGROUND_MUSIC_FAIL,
  UPDATE_BACKGROUND_MUSIC,
  UPDATE_BACKGROUND_MUSIC_SUCCESS,
  UPDATE_BACKGROUND_MUSIC_FAIL,
  BACKGROUND_MUSIC_SNACKBAR,
} from './constants';

export const initialState = {
  list: [],
  current: {
    name: '',
    url: '',
    description: '',
    isTemplate: false,
  },
  publicStoreDetail: {},
  isReady: false,
  name: '',
  description: '',
  logo: '',
  assistant: {
    url: '',
    texture: [],
    ratio: 1,
  },
  map: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 8, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0,
  ],
  mapBase64Image: '',
  theme: [],
  selectedTheme: {},
  error: null,
  loading: false,
  isShowSnackbar: false,
  layoutSnackbar: {
    title: 'Successfully updated',
    isShow: false,
    type: null,
  },
  backgroundMusicSnackbar: {
    title: 'Successfully updated',
    isShow: false,
    type: null,
  },
};

const storeReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CREATE_STORE:
    case GET_STORE_BY_PUBLIC_ID_WITH_ITEMS:
      draft.loading = true;
      break;
    case CREATE_STORE_SUCCESS: {
      draft.loading = false;
      const { _id, name } = action.data.data;
      const tempList = _.cloneDeep(draft.list);
      tempList.push({
        _id,
        name,
      });
      draft.list = tempList;
      break;
    }
    case CREATE_STORE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_STORE_LIST:
      draft.loading = true;
      break;
    case GET_STORE_LIST_SUCCESS:
      draft.list = action.data;
      draft.loading = false;
      break;
    case GET_STORE_LIST_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_STORE_DETAIL:
      draft.loading = true;
      draft.isReady = false;
      break;
    case GET_STORE_DETAIL_SUCCESS:
      draft.loading = false;
      draft.current = action.data;
      draft.map = action.data.map;
      updater(action.data, draft);
      draft.isReady = true;
      break;
    case GET_STORE_DETAIL_FAIL:
      draft.loading = false;
      draft.isReady = true;
      draft.error = action.error;
      break;
    case UPDATE_STORE_DETAIL_FROM_ADMIN:
    case UPDATE_STORE_DETAIL:
      draft.loading = true;
      break;
    case UPDATE_ONBOARDING_STORE_NAME:
      draft.name = action.data;
      break;
    case UPDATE_STORE_DETAIL_SUCCESS: {
      draft.error = null;
      draft.loading = false;
      const store = action.data.data.items[0];
      updater(store, draft.current);
      const tempList = _.cloneDeep(draft.list);
      const index = _.findIndex(tempList, { _id: store._id });
      if (index >= 0) {
        tempList[index].name = store.name;
        tempList[index].isTemplate = store.isTemplate;
        draft.list = tempList;
      }
      draft.isShowSnackbar = true;
      break;
    }
    case UPDATE_STORE_DETAIL_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
      // case UPDATE_STORE_NAME:
      //   draft.name = action.data;
      //   localStorage.setItem('storeName', action.data);
      //   break;
    case UPDATE_STORE_LOGO:
      draft.logo = action.data;
      break;
    case UPDATE_STORE_MAP:
      // draft.map = action.data;
      break;
    case UPDATE_STORE_MAP_BASE64_IMG:
      draft.mapBase64Image = action.data;
      break;
    case UPDATE_STORE_ASSISTANT:
      draft.loading = true;
      // localStorage.setItem('storeAssistant', JSON.stringify(action.data));
      // updater(action.data, draft.assistant);
      break;
    case UPDATE_STORE_ASSISTANT_SUCCESS:
      draft.loading = false;
      draft.current.assistant[0] = action.data.data;
      break;
    case VALIDATE_STORE_NAME:
      draft.loading = true;
      break;
    case VALIDATE_STORE_NAME_SUCCESS:
      draft.loading = false;
      draft.error = null;
      break;
    case VALIDATE_STORE_NAME_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_STORE_BY_NAME:
    case GET_STORE_BY_NAME_WITH_ITEMS:
      draft.loading = true;
      break;
    case GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS:
      draft.loading = false;
      draft.publicStoreDetail = action.data;
      break;
    case GET_STORE_BY_NAME_SUCCESS:
      draft.loading = false;
      draft.current = action.data;
      break;
    case GET_STORE_BY_NAME_WITH_ITEMS_FAIL:
    case GET_STORE_BY_NAME_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_STORE_THEME:
      draft.loading = true;
      break;
    case GET_STORE_THEME_SUCCESS:
      draft.theme = action.data;
      // eslint-disable-next-line prefer-destructuring
      draft.selectedTheme = action.data[0];
      draft.loading = false;
      break;
    case GET_STORE_THEME_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    case SET_STORE_THEME:
      draft.selectedTheme = action.data;
      break;
    case UPDATE_STORE_MAP_SUCCESS:
      draft.current.map = [action.data.data];
      draft.map = [action.data.data];
      draft.layoutSnackbar = {
        title: 'Successfully updated',
        isShow: true,
        type: null,
      };
      break;
    case UPDATE_STORE_MAP_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case STORE_SHOW_SNACKBAR_BAR:
      draft.isShowSnackbar = action.bool;
      break;
    case UPDATE_STORE_CURRENT_THEME:
      draft.current.theme[0] = action.theme;
      break;
    case SET_LAYOUT_SHOW_SNACKBAR_BAR: {
      const temp = _.cloneDeep(draft.layoutSnackbar);
      temp.isShow = false;
      draft.layoutSnackbar = temp;
      break;
    }
    case CREATE_STORE_BY_TEMPLATE_SUCCESS:
      break;
    case CREATE_STORE_BY_TEMPLATE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case ADD_BACKGROUND_MUSIC:
      draft.loading = true;
      break;
    case ADD_BACKGROUND_MUSIC_SUCCESS: {
      draft.loading = false;
      const current = _.cloneDeep(draft.current);
      current.backgroundMusic = [action.data];
      draft.backgroundMusicSnackbar = {
        title: 'Successfully added',
        isShow: true,
        type: null,
      };
      break;
    }
    case ADD_BACKGROUND_MUSIC_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case DELETE_BACKGROUND_MUSIC:
      draft.loading = true;
      break;
    case DELETE_BACKGROUND_MUSIC_SUCCESS: {
      draft.loading = false;
      const current = _.cloneDeep(draft.current);
      current.backgroundMusic = [];
      draft.current = current;
      draft.backgroundMusicSnackbar = {
        title: 'Successfully deleted',
        isShow: true,
        type: null,
      };
      break;
    }
    case DELETE_BACKGROUND_MUSIC_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case UPDATE_BACKGROUND_MUSIC:
      draft.loading = true;
      break;
    case UPDATE_BACKGROUND_MUSIC_SUCCESS: {
      draft.loading = false;
      const current = _.cloneDeep(draft.current);
      current.backgroundMusic = [];
      draft.current = current;
      draft.backgroundMusicSnackbar = {
        title: 'Successfully updated',
        isShow: true,
        type: null,
      };
      break;
    }
    case UPDATE_BACKGROUND_MUSIC_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case BACKGROUND_MUSIC_SNACKBAR:
      // eslint-disable-next-line no-case-declarations
      const temp = _.cloneDeep(draft.backgroundMusicSnackbar);
      temp.isShow = false;
      draft.backgroundMusicSnackbar = temp;
      break;
    default:
  }
});

export default storeReducer;
