/* eslint-disable */
AFRAME.registerComponent('chatbox-look-at', {
  schema: { type: 'selector' },

  init: function () {},

  tick: function () {
    // is look at?
    var isLookAt = true;
    if (isLookAt) {
      this.el.object3D.lookAt(this.data.object3D.position);
    }
  }
})