import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';
import FormHelperText from '@mui/material/FormHelperText';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function VolumeControl(props) {
  const { volume, setAudioVolume } = props;
  // const [value, setAudioVolume] = React.useState(30);

  // const handleSliderChange = (event, newValue) => {
  //   console.log(event);
  //   setAudioVolume(event);
  // };

  const handleInputChange = (event) => {
    const e = {
      target: {
        name: event.target.name,
        value: Number(event.target.value),
      },
    };
    setAudioVolume(e);
  };

  const handleBlur = () => {
    if (volume < 0) {
      setAudioVolume(0);
    } else if (volume > 100) {
      setAudioVolume(100);
    }
  };

  useEffect(() => {
  }, [volume]);

  return (
    <Box sx={{ width: 250 }}>
      <Typography id="input-slider" gutterBottom>
        Volume
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider
            name="metadata.volume"
            value={typeof volume === 'number' ? volume : 0}
            onChange={setAudioVolume}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            name="metadata.volume"
            value={volume}
            size="small"
            onChange={handleInputChange}
            // onBlur={handleBlur}
            variant="standard"
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
          %
        </Grid>
      </Grid>
      <FormHelperText>How loud to play the sound.</FormHelperText>
    </Box>
  );
}

VolumeControl.propTypes = {
  setAudioVolume: PropTypes.func,
  volume: PropTypes.number,
};
