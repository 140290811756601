import React, {
  useEffect,
} from 'react';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import writeIconImg from 'assets/icon/write-icon.png';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import Stack from '@mui/material/Stack';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '30px',
  height: '30px',
  border: '1px solid #E5E5E5',
  boxShadow: '1px 2px rgba(0, 0, 0, 0.1))',
  borderRadius: '4px',
}));

const StyledCardMedia = styled((props) => {
  const { children, isImage, ...other } = props;

  return (
    isImage ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: '#D9D9D9',
        }}
      >
        <img
          {...other}
        />
      </Box>
    )
      : (
        <CardMedia {...other}>
          {children}
        </CardMedia>
      )
  );
})(({ theme }) => ({
  height: '238px',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: '0',
  },
}));

export default function Detail(props) {
  const {
    handleOpen,
    title,
    description,
    priceList,
    asset,
    index,
  } = props;

  const activeAsset = asset[0];
  const isModel = () => activeAsset.type === 'model';

  useEffect(() => {
  }, []);

  return (
    <Card elevation={1} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#dedede4d' } }} onClick={() => handleOpen(index)}>
      <CardHeader
        action={(
          <StyledIconButton
            size="small"
            onClick={() => handleOpen(index)}
          >
            <img src={writeIconImg} alt="" />
          </StyledIconButton>
      )}
        title={(
          <Typography
            variant="h6"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
        )}
        subheader={(
          <Typography
            variant="caption1"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#939393',
            }}
          >
            {description}
          </Typography>
      )}
      />
      { isModel()
        ? (
          <StyledCardMedia isImage={false} className="detail__model" sx={{ backgroundColor: '#fde2e4' }} loading="lazy" crossOrigin="anonymous">
            <ErrorBoundary>
              <CanvasModel model={activeAsset.url} ratio={activeAsset.metadata.ratio} />
            </ErrorBoundary>
          </StyledCardMedia>
        )
        : (
          <StyledCardMedia
            isImage
            className="detail__img"
            component="img"
            height="194"
            src={asset[0].url}
            alt="img"
            crossOrigin="anonymous"
          />
        )}
      <StyledCardContent>
        <Stack direction="row" spacing={10} sx={{ color: 'grey.D055', marginBottom: 1.5 }}>
          {priceList && priceList.map((crypto) => (
            <span key={crypto.currency}>{`${crypto.currency.toUpperCase()}: ${crypto.amount}`}</span>
          ))}
        </Stack>
      </StyledCardContent>
    </Card>
  );
}

Detail.propTypes = {
  handleOpen: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  priceList: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
  })),
  asset: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    metadata: PropTypes.shape({
      model: PropTypes.string,
      ratio: PropTypes.number,
    }),
  })),
  index: PropTypes.number,
};
