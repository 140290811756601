/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import _ from 'lodash';
import {
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_LIST_SUCCESS,
  GET_TEMPLATE_LIST_FAIL,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATE_DETAIL_FAIL,
} from './constants';

export const initialState = {
  templateList: [],
  current: {},
  error: null,
  loading: false,
  outletLoading: false,
};

const templateReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case GET_TEMPLATE_LIST:
      draft.error = null;
      draft.outletLoading = true;
      break;
    case GET_TEMPLATE_LIST_SUCCESS:
      draft.templateList = action.data;
      draft.error = null;
      draft.outletLoading = false;
      break;
    case GET_TEMPLATE_LIST_FAIL:
      draft.error = action.error;
      draft.outletLoading = false;
      break;
    case GET_TEMPLATE_DETAIL:
      draft.error = null;
      draft.loading = true;
      break;
    case GET_TEMPLATE_DETAIL_SUCCESS:
      draft.current = action.data;
      draft.error = null;
      draft.loading = false;
      break;
    case GET_TEMPLATE_DETAIL_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    default:
  }
});

export default templateReducer;
