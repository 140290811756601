import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TemplatesCategoryMenu from '../TemplatesCategoryMenu/TemplatesCategoryMenu';

export default function ThemeHeader(props) {
  const { search, handleSearchChange } = props;
  return (
    <div>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
        sx={{
          marginBottom: '40px',
          padding: '10px 33px 0px 33px',
        }}
      >
        <Grid sx={{ alignSelf: 'flex-end' }}>
          <Typography variant="h3" component="p">
            Choose a template
          </Typography>
        </Grid>
        <Grid>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-search">Search for templates</InputLabel>
            <Input
              id="standard-search"
              type="text"
              value={search}
              onChange={handleSearchChange}
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: '1px', // add margin to make sure it won't jump when focusing
                '&.Mui-focused': {
                  borderBottom: '2px solid #5219F6',
                  marginBottom: '0', // reducing margin to make sure it won't jump when focusing
                },
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ padding: '0 33px', marginBottom: '20px' }}
      >
        {['Business', 'Store', 'Portfolio', 'Blog', 'Community'].map((category) => (
          <TemplatesCategoryMenu key={category} category={category} />
        ))}
      </Stack>
    </div>
  );
}

ThemeHeader.propTypes = {
  search: PropTypes.string,
  handleSearchChange: PropTypes.func,
};
