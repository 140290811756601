export function saveData(key, value) {
  sessionStorage.setItem(key, value);
}

// get item from session storage
export function getData(key) {
  return sessionStorage.getItem(key);
}

// remove item from session storage
export function removeData(key) {
  sessionStorage.removeItem(key);
}

// clear all session stoarge
export function clearData() {
  sessionStorage.clear();
}
