/* eslint-disable */
AFRAME.registerComponent('tour-guide', {
  // schema: { type: 'selector' },
  init: function() {
    var el = this.el;
    this.cam = document.getElementById('rig');
    this.isMoving = false;
    this.isFollowUser = false;
    this.tween = new TWEEN.Tween();
    // const states = [ 'Idle', 'Walking', 'Running', 'Dance', 'Death', 'Sitting', 'Standing' ];
    const states = [ 'default' ];
    const emotes = [ 'Jump', 'Yes', 'No', 'Wave', 'Punch', 'ThumbsUp' ];

    el.emit('tour-guide-action-move', {clip: states[Math.floor(Math.random() * states.length)], loop: "Infinity"}, false);

    this.moveToPlayer = this.moveToPlayer.bind(this);
    var that = this;
    
    setTimeout(function() {
      el.object3D.lookAt(that.cam.object3D.position);
    }, 500)
    this.onTourGuideAction = this.onTourGuideAction.bind(this);
  },
  update: function () {
    var el = this.el;
    el.addEventListener("tour-guide-action", this.onTourGuideAction);
    el.addEventListener("tour-guide-action-move", this.onTourGuideActionMove);
  },
  onTourGuideAction: function(e) {
    if (e.detail) {
      // clip & loop
      var detail = e.detail; 
      var clip = detail.clip;
      var loop = detail.loop;
      var repetitions = detail.repetitions;
      var animation = this.el.getAttribute("animation-mixer");
      animation.clip = clip;
      animation.loop = loop;
      animation.repetitions = repetitions;
      this.el.setAttribute("animation-mixer", animation);
    }
  },
  onTourGuideActionMove: function () {
    this.el.emit('tour-guide-action', {clip: "Walking", loop: "Infinity"}, false);
    this.moveToPlayer();
  },
  remove: function() {
    this.el.removeEventListener("tour-guide-action", this.onTourGuideAction);
    this.el.removeEventListener("tour-guide-action-move", this.onTourGuideActionMove);
  },
  moveToPlayer: function () {
    var el = this.el;
    var that = this;
    var cameraPosition = this.cam.getAttribute('position');
    var botPosition = el.getAttribute('position');

    var xSign = (botPosition.x - cameraPosition.x > 0) ? 1 : -1;
    var zSign = (botPosition.z - cameraPosition.z > 0) ? 1 : -1;

    var adjustedRig = new THREE.Vector3( cameraPosition.x + xSign * 0.75, 0, cameraPosition.z + zSign * 1);
    // var adjustedRig = new THREE.Vector3( cameraPosition.x + xSign * 0.75, 0, cameraPosition.z);
    var distance = 2.35;
    var isNeedMove = Math.abs(adjustedRig.x - botPosition.x) > distance
      || Math.abs(adjustedRig.y - botPosition.y) > distance
      || Math.abs(adjustedRig.z - botPosition.z) > distance;
    
    const demo = ['default', "Walking"];
    
    if (isNeedMove) {
      if (!this.isMoving && this.isFollowUser) {
        this.tween = new TWEEN.Tween(el.getAttribute('position')).to( adjustedRig, 3500 ); 
        this.tween.onComplete(function() {
          el.emit('tour-guide-action', {clip: demo[Math.floor(Math.random() * demo.length)], loop: "Infinity"}, false);
          el.object3D.lookAt(that.cam.object3D.position);
          that.isMoving = false;
        }).onStop(function() {
          el.emit('tour-guide-action', {clip: demo[Math.floor(Math.random() * demo.length)], loop: "Infinity"}, false);
          that.isMoving = false;
        }).onStart(function() {
          el.emit('tour-guide-action', {clip: "default", loop: "Infinity"}, false);
          el.object3D.lookAt(that.cam.object3D.position);
        });
        this.isMoving = true;
        this.tween.start();
      }
    } else {
      this.el.object3D.lookAt(this.cam.object3D.position);
      this.tween.stop();
      this.isFollowUser = true;
    }
  },
  tick: function(time) {
    this.moveToPlayer();
    TWEEN.update(time);
  }
})