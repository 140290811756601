/* eslint-disable */
AFRAME.registerComponent('popup-panel', {
  schema: {
    width: {default: 1},
    height: {default: 1},
    href: {default: '#'},
    to_location: {default: ''}
  },
  init: function() {
    var el = this.el;
    el.removeAttribute('link');
    var data = this.data;
    this.isShowPanel = true;
    this.cam = document.getElementById('rig');
    this.panel = document.createElement('a-entity');
    this.panelText = document.createElement('a-entity');

    this.onClickConfirmButton = this.onClickConfirmButton.bind(this);
    this.onClickCancelButton = this.onClickCancelButton.bind(this);

    this.panel.setAttribute("geometry", {primitive: "plane", height: data.height, width: data.width * 1.05});
    this.panel.setAttribute("material", {
      color: "black",
      opacity: 0.75
    });
    
    this.panelText.setAttribute('text', {
      value: `Teleport to ${data.to_location}`,
      color: "#ffffff",
      width: 0.97,
      side: "double",
    });

    this.panel.setAttribute("position", {x: 0, y: 0, z: 0.3});

    this.confirmButton = this.createConfirmButton(data);
    this.cancelButton = this.createCancelButton(data);

    this.panel.appendChild(this.panelText);

    this.panel.appendChild(this.confirmButton);
    this.panel.appendChild(this.cancelButton);

    el.appendChild(this.panel);
  },
  remove: function() {
    this.confirmButton.removeEventListener('click', this.onClickConfirmButton);
    this.cancelButton.removeEventListener('click', this.onClickCancelButton);
  },
  tick: function() {
    let camPos = this.cam.object3D.position;
    let spherePos = this.el.object3D.position;
    let distance = camPos.distanceTo(spherePos);
    if (distance <= 5.5) {
      if (this.isShowPanel) this.panel.setAttribute('visible', true);
    } else {
      this.isShowPanel = true;
      this.panel.setAttribute('visible', false);
    }
  },
  createCancelButton: function(data) {
    let cancelButton = this.createButton("Cancel", data, "right");
    cancelButton.addEventListener('click', this.onClickCancelButton);
    return cancelButton;
  },
  onClickCancelButton: function(e) {    
    this.isShowPanel = false;
    this.panel.setAttribute('visible', false);
  },
  createConfirmButton: function(data) {
    let confirmButton = this.createButton("Confirm", data);
    confirmButton.addEventListener('click', this.onClickConfirmButton);
    return confirmButton;
  },
  onClickConfirmButton: function(e) {
    // const API = require('../api');
    // const api = API.default.create();
    e.preventDefault();
    e.stopPropagation();
    this.panelText.setAttribute('text', {
      value: "Teleporting .",
    });
    var that = this;
    setTimeout(function() {
      that.panelText.setAttribute('text', {
        value: "Teleporting ..",
      });
    }, 1500)
    setTimeout(function() {
      that.panelText.setAttribute('text', {
        value: "Teleporting ...",
      });
      // var sceneEl = document.querySelector('a-scene');
      // if (sceneEl.is('vr-mode')) sceneEl.exitVR();
    }, 3000)
    
    setTimeout(function() {
      window.location.href=that.data.href;
    }, 3500)

    // api.crypto.smartAd.teleport({
    //   from: 'webxr-store',
    //   to: 'webxr-gallery'
    // }).then(result => {
    //   if (result.ok) {
    //     window.location.href='/game/exhibition';
    //   }
    // }).catch(err => {
    //   this.panelText.setAttribute('text', {
    //     value: "Please try again.",
    //   });
    //   console.warn(err);
    // });
  },
  createButton: function(content, size, direction="left") {
    var panelButton = document.createElement('a-entity');
    var panelButtonText = document.createElement('a-entity');
    var width = size.width;
    var height = size.height;

    panelButton.setAttribute("geometry", {primitive: "plane", width: width / 2.5, height: height / 5});
    // panelButton.setAttribute("material", {src:"#info_panel_button_1",color: "#333333", opacity: 0.8});
    panelButton.setAttribute("position", {x: ((direction === "left") ? 1 : -1) * width * 0.3, y: -(height) * 1/2.7, z: 0.02});

    panelButtonText.setAttribute('text', {
      value: content,
      color: "white",
      width: 0.97,
      side: "double",
      align: "center",
    });
    panelButtonText.setAttribute('scale', {x: 2, y: 2, z: 3});
    panelButton.appendChild(panelButtonText);
    return panelButton;
  }
})