import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import {
  updateStoreTheme,
} from 'services/store/actions';
import {
  makeSelectUserId,
} from 'services/user/selectors';
import {
  makeSelectStoreCurrentTheme,
} from 'services/store/selectors';
import {
  makeSelectThemeList,
  makeSelectThemeError,
  makeSelectThemeLoading,
  makeSelectThemeSnackbar,
} from 'services/theme/selectors';
import {
  setShowSnackbar,
} from 'services/theme/actions';
import ThemeSuccessModal from './components/Modal/ThemeSuccessModal';
import ThemeModal from './components/Modal/ThemeDetailModal';
import ThemeHeader from './components/ThemeHeader/ThemeHeader';
import ThemeBody from './components/ThemeBody/ThemeBody';
import styles from './Themes.module.scss';

function Themes(props) {
  const {
    themeList, currentTheme, snackbar,
  } = props;
  const [activeThemeId, setActiveThemeId] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = (themeIndex) => { setActiveThemeId(themeIndex); setOpen(true); };
  const handleClose = () => setOpen(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId = searchParams.get('sid');

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const goToEditTheme = ({ themeId, isEdit = true }) => {
    navigate(`edit${location.search}${isEdit ? `&tid=${themeId}` : '&new=true'}`);
  };

  const isCurrentTheme = (theme) => theme._id === currentTheme._id;

  useEffect(() => {
  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Themes" pageSubtitle="A theme includes all the design elements that gives your world its unique look and feel">
        <Grid container columnSpacing={1} sx={{ order: { xs: 1, sm: 2 } }}>
          <Grid>
            <NormalButton
              size="large"
              callback={() => {
                goToEditTheme({ isEdit: false });
              }}
            >
              <AddIcon
                sx={{
                  position: 'relative',
                  top: '0px',
                  left: '-3px',
                  marginRight: '0px',
                }}
              />
              Create custom theme
            </NormalButton>
          </Grid>
        </Grid>
      </GeneralTitle>
      <GeneralContent disablePadding display="flex" elevation={0}>
        <div className={`generalContentContainer ${styles.generalContentContainer}`}>
          <ThemeHeader
            search={search}
            handleSearchChange={handleSearchChange}
          />
          <ThemeBody
            search={search}
            themeList={themeList}
            currentTheme={currentTheme}
            goToEditTheme={goToEditTheme}
            handleOpen={handleOpen}
            isCurrentTheme={isCurrentTheme}
          />
        </div>
      </GeneralContent>
      { open && (
        <ThemeModal
          open={open}
          handleClose={handleClose}
          isCurrentTheme={isCurrentTheme}
          goToEditTheme={goToEditTheme}
          storeId={storeId}
          themeList={themeList}
          activeThemeId={activeThemeId}
        />
      )}
      <ThemeSuccessModal />
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectThemeError(),
  loading: makeSelectThemeLoading(),
  themeList: makeSelectThemeList(),
  currentTheme: makeSelectStoreCurrentTheme(),
  userId: makeSelectUserId(),
  snackbar: makeSelectThemeSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreTheme,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Themes.propTypes = {
  currentTheme: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
  }),
  current: PropTypes.shape({
    logo: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
  })),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  // updateStoreTheme: PropTypes.func,
  setShowSnackbar: PropTypes.func,
};

export default compose(
  withConnect,
)(Themes);
