/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import _ from 'lodash';
import { updateStoreMap, updateStoreMapBase64Image, setShowLayoutSnackbar } from 'services/store/actions';
import { makeSelectStoreMap, makeSelectStoreLayoutSnackbar } from 'services/store/selectors';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import {
  useNavigate, useLocation,
} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import styles from './CustomLayout.module.scss';

const Styles = {
  canvas: {
    border: '1px solid black',
  },
  block: {
    wall: {
      color: 'black',
    },
  },
};

function CustomLayout(props) {
  const {
    snackbar, map, save, isSnackbarClosed,
    setStoreLayout,
    storeLayout,
  } = props;
  // console.log(clonedMap.map((x, index) => (index !== 0 && index % storeLayout.width === 0 ? `${x}\n` : x)).join(''));
  const clonedMap2 = _.cloneDeep(storeLayout.data);
  const navigate = useNavigate();
  const location = useLocation();
  const size = 27;

  let tempMapChangeList = [];
  let isDrawing = false;
  let currentIndex = 0;

  const drapMap = () => {
    const buffer = document.createElement('canvas').getContext('2d');
    const ctx = document.querySelector('canvas');
    const context = ctx.getContext('2d');

    buffer.canvas.width = storeLayout.width * size;
    buffer.canvas.height = storeLayout.height * size;

    // for (let index = 0; index < clonedMap2.length; index += 1) {
    for (let index = 0; index < storeLayout.width * storeLayout.height; index += 1) {
      buffer.fillStyle = (clonedMap2[index] === 2) ? '#000000' : '#ffffff';
      buffer.fillRect((index % storeLayout.width) * size, Math.floor(index / storeLayout.width) * size, size, size);
    }

    context.canvas.width = storeLayout.width * size;
    context.canvas.height = storeLayout.height * size;
    context.drawImage(buffer.canvas, 0, 0, buffer.canvas.width, buffer.canvas.height, 0, 0, context.canvas.width, context.canvas.height);
  };

  const getMapIndex = (event) => {
    const canvas = document.querySelector('canvas');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const index = Math.floor(y / size) * storeLayout.width + Math.floor(x / size);
    return index;
  };

  const isFirstTimeDrawing = (index) => !tempMapChangeList[index];

  const isRedrawOn = (index) => currentIndex !== index;

  const onCanvasMouseDown = (event) => {
    if (snackbar.isShow) return;

    isDrawing = true;
    event.stopPropagation();
    event.preventDefault();
    const index = getMapIndex(event);
    if (isFirstTimeDrawing(index)) {
      currentIndex = index;
      tempMapChangeList[index] = true;
      clonedMap2[index] = clonedMap2[index] === 2 ? 0 : 2;
    } else if (isRedrawOn(index)) {
      currentIndex = index;
      clonedMap2[index] = clonedMap2[index] === 2 ? 0 : 2;
    }
    drapMap();
  };

  const onCanvasMouseMove = (event) => {
    if (isDrawing === true) {
      onCanvasMouseDown(event);
    }
  };

  const onMouseUp = () => {
    isDrawing = false;
    tempMapChangeList = [];
  };

  const goToLayoutsPage = () => {
    navigate(`/layouts${location.search}`);
  };

  const resetLayout = (value) => {
    if (value >= 19 && value <= 26) {
      const oldWidth = storeLayout.width;
      const oldHeight = storeLayout.height;
      const newMapCloned = [];

      const diff = value - oldHeight;
      const isExtend = diff > 0;
      const totalCount = isExtend ? oldWidth * oldHeight : value * value;

      for (let i = 0; i < totalCount; i += 1) {
        if (i !== 0 && i % oldWidth === 0 && isExtend) {
          for (let l = 0; l < diff; l += 1) {
            newMapCloned[i + Math.floor(i / oldWidth) * diff - l - 1] = 0;
          }
        }
        if (isExtend) {
          newMapCloned[i + Math.floor(i / oldWidth) * (value - oldWidth)] = clonedMap2[i] || 0;
        } else {
          newMapCloned[i] = clonedMap2[i + Math.floor(i / value) * (oldWidth - value)];
        }
      }

      if (isExtend) {
        const start = oldWidth * oldHeight + value;
        // console.log(`${start} ${value * value}`);

        for (let j = (oldWidth * oldHeight + value); j < value * value; j += 1) { // fill remaining with 0
          newMapCloned[j] = 0;
        }
      }

      // console.log(`${isExtend}: ${newMapCloned.length}; ${value}`);
      // console.log(newMapCloned);

      setStoreLayout({
        width: value,
        height: value,
        data: newMapCloned,
      });
    }
  };

  useEffect(() => {
    drapMap();
    const canvas = document.querySelector('canvas');
    canvas.addEventListener('mousedown', onCanvasMouseDown);
    canvas.addEventListener('mousemove', onCanvasMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      canvas.removeEventListener('mousedown', onCanvasMouseDown);
      canvas.removeEventListener('mousemove', onCanvasMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [storeLayout]);

  return (
    <div className={styles.mainSection}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <canvas style={Styles.canvas} id="layoutCanvas" />
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={5}>
            <Stack sx={{ paddingTop: '20px' }} direction="row" alignItems="center">
              <Box
                sx={{
                  width: '18px', height: '18px', backgroundColor: 'black', mr: 1,
                }}
              />
              <Typography variant="caption" component="p">
                Black lines represent walls
              </Typography>
            </Stack>
            <TextField
              required
              id="width-textfield"
              label="Width"
              type="number"
              min={19}
              helperText="min size: 19, max: 26"
              value={storeLayout.width}
              onChange={(event) => {
                const { value } = event.target;
                resetLayout(_.toNumber(value));
              }}
            />
            <TextField
              required
              id="height-textfield"
              label="Height"
              type="number"
              min={19}
              value={storeLayout.height}
              helperText="min size: 19, max: 26"
              onChange={(event) => {
                const { value } = event.target;
                resetLayout(_.toNumber(value));
              }}
            />
            <Typography variant="caption" component="p">
              *Please reset the item&apos;s position after resizing the layout.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={() => goToLayoutsPage()}
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.buttonGroup}>
        <Button
          variant="outlined"
          sx={{
            marginRight: 2,
          }}
          onClick={() => {
            setStoreLayout((preState) => ({ ...preState, data: storeLayout.data }));
          }}
        >
          Reset
        </Button>
        <NormalButton text="Save" size="large" callback={() => { save(storeLayout.width, storeLayout.height, clonedMap2); }} />
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  map: makeSelectStoreMap(),
  snackbar: makeSelectStoreLayoutSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreMap,
    updateStoreMapBase64Image,
    setShowLayoutSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

CustomLayout.propTypes = {
  map: PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  // UpdateTempStore: PropTypes.func.isRequired,
  updateStoreMap: PropTypes.func.isRequired,
  updateStoreMapBase64Image: PropTypes.func.isRequired,
  setShowLayoutSnackbar: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  storeLayout: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.number),
  }),
  setStoreLayout: PropTypes.func,
  save: PropTypes.func,
  isSnackbarClosed: PropTypes.bool,
};

export default compose(
  withConnect,
)(CustomLayout);
