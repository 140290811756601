import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import floating from 'assets/Items/art/floating.png';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  makeSelectThemeIsShowModal,
  makeSelectThemeModalType,
} from 'services/theme/selectors';
import { setShowModal } from 'services/theme/actions';
import { makeSelectStorePublicId } from 'services/store/selectors';
import { MODAL_TYPE } from 'services/theme/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  // p: 4,
  padding: '10px 32px 32px 32px',
  background: '#FFFFFF',
  boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
};

function TitleComponent(props) {
  const { modalType } = props;
  if (modalType === MODAL_TYPE.EDIT) {
    return (
      <>
        <Typography id="modal-success-theme-title" variant="h5" component="h2">
          Successfully edited.
        </Typography>
        <Typography id="modal-success-theme-subtitle" variant="h6" component="h2">
          See your store with the theme.
        </Typography>
      </>
    );
  } if (modalType === MODAL_TYPE.CREATE) {
    return (
      <>
        <Typography id="modal-success-theme-title" variant="h5" component="h2">
          Successfully created.
        </Typography>
        <Typography id="modal-success-theme-subtitle" variant="h6" component="h2">
          See your store with the new theme.
        </Typography>
      </>
    );
  }
  return (
    <Typography id="modal-success-theme-title" variant="h5" component="h2">
      See your store with this theme.
    </Typography>
  );
}

TitleComponent.propTypes = {
  modalType: PropTypes.string,
};

function ThemeSuccessModal(props) {
  const { isShowModal, publicId, modalType } = props;
  const handleClose = () => props.setShowModal(false);

  return (
    <Modal
      open={isShowModal}
      onClose={handleClose}
      aria-labelledby="modal-success-theme-title"
      aria-describedby="modal-success-theme-description"
    >
      <Box sx={style}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label="close"
            sx={{ alignSelf: 'flex-end' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <TitleComponent modalType={modalType} />
        <Box
          sx={{
            width: 350,
            height: 350,
            backgroundImage: `url(${floating})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
        <NormalButton
          text="View store"
          fullWidth
          sx={{
            fontWeight: 700,
            marginTop: 2,
          }}
          callback={() => {
            handleClose();
            window.open(`/s/${publicId}`, '_blank');
          }}
        />
      </Box>
    </Modal>
  );
}

const mapStateToProps = createStructuredSelector({
  isShowModal: makeSelectThemeIsShowModal(),
  publicId: makeSelectStorePublicId(),
  modalType: makeSelectThemeModalType(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setShowModal,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ThemeSuccessModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  isShowModal: PropTypes.bool.isRequired,
  publicId: PropTypes.string.isRequired,
  modalType: PropTypes.string,
};

export default compose(
  withConnect,
)(ThemeSuccessModal);
