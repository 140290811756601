export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const REGET_HOME_DATA = 'REGET_HOME_DATA';
export const REGET_HOME_DATA_SUCCESS = 'REGET_HOME_DATA_SUCCESS';
export const GET_DATA_FROM_SCRATCH = 'GET_DATA_FROM_SCRATCH';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const SET_USER_SHOW_SNACKBAR_BAR = 'SET_USER_SHOW_SNACKBAR_BAR';
