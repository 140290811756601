import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Typography, Button } from '@mui/material';
import ImageSrc from 'webComponents/ImageSrc/ImageSrc';
import Backbutton from 'webComponents/BackButton/Backbutton';
import ImageButton from 'webComponents/ImageButton/ImageButton';
import { makeSelectOnboardingStoreName, makeSelectStoreTheme, makeSelectStoreLoading } from 'services/store/selectors';
import { getStoreTheme, setStoreTheme } from 'services/store/actions';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Grid from '@mui/material/Grid';
import Stepper from 'webComponents/Stepper/Stepper';
import { conversionAccountId, conversionEventId, googleConversion } from 'utils/google.utils';

import doubleLogoImg from '../../../../assets/Onboarding/logo/double_logo.png';

import './StoreSelection.scss';

function StoreSelection(props) {
  const [activeStore, setActiveStore] = useState(0);

  const {
    handleNext, name, theme, loading, handlePrevious, isLogined, createNewStore,
  } = props;

  const handleNextButton = () => {
    googleConversion(conversionEventId.step2);
    if (isLogined) {
      createNewStore();
    } else {
      handleNext();
    }
  };

  const setStore = (index) => {
    const defaultLogoUrl = 'https://double-storage.s3.ap-southeast-1.amazonaws.com/theme/default/gamers_paradise_logo_web.jpeg';
    // eslint-disable-next-line no-underscore-dangle
    const tempTheme = _.cloneDeep(theme[index]);
    tempTheme.logo = defaultLogoUrl;
    props.setStoreTheme(tempTheme);
    setActiveStore(index);
    const child = document.querySelector('iframe');
    child.src += '';
  };

  useEffect(() => {
    props.getStoreTheme();
  }, []);

  return (
    (loading)
      ? <SimpleBackdrop open />
      : (
        <div className="page-storeSelection">
          {/* "page-storeSelection__left" is image */}
          <div className="page-storeSelection__container">
            <iframe id="iframe-theme" className="page-storeSelection__container__left" src={`/theme?data=${encodeURIComponent(JSON.stringify(theme[activeStore]))}&name=${name}`} title="theme"></iframe>
            <div className="page-storeSelection__container__right">
              <div className="page-storeSelection__container__right__wrapper">
                <Backbutton
                  className="page-storeSelection__container__right__backButton"
                  onClick={() => handlePrevious()}
                />
                <img className="page-storeSelection__container__right__wrapper__logo logo" src={doubleLogoImg} alt="" />
                <Typography className="page-storeSelection__container__right__wrapper__header header" variant="h5">Choose a theme for your world</Typography>
                <Typography
                  className="page-storeSelection__container__right__wrapper__label label"
                  component="p"
                  variant="body2"
                  sx={{
                    color: '#404040',
                  }}
                >
                  You can always change it later
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    overflow: 'scroll',
                    height: '200px',
                  }}
                >
                  {props.theme.map((store, index) => (
                    <Grid item xs={4} md={4} key={store._id}>
                      <ImageButton
                        key={store._id}
                        style={activeStore === index ? { border: '3px #58A1F9 solid' } : {}}
                        onClick={() => setStore(index)}
                      >
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          src={store.thumbnail}
                          crossOrigin="anonymous"
                          loading="lazy"
                        />
                      </ImageButton>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    marginTop: '40px',
                    height: '56px',
                    marginBottom: '56px',
                    fontSize: '18px',
                  }}
                  onClick={handleNextButton}
                >
                  Next
                </Button>
                <Stepper className="page-storeSelection__container__right__stepper" activeStep={1} />
              </div>
            </div>
          </div>
        </div>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectOnboardingStoreName(),
  theme: makeSelectStoreTheme(),
  loading: makeSelectStoreLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreTheme,
    setStoreTheme,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

StoreSelection.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  name: PropTypes.string,
  theme: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    url: PropTypes.string,
  })),
  setStoreTheme: PropTypes.func,
  getStoreTheme: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  createNewStore: PropTypes.func,
  isLogined: PropTypes.bool,
};

export default compose(
  withConnect,
)(StoreSelection);
