/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';
import { makeSelectKeymakerIsShow } from 'services/keymaker/selectors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const isWebxr = process.env.NODE_ENV !== 'production';

function MapPositionLegend(props) {
  const {
    map, isFly, setIsFly, showGridSystem, setShowGridSystem,
  } = props;
  const [showInstruction, setShowInstruction] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    z: 0,
    index: 0,
  });

  const drapMap = (cursorIndex) => {
    const buffer = document.createElement('canvas').getContext('2d');
    const ctx = document.getElementById('mapLegendCanvas');
    const context = ctx.getContext('2d');
    const mapWidth = map.width;
    const mapHeight = map.height;
    const size = 8;

    buffer.canvas.width = mapWidth * size;
    buffer.canvas.height = mapHeight * size;

    for (let index = 0; index < map.data.length; index += 1) {
      buffer.fillStyle = (map.data[index] === 2) ? '#000000' : '#ffffff';

      if (index === cursorIndex) {
        buffer.fillStyle = '#808080';
      }

      buffer.fillRect((index % mapWidth) * size, Math.floor(index / mapWidth) * size, size, size);
    }

    context.canvas.width = mapWidth * size;
    context.canvas.height = mapHeight * size;
    context.drawImage(buffer.canvas, 0, 0, buffer.canvas.width, buffer.canvas.height, 0, 0, context.canvas.width, context.canvas.height);
  };

  const onKeydown = (e) => {
    const { shiftKey } = e;
    if (shiftKey) {
      setShowInstruction(true);
    }
  };

  const onReceiveMessage = (event) => {
    const { data } = event;
    if (data.type === 'cursor-listener') {
      setPosition({
        x: data.data.x.toFixed(3),
        y: data.data.y.toFixed(3),
        z: data.data.z.toFixed(3),
        index: data.data.index,
      });
      drapMap(data.data.index);
    }
  };

  useEffect(() => {
    const channel = new BroadcastChannel('cursor-listener');
    channel.addEventListener('message', onReceiveMessage);
    window.addEventListener('keydown', onKeydown);
    return () => {
      window.removeEventListener('keydown', onKeydown);
      window.removeEventListener('message', onReceiveMessage);
    };
  }, []);

  return (
    <div
      style={{
        zIndex: 1000,
        right: '56px',
        top: '58px',
        position: 'absolute',
        borderRadius: '4px',
        border: '1px solid rgba(255, 255, 255, 0.85)',
        background: 'rgba(0, 0, 0, 0.45)',
        padding: '36px 32px 37px 32px',
        color: 'white',
        display: showInstruction ? 'block' : 'none',
      }}
    >
      <IconButton
        aria-label="close"
        sx={{
          color: 'white',
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}
        onClick={() => { setShowInstruction(false); }}
      >
        <CancelIcon />
      </IconButton>
      <Stack direction="column" spacing={2} alignItems="center">
        <Typography variant="h6" component="div">Edit Mode</Typography>
        <Typography variant="body1">{`X: ${position.x}`}</Typography>
        <Typography variant="body1">{`Y: ${position.y}`}</Typography>
        <Typography variant="body1">{`Z: ${position.z}`}</Typography>
        {/* <Typography variant="body1">{`Index: ${position.index}`}</Typography> */}
        <canvas id="mapLegendCanvas"></canvas>
        <FormControlLabel labelPlacement="start" control={<Checkbox value={isFly} />} label="Fly" onChange={(e) => { setIsFly(e.target.checked); }} />
        {/* <FormControlLabel
          labelPlacement="start"
          control={<Checkbox value={showGridSystem} />}
          label="Grid System"
          onChange={(e) => {
            const scene = document.querySelector('a-scene');
            console.log(scene);
            scene.flushToDOM();
            scene.setAttribute('physics', 'debug: true;');
            setShowGridSystem(e.target.checked);
          }}
        /> */}
      </Stack>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  isShowKeymakerChat: makeSelectKeymakerIsShow(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIsShowKeymakerChat,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

MapPositionLegend.defaultProps = {
  map: {
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0, 8, 0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0,
    ],
    height: 19,
    width: 19,
  },
};

MapPositionLegend.propTypes = {
  map: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  setIsFly: PropTypes.func,
  isFly: PropTypes.bool,
  showGridSystem: PropTypes.bool,
  setShowGridSystem: PropTypes.func,
};

export default compose(
  withConnect,
)(MapPositionLegend);
