// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p7RWecShI2jxvZaLnxnA{max-width:1360px;margin:0 auto}.ah9qF9yCY8i5idpC1CsN{padding-right:min(10%,120px);margin-bottom:50px}.hvtL8KYU7HI4VgO6thJj{display:grid;gap:24px;grid-template-columns:repeat(auto-fit, minmax(125px, min-content));width:100%}.hQGLmFdRCMcsXidxGLve{display:flex;justify-content:flex-end;margin-top:auto}.hoeZhXj2wpS_Ukj_wxrh{display:grid;grid-template-columns:minmax(393px, 58%) minmax(200px, 40%);gap:40px}@media all and (max-width: 950px){.hoeZhXj2wpS_Ukj_wxrh{grid-template-columns:100%}}.sbVtewl2AeTENC2y9mSX{display:flex;justify-content:space-between;width:100%;margin-top:15px}`, "",{"version":3,"sources":["webpack://./src/routes/items/ItemList/EditItem/EditItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,aAAA,CAGF,sBACE,4BAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,kEAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,2DAAA,CACA,QAAA,CAEA,kCALF,sBAMI,0BAAA,CAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".contentWrapper {\n  max-width: 1360px;\n  margin: 0 auto;\n}\n\n.mainSection {\n  padding-right: min(10%, 120px);\n  margin-bottom: 50px;\n}\n\n.priceRow {\n  display: grid;\n  gap: 24px;\n  grid-template-columns: repeat(auto-fit, minmax(125px, min-content)); \n  width: 100%;\n}\n\n.buttonGroup {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: auto;\n}\n\n.itemFilePriceContainer {\n  display: grid;\n  grid-template-columns: minmax(393px, 58%) minmax(200px, 40%);\n  gap: 40px;\n\n  @media all and (max-width: 950px) {\n    grid-template-columns: 100%;\n  }\n}\n\n.itemFileLinkBox {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `p7RWecShI2jxvZaLnxnA`,
	"mainSection": `ah9qF9yCY8i5idpC1CsN`,
	"priceRow": `hvtL8KYU7HI4VgO6thJj`,
	"buttonGroup": `hQGLmFdRCMcsXidxGLve`,
	"itemFilePriceContainer": `hoeZhXj2wpS_Ukj_wxrh`,
	"itemFileLinkBox": `sbVtewl2AeTENC2y9mSX`
};
export default ___CSS_LOADER_EXPORT___;
