/* eslint-disable */
// This wall-generator was inspiried by Shane Hudon's post
// https://24ways.org/2016/first-steps-in-vr/

// Walls generator
// This will auto generate a map. Right now it needs to be an equal height and width
// Numbers are used to create the map

// NUMBER KEY:
// 0 = no walls
// 1 = normal wall
// 2 = black wall

const map = {
  'data': [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 0, 0, 0, 0, 8, 0, 0, 0, 0, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 2, 2, 2, 2, 2, 0, 2, 2, 2, 2, 2, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0
  ],
  'height':19,
  'width':19,
}

AFRAME.registerComponent('init-walls', {
  schema: {
    repeat: {default: '0.2 1'},
    map: {default: map.data},
    width: { default: map.width },
    height: { default: map.height },
    ratio: {default: 1},
    wallColor: {default: '#aa63ff'},
    wall: {default: '#wall-texture'},
  },

  init: function() {
    const data = this.data;
    const schema = this.schema;
    const {height, width} = data;
    const WALL_SIZE = 1.5 * (data.ratio || schema.ratio.default);
    const WALL_HEIGHT = 5;
    const el = document.querySelector('#walls');
    let playerPos;

    for (var x = 0; x < height; x++)  {
      for (var y = 0; y < width; y++) {

        const i = (y * width ) + x;
        const position = `${((x - (width / 2)) * WALL_SIZE)} 1.5 ${(y - (height / 2)) * WALL_SIZE}`;
        // if the number is 1 - 4, create a wall
        const intData = parseInt(data.map[i]);
        if (intData === 1 || intData == 2 || intData === 3 || intData === 4) {
          var wall = document.createElement('a-box');
          el.appendChild(wall);

          wall.setAttribute('width', WALL_SIZE);
          wall.setAttribute('height', WALL_HEIGHT);
          wall.setAttribute('depth', WALL_SIZE);
          wall.setAttribute('position', position);
          wall.setAttribute('shadow', 'receive: true');

          // normal wall
          if (intData === 2) {
            wall.setAttribute('color', data.wallColor || schema.wallColor.default);
            wall.setAttribute('material', 'shader: flat; src: ' + (data.wall || schema.wall.default) +`; repeat: ${data.repeat};`);
            wall.setAttribute('static-body', '');
          }

          // secretwall
          else if (intData === 3) {
            wall.setAttribute('color', 'grey');
            // wall.setAttribute('material', 'src: #elegant1; repeat: 1 2');
            wall.setAttribute('static-body', '');
          }

          // brick wall
          else if (intData === 4) {
            wall.setAttribute('color', 'white');
            // wall.setAttribute('material', 'src: #wall-brick; repeat: 2 2');
            wall.setAttribute('static-body', '');
            // wall.setAttribute('width', '0.5');
          }

          else { // black walls
            wall.setAttribute('color', 'white');
            wall.setAttribute('material', 'src: #purple-galaxy;');
            wall.setAttribute('static-body', '');
          }
        }
        // set player position if the number is a 2
        if (intData === 8)  {
          playerPos = position;
        }
      }
    }
  },
});
