import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeSelectItemList, makeSelectItemSnackbar } from 'services/item/selectors';

import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import CustomTabs from 'webComponents/Tabs/CustomTabs';
import _ from 'lodash';
import StoreNameTabPanel from './panels/StoreNameTabPanel';
import ThemeTabPanel from './panels/ThemeTabPanel';
import ItemTabPanel from './panels/ItemTabPanel';
import ShareTabPanel from './panels/ShareTabPanel';
import SupportGuide from './SupportGuide';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function SetupGuide(props) {
  const { itemList } = props;

  const [value, setValue] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isNoItemList = () => _.isEmpty(itemList);

  useEffect(() => {
    // if (!isNoItemList()) {
    //   setValue(2);
    // }
  }, []);

  return (
    <GeneralContainer>
      <Box
        className="setupGuide"
        sx={{
          width: '100%',
          margin: '50px 0 50px',
          padding: '10px 0 36px',
          background: '#E9E9E9',
          flexGrow: '1',
        }}
      >
        <Box
          sx={{
            maxWidth: '890px',
            width: 'calc(100% - 40px)',
            margin: '0 auto',
          }}
        >
          <GeneralTitle pageTitle="Setup guide"></GeneralTitle>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
              marginBottom: '44px',
              height: 'auto',
            }}
          >
            <CustomTabs
              value={value}
              onChange={handleChange}
            >
              <Tab label="Name Your World" icon={<CheckCircleOutlinedIcon color="success" />} iconPosition="start" {...a11yProps(0)} />
              <Tab label="Choose a theme" icon={<CheckCircleOutlinedIcon color="success" />} iconPosition="start" {...a11yProps(1)} />
              <Tab label={isNoItemList() ? 'Add Items' : 'Customize Your World'} icon={isNoItemList() ? <AddIcon /> : <CheckCircleOutlinedIcon color="success" />} iconPosition="start" {...a11yProps(2)} />
              <Tab label="Share your World" icon={<PeopleIcon />} iconPosition="start" {...a11yProps(3)} />
            </CustomTabs>
            <StoreNameTabPanel value={value} index={0} />
            <ThemeTabPanel value={value} index={1} />
            <ItemTabPanel value={value} index={2} isNoItemList={isNoItemList()} />
            <ShareTabPanel value={value} index={3} />
          </Stack>
          <GeneralTitle pageTitle="Support guide"></GeneralTitle>
          <SupportGuide />
        </Box>
      </Box>

    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  itemList: makeSelectItemList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

SetupGuide.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    priceList: PropTypes.arrayOf(PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    })),
    isActive: PropTypes.bool,
  })),
};

export default compose(
  withConnect,
)(SetupGuide);
