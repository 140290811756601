/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-mixed-operators */
export function uuidv4() {
  return 'Wi-xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = parseInt(Math.random() * 16, 10);
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

export function jsonToFormData(jsonObject, form, namespace) {
  const formData = form || new FormData();
  for (const property in jsonObject) {
    if (jsonObject.hasOwnProperty(property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (jsonObject[property] instanceof Date) {
        formData.append(formKey, jsonObject[property].toISOString());
      } else if (typeof jsonObject[property] === 'object' && !(jsonObject[property] instanceof File)) {
        jsonToFormData(jsonObject[property], formData, formKey);
      } else {
        formData.append(formKey, jsonObject[property]);
      }
    }
  }
  return formData;
}
