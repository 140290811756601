import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getChannelInfoSuccess,
  getChannelInfoFail,
} from './actions';
import {
  GET_CHANNEL_INFO,
} from './constants';

const api = API.create();

export function* getChannelInfo(data) {
  const result = yield call(api.channel.get, data.data);
  if (result.ok) {
    yield put(getChannelInfoSuccess(result.data.data));
    // yield data.callback(result.data.data);
  } else {
    yield put(getChannelInfoFail(result.data.error));
  }
}

export default function* channelSaga() {
  yield all([
    takeLatest(GET_CHANNEL_INFO, getChannelInfo),
  ]);
}
