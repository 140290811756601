/* eslint-disable */
import nipplejs from 'nipplejs';

const style =
  'position:fixed;display:block;width:100px;height:100px;right:20px;bottom:20px;background-color:#c8c8c880;z-index:20';

function initJoystick() {
  // create element
  const d = document.createElement('DIV');
  d.setAttribute('id', 'np');
  d.setAttribute('style', style);
  document.querySelector('body').appendChild(d);

  // create text overlay
  const p = document.createElement('p');
  p.setAttribute(
    'style',
    'text-align: center;margin-top:40px;font-size:12px Roboto; opacity:.5;'
  );
  p.innerHTML = 'hold and drag to move';
  d.appendChild(p);
}

let moveData = '';

// create standard NipplesJS joystick
function createJoystick() {
  initJoystick();
  const options = {
    mode: 'static',
    position: { left: '50%', top: '50%' },
    zone: document.getElementById('np'),
    color: '#0F0000',
    fadeTime: 10,
  };

  const manager = nipplejs.create(options);
  function bindNipple() {
    manager.on('move', (evt, data) => {
      moveData = data;
    });
    manager.on('end', (evt, data) => {
      moveData = '';
    });
  }
  bindNipple();
}

// turn joystick data into WASD movement in AFRAME
let f;
let ang;
let x_vec;
let y_vec;
let cam;
let x = 0;
let y = 0;
let z = 0;

let xRotate = 0;
let yRotate = 0;
let zRotate = 0;

function updatePosition(data) {
  f = data.force;
  ang = data.angle.radian;
  cam = document.getElementById('player');

  console.log(data.position.x);

  x_vec = Math.cos(ang + (3.14 / 180) * cam.getAttribute('rotation').y);
  y_vec = Math.sin(ang + (3.14 / 180) * cam.getAttribute('rotation').y);

  x = cam.getAttribute('position').x + (f / 15) * x_vec;
  y = cam.getAttribute('position').y;
  z = cam.getAttribute('position').z - (f / 15) * y_vec;

  xRotate = cam.getAttribute('rotation').x;
  yRotate = cam.getAttribute('rotation').y;
  zRotate = cam.getAttribute('rotation').z;

  cam.setAttribute('position', `${x} ${y} ${z}`);

  if (data.direction) {
    if (data.direction.angle === 'left') {
      cam.setAttribute('rotation', `${xRotate}, ${yRotate + (f / 2) * 5} ${zRotate}`);
    } else if (data.direction.angle === 'right') {
      cam.setAttribute('rotation', `${xRotate}, ${yRotate - (f / 2) * 5} ${zRotate}`);
    }
  }
}

AFRAME.registerComponent('joystick', {
  init() {
    createJoystick();
  },
  tick(time, timeDelta) {
    if (moveData != '') {
      updatePosition(moveData);
    }
  },
});
