import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getAllItemsSuccess,
  getAllItemsFail,
  addItemSuccess,
  addItemFail,
  editItemSuccess,
  editItemFail,
  deactivateItemSuccess,
  deactivateItemFail,
  updateItemsLocationSuccess,
  updateItemsLocationFail,
  editItemFromWorldSuccess,
  editItemFromWorldFail,
} from './actions';
import {
  GET_ALL_ITEMS,
  ADD_ITEM,
  EDIT_ITEM,
  DEACTIVTATE_ITEM,
  UPDATE_ITEMS_LOCATION,
  EDIT_ITEM_FROM_WORLD,
} from './constants';

const api = API.create();

export function* getAllItems(data) {
  const { storeId } = data;
  const result = yield call(api.item.getAll, storeId);
  if (result.ok) {
    yield put(getAllItemsSuccess(result.data));
  } else {
    yield put(getAllItemsFail(result.data.error));
  }
}

export function* addItem(data) {
  const { callback } = data;
  const result = yield call(api.item.create, data.data);
  if (result.ok) {
    yield put(addItemSuccess(result.data));
    callback();
  } else {
    yield put(addItemFail(result.data.error.message));
  }
}

export function* editItem(data) {
  const { index, callback } = data;
  const result = yield call(api.item.update, data.data);
  if (result.ok) {
    yield put(editItemSuccess(index, result.data));
    callback();
  } else {
    yield put(editItemFail(result.data.error.message));
  }
}

export function* deactivateItem(data) {
  const { itemId, callback } = data;
  const result = yield call(api.item.deactivate, itemId);
  if (result.ok) {
    yield put(deactivateItemSuccess(result.data));
    callback();
  } else {
    yield put(deactivateItemFail(result.data.error.message));
  }
}

export function* updateItemsLocation(data) {
  const { storeId, items } = data;
  const result = yield call(api.item.location, { storeId, items });
  if (result.ok) {
    yield put(updateItemsLocationSuccess(items));
  } else {
    yield put(updateItemsLocationFail(result.data.error.message));
  }
}

export function* editItemFromWorld(data) {
  const { items } = data.data;
  const result = yield call(api.item.updateFromWorld, { items });
  if (result.ok) {
    yield put(editItemFromWorldSuccess(result.data));
    // callback();
  } else {
    yield put(editItemFromWorldFail(result.data.error.message));
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(GET_ALL_ITEMS, getAllItems),
    takeLatest(ADD_ITEM, addItem),
    takeLatest(EDIT_ITEM, editItem),
    takeLatest(DEACTIVTATE_ITEM, deactivateItem),
    takeLatest(UPDATE_ITEMS_LOCATION, updateItemsLocation),
    takeLatest(EDIT_ITEM_FROM_WORLD, editItemFromWorld),
  ]);
}
