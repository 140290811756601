import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectTemplateDomain = (state) => state.template || initialState;
export const makeSelectTemplateList = () => createSelector(selectTemplateDomain, _.property('templateList'));
export const makeSelectTemplateLoading = () => createSelector(selectTemplateDomain, _.property('loading'));
export const makeSelectTemplateError = () => createSelector(selectTemplateDomain, _.property('error'));
export const makeSelectTemplateOutletLoading = () => createSelector(selectTemplateDomain, _.property('outletLoading'));
export const makeSelectTemplateCurrent = () => createSelector(selectTemplateDomain, _.property('current'));
