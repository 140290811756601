// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gua3gTW_6e4eCDcPXEVm .BcP5onA3jlRaCtqIxsJr .k9Fu7gOCWQX4K68P0S2_{display:flex;justify-content:space-between;align-items:center;margin-bottom:8px}.Gua3gTW_6e4eCDcPXEVm .BcP5onA3jlRaCtqIxsJr .sGnnyPAe652alKeZwyKE{display:flex;width:100%;align-items:center;justify-content:center;max-width:884px;background:#fde2e4;border:1px solid #d9d9d9;margin-bottom:25px;height:308px}.Gua3gTW_6e4eCDcPXEVm .BcP5onA3jlRaCtqIxsJr .sGnnyPAe652alKeZwyKE canvas{position:absolute;width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/themes/EditThemes/components/AssistantTabPanel.module.scss"],"names":[],"mappings":"AAEI,kEACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAEF,kEACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,YAAA,CACA,yEACE,iBAAA,CACA,UAAA","sourcesContent":[".assistantTabPanel {\n  .fileUploaded {\n    .titleGroup {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-bottom: 8px;\n    }\n    .imgWrapper {\n      display: flex;\n      width: 100%;\n      align-items: center;\n      justify-content: center;\n      max-width: 884px;\n      background: #fde2e4;\n      border: 1px solid #D9D9D9;\n      margin-bottom: 25px;\n      height: 308px;\n      canvas {\n        position: absolute;\n        width: 100%;        \n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assistantTabPanel": `Gua3gTW_6e4eCDcPXEVm`,
	"fileUploaded": `BcP5onA3jlRaCtqIxsJr`,
	"titleGroup": `k9Fu7gOCWQX4K68P0S2_`,
	"imgWrapper": `sGnnyPAe652alKeZwyKE`
};
export default ___CSS_LOADER_EXPORT___;
