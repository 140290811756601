import React, { useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Typography, InputAdornment, IconButton,
} from '@mui/material';
import _ from 'lodash';
import Backbutton from 'webComponents/BackButton/Backbutton';
import LoadingButton from '@mui/lab/LoadingButton';
import OutlinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import Stepper from 'webComponents/Stepper/Stepper';
import { createUser } from 'services/user/actions';
import { makeSelectUserError, makeSelectUserLoading } from 'services/user/selectors';
import { makeSelectOnboardingStoreName, makeSelectSelectedStoreTheme } from 'services/store/selectors';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import { isErrorOccurredIn } from 'utils/error';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { conversionAccountId, conversionEventId, googleConversion } from 'utils/google.utils';

import './Register.scss';

import doubleLogoImg from '../../../../assets/Onboarding/logo/double_logo.png';

function Register(props) {
  const {
    handleNext, name, error, selectedStoreTheme, handlePrevious, loading,
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSumbit = () => {
    googleConversion(conversionEventId.signUp);
    const clonedSelectedTheme = _.cloneDeep(selectedStoreTheme);
    clonedSelectedTheme.name = name;
    props.createUser({
      email, password, store: clonedSelectedTheme, themeId: selectedStoreTheme._id,
    }, handleNext);
  };

  return (
    <div className="page-register">
      <div className="page-register__container">
        <div className="page-register__container__left"></div>
        <div className="page-register__container__right">
          <Backbutton
            className="page-register__container__right__backButton"
            onClick={() => handlePrevious()}
          />
          <div className="page-register__container__right__wrapper">
            <img className="page-register__container__right__wrapper__logo logo" src={doubleLogoImg} alt="" />
            <Typography
              className="page-register__container__right__wrapper__header header"
              variant="h5"
            >
              Create an account to save your store
            </Typography>
            <OutlinedTextInput
              className="page-register__container__right__wrapper__email input"
              error={isErrorOccurredIn('email')}
              id="outlined-basic"
              variant="outlined"
              placeholder="Email"
              autoFocus
              fullWidth
              onChange={handleEmailChange}
            />
            <Typography
              className="page-register__container__right__wrapper__email error"
              variant="caption"
              display="block"
              gutterBottom
            >
              <ErrorComponet error={error} objectKey="email" />
            </Typography>
            <OutlinedTextInput
              className="page-register__container__right__wrapper__password input"
              error={isErrorOccurredIn('password')}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              fullWidth
              onChange={handlePasswordChange}
              sx={{
                paddingRight: '3px',
                border: '0',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#979797',
                },
                '& .MuiOutlinedInput-input': {
                  borderColor: 'transparent',
                },
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
            <Typography
              className="page-register__container__right__wrapper__email error"
              variant="caption"
              display="block"
              gutterBottom
            >
              <ErrorComponet error={error} objectKey="password" />
            </Typography>
            <LoadingButton
              className="page-register__container__right__wrapper__button"
              variant="contained"
              fullWidth
              onClick={handleSumbit}
              sx={{
                height: '56px',
                marginBottom: '56px',
                fontSize: '18px',
              }}
              loading={loading}
            >
              Sign Up
            </LoadingButton>
            <Stepper className="page-register__container__right__stepper" activeStep={2} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  name: makeSelectOnboardingStoreName(),
  error: makeSelectUserError(),
  selectedStoreTheme: makeSelectSelectedStoreTheme(),
  loading: makeSelectUserLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createUser,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Register.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  createUser: PropTypes.func.isRequired,
  selectedStoreTheme: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(Register);
