import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import styles from './TabPanel.module.scss';

export default function TabPanel(props) {
  const {
    children,
    value,
    index,
    className,
  } = props;

  return (
    <div
      className={`${className && className} ${styles.tabPanel}`}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};
