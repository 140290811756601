/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import _ from 'lodash';
import { DEAUTHENTICATE } from 'services/auth/constants';
import { DEMODATA } from './demo';
import {
  ADD_ITEM,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAIL,
  EDIT_ITEM,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_FAIL,
  MASS_EDIT_ITEM,
  GET_ALL_ITEMS,
  GET_ALL_ITEMS_SUCCESS,
  GET_ALL_ITEMS_FAIL,
  DEACTIVTATE_ITEM,
  DEACTIVTATE_ITEM_SUCCESS,
  DEACTIVTATE_ITEM_FAIL,
  UPDATE_ITEMS_LOCATION,
  UPDATE_ITEMS_LOCATION_SUCCESS,
  UPDATE_ITEMS_LOCATION_FAIL,
  SET_ITEM_SHOW_SNACKBAR,
  SET_ITEM_SHOW_MODAL,
  SNACKBAR_TYPE,
} from './constants';

export const initialState = {
  list: [],
  loading: false,
  outletLoading: false,
  error: null,
  isShowSnackbar: false,
  snackbarTitle: '',
  snackbar: {
    title: '',
    isShow: false,
    type: null,
  },
  modal: {
    isShow: false,
    type: null,
  },
};

const itemReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ADD_ITEM:
      draft.loading = true;
      draft.error = null;
      // draft.list.push(action.data);
      // localStorage.setItem('itemList', JSON.stringify(draft.list));
      break;
    case ADD_ITEM_SUCCESS:
      draft.list.push(action.data.data.items[0]);
      draft.loading = false;
      draft.error = null;
      draft.isShowSnackbar = true;
      draft.snackbar = {
        title: 'Successfully added item. You can now place the item inside your store.',
        isShow: true,
        type: SNACKBAR_TYPE.ADD,
      };
      break;
    case ADD_ITEM_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    case EDIT_ITEM:
      draft.loading = true;
      draft.error = null;
      break;
    case EDIT_ITEM_SUCCESS: {
      const { index } = action;
      const { items } = action.data.data;
      const tempList = _.cloneDeep(draft.list);
      const editedItem = _.findIndex(draft.list, ['_id', index]);
      // eslint-disable-next-line prefer-destructuring
      tempList[editedItem] = items[0];
      draft.list = tempList;
      draft.error = null;
      draft.loading = false;
      draft.snackbar = {
        title: 'Successfully updated',
        type: SNACKBAR_TYPE.EDIT,
        isShow: true,
      };
      break;
    }
    case EDIT_ITEM_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    case MASS_EDIT_ITEM:
      draft.list = action.data;
      draft.error = null;
      // localStorage.setItem('itemList', JSON.stringify(draft.list));
      break;
    case GET_ALL_ITEMS:
      draft.outletLoading = true;
      // draft.list = [];
      // localStorage.setItem('itemList', JSON.stringify([]));
      break;
    case GET_ALL_ITEMS_SUCCESS: {
      const { items } = action.data.data;
      if (!_.isEmpty(items)) {
        // draft.list = DEMODATA.concat(items);
        draft.list = items;
      }
      draft.error = null;
      draft.outletLoading = false;
      break;
    }
    case GET_ALL_ITEMS_FAIL:
      draft.error = action.error;
      draft.outletLoading = false;
      break;
    case DEACTIVTATE_ITEM:
      draft.loading = true;
      break;
    case DEACTIVTATE_ITEM_SUCCESS: {
      const { _id } = action.data.data;
      const temp = _.cloneDeep(draft.list);
      _.remove(temp, (item) => item._id === _id);
      temp.isActive = false;
      draft.list = temp;
      draft.error = null;
      draft.loading = false;
      draft.snackbar = {
        title: 'Successfully deleted',
        isShow: true,
        type: SNACKBAR_TYPE.DELETE,
      };
      break;
    }
    case DEACTIVTATE_ITEM_FAIL:
      draft.loading = false;
      break;
    case UPDATE_ITEMS_LOCATION:
      draft.loading = true;
      break;
    case UPDATE_ITEMS_LOCATION_SUCCESS: {
      const { items } = action.data;
      action.data.forEach((item) => {
        const index = _.findIndex(draft.list, { _id: item.id });
        draft.list[index].hidden = item.hidden;
        draft.list[index].location = item.location;
        draft.list[index].color = item.color;
      });
      draft.loading = false;
      draft.snackbar = {
        title: 'Successfully placed item. See your store.',
        isShow: true,
        type: SNACKBAR_TYPE.PLACE,
      };
      break;
    }
    case UPDATE_ITEMS_LOCATION_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    case DEAUTHENTICATE:
      draft.list = [];
      break;
    case SET_ITEM_SHOW_SNACKBAR:
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = false;
      draft.snackbar = temp;
      break;
    case SET_ITEM_SHOW_MODAL:
      draft.isShowModal = action.bool;
      break;
    default:
  }
});

export default itemReducer;
