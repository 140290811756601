/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import {
  Typography, FormControl, Stack, Avatar, Grid, Button,
} from '@mui/material';
import OutlinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import { makeSelectUser, makeSelectUserSnackbar, makeSelectUserError } from 'services/user/selectors';
import { updateUser, setShowSnackbar } from 'services/user/actions';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import _ from 'lodash';

function Profile(props) {
  const { snackbar, error } = props;
  const [user, setUser] = useState(props.user);
  const [isEdit, setIsEdit] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
  }, []);

  const getIsDisable = () => isEdit && _.isNull(error);

  const onSave = () => {
    const { _id, username, email } = user;
    const disabled = getIsDisable();
    if (!disabled) { // save
      props.updateUser({ _id, username, email });
    }
    return setIsEdit(!disabled);
  };

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Profile"></GeneralTitle>
      <GeneralContent>
        <Container component="main" maxWidth="md" sx={{ mb: 6 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Avatar
                  alt={user.username}
                  sx={{ width: 156, height: 156 }}
                >
                  {user.username}
                </Avatar>
              </Grid>
              <Grid item xs={6} md={9}>
                <Stack spacing={3}>
                  <FormControl fullWidth disabled={getIsDisable()}>
                    <Typography component="p" variant="menuAndInput" sx={{ marginBottom: '8px' }}>
                      Username
                    </Typography>
                    <OutlinedTextInput
                      id="outlined-basic"
                      variant="outlined"
                      name="username"
                      value={user.username}
                      onChange={handleChange}
                    />
                    <ErrorComponet error={error} objectKey="username" />
                  </FormControl>
                  <FormControl fullWidth disabled={getIsDisable()}>
                    <Typography component="p" variant="menuAndInput" sx={{ marginBottom: '8px' }}>
                      Email
                    </Typography>
                    <OutlinedTextInput
                      id="outlined-basic"
                      variant="outlined"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                    <ErrorComponet error={error} objectKey="email" />
                  </FormControl>
                </Stack>
                <Button size="large" variant="contained" sx={{ marginTop: '50px', ml: 0 }} onClick={() => onSave()}>{getIsDisable() ? 'Edit' : 'Save'}</Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </GeneralContent>
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  snackbar: makeSelectUserSnackbar(),
  error: makeSelectUserError(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateUser,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Profile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string,
  }),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  updateUser: PropTypes.func.isRequired,
  setShowSnackbar: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Profile);
