/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect,
} from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  useNavigate, useLocation, useSearchParams, json,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import { Typography } from '@mui/material';
import NormalButton from 'webComponents/NormalButton/NormalButton';
// import { ColorPicker } from 'material-ui-color';
import { makeSelectItemList, makeSelectItemIsShowSnackbar, makeSelectItemSnackbar } from 'services/item/selectors';
import { updateItemsLocation, setShowSnackbar } from 'services/item/actions';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import { SNACKBAR_TYPE } from 'services/item/constants';
import { makeSelectStorePublicId } from 'services/store/selectors';
import Map from './Map';

import styles from './PlaceOnMapItem.module.scss';

function PlaceOnMapItem(props) {
  const { itemList, snackbar, publicId } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState(_.cloneDeep(itemList));
  const [mapCheckboxList, setMapCheckboxList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const storeId = searchParams.get('sid');
  const colorFilterList = [
    '000000', // black
    'FFFFFF', // white
    'ffffff',
  ];

  const goToItemsPage = () => {
    props.setShowSnackbar(false);
    navigate(`/items${location.search}`);
  };

  const goToStorePage = () => {
    window.open(`/s/${publicId}`, '_blank');
  };

  const pinItemOnMap = (mapIndex) => {
    const newItem = [...mapCheckboxList];
    newItem[selectedIndex].location = _.toString(mapIndex);
    setItems(newItem);
  };

  const generateColor = () => {
    const color = `${Math.floor(Math.random() * 16777215).toString(16).padStart(6, 0)}`;
    if (colorFilterList.includes(color)) { return generateColor(); }
    colorFilterList.push(color);
    return `#${color}`;
  };

  const initListCheck = () => {
    const list = [];
    items.filter((i) => i.isActive).forEach((item, index) => {
      list.push({
        id: item._id,
        title: item.title,
        color: item.color,
        location: item.location,
        hidden: _.has(item, 'hidden') ? item.hidden : false,
      });
    });
    setMapCheckboxList(list);
  };

  const generateColorForItems = () => {
    const newItem = [...items];
    newItem.forEach((item, index) => {
      if (_.isEmpty(newItem[index].color)) newItem[index].color = generateColor();
    });
    setItems(newItem);
  };

  const onClickItem = (index) => {
    setSelectedIndex(index);
  };

  const handleItemCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const newMapCheckboxList = [...mapCheckboxList];
    newMapCheckboxList[index].hidden = !checked;
    setMapCheckboxList(newMapCheckboxList);
  };

  const saveItemsLocation = () => {
    props.updateItemsLocation({
      storeId,
      items: mapCheckboxList,
    });
  };

  useEffect(() => {
    generateColorForItems();
    initListCheck();
  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle
        pageTitle="Place Items in Store"
        isNeedBackButton
        // pageSubtitle="Click the item on the ‘Item List’ and then click the map to place it in your store"
      />
      <GeneralContent display="flex">
        <div className={styles.mainSection}>
          <Box
            className={`form ${styles.form}`}
            component="form"
            noValidate
          >
            <div className={`formFields ${styles.formFields}`}>
              <div className={`formCheckBox ${styles.formCheckBox}`}>
                {/* <Typography variant="subtitle1" component="h6" sx={{ visibility: 'hidden' }}>Preview</Typography> */}
                <div className={styles.stepTitle}>
                  <div className={styles.circle}>1</div>
                  <Typography variant="h3" component="h6">Select an Item</Typography>
                </div>
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    border: '1px solid #BFBFBF',
                    borderRadius: '4px',
                  }}
                >
                  {mapCheckboxList.map((item, index) => (
                    <ListItem
                      disablePadding
                      key={`${item.id}-${item.title}`}
                      alignItems="center"
                    >
                      <ListItemButton
                        selected={index === selectedIndex}
                        onClick={() => onClickItem(index)}
                      >
                        <Checkbox
                          checked={mapCheckboxList[index] ? !mapCheckboxList[index].hidden : false}
                          onChange={(event) => handleItemCheckboxChange(event, index)}
                        />
                        <ListItemText
                          primary={item.title}
                          sx={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          }}
                        />
                        <Box
                          sx={{
                            flex: '0 0 auto',
                            width: '18px',
                            height: '18px',
                            borderRadius: '4px',
                            backgroundColor: item.color,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                {/* <WhiteNormalButton
                  text="＋ Add Item"
                  callback={() => addItem()}
                /> */}
              </div>
              <div className={`formMap ${styles.formMap}`}>
                <div className={styles.stepTitle}>
                  <div className={styles.circle}>
                    2
                  </div>
                  <Typography variant="h3" component="h6">Click inside Store Map to place</Typography>
                </div>
                <div className={`mapWrapper ${styles.mapWrapper}`}>
                  <Map pinItemOnMap={pinItemOnMap} items={mapCheckboxList} selectedIndex={selectedIndex} />
                  <Stack sx={{ paddingTop: '20px' }} direction="row" alignItems="center">
                    <Box
                      sx={{
                        width: '18px', height: '18px', backgroundColor: 'black', mr: 1,
                      }}
                    />
                    <Typography variant="caption" component="p">
                      Black lines represent walls, not allow to place item on walls.
                    </Typography>
                  </Stack>
                </div>
              </div>
            </div>
          </Box>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={() => goToItemsPage()}
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.buttonGroup}>
            <NormalButton text="Save" size="large" callback={() => saveItemsLocation()} />
          </div>
        </div>
      </GeneralContent>
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        anchorOrigin={snackbar.type === SNACKBAR_TYPE.PLACE ? {
          vertical: 'top',
          horizontal: 'center',
        } : {
          vertical: 'bottom',
          horizontal: 'left',
        }}
        handleClose={() => props.setShowSnackbar(false)}
      >
        {(snackbar.type === SNACKBAR_TYPE.PLACE)
          ? (
            <div>
              Successfully placed item.
              {' '}
              <span
                onClick={() => goToStorePage()}
                onKeyPress={() => {
                  goToStorePage();
                }}
                tabIndex={0}
                role="button"
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                See your store.
              </span>
            </div>
          )
          : null}
      </SimpleSnackbar>
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  itemList: makeSelectItemList(),
  snackbar: makeSelectItemSnackbar(),
  publicId: makeSelectStorePublicId(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateItemsLocation,
    setShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

PlaceOnMapItem.propTypes = {
  setShowSnackbar: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    priceList: PropTypes.arrayOf(PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    })),
    isActive: PropTypes.bool,
  })),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
  updateItemsLocation: PropTypes.func.isRequired,
  publicId: PropTypes.string.isRequired,
};

export default compose(
  withConnect,
)(PlaceOnMapItem);
