import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  Button, Typography,
} from '@mui/material';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import { useNavigate } from 'react-router-dom';
import {
  makeSelectStoreId,
} from 'services/store/selectors';
import Paper from '@mui/material/Paper';

const testJson = [
  {
    type: 0,
    title: 'Typical Size',
    items: [
      { title: 'Small', description: 'Suitable for 1-5 items' },
      { title: 'Medium', description: 'Suitable for 6-10 items' },
      { title: 'Large', description: 'Suitable for 11-20 items' },
    ],
  },
  {
    type: 1,
    title: 'Featured Layout',
    items: [
      { title: 'Shopping mall', description: 'Mall' },
      { title: 'Gallery', description: 'Best fit for NFTs and Images' },
      { title: 'Office', description: 'Classic office layout' },
    ],
  },
];

function Layouts(props) {
  const { storeId } = props;
  const navigate = useNavigate();

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Layouts">
      </GeneralTitle>
      <GeneralContent display="flex" elevation={0}>
        <Stack
          spacing={10}
        >
          {testJson && testJson.map((layoutType) => (
            <div key={layoutType.type}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5">{layoutType.title}</Typography>
                </Grid>
                {layoutType.items.map((typeItem) => (
                  <Grid
                    item
                    xs={3}
                    key={typeItem.title}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        height: 175,
                        width: 175,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <div>
                        <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>{typeItem.title}</Typography>
                        <Typography variant="body1" component="h6">{typeItem.description}</Typography>
                      </div>
                    </Paper>

                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
          <Paper
            elevation={3}
            sx={{
              height: 175,
              width: 175,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/layouts/custom?sid=${storeId}`);
            }}
          >
            <Typography variant="h5" component="h5">Customize your layout</Typography>
          </Paper>
        </Stack>
      </GeneralContent>
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  storeId: makeSelectStoreId(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Layouts.propTypes = {
  // userId: PropTypes.string,
  storeId: PropTypes.string.isRequired,
};

export default compose(
  withConnect,
)(Layouts);
