/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getStoreByPublicIdWithItems } from 'services/store/actions';
import { makeSelectStorePublicStoreDetail, makeSelectStoreError, makeSelectStoreLoading } from 'services/store/selectors';
import { makeSelectChannelInfo, makeSelectChannelLoading, makeSelectChannelError } from 'services/channel/selectors';
import SimpleBackdrop from 'webComponents/SimpleBackdrop/SimpleBackdrop';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';
import { getChannelInfo } from 'services/channel/actions';

import TWEEN from '@tweenjs/tween.js';

import InstructionLegend from 'webComponents/InstructionLegend/InstructionLegend';
import CustomEntity from 'webComponents/CustomEntity/CustomEntity';
import infoPanel from 'assets/component/3b4174ec8224442b47b3418553b75556.jpeg';
import KeymakerChatBox from 'webComponents/keymaker/KeymakerChatBox';
// import Robot from './assets/RobotExpressive/RobotExpressive.glb';
import bitcoinIcon from 'assets/bitcoin_icon.png';
import ethIcon from 'assets/eth_icon.png';
import { isIgnoreAsset, isIgnoreBackgroundMusic } from 'utils/asset.utils';
import 'networked-aframe';
import { generateCustomItemList } from 'utils/item.utils';
import { MultiplayerRigTemplate, MultiplayerAvatarTemplate, MultiplayerAvatarBody, TestTemplate } from './components/Multiplayer';
import { PublicStoreButton } from './components/PublicStoreButton'
import AgoraContainer from './components/agora/AgoraContainer';
import DefaultAvatarHand from 'assets/avatar/metaPeople_a_leftHand.glb';
import { VRButton, EnvironmentComponent, FloorComponent, WallComponent } from './components/PublisStoreComponents';
import { setBackgroundMusic } from 'utils/public-store.utils';

import './PublicStore.css';

import 'components/map';
import 'components/rounded';
import 'components/info-panel';
import 'components/keymaker-panel';

import 'components/chatbot/tour-guide';
import 'components/chatbot/chatbox-info-panel';
import 'components/chatbot/chatbox-look-at';
import 'components/chatbot/chatbox';
import 'components/popup-panel';
import LeftBottomIconList from './components/LeftBottomIconList';

require('aframe');
require('aframe-extras');

const isWebxr = process.env.NODE_ENV !== 'production';

function PublicStoreMultiplayer(props) {
  const { current, error, loading } = props;
  const [isShowKeymakerChatBox, setIsShowKeymakerChatBox] = useState(false);
  const [isShowInstruction, setIsShowInstruction] = useState(true);
  const [isShowChat, setIsShowChat] = useState(false);
  const storePublicId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  window.TWEEN = TWEEN;

  function onConnect() {
    console.log('onConnect', new Date());
  }

  function getRandomAvatar() {
    const head = <a-asset-item key="defaultAvatarHead" id="defaultAvatarHead" src={`https://double-storage.s3.ap-southeast-1.amazonaws.com/model/avatars/metaPeople_${"abcdefghijkl".split('')[Math.floor(Math.random() * 12)]}_head.glb`}></a-asset-item>
    const body = <a-asset-item key="defaultAvatarBody" id="defaultAvatarBody" src={`https://double-storage.s3.ap-southeast-1.amazonaws.com/model/avatars/metaPeople_${"abcdefghijkl".split('')[Math.floor(Math.random() * 12)]}.glb`}></a-asset-item>
    return [head, body];
  }

  useEffect(() => {
    try {
      props.getStoreByPublicIdWithItems(storePublicId);
      // Add event listeners to the client.
      NAF.schemas.getComponentsOriginal = NAF.schemas.getComponents;
      NAF.schemas.getComponents = (template) => {
        if (!NAF.schemas.hasTemplate('#avatar-template')) {
          NAF.schemas.add({
            template: '#avatar-template',
            components: [
              'position',
              'rotation',
            ]
          });

          if (!NAF.schemas.hasTemplate('#body-template')) {
            NAF.schemas.add({
              template: '#body-template',
              components: ['position']
            });
          }

          if (!NAF.schemas.hasTemplate('#left-hand-template')) {
            NAF.schemas.add({
              template: '#left-hand-template',
              components: [
                {
                  component: "position",
                  requiresNetworkUpdate: NAF.utils.vectorRequiresUpdate(0.001)
                },
                {
                  component: "rotation",
                  requiresNetworkUpdate: NAF.utils.vectorRequiresUpdate(0.5)
                },
                // 'networked-hand-controls'
              ]
            });
          }

          if (!NAF.schemas.hasTemplate('#right-hand-template')) {
            NAF.schemas.add({
              template: '#right-hand-template',
              components: [
                {
                  component: "position",
                  requiresNetworkUpdate: NAF.utils.vectorRequiresUpdate(0.001)
                },
                {
                  component: "rotation",
                  requiresNetworkUpdate: NAF.utils.vectorRequiresUpdate(0.5)
                },
                // 'networked-hand-controls'
              ]
            });
          }
        }
        const components = NAF.schemas.getComponentsOriginal(template);
        return components;
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (error) {
    return (<Alert severity="error"> {error} </Alert>);
  }
  if (!storePublicId) {
    return (<Alert severity="error"> World Public Id error </Alert>);
  }
  
  return (
    (loading || _.isEmpty(current)) ? <SimpleBackdrop open={loading} />
      : (
        <div className="App">
          <header className="App-header">
          </header>
            <div>
              <Stack sx={{
                  position: 'absolute',
                  left: '10px',
                  top: '10px',
                  zIndex: '100',
                }}
                spacing={1}
              >
                <PublicStoreButton
                  onClick={() => { document.querySelector('.a-enter-vr-button').click();}}
                >
                  VR
                </PublicStoreButton>
                <AgoraContainer
                  current={current} 
                  setIsShowChat={setIsShowChat}
                  isShowChat={isShowChat}
                />
              </Stack>
              <LeftBottomIconList 
                isShowInstruction={isShowInstruction}
                setIsShowInstruction={setIsShowInstruction} 
                setIsShowChat={setIsShowChat}
                isShowChat={isShowChat}
              />
              <InstructionLegend 
                name={current.name}
                isShowKeymakerChatBox={isShowKeymakerChatBox}
                isShowInstruction={isShowInstruction}
                setIsShowKeymakerChatBox={setIsShowKeymakerChatBox}
                setIsShowInstruction={setIsShowInstruction}
              />
              {isWebxr && <KeymakerChatBox name={current.name} />}
              <a-scene
                // light="defaultLightsEnabled: true"
                loading-screen="dotsColor: blue; backgroundColor: black"
                // environment="preset: studio"
                // cursor="rayOrigin: mouse"
                // joystick
                networked-scene={`
                room: ${storePublicId};
                debug: false;
                adapter: socketio;
                serverURL: https://socket.double.store/;`}
                sound={setBackgroundMusic(current)}
              >
                <a-assets>
                  {/* {Math.floor(Math.random()*2) === 0 ? <a-asset-item id="defaultAvatarHead" src={DefaultAvatarHead}></a-asset-item> : <a-asset-item id="defaultAvatarHead" src={DefaultAvatarHeadA}></a-asset-item>}
                  {Math.floor(Math.random()*2) === 0 ? <a-asset-item id="defaultAvatarBody" src={DefaultAvatarBody}></a-asset-item> : <a-asset-item id="defaultAvatarBody" src={DefaultAvatarBodyA}></a-asset-item>}                 */}
                  {getRandomAvatar()}
                  <a-asset-item id="defaultAvatarLeftHand" src={DefaultAvatarHand}></a-asset-item>
                  <a-asset-item id="defaultAvatarRightHand" src={DefaultAvatarHand}></a-asset-item>
                  
                  <img id="pexels_anni" src={current.theme[0].floor} crossOrigin="anonymous" />
                  {/* <img id="background_360_sky" src={current.theme[0].environment} crossOrigin="anonymous" /> */}
                  <img id="info_panel_1" src={infoPanel} crossOrigin="anonymous" />
                  <img id="ethIcon" src={ethIcon} crossOrigin="anonymous" />
                  <img id="bitcoinIcon" src={bitcoinIcon} crossOrigin="anonymous" />
                  {current.theme[0].wall && <img id="wall" src={current.theme[0].wall} crossOrigin="anonymous" />}
                  {!isIgnoreBackgroundMusic(current.backgroundMusic) && 
                    <audio id="backgroundMusicAudio" src={current.backgroundMusic[0].url} preload="auto" crossOrigin="anonymous"></audio>
                  }
                  <MultiplayerRigTemplate />
                  <MultiplayerAvatarTemplate />
                </a-assets>
                {EnvironmentComponent(current.theme[0].environment)}
                {FloorComponent(current.theme[0].floor)}
                {WallComponent(current.theme[0].wall, current.map)}

                {generateCustomItemList(current).map((item) => (
                  <CustomEntity
                    key={item._id || item.id}
                    item={item}
                  />
                ))}
                <a-entity
                  id="shop_logo"
                  position="-0.5 2.5 -4.25"
                  geometry="primitive: plane; height: 2; width: 3;"
                  material={`shader: flat; src: ${`url(${current.logo})`}; side: double`}
                  rotation="0 0 0"
                />

                {/* Player */}
                <a-entity
                  id="rig"
                  movement-controls="speed: 0.3; fly: false"
                  networked="template:#rig-template;"
                >
                  <a-entity
                    id="player"
                    camera="fov:80"
                    look-controls="touchEnabled: true"
                    position="0 1.6 0"
                    networked="template:#avatar-template; attachTemplateToLocal:false"
                  >
                    {/* <a-entity raycaster="showLine: true; far: 100; lineColor: red; lineOpacity: 0.5"></a-entity> */}
                  </a-entity>
                  <a-entity id="body" position="0 1.6 0" networked="template:#body-template;attachTemplateToLocal:false;"></a-entity>
                  <a-entity id="left-hand" position="0 1.6 0" networked="template:#left-hand-template;attachTemplateToLocal:false;"></a-entity>
                  <a-entity id="right-hand" position="-0.525 1.6 0" networked="template:#right-hand-template;attachTemplateToLocal:false;"></a-entity>
                  {/* <a-entity laser-controls="hand: left" />
                  <a-entity laser-controls="hand: right" /> */}
                </a-entity>

              {!isIgnoreAsset(current.theme[0].assistant)
              && (
              <a-entity
                id="tour-guide-bot"
                position="-1.5 0.1 9"
                gltf-model={`${current.theme[0].assistant ? current.theme[0].assistant[0].url : '#tour-guide-bot-glb'}`}
                scale={`${current.theme[0].assistant ? `${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio} ${current.theme[0].assistant[0].ratio}` : '1 1 1'}`}
                animation-mixer="clip:Wave"
                chatbox=""
                // chatbox-info-panel={`content: Welcome to Gamers’ Paradise.\n\nLevel up your character with elite gear, rare weapons and epic loot.  \n\nLet’s go!; scale: ${current.theme[0].assistant ? current.theme[0].assistant[0].ratio : 1}`}
                tour-guide="#rig"
              />
              )}
              </a-scene>
            </div>
        </div>
      )
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectStorePublicStoreDetail(),
  error: makeSelectStoreError(),
  loading: makeSelectStoreLoading(),
  channelInfo: makeSelectChannelInfo(),
  channelError: makeSelectChannelError(),
  channelLoading: makeSelectChannelLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getStoreByPublicIdWithItems,
    setIsShowKeymakerChat,
    getChannelInfo,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

PublicStoreMultiplayer.propTypes = {
  map: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
    }), PropTypes.number]),
  ),
  loading: PropTypes.bool,
  current: PropTypes.shape({
    name: PropTypes.string,
    map: PropTypes.arrayOf(PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.number),
    })),
    theme: PropTypes.arrayOf(PropTypes.shape({
      assistant: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        ratio: PropTypes.number,
      })),
      environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
      floor: PropTypes.string,
      wall: PropTypes.string,
    })),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.string,
  }),
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  setIsShowKeymakerChat: PropTypes.func,
  getStoreByPublicIdWithItems: PropTypes.func,
  getChannelInfo: PropTypes.func,
};

export default compose(
  withConnect,
)(PublicStoreMultiplayer);
