/* eslint-disable import/extensions */
import React, { useEffect, useRef } from 'react';
// import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';

// eslint-disable-next-line func-names
export default function () {
  const sceneRef = useRef(null);
  const demo = {
    target: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/logo1.mind',
    image: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/logo1.png',
    model: 'https://double-storage.s3.ap-southeast-1.amazonaws.com/model/assistant/default/RobotExpressive.glb',
  };

  const demo1 = {
    target: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.0/examples/image-tracking/assets/card-example/card.mind',
    image: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/card.png',
    model: 'https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/softmind/scene.gltf',
  };

  useEffect(() => {
    const sceneEl = sceneRef.current;
    console.log(sceneEl.systems);
    console.log(sceneEl.systems['mindar-face-system']);
    const arSystem = sceneEl.systems['mindar-face-system'];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR
    });
    return () => {
      arSystem.stop();
    };
  }, []);

  return (
    <a-scene ref={sceneRef} mindar-face="autoStart: false" embedded color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
      <a-assets>
        <a-asset-item id="glassesModel" src="https://double-storage.s3.ap-southeast-1.amazonaws.com/ar/ape_face.glb"></a-asset-item>
      </a-assets>

      <a-camera active="false" position="0 0 0"></a-camera>
      <a-entity mindar-face-target="anchorIndex: 168">
        <a-gltf-model rotation="0 0 0" position="0 -0.4 0" scale="3.8 3.8 3.8" src="#glassesModel"></a-gltf-model>
      </a-entity>
    </a-scene>
  );
}
