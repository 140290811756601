export const listItem = {
  MuiListItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#f3f3f3',
        },
      },
    },
  },
};
