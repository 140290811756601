export const CREATE_STORE = 'CREATE_STORE';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAIL = 'CREATE_STORE_FAIL';
export const GET_STORE_DETAIL = 'GET_STORE_DETAIL';
export const GET_STORE_DETAIL_SUCCESS = 'GET_STORE_DETAIL_SUCCESS';
export const GET_STORE_DETAIL_FAIL = 'GET_STORE_DETAIL_FAIL';
export const GET_STORE_LIST = 'GET_STORE_LIST';
export const GET_STORE_LIST_SUCCESS = 'GET_STORE_LIST_SUCCESS';
export const GET_STORE_LIST_FAIL = 'GET_STORE_LIST_FAIL';
export const UPDATE_STORE_NAME = 'UPDATE_STORE_NAME';
export const UPDATE_ONBOARDING_STORE_NAME = 'UPDATE_ONBOARDING_STORE_NAME';
export const UPDATE_STORE_LOGO = 'UPDATE_STORE_LOGO';
export const UPDATE_STORE_MAP = 'UPDATE_STORE_MAP';
export const UPDATE_STORE_MAP_SUCCESS = 'UPDATE_STORE_MAP_SUCCESS';
export const UPDATE_STORE_MAP_FAIL = 'UPDATE_STORE_MA_FAILP';
export const UPDATE_STORE_MAP_BASE64_IMG = 'UPDATE_STORE_MAP_BASE64_IMG';
export const VALIDATE_STORE_NAME = 'VALIDATE_STORE_NAME';
export const VALIDATE_STORE_NAME_SUCCESS = 'VALIDATE_STORE_NAME_SUCCESS';
export const VALIDATE_STORE_NAME_FAIL = 'VALIDATE_STORE_NAME_FAIL';
export const GET_STORE_BY_NAME = 'GET_STORE_BY_NAME';
export const GET_STORE_BY_NAME_SUCCESS = 'GET_STORE_BY_NAME_SUCCESS';
export const GET_STORE_BY_NAME_FAIL = 'GET_STORE_BY_NAME_FAIL';
export const GET_STORE_BY_NAME_WITH_ITEMS = 'GET_STORE_BY_NAME_WITH_ITEMS';
export const GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS = 'GET_STORE_BY_NAME_WITH_ITEMS_SUCCESS';
export const GET_STORE_BY_NAME_WITH_ITEMS_FAIL = 'GET_STORE_BY_NAME_WITH_ITEMS_FAIL';
export const GET_STORE_BY_PUBLIC_ID_WITH_ITEMS = 'GET_STORE_BY_PUBLIC_ID_WITH_ITEMS';
export const GET_STORE_THEME = 'GET_STORE_THEME';
export const GET_STORE_THEME_SUCCESS = 'GET_STORE_THEME_SUCCESS';
export const GET_STORE_THEME_FAIL = 'GET_STORE_THEME_FAIL';
export const SET_STORE_THEME = 'SET_STORE_THEME';
export const UPDATE_STORE_ASSISTANT = 'UPDATE_STORE_ASSISTANT';
export const UPDATE_STORE_ASSISTANT_SUCCESS = 'UPDATE_STORE_ASSISTANT_SUCCESS';
export const UPDATE_STORE_ASSISTANT_FAIL = 'UPDATE_STORE_ASSISTANT_FAIL';
export const GET_STORE_MAP = 'GET_STORE_MAP';
export const UPDATE_STORE_DETAIL_FROM_ADMIN = 'UPDATE_STORE_DETAIL_FROM_ADMIN';
export const UPDATE_STORE_DETAIL = 'UPDATE_STORE_DETAIL';
export const UPDATE_STORE_DETAIL_SUCCESS = 'UPDATE_STORE_DETAIL_SUCCESS';
export const UPDATE_STORE_DETAIL_FAIL = 'UPDATE_STORE_DETAIL_FAIL';
export const STORE_SHOW_SNACKBAR_BAR = 'STORE_SHOW_SNACKBAR_BAR';
export const UPDATE_STORE_THEME = 'UPDATE_STORE_THEME';
export const UPDATE_STORE_THEME_SUCCESS = 'UPDATE_STORE_THEME_SUCCESS';
export const UPDATE_STORE_THEME_FAIL = 'UPDATE_STORE_THEME_FAIL';
export const UPDATE_STORE_CURRENT_THEME = 'UPDATE_STORE_CURRENT_THEME';
export const SET_LAYOUT_SHOW_SNACKBAR_BAR = 'SET_LAYOUT_SHOW_SNACKBAR_BAR';
export const CREATE_STORE_BY_TEMPLATE = 'CREATE_STORE_BY_TEMPLATE';
export const CREATE_STORE_BY_TEMPLATE_SUCCESS = 'CREATE_STORE_BY_TEMPLATE_SUCCESS';
export const CREATE_STORE_BY_TEMPLATE_FAIL = 'CREATE_STORE_BY_TEMPLATE_FAIL';
export const ADD_BACKGROUND_MUSIC = 'ADD_BACKGROUND_MUSIC';
export const ADD_BACKGROUND_MUSIC_SUCCESS = 'ADD_BACKGROUND_MUSIC_SUCCESS';
export const ADD_BACKGROUND_MUSIC_FAIL = 'ADD_BACKGROUND_MUSIC_FAIL';
export const DELETE_BACKGROUND_MUSIC = 'DELETE_BACKGROUND_MUSIC';
export const DELETE_BACKGROUND_MUSIC_SUCCESS = 'DELETE_BACKGROUND_MUSIC_SUCCESS';
export const DELETE_BACKGROUND_MUSIC_FAIL = 'DELETE_BACKGROUND_MUSIC_FAIL';
export const UPDATE_BACKGROUND_MUSIC = 'UPDATE_BACKGROUND_MUSIC';
export const UPDATE_BACKGROUND_MUSIC_SUCCESS = 'UPDATE_BACKGROUND_MUSIC_SUCCESS';
export const UPDATE_BACKGROUND_MUSIC_FAIL = 'UPDATE_BACKGROUND_MUSIC_FAIL';
export const BACKGROUND_MUSIC_SNACKBAR = 'BACKGROUND_MUSIC_SNACKBAR';
