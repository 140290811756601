import * as React from 'react';
import { InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.menuAndInput,
  color: theme.palette.grey.D015,
  marginBottom: '8px',
  transform: 'scale(1)',
  '&.Mui-focused': {
    color: theme.palette.grey.D015,
  },
}));

export default function CustomInputLabel(props) {
  return (
    <StyledInputLabel {...props} />
  );
}
