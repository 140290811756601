import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from 'webComponents/ListItemText/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import CustomListItemButton from 'webComponents/CustomListItemButton/CustomListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import {
  NavLink, useNavigate, useParams, useLocation,
} from 'react-router-dom';

import {
  makeSelectStoreCurrent,
  makeSelectStoreList,
} from 'services/store/selectors';
import { getStoreId } from 'utils/path.utils';
import MenuHomeSvg from '../assets/icon/menu-home.svg';
import MenuItemsSvg from '../assets/icon/menu-items.svg';
import MenuPaintSvg from '../assets/icon/menu-paint.svg';
import MenuShoppingCartSvg from '../assets/icon/menu-shopping-cart.svg';
import MenuTemplateSvg from '../assets/icon/menu-templates.svg';

const isWebxr = process.env.NODE_ENV !== 'production';

function MainListItems(props) {
  const { storelist } = props;
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const storeId = getStoreId(location.search);

  const onAdvancedClick = () => {
    setOpenAdvanced(!openAdvanced);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openEl = Boolean(anchorEl);
  const onMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line no-shadow
  const onMenuItemClick = (storeId) => {
    window.location.replace(`/home?sid=${storeId}`);
  };

  const currentStoreName = () => {
    const currentStore = storelist.filter((x) => x._id === storeId);
    if (currentStore.length > 0) {
      return `${currentStore[0].name}${currentStore[0].isTemplate ? ' (T)' : ''}`;
    }
    return '';
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <CustomListItemButton
        aria-controls={openEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openEl ? 'true' : undefined}
        onClick={onMenuClick}
        itemPath={currentStoreName()}
      >
        <ListItemText
          primary={currentStoreName()}
          sx={{
            paddingLeft: '8px',
          }}
        />
        <ListItemIcon>
          {/* <img className="leftArrowIcon" src={leftArrowIconImg} alt="" /> */}
          <KeyboardArrowDownIcon fontSize="large" />
        </ListItemIcon>
      </CustomListItemButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openEl}
        onClose={onMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiList-root': {
            minWidth: '200px',
            maxHeight: '500px',
            overflow: 'scroll',
          },
        }}
      >
        {storelist && storelist.filter((x) => x._id !== storeId).map((store) => (
          <MenuItem key={store._id} onClick={() => onMenuItemClick(store._id)}>{`${store.name}${store.isTemplate ? ' (T)' : ''}`}</MenuItem>
        ))}
        <MenuItem
          key="none"
          onClick={() => navigate('/onboarding')}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add Store" />
        </MenuItem>
      </Menu>
      <Divider />
      <NavLink className="navLink" to={`/home?sid=${props.current._id}`}>
        <CustomListItemButton itemPath="/home">
          <ListItemIcon>
            <SvgIcon component={MenuHomeSvg} viewBox="-7 -5 36 36" />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </CustomListItemButton>
      </NavLink>
      {isWebxr
      && (
      <NavLink className="navLink" to={`/templates?sid=${props.current._id}`}>
        <CustomListItemButton itemPath="/templates">
          <ListItemIcon>
            <SvgIcon component={MenuTemplateSvg} viewBox="0 0 1200 1200" />
          </ListItemIcon>
          <ListItemText primary="Templates" />
        </CustomListItemButton>
      </NavLink>
      )}
      <NavLink className="navLink" to={`/store?sid=${props.current._id}`}>
        <CustomListItemButton itemPath="/store">
          <ListItemIcon>
            <SvgIcon component={MenuShoppingCartSvg} viewBox="0 0 24 24" />
          </ListItemIcon>
          <ListItemText primary="World" />
        </CustomListItemButton>
      </NavLink>

      <NavLink className="navLink" to={`/themes?sid=${props.current._id}`}>
        <CustomListItemButton itemPath="/themes">
          <ListItemIcon>
            <SvgIcon component={MenuPaintSvg} viewBox="-2 -1 24 24" />
          </ListItemIcon>
          <ListItemText primary="Themes" />
        </CustomListItemButton>
      </NavLink>
      <NavLink className="navLink" to={`/items?sid=${props.current._id}`}>
        <CustomListItemButton itemPath="/items">
          <ListItemIcon>
            <SvgIcon component={MenuItemsSvg} inheritViewBox />
          </ListItemIcon>
          <ListItemText primary="Items" />
        </CustomListItemButton>
      </NavLink>
      <NavLink
        style={{
          bottom: 0,
          position: 'absolute',
          width: '-webkit-fill-available',
          background: '#E9E9E9',
          borderTop: '1px solid #DEDEDE',
        }}
        to="/onboarding"
      >
        <CustomListItemButton
          itemPath=""
          sx={{
            flexGrow: 0,
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add Store" />
        </CustomListItemButton>
      </NavLink>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  current: makeSelectStoreCurrent(),
  storelist: makeSelectStoreList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

MainListItems.propTypes = {
  current: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    isTemplate: PropTypes.bool,
  }),
  storelist: PropTypes.arrayOf(PropTypes.shape({})),
};

export default compose(
  withConnect,
)(MainListItems);
