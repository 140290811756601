/* eslint-disable camelcase */
import {
  takeLatest, all, put, call, select,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  createUserSuccess,
  createUserFail,
} from 'services/user/actions';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import {
  getPersistedTokenSuccess,
  deleteAuthTokenSuccess,
  deleteAuthTokenFail,
  deauthenticate,
  createAuthTokenSuccess,
  setShowSnackbar,
} from './actions';
import { selectAuthAccessToken } from './selectors';
import {
  CREATE_AUTH_TOKEN_SUCCESS,
  DELETE_AUTH_TOKEN,
  DEAUTHENTICATE,
  LOGOUT,
  REGISTER_WITH_TEMPLATE,
} from './constants';

const api = API.create();

export function* restorePersistedToken(persistedState) {
  const access_token = _.get(persistedState, 'access_token');
  if (access_token) {
    yield put(getPersistedTokenSuccess(access_token));
  }
}

export function* restorePersistedState() {
  // let put it here, if the need for persistence layer increase,
  // move away from this simple approch
  let persistedState;
  try {
    const rawState = localStorage.getItem('double-dashboard/auth');
    persistedState = JSON.parse(rawState);
  } catch (err) {
    return; // cannot read the state, most likely there isn't presisted state
  }

  yield all([
    restorePersistedToken(persistedState),
  ]);
}

export function* deleteAuthToken(data) {
  const { id, callback } = data;
  const result = yield call(api.auth.logout, { id });

  if (result.ok) {
    yield put(deleteAuthTokenSuccess());
    yield put(deauthenticate({ isVoluntary: true }));
    callback();
  } else {
    yield put(deleteAuthTokenFail());
  }
}

export function* persistToken() {
  const access_token = yield select(selectAuthAccessToken());
  const serialized = JSON.stringify({ access_token });
  localStorage.setItem('double-dashboard/auth', serialized);
}

export function* registerWithTemplate(data) {
  const { callback } = data;
  const result = yield call(api.auth.registerWithTemplate, data.data);
  if (result.ok) {
    const { access_token, refresh_token } = result.data.data;
    yield put(createUserSuccess(result.data));
    yield put(createAuthTokenSuccess(access_token, refresh_token));
    callback();
  } else {
    yield put(createUserFail(result.data.error.message));
  }
}

export default function* authSaga() {
  yield restorePersistedState();

  yield all([
    takeLatest(CREATE_AUTH_TOKEN_SUCCESS, persistToken),
    takeLatest(DELETE_AUTH_TOKEN, deleteAuthToken),
    takeLatest(DEAUTHENTICATE, persistToken),
    takeLatest(LOGOUT, deleteAuthToken),
    takeLatest(REGISTER_WITH_TEMPLATE, registerWithTemplate),
  ]);
}
