import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21.9px',
  },
}));

export default StyledListItemText;
