import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import BootstrapInput from 'webComponents/Input/BootstrapInput';
import Stack from '@mui/material/Stack';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import _ from 'lodash';

function HomeTabPanel(props) {
  const {
    children, value, index, info, setInfo, isShowError, ...other
  } = props;

  const handleValueChange = (event) => {
    // eslint-disable-next-line no-shadow
    const { name, value } = event.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isShowError && <ErrorComponet objectKey="" error="* Missing Title" />}
      <Stack spacing={2}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel
            shrink
            htmlFor="title"
            sx={{
              fontSize: '18px',
              '&:focus, &.Mui-focused': { color: '#272626' },
              transform: 'scale(1)',
            }}
          >
            Title
          </InputLabel>
          <BootstrapInput
            id="title"
            name="title"
            sx={{
              '&.MuiInputBase-root': {
                marginTop: '30px',
              },
            }}
            inputProps={{ maxLength: 50 }}
            value={info.title}
            placeholder="Name of theme"
            onChange={handleValueChange}
          />
          {/* <FormHelperText>
            Limited to 50 characters:
            {info.title.length}
          </FormHelperText> */}
        </FormControl>
        <FormControl variant="standard" sx={{ width: '100%', marginTop: '30px!important' }}>
          <InputLabel
            shrink
            htmlFor="description"
            sx={{
              fontSize: '18px',
              '&:focus, &.Mui-focused': { color: '#272626' },
              transform: 'scale(1)',
            }}
          >
            Description (Optional)
          </InputLabel>
          <BootstrapInput
            id="description"
            name="description"
            sx={{
              '&.MuiInputBase-root': {
                marginTop: '30px',
              },
              '& .MuiInputBase-input': {
                backgroundColor: '#ffffff',
              },
            }}
            value={info.description}
            placeholder="Description of theme"
            onChange={handleValueChange}
            multiline
            rows={4}
          />
          <FormHelperText>
            {/* <ErrorComponet error={error} objectKey="title" /> */}
          </FormHelperText>
        </FormControl>
        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={0}
            label="type"
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Business</MenuItem>
            <MenuItem value={20}>Store</MenuItem>
            <MenuItem value={30}>Portfolio</MenuItem>
            <MenuItem value={40}>Blog</MenuItem>
            <MenuItem value={50}>Community</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Sub-type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={0}
            label="Sub-type"
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={10}>Office</MenuItem>
            <MenuItem value={20}>Mall</MenuItem>
          </Select>
        </FormControl> */}
      </Stack>
    </div>
  );
}

HomeTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  info: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  setInfo: PropTypes.func,
  isShowError: PropTypes.bool.isRequired,
};

export default HomeTabPanel;
