import React, { Suspense, useEffect, useState } from 'react';
import {
  Environment, OrbitControls, useGLTF, Html, useProgress, Center, Resize, Bounds,
  Box as XBox,
} from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';

import { PropTypes } from 'prop-types';
import { uuidv4 } from 'utils/utils';
import Model from 'webComponents/Model/Model';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function Loader() {
  const { progress } = useProgress();
  if (progress === 100) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <Html center>
      <CircularProgressWithLabel sx={{ color: '#9B51E0' }} value={progress} />
    </Html>
  );
}

export default function CanvasModel(props) {
  const { model, ratio } = props;
  const [isBoundObservable, setIsBoundObservable] = useState(true);
  const id = uuidv4();

  const interpolateFunc = (t) => 1 - Math.exp(-5 * t) + 0.007 * t; // Matches the default Bounds behavior
  const interpolateFunc1 = (t) => -t * t * t + 2 * t * t; // Start smoothly, finish linearly
  const interpolateFunc2 = (t) => -t * t * t + t * t + t; // Start linearly, finish smoothly

  useEffect(() => {
    // useLoader.preload(GLTFLoader, model);
    useGLTF.preload(model);
    setIsBoundObservable(false);
  }, [model]);

  return (
    <ErrorBoundary>
      <Canvas id={id} performance={{ min: 0.1 }} camera={{ fov: 20 }}>
        <Suspense fallback={null}>
          {/* <Environment preset="studio" blur={1} /> */}
          <directionalLight position={[0, 3.0, 0]} intensity={0.1} />
          <directionalLight position={[0, -3.0, 0]} intensity={0.1} />
          <directionalLight position={[3, 1.0, 3]} intensity={1.25} />
          <directionalLight position={[3, 1.0, -3]} intensity={1.25} />
          <directionalLight position={[-3, 1.0, 3]} intensity={1.25} />
          <directionalLight position={[-3, -1.0, -3]} intensity={1.25} />
          <ambientLight intensity={1} />
          <OrbitControls />
          <Bounds fit clip observe={isBoundObservable} margin={1.2} maxDuration={1} interpolateFunc={interpolateFunc}>
            <Center>
              <Model model={model} ratio={parseFloat(ratio)} />
            </Center>
          </Bounds>
        </Suspense>
        <Loader />
      </Canvas>
    </ErrorBoundary>
  );
}

CanvasModel.propTypes = {
  model: PropTypes.string,
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
