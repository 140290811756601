/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateStoreMap, updateStoreMapBase64Image, setShowLayoutSnackbar } from 'services/store/actions';
import { makeSelectStoreMap, makeSelectStoreLayoutSnackbar } from 'services/store/selectors';
import { Typography } from '@mui/material';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import CustomLayout from './CustomLayout';

function CustomLayoutContainer(props) {
  const { map, snackbar } = props;
  const [storeLayout, setStoreLayout] = useState({
    width: map.width,
    height: map.height,
    data: map.data,
  });

  const save = (width, height, data) => {
    props.updateStoreMap({
      id: map._id,
      data: {
        width,
        height,
        data,
      },
    });
  };

  useEffect(() => () => props.setShowLayoutSnackbar(false), []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="Custom your layout"></GeneralTitle>
      <GeneralContent>
        <Typography variant="h6" component="h6">Draw your line</Typography>
        <CustomLayout
          save={save}
          storeLayout={storeLayout}
          setStoreLayout={setStoreLayout}
        />
      </GeneralContent>
      <SimpleSnackbar
        severity="success"
        open={snackbar.isShow}
        title={snackbar.title}
        handleClose={() => props.setShowLayoutSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  map: makeSelectStoreMap(),
  snackbar: makeSelectStoreLayoutSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateStoreMap,
    updateStoreMapBase64Image,
    setShowLayoutSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

CustomLayoutContainer.propTypes = {
  map: PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  // UpdateTempStore: PropTypes.func.isRequired,
  updateStoreMap: PropTypes.func.isRequired,
  updateStoreMapBase64Image: PropTypes.func.isRequired,
  setShowLayoutSnackbar: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
    type: PropTypes.string,
  }),
};

export default compose(
  withConnect,
)(CustomLayoutContainer);
