import React from 'react';
import _ from 'lodash';
import { isIgnoreAsset } from 'utils/asset.utils';

// const isModel = (env) => _.isArray(env) || env.type !== 'image';
const isModel = (env) => (_.isArray(env) ? env[0].type !== 'image' : false);

export function VRButton() {
  return (
    <button
      type="button"
      className="button"
      onClick={() => { document.querySelector('.a-enter-vr-button').click(); }}
    >
      VR
    </button>
  );
}

export function EnvironmentComponent(environment) {
  return isModel(environment) ? (
    <a-entity
      id="env"
      position="0 0 0"
      gltf-model={`${environment[0].url}`}
      scale={`${environment[0].metadata.ratio} ${environment[0].metadata.ratio} ${environment[0].metadata.ratio}`}
    />
  )
    : (
      <a-sky material={`src: ${_.isString(environment) ? environment : environment[0].url}`}></a-sky>
    );
}

export function FloorComponent(floor) {
  return !isIgnoreAsset(floor)
    && <a-grid id="ground" src="#pexels_anni" material="repeat: 2 2" rotation="-90 0 0" shadow="receive: true;" />;
}

export function WallComponent(wall, map) {
  return !isIgnoreAsset(wall)
    && (
      <a-entity
        id="walls"
        init-walls={`
        ${map ? `map:${map[0].data}` : ''};
        wall: ${wall ? '#wall' : '#wall-texture'};
        wallColor: ${wall ? '#fff' : '#aa63ff'};
        width: ${map[0].width};
        height: ${map[0].height};
        `}
      />
    );
}
