/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import _ from 'lodash';
import {
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAIL,
  EDIT_THEME,
  EDIT_THEME_SUCCESS,
  EDIT_THEME_FAIL,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAIL,
  GET_THEME_LIST_BY_USERID,
  GET_THEME_LIST_BY_USERID_SUCCESS,
  GET_THEME_LIST_BY_USERID_FAIL,
  SET_THEME_SHOW_SNACKBAR_BAR,
  SET_THEME_SHOW_MODAL,
  USE_THEME_SUCCESS,
  MODAL_TYPE,
  REMOVE_THEME_ERROR,
} from './constants';

export const initialState = {
  themeList: [],
  error: null,
  loading: false,
  outletLoading: false,
  isShowModal: false,
  modalType: null,
  snackbar: {
    title: '',
    isShow: false,
    type: null,
  },
};

const themeReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CREATE_THEME:
    case EDIT_THEME:
      draft.loading = true;
      draft.error = null;
      break;
    case CREATE_THEME_SUCCESS: {
      const { isUseTheme } = action.data;
      draft.modalType = MODAL_TYPE.CREATE;
      draft.loading = false;
      // updater(action.data.data, draft);
      draft.error = null;
      if (isUseTheme) {
        draft.isShowModal = true;
      } else {
        draft.snackbar = {
          title: 'Successfully created',
          isShow: true,
          type: null,
        };
      }
      break;
    }
    case CREATE_THEME_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case USE_THEME_SUCCESS:
      draft.error = null;
      draft.modalType = MODAL_TYPE.USE_THEME;
      draft.isShowModal = true;
      break;
    case EDIT_THEME_SUCCESS: {
      const { isUseTheme } = action.data;
      draft.modalType = MODAL_TYPE.EDIT;
      const updatedTheme = action.data.data.items[0];
      const { _id } = updatedTheme;
      const index = _.findIndex(draft.themeList, { _id });
      const temp = _.cloneDeep(draft.themeList);
      // eslint-disable-next-line prefer-destructuring
      temp[index] = updatedTheme;
      draft.themeList = temp;
      draft.loading = false;
      draft.error = null;
      if (isUseTheme) {
        draft.isShowModal = true;
      } else {
        draft.snackbar = {
          title: 'Successfully updated',
          isShow: true,
          type: null,
        };
      }
      break;
    }
    case EDIT_THEME_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_THEME_LIST_BY_USERID:
      draft.loading = true;
      draft.outletLoading = true;
      draft.error = null;
      break;
    case GET_THEME_LIST_BY_USERID_SUCCESS:
      draft.themeList = _.orderBy(action.data.data.items, ['isDefault', 'false'], ['createdAt', 'desc']);
      draft.error = null;
      draft.loading = false;
      draft.outletLoading = false;
      break;
    case GET_THEME_LIST_BY_USERID_FAIL:
      draft.outletLoading = false;
      draft.loading = false;
      draft.error = action.error;
      break;
    case SET_THEME_SHOW_SNACKBAR_BAR: {
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = false;
      draft.snackbar = temp;
      break;
    }
    case SET_THEME_SHOW_MODAL:
      draft.isShowModal = action.bool;
      break;
    case DELETE_THEME:
      draft.loading = true;
      draft.error = null;
      break;
    case DELETE_THEME_SUCCESS: {
      draft.snackbar = {
        title: 'Successfully deleted',
        isShow: true,
        type: null,
      };
      const { _id } = action.data.data;
      const temp = _.cloneDeep(draft.themeList);
      _.remove(temp, { _id });
      draft.themeList = temp;
      draft.error = null;
      draft.loading = false;
      break;
    }
    case DELETE_THEME_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case REMOVE_THEME_ERROR:
      draft.error = null;
      break;
    default:
  }
});

export default themeReducer;
