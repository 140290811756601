// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MxfAgh__AamzfxVtFidB{width:100%;display:flex;flex-direction:column;max-width:1300px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/webComponents/General/GeneralContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".generalContainer__Inner {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  max-width: 1300px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalContainer__Inner": `MxfAgh__AamzfxVtFidB`
};
export default ___CSS_LOADER_EXPORT___;
