import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SimpleSnackbar from 'webComponents/Snackbar/SimpleSnackbar';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import { makeSelectStoreBackgroundMusic, makeSelectStoreId, makeSelectStoreBackgroundMusicSnackbar } from 'services/store/selectors';
import { setShowBackgroundMusicSnackbar } from 'services/store/actions';
import MusicPlayerSlider from './components/MusicPlayerSlider';
import MusicContainer from './components/MusicContainer';
import { DEFAULT_CREATE_BACKGROUND_MUSIC_DATA } from './constants';

function WorldMusic(props) {
  const { backgroundMusic, worldId, backgroundMusicSnackbar } = props;

  const getBackgroundMusic = () => {
    if (backgroundMusic.length > 0) {
      return backgroundMusic[0];
    }
    DEFAULT_CREATE_BACKGROUND_MUSIC_DATA.worldId = worldId;
    return DEFAULT_CREATE_BACKGROUND_MUSIC_DATA;
  };

  useEffect(() => {

  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle="World Music" isNeedBackButton>
      </GeneralTitle>
      <GeneralContent>
        <MusicContainer data={getBackgroundMusic()} worldId={worldId} />
      </GeneralContent>
      <SimpleSnackbar
        severity="success"
        open={backgroundMusicSnackbar.isShow}
        title={backgroundMusicSnackbar.title}
        handleClose={() => props.setShowBackgroundMusicSnackbar(false)}
      />
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  backgroundMusic: makeSelectStoreBackgroundMusic(),
  worldId: makeSelectStoreId(),
  backgroundMusicSnackbar: makeSelectStoreBackgroundMusicSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setShowBackgroundMusicSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

WorldMusic.propTypes = {
  backgroundMusic: PropTypes.arrayOf(PropTypes.shape({

  })),
  worldId: PropTypes.string,
  backgroundMusicSnackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
  setShowBackgroundMusicSnackbar: PropTypes.func,
};

export default compose(
  withConnect,
)(WorldMusic);
