import React, {
  useState, useEffect, useMemo,
} from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  useNavigate, useSearchParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import CustomInputLabel from 'webComponents/InputLabel/InputLabel';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { makeSelectItemError, makeSelectItemLoading, makeSelectItemList } from 'services/item/selectors';
import { addItem, editItem, deactivateItem } from 'services/item/actions';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import ErrorBoundary from 'webComponents/ErrorBoundary/ErrorBoundary';
import CanvasModel from 'webComponents/CanvasModel/CanvasModel';
import BootstrapInput from 'webComponents/Input/BootstrapInput';
import GeneralContainer from 'webComponents/General/GeneralContainer';
import GeneralTitle from 'webComponents/General/GeneralTitle';
import GeneralContent from 'webComponents/General/GeneralContent';
import FileUploadInput from 'webComponents/FileUploadInput/FileUploadInput';
import { InputLabel, Typography } from '@mui/material';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import { isImage, isSupported3DModal } from 'utils/asset.utils';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import NumericFormatRatio from 'webComponents/NumericFormatRatio/NumericFormatRatio';
import { ITEM_INDEX, DEFAULT_EDIT_JSON } from '../../items.constants';
import styles from './EditItem.module.scss';

const isWebxr = process.env.NODE_ENV !== 'production';

function EditItem(props) {
  const { error, loading, itemList } = props;
  const navigate = useNavigate();
  const [imageObjectUrl, setImageObjectURL] = useState(null);
  const [modelObjectUrl, setModelObjectURL] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateItem, setIsCreateItem] = useState(searchParams.get('new') || false);
  const [isUnknownFile, setIsUnknownFile] = useState(false);
  const [filename, setFilename] = useState('');
  const [item, setItem] = useState(DEFAULT_EDIT_JSON);

  const [callToActionButtonList, setActionButtonList] = useState([]);

  const handleActionButtonListChange = (index, key, value) => {
    const temp = _.cloneDeep(callToActionButtonList);
    temp[index][key] = value;
    setActionButtonList(temp);
  };

  const removeActionButton = (index) => {
    const temp = _.cloneDeep(callToActionButtonList);
    temp.splice(index, 1);
    setActionButtonList(temp);
  };

  const addActionButton = () => {
    const temp = _.cloneDeep(callToActionButtonList);
    temp.push({ title: '', url: '' });
    setActionButtonList(temp);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getFileType = (file) => {
    const { name, type } = file;
    if (isImage(type)) return 'image';
    if (isSupported3DModal(name)) return 'model';
    return 'unknown';
  };

  const resetInvalidFile = () => {
    setItem((prevState) => ({
      ...prevState,
      modelFile: null,
      imageFile: null,
    }));
    setIsUnknownFile(true);
    // setFilename('');
  };

  const handleAssetChange = (event) => {
    const [file] = event.target.files;

    if (!file) {
      resetInvalidFile();
      return;
    }

    const temp = _.cloneDeep(item.asset);
    const assetType = getFileType(file);
    setFilename(_.isUndefined(file.name) ? '' : file.name);
    if (assetType === 'image') {
      setImageObjectURL(URL.createObjectURL(file));
      temp[0].type = 'image';
      delete temp[0].metadata;
      setItem((prevState) => ({
        ...prevState,
        imageFile: file,
        asset: temp,
      }));
      setIsUnknownFile(false);
    } else if (assetType === 'model') {
      temp[0].type = 'model';
      temp[0].metadata = {
        model: 'glb',
        ratio: 1,
      };
      setModelObjectURL(URL.createObjectURL(file));
      setItem((prevState) => ({
        ...prevState,
        modelFile: file,
        asset: temp,
      }));
      setIsUnknownFile(false);
    } else { // unknown
      resetInvalidFile();
    }
  };

  const handleAssetRatioChange = (event) => {
    const { value } = event.target;
    console.log('ratio', value);
    if (value >= 0) {
      const temp = _.cloneDeep(item.asset);
      temp[0].metadata.ratio = parseFloat(value);
      setItem((prevState) => ({
        ...prevState,
        asset: temp,
      }));
    }
  };

  const handleCurrencyChange = (event) => {
    const { name, value } = event.target;
    const { priceList } = item;
    const index = _.findIndex(priceList, { currency: name });
    const temp = _.cloneDeep(priceList);
    // console.log(typeof value, value, value >= 0);
    if (value >= 0) {
      temp[index].amount = parseFloat(value, 10);
      setItem((prevState) => ({
        ...prevState,
        priceList: temp,
      }));
    }
  };

  const goToItemsPage = () => {
    navigate(`/items?sid=${item.storeId}`);
  };

  const goToPlaceItemPage = () => {
    navigate(`/items/place?sid=${item.storeId}`);
  };

  const isModal = () => item.asset[0].type === 'model';

  const generatePriceListComponent = useMemo(() => item.priceList.map((i) => (
    <NumericFormatRatio
      key={i.currency}
      id={i.currency}
      sx={{
        width: '125px',
      }}
      type="number"
      name={i.currency}
      value={i.amount}
      label={i.currency.toLocaleUpperCase()}
      // placeholder="0"
      inputProps={{ min: 0 }}
      variant="outlined"
      onChange={handleCurrencyChange}
    />
  )), [item]);

  const edit = () => {
    const temp = _.cloneDeep(item);
    if (callToActionButtonList.length > 0) {
      temp.actionList = callToActionButtonList;
      setItem(temp);
    } else {
      delete temp.actionList;
    }
    props.editItem(item._id, temp, goToItemsPage);
  };

  const add = () => {
    const temp = _.cloneDeep(item);
    if (callToActionButtonList.length > 0) {
      temp.actionList = callToActionButtonList;
      setItem(temp);
    }
    props.addItem(temp, goToPlaceItemPage);
  };

  useEffect(() => {
    const storeId = searchParams.get('sid');
    if (isCreateItem) {
      setItem((prevState) => ({
        ...prevState,
        storeId,
      }));
    } else {
      const itemIndex = searchParams.get(ITEM_INDEX);
      if (_.isEmpty(itemList[itemIndex])) goToItemsPage();
      const currentItem = itemList[itemIndex];
      setItem(currentItem);
      if (currentItem.actionList) setActionButtonList(currentItem.actionList);
    }
  }, []);

  return (
    <GeneralContainer>
      <GeneralTitle pageTitle={isCreateItem ? 'New Item' : 'Edit Item'} isNeedBackButton></GeneralTitle>
      <GeneralContent display="flex">
        <Box
          component="form"
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
          }}
        >
          <Box className={styles.mainSection}>
            {/* Title Field */}
            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '38px' }}>
              <CustomInputLabel shrink htmlFor="bootstrap-input" sx={{ top: '-3px' }}>Title</CustomInputLabel>
              <BootstrapInput
                name="title"
                value={item.title}
                inputProps={{ maxLength: 50 }}
                placeholder="Name of item"
                onChange={handleChange}
              />
              <FormHelperText>
                <ErrorComponet error={error} objectKey="title" />
              </FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={{ width: '100%', marginBottom: '38px' }}>
              <CustomInputLabel shrink htmlFor="bootstrap-input" sx={{ top: '-3px' }}>Description</CustomInputLabel>
              <BootstrapInput
                name="description"
                value={item.description}
                inputProps={{ maxLength: 180 }}
                placeholder="Description of item"
                onChange={handleChange}
              />
              <FormHelperText>
                <br />
                <ErrorComponet error={error} objectKey="description" />
              </FormHelperText>
            </FormControl>
            {isCreateItem
              ? (
                // {/* --------------- update load item Asset ----------------- */}
                <Box sx={{ marginBottom: '38px' }}>
                  <FileUploadInput callback={handleAssetChange} isValid={!isUnknownFile} filename={filename} />
                  {isModal() && (
                    <NumericFormatRatio
                      sx={{ mt: 2, width: '475px' }}
                      name="ratio"
                      value={item.asset[0].metadata.ratio}
                      label="Ratio"
                      type="number"
                      variant="outlined"
                      onChange={handleAssetRatioChange}
                      placeholder="ratio"
                    />
                  )}
                </Box>
              )
              : (
                <div className={styles.itemFilePriceContainer}>
                  <div>
                    <Typography variant="menuAndInput" component="div" sx={{ marginBottom: '8px !important' }}>Item file</Typography>
                    {isModal()
                      ? (
                        <>
                          <div style={{ height: '308px', backgroundColor: '#fde2e4', border: '1px solid #D9D9D9' }}>
                            {/* <ErrorBoundary> */}
                            <CanvasModel model={modelObjectUrl || item.asset[0].url} ratio={item.asset[0].metadata.ratio} />
                            {/* </ErrorBoundary> */}
                          </div>
                          <NumericFormatRatio
                            fullWidth
                            sx={{ mt: 2 }}
                            name="ratio"
                            type="number"
                            value={item.asset[0].metadata.ratio}
                            label="Ratio"
                            variant="outlined"
                            onChange={handleAssetRatioChange}
                            placeholder="ratio"
                          />
                        </>
                      )
                      : (
                        <Box
                          sx={{
                            width: '100%',
                            backgroundColor: '#D9D9D9',
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            component="img"
                            src={imageObjectUrl || item.asset[0].url}
                            sx={{
                              height: '293px',
                            }}
                            alt=""
                            crossOrigin="anonymous"
                          />
                        </Box>
                      )}
                    <div className={styles.itemFileLinkBox}>
                      <span style={{ color: '#5219F6', textDecoration: 'underline' }}>
                        <label htmlFor="file_uploads" style={{ cursor: 'pointer' }}>Replace file</label>
                        <input
                          type="file"
                          name="file_uploads"
                          id="file_uploads"
                          required="required"
                          hidden
                          onChange={handleAssetChange}
                        />
                      </span>
                      <span
                        style={{
                          textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer',
                        }}
                        onClick={() => {
                          goToPlaceItemPage();
                        }}
                        onKeyPress={() => {
                          goToPlaceItemPage();
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        <LocationOnIcon
                          fontSize="small"
                          sx={{
                            position: 'relative',
                            top: '5px',
                            marginRight: '5px',
                          }}
                        />
                        Place on map
                      </span>
                    </div>
                  </div>
                  <div>
                    <Typography variant="menuAndInput" component="div" sx={{ marginBottom: '12px !important' }}>Price</Typography>
                    <div className={styles.priceRow}>
                      {generatePriceListComponent}
                      <ErrorComponet error={error} objectKey="priceList" />
                    </div>
                  </div>
                </div>
              )}
            {isCreateItem && (
              <div className="price">
                <Typography variant="menuAndInput" component="div" sx={{ marginBottom: '12px !important' }}>
                  Price
                </Typography>
                <div className={styles.priceRow}>
                  {generatePriceListComponent}
                  <ErrorComponet error={error} objectKey="priceList" />
                </div>
              </div>
            )}
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Typography variant="menuAndInput" component="div">Action Button</Typography>
              {callToActionButtonList.map((button, index) => (
                <Box
                  sx={{
                    display: 'flex',
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`actionButtion-${index}`}
                >
                  <TextField id="demo-helper-text-misaligned-no-helper" label="Title" value={button.title} onChange={(event) => handleActionButtonListChange(index, 'title', event.target.value)} placeholder="Buy now" />
                  <TextField id="demo-helper-text-misaligned-no-helper" label="Url" value={button.url} sx={{ ml: 2, width: '50ch' }} onChange={(event) => handleActionButtonListChange(index, 'url', event.target.value)} placeholder="https://www.google.com" helperText="starts with https:// or http://" />
                  <IconButton size="small" sx={{ paddingBottom: '30px', background: 'none !important' }} aria-label="delete" onClick={() => removeActionButton(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <ErrorComponet error={error} objectKey="actionList" />
              {callToActionButtonList.length < 2
              && <Button variant="contained" startIcon={<AddIcon />} onClick={() => addActionButton()}>New Button</Button>}
            </Stack>

            <ErrorComponet error={error} objectKey="" />
            {/* ------------------ closing button ----------------------*/}
            <IconButton
              aria-label="close"
              sx={{ position: 'absolute', top: '-9px', right: '-3px' }}
              onClick={() => goToItemsPage()}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* ---------------------- bottom ---------------------------- */}
          <div className={styles.buttonGroup}>
            {/* delete button */}
            { !isCreateItem && (
              <LoadingButton
                size="large"
                loading={loading}
                variant="outlined"
                color="error"
                onClick={() => {
                  props.deactivateItem(item._id, goToItemsPage);
                }}
                sx={{
                  marginRight: '15px',
                }}
              >
                delete
              </LoadingButton>
            )}
            {/* save button */}
            <NormalButton
              text="Save"
              loading={loading}
              size="large"
              callback={() => {
                if (isCreateItem) {
                  add();
                } else {
                  edit();
                }
              }}
            />
          </div>
        </Box>
      </GeneralContent>
    </GeneralContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectItemError(),
  loading: makeSelectItemLoading(),
  itemList: makeSelectItemList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addItem,
    editItem,
    deactivateItem,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

EditItem.propTypes = {
  loading: PropTypes.bool.isRequired,
  deactivateItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  itemList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    priceList: PropTypes.arrayOf(PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    })),
    isActive: PropTypes.bool,
    actionList: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })),
  })),
};

export default compose(
  withConnect,
)(EditItem);
