/* eslint-disable */
const TELEPORT_INTENT = "TELEPORT_INTENT";
const PRODUCT_NAME = [
  "Goku Mosaic NFT", "Minecraft Diamond Sword", "Stone giant", "R2 D2"
];
const DEFAUTL_REQUEST = {
  message: {
    message_id: 'Wi-30a1886c-dfcd-4be2-9d87-16a48bdc466b',
    from: {
      id: 'Wi-5cf62265-b39f-4ee7-96cd-7b1fe88d23d7',
      username: 'Wi-5cf62265-b39f-4ee7-96cd-7b1fe88d23d7',
      language_code: 'en',
    },
    date: new Date().getTime(),
  },
};
AFRAME.registerComponent('chatbox', {
  schema: {
    URL: { default: "https://qa.delight.global/webhook/webwidget/4ea0ad21-4c42-41e4-a464-03d7b42ec7a8/" }, // "https://api.delight.global/webhook/webwidget/afd98514-56f2-4a90-9c1e-1adabb4e9e85/"
    grammarList: {default: '#JSGF V1.0; grammar triggerKeyword; public <triggerKeyword> = hey bot | hey robot | hey key maker ;'}, 
  },
  init: async function() {
    var el = this.el;
    // this.sendMessage("Hi");
    this.sendMessage = this.sendMessage.bind(this);
    // this.requestRecorder = this.requestRecorder.bind(this);
    this.startRecognition = this.startRecognition.bind(this);
    this._ = require('lodash');
    this.recognition = null;
    var requestRecorder = this.requestRecorder;
    
    try {
      this.startRecognition();
    } catch (err) {
      console.warn('Not support Web Speech API');
    }
    // this.speak(content.split("content:")[1]);
  },
  update: function() {

  },
  remove: function() {
  },
  sendMessage: async function(text) {
    try {
      var API = require('../../api');
      var api = API.default.create();
      var el = this.el;
      var speak = this.speak;
      var defaultMessageRequest = DEFAUTL_REQUEST;
      defaultMessageRequest.message['text'] = text;

      const result = await api.message.send({webhook: '4ea0ad21-4c42-41e4-a464-03d7b42ec7a8', data: defaultMessageRequest});
      console.log(result);
      if (result.ok) {
        const response = await fetch(`https://qa.delight.global${result.data.poll}`)
        if (response.ok) {
          const message = await response.json()
          console.log(message);
        }
      } else {
        return 
      }


    //   if (message.ok) {
    //     if (message.data) {
    //       var content = `content: ${message.data.text}`;
    //       if (message.data.actions) {
    //         content += "\n\nor\n\n";
    //         content += message.data.actions.map(x => "[ " + x.title + " ]").join("\n\n");
    //       }
    //       // speak(content.split("content:"));
    //       el.setAttribute("chatbox-info-panel", content);
    //     }
    //   }
    } catch (err) {
      // TODO: handle exception
    }
  },
  requestRecorder: async function() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { sampleRate: 44100, echoCancellation: true },
    });
    return new MediaRecorder(stream);
  },
  speak: function(text) {
    var synth = window.speechSynthesis;
    var utterThis = new SpeechSynthesisUtterance(text.replaceAll("\n",""));
    utterThis.pitch = 1;
    utterThis.rate = 0.9;
    setTimeout(() => {
      var voice = synth.getVoices()[49];
      utterThis.voice = voice;
      synth.speak(utterThis);
    }, 1000);
  },
  stopSpeak: function() {
    var synth = window.speechSynthesis;
    synth.cancel();
  },
  startRecognition: async function() {
    var el = this.el;
    var that = this;
    if (this.recognition) {
      this.recognition.start()
      return
    }

    this.recognition = new window.webkitSpeechRecognition();
    var speechRecognitionList = new window.webkitSpeechGrammarList();
    var sendMessage = await this.sendMessage;
    speechRecognitionList.addFromString(this.data.grammarList, 1);
    this.recognition.grammars = speechRecognitionList;
    this.recognition.lang = 'en-US';
    this.recognition.interimResults = false;
    this.recognition.maxAlternatives = 1;
    this.recognition.continuous = true;

    this.recognition.start()
    var that = this;
    this.recognition.onresult = function(event) {
      var result = Object.entries(event.results).reverse()[0][1][0].transcript.toLowerCase();
      console.log(result);
      var intent = that.defectIntent(result);
      switch (intent) {
        case TELEPORT_INTENT:
          var r2d2Position = document.getElementById("R2D2").getAttribute('position');
          var gokuPosition = document.getElementById("goku").getAttribute('position');
          var MinecraftPosition = document.getElementById("Minecraft").getAttribute('position');
          var stoneGiantPosition = document.getElementById("stone-giant").getAttribute('position');

          const re = result.replace('go to', '').split(' ');
          let productIndex = -1;
          re.map(x => {
            PRODUCT_NAME.map((y, index) => {
              let name = y.toLowerCase().split(' ');
              if (name.indexOf(x) >= 0) {
                return productIndex = index;
              }
            });
          });
          
          let userTeleportationPosition = {
            x: r2d2Position.x + 1,
            y: 0,
            z: r2d2Position.z + 0.5
          };

          switch(productIndex) {
            case 0:
              userTeleportationPosition = {
                x: gokuPosition.x + 1,
                y: 0,
                z: gokuPosition.z + 0.5
              };
              break;
            case 1:
              userTeleportationPosition = {
                x: MinecraftPosition.x + 1,
                y: 0,
                z: MinecraftPosition.z + 0.5
              };
              break;
            case 2:
              userTeleportationPosition = {
                x: stoneGiantPosition.x + 1,
                y: 0,
                z: stoneGiantPosition.z + 0.5
              };
              break;
            case 3:
              break;
            default:
          }

          if (productIndex > -1) {
            el.setAttribute("chatbox-info-panel", `content: Teleporting to ${PRODUCT_NAME[productIndex]} ...`);
            setTimeout(function() {
              document.getElementById("rig").setAttribute('position', userTeleportationPosition)
              el.setAttribute("chatbox-info-panel", "content: Done!");
            }, 2500);
          } else {
            el.setAttribute("chatbox-info-panel", "content: Nothing Matched!");
          }          
          break;
        default:
          sendMessage(result);
      }
    }

    this.recognition.onaudiostart = function() {
      // console.log('Audio capturing started');
    }

    this.recognition.onaudioend = function() {
      // console.log('Audio capturing End');
      that.recognition.abort();
      setTimeout(function(){ 
        that.recognition.start();
      }, 100);
    }
  },
  stopRecognition: function() {
    this.recognition.stop();
  },
  defectIntent: function(content) {
    const teleportIntent = ["teleport to", "go to"];
    var isTeleportIntent = teleportIntent.some(v => content.includes(v));
    if (isTeleportIntent) return TELEPORT_INTENT;
    return "";
  }
});