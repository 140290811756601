import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import SvgIcon from '@mui/material/SvgIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { setIsShowKeymakerChat } from 'services/keymaker/actions';
import { makeSelectKeymakerIsShow } from 'services/keymaker/selectors';
import ChatIcon from '@mui/icons-material/Chat';
import KeyboardIcon from '../../assets/Onboarding/icons/noun-keyboard.png';
import SwipeIcon from '../../assets/Onboarding/icons/noun-swipe.png';
import EscIcon from '../../assets/Onboarding/icons/esc.png';

import './InstructionLegend.scss';

const isWebxr = process.env.NODE_ENV !== 'production';

function InstructionLegend(props) {
  const {
    name, isShowKeymakerChat, isShowInstruction, setIsShowInstruction,
  } = props;

  return (
    isShowInstruction ? (
      <div
        className="instructionLegend"
        style={{
          zIndex: 1000,
          left: '56px',
          bottom: '75px',
          position: 'absolute',
          borderRadius: '4px',
          border: '1px solid rgba(255, 255, 255, 0.85)',
          background: 'rgba(0, 0, 0, 0.45)',
          padding: '36px 32px 37px 32px',
          color: 'white',
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            color: 'white',
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => { setIsShowInstruction(false); }}
        >
          <CancelIcon />
        </IconButton>
        {/* </div> */}
        <Typography variant="h6" component="h5" sx={{ marginBottom: '18px' }}>
          {`Welcome to ${name}`}
        </Typography>
        <div className="instructionLegend__itemRow">
          <div className="imgWrapper">
            <img
              src={KeyboardIcon}
              alt=""
              style={{
                width: '59.95px',
                height: '41.15px',
              }}
            />
          </div>
          <Typography variant="body2" component="p">
            MOVE with arrow keys
          </Typography>
        </div>
        <div className="instructionLegend__itemRow">
          <div className="imgWrapper">
            <img
              src={SwipeIcon}
              alt=""
              style={{
                width: '31.43px',
                height: '44.75px',
              }}
            />
          </div>
          <Typography variant="body2" component="p">
            PAN around by swiping
          </Typography>
        </div>
        <div className="instructionLegend__itemRow esc">
          <div className="imgWrapper">
            <img
              src={EscIcon}
              alt=""
              style={{
                width: '37px',
                height: '40px',
              }}
            />
          </div>
          <Typography variant="body2" component="p">
            EXIT world
          </Typography>
        </div>
        {isWebxr
        && (
        <div className="instructionLegend__itemRow">
          <Button
            variant="contained"
            sx={{
              fontWeight: 700,
              backgroundColor: 'white',
              color: '#4E2FEA',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            onClick={() => props.setIsShowKeymakerChat(!isShowKeymakerChat)}
          >
            Search
          </Button>
        </div>
        )}
        <Button
          variant="contained"
          sx={{
            fontWeight: 700,
            backgroundColor: 'white',
            color: '#4E2FEA',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          onClick={() => {
            setIsShowInstruction(false);
          }}
        >
          EXPLORE YOUR STORE
        </Button>
      </div>
    ) : (
      <div></div>
    )
  );
}

const mapStateToProps = createStructuredSelector({
  isShowKeymakerChat: makeSelectKeymakerIsShow(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIsShowKeymakerChat,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

InstructionLegend.propTypes = {
  name: PropTypes.string,
  setIsShowKeymakerChat: PropTypes.func,
  isShowInstruction: PropTypes.bool,
  isShowKeymakerChat: PropTypes.bool,
  setIsShowInstruction: PropTypes.func,
};

export default compose(
  withConnect,
)(InstructionLegend);
