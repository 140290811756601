/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useGLTF } from '@react-three/drei';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function Model(props) {
  const { model, ratio } = props;
  // const { scene } = useLoader(GLTFLoader, model);
  const { scene } = useGLTF(model);
  // const copiedScene = useMemo(() => scene.clone(), [scene]);
  try {
    return (
      <ErrorBoundary>
        <primitive
          object={scene}
          scale={ratio}
          position={[0, 0, 0]}
          dispose={null}
        />
      </ErrorBoundary>
    );
  } catch (err) {
    return <></>;
  }
}

Model.propTypes = {
  model: PropTypes.string,
  ratio: PropTypes.number,
};

export default Model;
