import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  createStoreByTemplate,
} from 'services/store/actions';
import OutlinedTextInput from 'webComponents/OutlinedTextInput/OutlinedTextInput';
import {
  Typography, InputAdornment, IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorComponet from 'webComponents/ErrorComponet/ErrorComponet';
import { isErrorOccurredIn } from 'utils/error';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { registerWithTemplate } from 'services/auth/actions';
import { makeSelectUserError } from 'services/user/selectors';
import { makeSelectStoreError } from 'services/store/selectors';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { saveData } from 'utils/sessionStoarge.utils';
import styles from '../../Templates.module.scss';

function TemplateCreateModal(props) {
  const {
    userId, templateId, handleClose, open, isLogined, error, storeError,
  } = props;
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('12345678');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const redirectToDashboard = () => {
    saveData('showHomeSnackbar', true);
    navigate('/home');
  };

  const handleCreate = () => {
    if (isLogined) {
      props.createStoreByTemplate({
        name,
        userIdList: [userId],
        templateId,
      });
    } else {
      props.registerWithTemplate({
        email,
        password,
        name,
        templateId,
      }, redirectToDashboard);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modalBody} sx={{ maxWidth: '480px !important' }}>
        <div className={styles.modalBoxWrapper}>
          <IconButton
            className={styles.modalCloseButton}
            aria-label="close"
            sx={{ alignSelf: 'flex-end' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create World
          </Typography>
          <Typography id="modal-modal-description">
            {isLogined ? 'Enter a world name to use this template.' : 'Create an account to use this template.'}
          </Typography>
          <Stack spacing={1}>
            <OutlinedTextInput
              sx={{ mt: 2 }}
              id="name"
              placeholder="World Name"
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              onChange={handleNameChange}
            />
            <ErrorComponet error={error || storeError} objectKey="name" />
            {!isLogined
            && (
            <>
              <OutlinedTextInput
                error={isErrorOccurredIn('email')}
                id="outlined-basic"
                variant="outlined"
                placeholder="Email"
                fullWidth
                onChange={handleEmailChange}
              />
              <ErrorComponet error={error} objectKey="email" />
              <OutlinedTextInput
                error={isErrorOccurredIn('password')}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                fullWidth
                onChange={handlePasswordChange}
                sx={{
                  paddingRight: '3px',
                  border: '0',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#979797',
                  },
                  '& .MuiOutlinedInput-input': {
                    borderColor: 'transparent',
                  },
                }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  )}
              />
              <ErrorComponet error={error} objectKey="password" />
            </>
            )}
            <NormalButton
              text="Create"
              sx={{
                marginTop: '30px !important',
                fontWeight: 700,
              }}
              callback={() => {
                handleCreate();
              }}
            />
          </Stack>
        </div>
      </Box>
    </Modal>
  );
}

TemplateCreateModal.propTypes = {
  userId: PropTypes.string,
  templateId: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  createStoreByTemplate: PropTypes.func,
  registerWithTemplate: PropTypes.func,
  isLogined: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  storeError: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

const mapStateToProps = createStructuredSelector({
  // snackbar: makeSelectThemeSnackbar(),
  error: makeSelectUserError(),
  storeError: makeSelectStoreError(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createStoreByTemplate,
    registerWithTemplate,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
)(TemplateCreateModal);
