import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import WhiteNormalButton from 'webComponents/NormalButton/WhiteNormalButton';
import NormalButton from 'webComponents/NormalButton/NormalButton';
import styles from '../../Templates.module.scss';

function TemplateDetailModal(props) {
  const {
    open, handleClose, templateList, activeTemplateId, isCurrentTheme, storeId, goToEditTheme,
    handleOpenCreateModel,
  } = props;

  return (
    <Modal
      className="ThemeModal"
      open={open}
      onClose={handleClose}
      aria-labelledby="theme-detail-modal-title"
      aria-describedby="theme-detail-modal-description"
    >
      <Box className={styles.modalBody}>
        <div className={styles.modalBoxWrapper}>
          <IconButton
            className={styles.modalCloseButton}
            aria-label="close"
            sx={{ alignSelf: 'flex-end' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.modalContentWrapper}>
            <img
              style={{
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '10px',
                width: 203,
                height: 204,
                backgroundColor: 'white',
                objectFit: 'cover',
              }}
              src={templateList[activeTemplateId].thumbnail || templateList[activeTemplateId].logo}
              crossOrigin="anonymous"
            />
            <div className={styles.modalText}>
              <Typography variant="h6" component="h6">
                {templateList[activeTemplateId].name}
              </Typography>
              <Typography
                id="theme-modal-title"
                variant="body1"
                component="p"
                sx={{
                  marginTop: 1,
                  overflow: 'scroll',
                  display: '-webkit-box',
                  height: '150px',
                }}
              >
                {templateList[activeTemplateId].description}
              </Typography>
            </div>
          </div>
          <Grid container>
            <Grid xs={4}>
              <Typography variant="h6" component="h6" />
            </Grid>
            <Grid xs={4}>
              <WhiteNormalButton
                text="Preview Template"
                callback={() => {
                  window.open(`${window.location.origin}/template/${templateList[activeTemplateId]._id}`);
                }}
              />
            </Grid>
            <Grid xs={4} sx={{ pl: 1 }}>
              <NormalButton
                fullWidth
                text="Use Template"
                callback={() => {
                  handleOpenCreateModel();
                  handleClose();
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TemplateDetailModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpenCreateModel: PropTypes.func,
  isCurrentTheme: PropTypes.func,
  goToEditTheme: PropTypes.func,
  activeTemplateId: PropTypes.number,
  storeId: PropTypes.string,
  templateList: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    thumbnail: PropTypes.string,
    isDefault: PropTypes.bool,
    theme: PropTypes.arrayOf(PropTypes.shape({
      environment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    })),
  })),
};

export default compose(
  withConnect,
)(TemplateDetailModal);
