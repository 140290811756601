import * as React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { isErrorOccurredIn } from 'utils/error';

export default function ErrorComponet(props) {
  const { error, objectKey } = props;

  const showErrorMessageByKey = () => {
    if (typeof error === 'string' && objectKey === '') {
      return <span style={{ color: 'red', marginBottom: '10px' }}>{error}</span>;
    }
    if (isErrorOccurredIn(error, objectKey)) {
      return (
        <>
          {_.values(error[objectKey]).map((v) => (
            <span key={v} style={{ color: 'red' }}>
              {v}
              <br />
            </span>
          ))}
        </>
      );
    }
    return '';
  };

  return showErrorMessageByKey(objectKey);
}

ErrorComponet.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  objectKey: PropTypes.string.isRequired,
};
