/* eslint-disable camelcase */
import {
  takeLatest, call, put, all, select, putResolve,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import { createAuthTokenSuccess, deauthenticate } from 'services/auth/actions';
import { getStoreList } from 'services/store/actions';
import { makeSelectUserId } from './selectors';
import {
  createUserSuccess,
  createUserFail,
  loginSuccess,
  loginFail,
  getProfileSuccess,
  getProfileFail,
  updateUserSuccess,
  updateUserFail,
} from './actions';

import * as userActions from './actions';

import {
  CREATE_USER,
  LOGIN,
  GET_PROFILE,
  UPDATE_USER,
  REGET_HOME_DATA,
} from './constants';

const api = API.create();

export function* createUser(data) {
  const { callback } = data;
  const result = yield call(api.auth.register, data.data);
  if (result.ok) {
    const { access_token, refresh_token } = result.data.data;
    yield put(createUserSuccess(result.data));
    yield put(createAuthTokenSuccess(access_token, refresh_token));
    callback();
  } else {
    yield put(createUserFail(result.data.error.message));
  }
}

export function* getProfile(data) {
  const { callback, storeId } = data;
  const result = yield call(api.user.profile, {});
  if (result.ok) {
    yield put(getProfileSuccess(result.data.data.items[0]));
    const { _id } = result.data.data.items[0];
    if (storeId) {
      yield put(getStoreList(_id, storeId));
    }
    callback(_id);
  } else {
    yield put(getProfileFail(result.data.error.message));
    yield put(deauthenticate({ isVoluntary: false }));
    window.location.replace('/login');
  }
}

export function* getDataFromScratch(data) {
  const { callback } = data;
  yield call(getProfile, { callback: () => {} });
  const userId = yield select(makeSelectUserId());
  if (userId) {
    yield put(getStoreList(userId, null, callback));
  }
}

export function* login(data) {
  const { callback } = data;
  const result = yield call(api.auth.login, data.data);
  if (result.ok) {
    const { access_token, refresh_token } = result.data.data;
    yield put(createAuthTokenSuccess(access_token, refresh_token));
    yield put(loginSuccess(result.data));
    yield put(userActions.getProfile(callback));
  } else {
    yield put(loginFail(result.data.error.message));
  }
}

export function* logout(data) {
  const result = yield call(api.auth.logout, data.data);
}

export function* updateUser(data) {
  const result = yield call(api.user.update, { userId: data.data._id, data: data.data });
  if (result.ok) {
    yield put(updateUserSuccess(result.data));
  } else {
    yield put(updateUserFail(result.data.error.message));
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(CREATE_USER, createUser),
    takeLatest(LOGIN, login),
    takeLatest(GET_PROFILE, getProfile),
    takeLatest(UPDATE_USER, updateUser),
    takeLatest(REGET_HOME_DATA, getDataFromScratch),
  ]);
}
