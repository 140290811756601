/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { getChannelInfo } from 'services/channel/actions';
import _ from 'lodash';
import { makeSelectChannelInfo, makeSelectChannelLoading, makeSelectChannelError } from 'services/channel/selectors';
import { AgoraMessageChat } from '../AgoraMessageChat';
import { AgoraVoiceChat } from '../AgoraVoiceChat';

export function AgoraContainer(props) {
  const {
    current, channelLoading, channelInfo, setIsShowChat, isShowChat,
  } = props;
  let user = sessionStorage.getItem('user');

  useEffect(() => {
    if (!user) {
      const id = (`${Math.random()}`).substring(2, 7);
      user = {
        id: `User${id}`,
      };
      sessionStorage.setItem('user', JSON.stringify(user));
    }
    props.getChannelInfo({ worldId: current._id, type: 'voice', userId: user.id }, () => {});
  }, []);

  return channelLoading ? (<div>{`${channelLoading}`}</div>) : (
    <>
      <AgoraMessageChat
        channelInfo={channelInfo}
        setIsShowChat={setIsShowChat}
        isShowChat={isShowChat}
      />
      <AgoraVoiceChat channelInfo={channelInfo} />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectStoreError(),
  // loading: makeSelectStoreLoading(),
  channelInfo: makeSelectChannelInfo(),
  channelError: makeSelectChannelError(),
  channelLoading: makeSelectChannelLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getChannelInfo,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

AgoraContainer.propTypes = {
  current: PropTypes.shape({
    _id: PropTypes.string,
  }),
  channelInfo: PropTypes.shape({}),
  channelLoading: PropTypes.bool,
  getChannelInfo: PropTypes.func,
  setIsShowChat: PropTypes.func,
  isShowChat: PropTypes.bool,
};

export default compose(
  withConnect,
)(AgoraContainer);
