import React from 'react';
import Container from '@mui/material/Container';

import { PropTypes } from 'prop-types';

import styles from './GeneralContainer.module.scss';

function GeneralContainer({ children }) {
  return (
    <Container
      className="generalContainer"
      maxWidth={false}
      sx={{
        backgroundColor: '#f1f1f1',
        minHeight: 'calc(100vh - 65px)',
        display: 'flex',
      }}
    >
      <div className={`generalContainer__Inner ${styles.generalContainer__Inner}`}>
        {children}
      </div>
    </Container>
  );
}

GeneralContainer.propTypes = {
  children: PropTypes.node,
};

export default GeneralContainer;
