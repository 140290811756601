import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectThemeDomain = (state) => state.theme || initialState;
export const makeSelectThemeList = () => createSelector(selectThemeDomain, _.property('themeList'));
export const makeSelectThemeLoading = () => createSelector(selectThemeDomain, _.property('loading'));
export const makeSelectThemeError = () => createSelector(selectThemeDomain, _.property('error'));
export const makeSelectThemeOutletLoading = () => createSelector(selectThemeDomain, _.property('outletLoading'));
export const makeSelectThemeIsShowSnackbar = () => createSelector(selectThemeDomain, _.property('isShowSnackbar'));
export const makeSelectThemeSnackbarTitle = () => createSelector(selectThemeDomain, _.property('snackbarTitle'));
export const makeSelectThemeIsShowModal = () => createSelector(selectThemeDomain, _.property('isShowModal'));
export const makeSelectThemeModalType = () => createSelector(selectThemeDomain, _.property('modalType'));
export const makeSelectThemeSnackbar = () => createSelector(selectThemeDomain, _.property('snackbar'));
